import React, { FunctionComponent, useEffect, ReactElement, useState, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { UncontrolledTooltip, TooltipProps } from 'reactstrap';
import styled from 'styled-components';

interface Props extends Partial<TooltipProps> {
    triggerBtn: ReactElement<any>;
}

const CustomTooltip: any = styled(UncontrolledTooltip)`
    .arrow::before {
        border-top-color: var(--gray) !important;
        border-bottom-color: var(--gray) !important;
    }
    .tooltip.show {
        opacity: 0.8;
    }
`;

const Tooltip: FunctionComponent<Props> = ({ triggerBtn, children, ...props }) => {
    const [uniqID, setUniqID] = useState('');
    let triggerBtnRef = useRef(null);

    useEffect(() => {
        setUniqID(uniqueId('tooltip'));
    }, []);

    if (!uniqID) {
        return null;
    }

    return (
        <>
            {React.cloneElement(triggerBtn, { id: uniqID, ref: (node: any) => triggerBtnRef = node })}
            {triggerBtnRef && children && (
                <CustomTooltip
                    innerClassName="bg-gray"
                    placement="top"
                    trigger="hover"
                    target={uniqID}
                    delay={{ show: 0, hide: 0 }}
                    {...props}
                >
                    {children}
                </CustomTooltip>
            )}
        </>
    );
};

export default Tooltip;