import React from 'react';
import { User } from "constants/api";
import { getUserSelector } from 'redux/apiData/apiDataSelectors';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';

interface StateProps {
    currentUser: User;
}

const withUser = <Props,>(WrappedComponent: React.ComponentType<any>): React.ComponentType<Props> => {
    const mapStateToProps = (state: ReduxState): StateProps => ({
        currentUser: getUserSelector(state)
    });

    return connect(mapStateToProps)(WrappedComponent);
};

export default withUser;
