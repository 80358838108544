import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { ApiResponse, User, ReactApiDataActions } from 'constants/api';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import ViewContainer from 'components/utils/ViewContainer';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import SyncButton from 'components/utils/SyncButton';
import { BreadcrumbItem, Breadcrumb } from 'reactstrap';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import PageLoading from 'components/utils/PageLoading';
import Card from 'reactstrap/lib/Card';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';
import { DetailsTable } from 'components/styled';
import BoolIcon from 'components/utils/BoolIcon';
import moment from 'moment';
import { getLinkToOrganisation } from 'utils/linksUtils';
import { Link } from 'react-router-dom';
import UserContractList from 'components/cards/UserContractList';
import UserEditModal from 'components/modals/UserEditModal';
import { Roles } from 'constants/literals';
import withUserRoles from 'hocs/withUserRoles';
import { hasSomeRole, isAdmin } from 'utils/userUtils';
import withUser from 'hocs/withUser';
import Button from 'reactstrap/lib/Button';
import LoginAsUserModal from 'components/modals/LoginAsUserModal';

type InProps = RouteComponentProps<{ userId: string }>;

interface EnhanceProps {
    userRequest: ApiDataBinding<ApiResponse<User>>;
    apiDataActions: ReactApiDataActions;
    userRoles: Roles[];
    currentUser: User;
}

type WrapperProps = InProps & EnhanceProps;

const enhance = compose<WrapperProps, InProps>(
    withApiData(
        {
            userRequest: 'getUser'
        },
        (ownProps: InProps) => ({
            userRequest: {
                userId: ownProps.match.params.userId
            }
        })
    ),
    withUserRoles,
    withUser
);

const UserDetailsWrapper: FunctionComponent<WrapperProps> = ({ currentUser, userRoles, userRequest, apiDataActions, match }) => {
    const { t } = useTranslation();

    const refetchUser = () => {
        userRequest.invalidateCache();
    };

    const refetchContracts = () => {
        apiDataActions.invalidateCache('getUserContracts', { userId: match.params.userId });
    };

    return (
        <RequestStatusRenderer
            request={userRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => (
                <UserDetails
                    user={userRequest.data!.data!}
                    refetchUser={refetchUser}
                    refetchContracts={refetchContracts}
                    userRoles={userRoles}
                    currentUser={currentUser}
                />
            )}
        />
    );
};

interface UserDetailsProps {
    user: User;
    userRoles: Roles[];
    currentUser: User;
    refetchUser: () => void;
    refetchContracts: () => void;
}

const UserDetails: FunctionComponent<UserDetailsProps> = ({ currentUser, user, userRoles, refetchUser, refetchContracts }) => {
    const { t } = useTranslation();

    const canEditUser = currentUser.uuid === user.uuid || hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.ixaas_client);

    return (
        <>
            <Helmet title={user.username} />
            <ViewContainer>
                <Breadcrumb data-test="breadcrumb">
                    <BreadcrumbItem>{t('usersList.pageTitle')}</BreadcrumbItem>
                    <BreadcrumbItem active>{user.username}</BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col lg={4}>
                        <Row>
                            <Col>
                                <h2>
                                    {`${t('userDetails.pageTitle')} ${user.username}`}
                                    <SyncButton
                                        className="ml-2"
                                        title={t('userDetails.refreshUser')}
                                        onClick={refetchUser}
                                    />
                                </h2>
                            </Col>
                        </Row>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('common.details')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('common.userName')}</th>
                                            <td>{user.username}</td>

                                            {canEditUser && (
                                                <UserEditModal user={user} />
                                            )}
                                        </tr>
                                        <tr>
                                            <th>{t('common.name')}</th>
                                            <td>{`${user.first_name} ${user.last_name}`}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('common.email')}</th>
                                            <td>{user.email}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('common.role')}</th>
                                            <td>{user.roles.map((role) => t(`rolesMap.${role}`)).join(', ')}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('userDetails.lastLoggedIn')}</th>
                                            <td>{moment(user.last_logged_in).format('YYYY-MM-DD HH:mm')}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('common.active')}</th>
                                            <td>
                                                <BoolIcon value={user.active} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('userDetails.mfaEnabled')}</th>
                                            <td>
                                                <BoolIcon value={user.mfa_enabled} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('common.organisation')}</th>
                                            <td><Link to={getLinkToOrganisation(user.organisation_uuid)}>{user.organisation_name}</Link></td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>

                                {isAdmin(currentUser.roles) && (
                                    <LoginAsUserModal user={user} />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <h2>
                                    {t('userDetails.contracts')}
                                    <SyncButton
                                        className="ml-2"
                                        title={t('userDetails.refreshContracts')}
                                        onClick={refetchContracts}
                                    />
                                </h2>
                            </Col>
                        </Row>
                        <hr />
                        <UserContractList userId={user.uuid} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default enhance(UserDetailsWrapper);
