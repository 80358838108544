import React from 'react';
import { connect } from 'react-redux';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import get from 'lodash/get';

// constants
import { Account } from 'constants/api';
import { ReduxState } from 'createStore';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';

// components
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import Tooltip from 'components/utils/Tooltip';

interface InputProps {
    uuid: string;
    contractUuid: string;
    account: Account;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'deleteAccount', {
        accountUuid: get(ownProps, 'account.uuid'),
        contractUuid: get(ownProps, 'contractUuid')
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            dispatch(performApiRequest('deleteAccount', {
                accountUuid: get(ownProps, 'account.uuid'),
                contractUuid: get(ownProps, 'contractUuid')
            }));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class AccountDeleteModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { request, t } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            errorMessage: `${t('notification.deleteAccountFailed')}: `,
            successMessage: t('notification.deleteAccountSuccessful'),
            pathToError: 'errorBody.errors'
        });
    }

    render(): any {
        const { request, onSubmit, account, t } = this.props;
        const username = get(account.user, 'email');

        return (
            <React.Fragment>
                <BaseModalTemplate
                    data-test="user-delete-modal-tmpl"
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('deleteAccountModal.headerTitle')}
                    body={
                        <Trans i18nKey="deleteAccountModal.bodyMessage">
                            <strong>{{ username }}</strong>
                        </Trans>
                    }
                    footer={
                        <FormModalButtons
                            data-test="user-delete-modal-buttons"
                            loading={get(request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />

                <Tooltip
                    data-test="user-delete-btn"
                    triggerBtn={<i className="fas fa-trash fa-pull-right mt-1 cursor-pointer text-primary" onClick={this.toggle} />}
                >
                    {t('deleteAccountModal.headerTitle')}
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default enhance(AccountDeleteModal);
