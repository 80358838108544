import React, { FunctionComponent } from 'react';
// import { keyframes } from 'styled-components';
import classNames from 'classnames';

import Tooltip from './Tooltip';

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// `;

interface Props {
    title?: string | undefined;
    className?: string;
    onClick?: () => any;
}

const SyncButton: FunctionComponent<Props> = ({ title, className, onClick }) => (
    <>
        <Tooltip
            triggerBtn={
                <i
                    className={classNames([
                        `fas fa-sync-alt font-sm text-primary align-middle cursor-pointer`,
                        (!!className && className)
                    ])}
                    onClick={onClick && onClick}
                />
            }
        >
            {title}
        </Tooltip>
    </>
);

export default SyncButton;