import React, { FunctionComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import Alert from 'reactstrap/lib/Alert';
import { useTranslation, Trans } from 'react-i18next';
import { User, AuthenticationToken } from 'constants/api';
import { getUserSelector } from 'redux/apiData/apiDataSelectors';
import { updateAppPathAction } from 'redux/app/appActions';
import { Button } from 'reactstrap';
import { purgeApiData, performApiRequest } from 'react-api-data';

interface Props {
    stopImpersonation: (currentToken: AuthenticationToken, originalToken: AuthenticationToken) => void;
    authenticationToken: AuthenticationToken;
    impersonatorToken: AuthenticationToken;
    user: User;
}

const enhance = compose<Props, {}>(
    connect(
        (state: ReduxState) => ({
            authenticationToken: state.app.authenticationToken,
            impersonatorToken: state.app.impersonatorToken,
            user: getUserSelector(state),
        }),
        (dispatch: any) => ({
            stopImpersonation: (currentToken: AuthenticationToken, originalToken: AuthenticationToken) => {
                dispatch(performApiRequest('revokeToken', {}, { token: currentToken.access_token }));

                // clear all cached data first
                dispatch(updateAppPathAction('authenticationToken', undefined));
                dispatch(updateAppPathAction('impersonatorToken', undefined));
                dispatch(purgeApiData());

                dispatch(updateAppPathAction('authenticationToken', originalToken));
                window.location.reload();
            }
        })
    )
);

const LoggedInAsUserBanner: FunctionComponent<Props> = ({ user, stopImpersonation, authenticationToken, impersonatorToken }) => {
    const { t } = useTranslation();

    const onStopImpersonation = () => stopImpersonation(authenticationToken, impersonatorToken);

    if (impersonatorToken) {
        return (
            <Alert color="danger" className="mb-0 d-flex align-items-center" style={{ whiteSpace: 'pre' }}>
                <>
                    <Trans i18nKey="loggedInAsUserBanner.message">
                        <strong>{{ user: user.username }}</strong>
                    </Trans>
                    &nbsp;
                    <Button color="link" className="m-0 p-0 text-dark" onClick={onStopImpersonation}><strong>{t('loggedInAsUserBanner.logoutMessage')}</strong></Button>
                </>
            </Alert>
        );
    }

    return (<></>);
};

export default enhance(LoggedInAsUserBanner);
