import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ApiDataBinding, withApiData, invalidateApiDataRequest, performApiRequest, ApiDataState } from 'react-api-data';
import { ApiResponse, IpRange } from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { ReduxState } from 'createStore';
import BoolIcon from 'components/utils/BoolIcon';
import IpRangeEditModal from 'components/modals/IpRangeEditModal';
import { Link } from 'react-router-dom';
import { getLinkToVlan } from 'utils/linksUtils';
import { IpRangeType } from 'constants/literals';
import IpsList from 'components/cards/IpsList';

interface IpRangeDetailsProps {
    ipRangeComp: IpRange;
    apiData: ApiDataState;
    refetchIpRange: () => undefined;
}

function IpRangeDetails(props: IpRangeDetailsProps) {
    const { ipRangeComp, refetchIpRange } = props;
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={ipRangeComp.value} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('ipRangeDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('ipRangeDetails.refreshButton')}
                                onClick={refetchIpRange}
                            />
                            <IpRangeEditModal ipRange={ipRangeComp}/>
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('ipRangeDetails.pageTitle')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('ipRangeDetails.value')}</th>
                                            <td>{ipRangeComp.value}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('ipRangeDetails.subnetMask')}</th>
                                            <td>{ipRangeComp.subnet_mask}</td>
                                        </tr>
                                        {ipRangeComp.type === IpRangeType.Ipv4Range && (
                                            <tr>
                                                <th>{t('ipRangeDetails.freeIpCount')}</th>
                                                <td>{ipRangeComp.free_ip_count}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>{t('ipRangeDetails.type')}</th>
                                            <td>{ipRangeComp.type}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('ipRangeDetails.description')}</th>
                                            <td>{ipRangeComp.description}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('ipRangeDetails.vlan')}</th>
                                            <td><Link to={getLinkToVlan(ipRangeComp.exchange.short_name, ipRangeComp.vlan.uuid)}>{ipRangeComp.vlan.number}</Link></td>
                                        </tr>
                                        <tr>
                                            <th>{t('ipRangeDetails.public')}</th>
                                            <td>
                                                <BoolIcon value={ipRangeComp.public} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                        <hr />
                        <IpsList ipRange={ipRangeComp} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type IpRangeProps = RouteComponentProps<{ id: string }>;

interface ApiProps {
    ipRangeRequest: ApiDataBinding<ApiResponse<IpRange>>;
    apiData: ApiDataState;
    searchTerm: string;
    updateSearchTerm: (searchTerm?: string) => void;
    refetchIpRange: () => undefined;
}

type WrapperProps = ApiProps & IpRangeProps;

function IpRangeDetailsWrapper(props: WrapperProps) {
    const { ipRangeRequest, apiData, refetchIpRange } = props;
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={ipRangeRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => (
                <IpRangeDetails
                    ipRangeComp={ipRangeRequest.data!.data!}
                    apiData={apiData}
                    refetchIpRange={refetchIpRange}
                />
            )}
        />
    );
}

const connectApiData = withApiData({ ipRangeRequest: 'getIpRange' }, (ownProps: IpRangeProps) => ({
    ipRangeRequest: {
        id: ownProps.match.params.id
    },
}));

const mapStateToProps = (state: ReduxState) => ({
    apiData: state.apiData,
});

const mapDispatchToProps = (dispatch: any, ownProps: WrapperProps) => ({
    refetchIpRange: () => {
        const params = { id: ownProps.match.params.id };
        dispatch(invalidateApiDataRequest('getIpRange', params));
        dispatch(invalidateApiDataRequest('getIps', params));
        dispatch(performApiRequest('getIpRange', params));
        dispatch(performApiRequest('getIps', params));
    },
});

const enhance = compose<WrapperProps, IpRangeProps>(connectApiData, connect(mapStateToProps, mapDispatchToProps));

export default enhance(IpRangeDetailsWrapper);


