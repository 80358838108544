import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { ApiDataRequest, performApiRequest, getApiDataRequest, EndpointParams } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull, Vlan, SwappableLinkVlan } from 'constants/api';
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import RolesChecker from 'components/utils/RolesChecker';

interface InputProps {
    link: LinkFull;
    vlan: Vlan;
    swappableLinkVlans: SwappableLinkVlan[];
    endpointKey: string;
    endpointParams: EndpointParams;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps & RouteComponentProps<{
    exchange: string;
}>;

const mapStateToProps = (state: ReduxState, ownProps: Props) => {
    return {
        request: getApiDataRequest(state.apiData, ownProps.endpointKey, ownProps.endpointParams),
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest(ownProps.endpointKey, ownProps.endpointParams, values));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class SwapVlanIpsModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            pathToError: 'response.swapIps.0',
            successMessage: this.props.t('notification.swapIpsSuccessful'),
        });
    }

    render() {
        const { onSubmit, t } = this.props;

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ receiver_link_vlan_uuid: this.props.vlan.uuid }}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            header={t('swapVlanIpsModal.headerTitle')}
                            handleSubmit={handleSubmit}
                            onClosed={this.handleModalClose}
                            body={
                                <FormGroup row className="mb-0">
                                    <Col md={3}>
                                        <Label htmlFor="donor_link_vlan_uuid">{t('swapVlanIpsModal.currentLinkId')}</Label>
                                        <p>{this.props.link.xml_id}</p>
                                    </Col>
                                    <Col md={9}>
                                    <FormControl
                                            component="select"
                                            name="donor_link_vlan_uuid"
                                            errors={errors}
                                            touched={touched}
                                            defaultValue=""
                                    >
                                        <option disabled value="">Select link to swap with</option>
                                        {this.props.swappableLinkVlans.map((item) => (
                                            <option value={item.donor_uuid} key={item.donor_uuid}>
                                                {`${item.donor_link} -> vlan: ${item.vlan_description} -> qtag: ${item.qtag}`}
                                            </option>
                                        ))}
                                    </FormControl>
                                    </Col>
                                </FormGroup>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    disabled={!dirty}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.qnoc]}>
                    <span data-test="edit-btn-renderer" className="fas fa-edit fa-pull-right mt-1 cursor-pointer text-primary"
                          onClick={this.toggle} title={t('swapVlanIpsModal.headerTitle')}>                    
                    </span>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(SwapVlanIpsModal);