import React, { FunctionComponent, useState, ReactNode } from 'react';
import {
    Nav, NavLink, NavItem, TabContent, TabPane, Button
} from 'reactstrap';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import find from 'lodash/find';

import { LinkFull } from 'constants/api';

import BaseModal from './BaseModal';
import { BaseModalLarge } from 'components/styled';
import AlertMessage from 'components/utils/AlertMessage';
import ViewContainer from 'components/utils/ViewContainer';

// tabs
import PortInformation from 'components/cards/PortInformation';
import PortConfig from 'components/cards/PortConfig';
import PortPeering from 'components/cards/PortPeering';
import LinkFlapInformation from 'components/cards/LinkFlapInformation';
import L2Violations from 'components/cards/L2Violations';
import OpticsInformation from 'components/cards/OpticsInformation';
import UnblockBGPSession from 'components/cards/UnblockBGPSession';
import BgpLogs from 'components/cards/BgpLogs';
import BgpSessionPrefixLimit from 'components/cards/BgpSessionPrefixLimit';

interface InputProps {
    link: LinkFull;
    renderModalToggleBtn?: (props: { onClick: () => void }) => ReactNode;
}

type Props = InputProps & WithTranslation;

interface State {
    isOpen: boolean;
    activeTab: string;
}

interface QuickdebugTab {
    name: string;
    translateKey: string;
    tabComponent?: any;
}

interface QuickdebugBodyType {
    t: any;
    memberLink: LinkFull;
}

const enhance = compose<Props, InputProps>(
    withTranslation(),
);

class QuickdebugModal extends BaseModal<Props, State> {

    render() {
        const { link, t, renderModalToggleBtn } = this.props;
        return (
            <>
                <BaseModalLarge
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('quickdebug.pageTitle')}
                    body={
                        <QuickdebugBody
                            memberLink={link}
                            t={t}
                        />
                    }
                    footer={
                        <Button color="light" onClick={this.toggle} >{t('modal.closeBtn')}</Button>
                    }
                />
                {renderModalToggleBtn ? (
                    renderModalToggleBtn({ onClick: this.toggle })
                ) :
                    <i className="fas fa-bug cursor-pointer text-primary" onClick={this.toggle} title={t('connection.viewQuickDebug')} />
                }
            </>
        );
    }
}

const InfoTab = withTranslation()(({ t }) => (
    <div>{t('quickdebug.defaultTab')}</div>
));

const NotImplementedTab: FunctionComponent<{ t: any }> = ({ t }) => (
    <AlertMessage className="mb-0" message={t('common.notImplementedYet')} />
);

const quickdebugTabs: QuickdebugTab[] = [
    {
        name: 'info',
        translateKey: 'quickdebug.info',
        tabComponent: InfoTab,
    },
    {
        name: 'portInformation',
        translateKey: 'quickdebug.portInfo',
        tabComponent: PortInformation,
    },
    {
        name: 'portConfig',
        translateKey: 'quickdebug.configInfo',
        tabComponent: PortConfig,
    },
    {
        name: 'portPeering',
        translateKey: 'quickdebug.portPeering',
        tabComponent: PortPeering,
    },
    {
        name: 'bgpLogs',
        translateKey: 'quickdebug.bgpLogs',
        tabComponent: BgpLogs,
    },
    {
        name: 'linkFlapInfo',
        translateKey: 'quickdebug.linkFlapInfo',
        tabComponent: LinkFlapInformation
    },
    {
        name: 'l2Violations',
        translateKey: 'quickdebug.l2Violations',
        tabComponent: L2Violations,
    },
    {
        name: 'optics',
        translateKey: 'quickdebug.optics',
        tabComponent: OpticsInformation,
    },
    {
        name: 'bgpSessions',
        translateKey: 'quickdebug.bgpSessions',
        tabComponent: UnblockBGPSession,
    },
    {
        name: 'bgpSessionsPrefixLimit',
        translateKey: 'quickdebug.bgpSessionsPrefixLimit',
        tabComponent: BgpSessionPrefixLimit,
    },

];

const QuickdebugBody: FunctionComponent<QuickdebugBodyType> = ({ t, memberLink }) => {
    const [activeTab, setActiveTab] = useState('info');
    let renderedTab;
    
    const tabData = find(quickdebugTabs, ['name', activeTab]);
    if (tabData && tabData.tabComponent) {
        const TabComponent = tabData.tabComponent;
        renderedTab = <TabComponent link={memberLink} />;
    } else {
        renderedTab = <NotImplementedTab t={t} />;
    }

    return (
        <ViewContainer>
            <Nav tabs>
                {quickdebugTabs.map((tab: QuickdebugTab) => (
                    <NavItem key={tab.name}>
                        <NavLink
                            className={activeTab === tab.name ? 'active' : ''}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {t(tab.translateKey)}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent>
                <TabPane className="active">{renderedTab}</TabPane>
            </TabContent>
        </ViewContainer>
    );
};

export default enhance(QuickdebugModal);
