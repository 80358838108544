import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import { Field, FormikErrors, FormikTouched, FormikValues, FieldProps } from 'formik';
import get from 'lodash/get';
import classnames from 'classnames';
import FormControlError from './FormControlError';

interface FormControlProps extends InputHTMLAttributes<any> {
    type?: string;
    name: string;
    component?: string | React.ComponentType<FieldProps>;
    disabled?: boolean;
    className?: string;
    showErrorHint?: boolean;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
}

const FormControl: FunctionComponent<FormControlProps> = ({
    id, name, type = 'text', disabled, touched, errors, component, showErrorHint = true, className = '', children, ...props
}) => (
    <>
        <Field
            type={type}
            name={name}
            disabled={disabled}
            component={component}
            className={classnames({
                'form-control': true,
                'is-invalid': get(errors, name) && get(touched, name),
                [className]: !!className
            })}
            autoComplete="off"
            {...props}
        >
            {children}
        </Field>
        {showErrorHint && (
            <FormControlError
                controlName={name}
                errors={errors}
                touched={touched}
            />
        )}
    </>
);

export default FormControl;