import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import {
    Col, FormGroup, Label
} from 'reactstrap';
import {
    ApiDataRequest, performApiRequest, getApiDataRequest
} from 'react-api-data';
import { Formik } from 'formik';
import { string, object } from 'yup';
import get from 'lodash/get';
import find from 'lodash/find';
import size from 'lodash/size';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Vlan, LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

// utils
import { refetchMemberLink } from 'utils/linksUtils';

// selectors
import { getLinksByStatusSelector, getLinksWithoutVlanIdSelector } from 'redux/apiData/apiDataSelectors';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';

interface InProps {
    exchange: string;
    vlanLabel?: string;
    vlan: Vlan;
    renderCloneButton?: (props: { onClick: () => void }) => ReactNode;
}

interface StateProps {
    linkOptions: LinkFull[] | undefined;
    submission: ApiDataRequest | undefined;
}

interface DispatchProps {
    onSubmit: (values: { destination_link_uuid: string }) => boolean;
    refetchMemberLink: (selectedLink: LinkFull) => void;
}

type Props = InProps & BaseFormModalProps & StateProps & DispatchProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: InProps): StateProps => ({
    linkOptions: getLinksWithoutVlanIdSelector(
        ownProps.vlan.vlan_id,
        getLinksByStatusSelector('configured', get(state, 'apiData.entities.link'))
    ),
    submission: getApiDataRequest(state.apiData, 'postLinkVlanClone', {
        vlanId: ownProps.vlan.uuid,
        exchange: ownProps.exchange,
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps): DispatchProps => {
    return {
        onSubmit: ({ destination_link_uuid }) => {
            dispatch(performApiRequest(
                'postLinkVlanClone',
                {
                    vlanId: ownProps.vlan.uuid,
                    exchange: ownProps.exchange,
                },
                { destination_link_uuid }
            ));

            return true;
        },
        refetchMemberLink: (link: LinkFull) => {
            refetchMemberLink(dispatch, {
                exchange: get(link, 'exchange.short_name'),
                linkId: get(link, 'uuid'),
            });
        }
    };
};

class LinkVlanCloneModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { submission, t } = this.props;
        if (prevProps.submission && submission) {
            this.handleFormSubmission(prevProps.submission, submission, {
                successMessage: t('notification.cloneVlanSuccessful')
            });
        }
    }

    render() {
        const { linkOptions, vlanLabel, t } = this.props;
        return (
            <>
                <Formik
                    data-test="form"
                    ref={formik => this.formik = formik}
                    initialValues={{
                        destination_link_uuid: '',
                    }}
                    validationSchema={object().shape({
                        destination_link_uuid: string().required(t('validation.linkNotSelected'))
                    })}
                    onSubmit={this.props.onSubmit}
                >
                    {({ handleSubmit, isSubmitting, errors, touched }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('cloneVlan.headerTitle', { vlanLabel: (vlanLabel ? vlanLabel : '') })}
                            body={<>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="destination_link_uuid">{t('common.destinationLink')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            component="select"
                                            name="destination_link_uuid"
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option disabled value={''}>{t('common.selectLink')}</option>
                                            {linkOptions && linkOptions.map((linkItem: LinkFull) => (
                                                <option
                                                    key={linkItem.xml_id}
                                                    value={linkItem.uuid}
                                                >
                                                    {linkItem.xml_id}
                                                </option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                </Formik>
                {size(linkOptions) > 0 && process.env.REACT_APP_SHOW_CLONE_VLAN === 'true' && (
                    this.props.renderCloneButton ?
                    this.props.renderCloneButton({ onClick: this.toggle }) :
                    <Tooltip
                        triggerBtn={
                            <i
                                className="fas fa-copy ml-2 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('cloneVlan.cloneVlanBtn')}
                    </Tooltip>
                )}
            </>
        );
    }

    protected afterSuccessCallback = () => {
        const destinationLink = find(this.props.linkOptions, ['uuid', get(this.formik, 'state.values.destination_link_uuid')]);
        if (destinationLink) {
            this.props.refetchMemberLink(destinationLink);
        }
    }
}

const VlanCloneModalContainer = compose<Props, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
)(LinkVlanCloneModal);

export default VlanCloneModalContainer;