import React from 'react';
import { Link } from 'react-router-dom';
import memoizeOne from 'memoize-one';

// utils
import { getLinkToConnection, getLinkToRouteServer, getLinkToVlan} from 'utils/linksUtils';
import i18next from 'locales/i18next';

import { RouteServer} from 'constants/api';
import RouteServerDeleteModal from 'components/modals/RouteServerDeleteModal';

export const getRouteServersTableColumns = memoizeOne((): any => {
    return [{
        dataField: 'name',
        text: i18next.t('routeServers.id'),
        sort: true,
    }, {
        dataField: 'description',
        text: i18next.t('routeServers.description'),
        sort: true,
    }, {
        dataField: 'ipv4_address',
        isDummyField: true,
        text: i18next.t('routeServers.Ipv4'),
        formatter: (cell: any, row: RouteServer) => (
            <Link to={getLinkToConnection(row.exchange, row.link_ipv4_uuid)}>{row.peering_ipv4}</Link>
        )
    },  {
        dataField: 'ipv6_address',
        isDummyField: true,
        text: i18next.t('routeServers.Ipv6'),
        formatter: (cell: any, row: RouteServer) => (
            <Link to={getLinkToConnection(row.exchange, row.link_ipv6_uuid)}>{row.peering_ipv6}</Link>
        )
    }, {
        dataField: 'as_number',
        text: i18next.t('routeServers.asnNumber'),
        sort: true,
    }, {
        dataField: 'vlan_number',
        text: i18next.t('routeServers.vlanNumber'),
        formatter: (cell: any, row: RouteServer) => (
            !row.vlan.number ? null : <Link to={getLinkToVlan(row.exchange, row.vlan.uuid)}>{row.vlan.number}</Link>
        )
    }, {
        dataField: 'link',
        isDummyField: true,
        text: i18next.t('routeServers.link'),
        formatter: (cell: any, row: RouteServer) => (
            <Link to={getLinkToRouteServer(row.exchange, row.uuid)}>{i18next.t('common.view')}</Link>
        )
    }, {
        dataField: 'remove',
        isDummyField: true,
        text: i18next.t('routeServers.remove'),
        formatter: (cell: any, row: RouteServer) => {
            return <RouteServerDeleteModal data-test="user-delete-modal" exchange={row.exchange} uuid={row.uuid} />;
        }
    }];
});
