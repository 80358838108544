import React, { FunctionComponent, useState } from "react";
import { BaseFormModalProps } from "./BaseFormModal";
import { ApiResponse, PortDetails } from "constants/api";
import { ApiDataBinding, withApiData } from "react-api-data";
import { useTranslation } from "react-i18next";
import { compose } from "recompose";
import { BaseModalTemplate, FormModalButtons } from "./BaseModalTemplate";
import Alert from "reactstrap/lib/Alert";
import { NetworkRequestStatus, SnmpStatus } from "constants/literals";
import DropdownItem from "reactstrap/lib/DropdownItem";

interface InProps {
    port: PortDetails;
    snmpStatus: SnmpStatus;
    onChangeStatusRequested?: () => void;
}

interface EnhanceProps {
    portChangeStatusRequest: ApiDataBinding<ApiResponse<{}>>;
}

type Props = EnhanceProps & InProps & BaseFormModalProps;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            portChangeStatusRequest: 'putPortChangeStatus',
        },
        (ownProps: InProps) =>  ({
            portChangeStatusRequest: {
                exchange: ownProps.port.exchange.toUpperCase(),
                portId: ownProps.port.id
            }
        })
    )
);

const PortChangeStatusModal: FunctionComponent<Props> = ({ portChangeStatusRequest, port, snmpStatus, onChangeStatusRequested }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const onSubmit = () => {
        portChangeStatusRequest.perform(
            {
                exchange: port.exchange.toUpperCase(),
                portId: port.id,
            },
            {
                status: snmpStatus === SnmpStatus.Disabled ? 'enable' : 'disable'
            }
        ).then(() => {
            if (onChangeStatusRequested) onChangeStatusRequested();
        });

        toggle();
    };

    return (
        <>
            <BaseModalTemplate
                isOpen={isOpen}
                toggle={toggle}
                header={snmpStatus === SnmpStatus.Disabled ? t('portChangeStatusModal.headerTitleEnable') : t('portChangeStatusModal.headerTitleDisable')}
                body={
                    <Alert color="warning" className="mb-0">
                        <strong>{t('common.warning')}</strong>
                        <p>{snmpStatus === SnmpStatus.Disabled ? t('portChangeStatusModal.warningMessageEnable') : t('portChangeStatusModal.warningMessageDisable')}</p>
                    </Alert>
                }
                footer={
                    <FormModalButtons
                        loading={portChangeStatusRequest.request.networkStatus === NetworkRequestStatus.Loading}
                        toggle={toggle}
                        onSubmit={onSubmit}
                    />
                }
            />

            <DropdownItem onClick={toggle}>
                {snmpStatus === SnmpStatus.Disabled ? t('portChangeStatusModal.requestEnable') : t('portChangeStatusModal.requestDisable')}
            </DropdownItem>
        </>
    );
};

export default enhance(PortChangeStatusModal);

