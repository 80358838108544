import { getEntity, ApiDataState, getResultData } from 'react-api-data';
import memoizeOne from 'memoize-one';
import pipe from 'lodash/fp/pipe';
import values from 'lodash/fp/values';
import sortBy from 'lodash/fp/sortBy';
import pickBy from 'lodash/fp/pickBy';
import filter from 'lodash/filter';
import size from 'lodash/size';
import some from 'lodash/some';
import get from 'lodash/get';

// constants
import { LinkFull, Exchange, Exchanges, User, PublicVlanItem, Contract } from 'constants/api';
import { exchangeSchema } from 'constants/endpointConfig';
import { Roles } from 'constants/literals';
import { ReduxState } from 'createStore';

// selectors
import { userUuidSelector } from 'redux/app/appSelectors';

export const getLinksByStatusSelector = memoizeOne((linkStatus: string, linkEntities: { [key: string]: LinkFull }): LinkFull[] => {
    // @ts-ignore
    return pipe(
        pickBy((link: LinkFull) => link.status === linkStatus),
        values,
        sortBy('xml_id')
    )(linkEntities);
});

/**
 * 
 * Returns links that doesn't have vlan with specific vlanId
 */
export const getLinksWithoutVlanIdSelector = memoizeOne((vlanId: number, links: LinkFull[]): LinkFull[] => {
    return filter(links, (link: LinkFull) => {
        if (!size(link.vlans)) {
            return true;
        }
        return !some(link.vlans, ['vlan_id', vlanId]);
    });
});

export const getAllExchangesSelector = memoizeOne((apiData: ApiDataState): Exchanges => (
    getResultData(apiData, 'getAllExchanges')
));

export const getAllExchangesForCurrentScopeSelector = memoizeOne((apiData: ApiDataState): Exchange[] => (
    getResultData(apiData, 'getAllExchanges')
        .scopes
        .find((scope: Exchanges['scopes'][number]) => scope.current_scope).exchanges
));

export const getExchangeByNameSelector = memoizeOne((apiData: ApiDataState, exchangeName: string): Exchange => (
    getEntity(apiData, exchangeSchema, exchangeName)
));

export const getBgpLogTypesSelector = memoizeOne((apiData: ApiDataState, exchangeName: string): string[] => (
    get(getExchangeByNameSelector(apiData, exchangeName), 'settings.bgp_log_types')
));

export const getFirstExchangeShortNameSelector = memoizeOne((apiData: ApiDataState): string => (
    get(getAllExchangesSelector(apiData), 'scopes[0].exchanges[0].short_name')
));

export const getPublicVlansSelector = memoizeOne((apiData: ApiDataState, exchangeName: string): PublicVlanItem[] => (
    get(getExchangeByNameSelector(apiData, exchangeName), 'vlans')
));

/**
 * Returns related links that share
 * Status
 * Contract
 * Location
 * Base port speed
 * The source_link must not have services/VLANs
 * The source_link must be configured
 * The destination_link must be configured
 * The destination_link must be a LAG (don't filter them out, but disable all not lag link options in the select box)
 */
export const getPortMergeableLinksSelector = memoizeOne((apiData: ApiDataState, link: LinkFull): LinkFull[] => {
    if (size(link.vlans) > 0 || link.status !== 'configured') {
        return [];
    }
    // @ts-ignore
    return pipe(
        pickBy((linkItem: LinkFull) => {
            return (
                linkItem.uuid !== link.uuid &&
                linkItem.status === link.status &&
                linkItem.contract_uuid === link.contract_uuid &&
                linkItem.location === link.location &&
                linkItem.base_port_speed === link.base_port_speed
            );
        }),
        values,
        sortBy('xml_id')
    )(get(apiData, 'entities.link'));
});

/**
 * Notice that user requires exchanges data
 */
export const getUserSelector = memoizeOne((state: ReduxState): User => {
    const userRequestResult = getResultData(state.apiData, 'getUser', {
        userId: userUuidSelector(state),
        exchange: getFirstExchangeShortNameSelector(state.apiData)
    });
    return get(userRequestResult, 'data');
});

export const getUserRolesSelector = memoizeOne((state: ReduxState): Roles[] => {
    const user = getUserSelector(state);
    return get(user, 'roles');
});

export const getUserEmailSelector = memoizeOne((state: ReduxState): Roles[] => {
    const user = getUserSelector(state);
    return get(user, 'email');
});

export const getHomeContractSelector = memoizeOne((state: ReduxState): Contract => {
    const contractRequestResult = getResultData(state.apiData, 'getHomeContract', {
        contractUuid: process.env.REACT_APP_HOME_CONTRACT_UUID!,
        exchange: process.env.REACT_APP_EXCHANGE_ID!
    });
    return contractRequestResult;
});
