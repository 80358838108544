import React, { FunctionComponent, useState } from 'react';
import { ApiDataBinding, withApiData, performApiRequest, invalidateApiDataRequest } from 'react-api-data/lib';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row, Input, Label } from 'reactstrap';
import { compose } from 'recompose';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { LinkFull } from 'constants/api';
import AlertMessage from 'components/utils/AlertMessage';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import IpAddressOptions from 'components/utils/IpAddressOptions';
import BgpLogOptions from 'components/utils/BgpLogTypeOptions';
import { RefreshLogsBtn } from 'components/cards/QuickDebugTab';

interface Props {
    link: LinkFull;
}

const BgpLogs: FunctionComponent<Props & WithTranslation> = ({ link, t }) => {
    const [selectedLogType, selectLogType] = useState('');
    const [selectedIp, selectIp] = useState('');
    const exchangeName = get(link, 'exchange.short_name');

    return (
        <>
            <Row>
                <Col md={6}>
                    <Label>{t('quickdebug.selectLogType')}</Label>
                    <Input
                        data-test="log-type-select"
                        type="select"
                        name="log-type"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => selectLogType(event.currentTarget.value)}
                        value={selectedLogType}
                    >
                        <BgpLogOptions exchange={exchangeName} />
                    </Input>
                </Col>
                <Col md={6}>
                    <Label>{t('quickdebug.selectIp')}</Label>
                    <Input
                        data-test="ip-select"
                        type="select"
                        name="ip"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => selectIp(event.currentTarget.value)}
                        value={selectedIp}
                    >
                        <option value="" disabled>{t('quickdebug.selectIp')}</option>)
                        <IpAddressOptions link={link} />
                    </Input>
                </Col>
            </Row>
            <div className="mt-4">
                {!selectedLogType || !selectedIp ? (
                    <AlertMessage
                        data-test="not-selected-message"
                        message={!selectedLogType ? t('validation.logTypeNotSelected') : t('validation.ipNotSelected')}
                    />
                ) : (
                    <BgpLogsRendererEnhanced
                        data-test="bgp-logs"
                        ip={selectedIp}
                        log={selectedLogType}
                        exchange={exchangeName}
                    />
                )}
            </div>
        </>
    );
};

interface BgpLogsRendererInProps {
    log: string;
    ip: string;
    exchange: string;
}

interface EnhanceProps {
    bgpLogsRequest: ApiDataBinding<any>;
    refetchLogs: () => void;
}

type BgpLogsRendererProps = BgpLogsRendererInProps & EnhanceProps & WithTranslation;

export const BgpLogsRenderer: FunctionComponent<BgpLogsRendererProps> = ({ bgpLogsRequest, t, refetchLogs }) => {
    return (
        <RequestStatusRenderer
            request={bgpLogsRequest}
            failed={<>
                <RefreshLogsBtn data-test="refresh-logs-btn" onClick={refetchLogs} buttonTitle={t('quickdebug.refreshLogsBtn')} />
                <AlertMessage data-test="failed-message" message={t('common.errorRetrievingData')} />
            </>}
            success={() => {
                const logs = get(bgpLogsRequest, 'data.logs');
                if (!logs) {
                    return <AlertMessage data-test="no-data-message" message={t('common.noData')} />;
                }
                return (<>
                    <RefreshLogsBtn data-test="refresh-logs-btn" buttonTitle={t('quickdebug.refreshLogsBtn')} onClick={refetchLogs} />
                    <pre data-test="logs-data">{logs}</pre>;
                </>);
            }}
        />
    );
};

const mapDispatchToProps = (dispatch: any, ownProps: BgpLogsRendererInProps) => ({
    refetchLogs: () => {
        const requestParams = {
            exchange: get(ownProps, 'exchange'),
            ip: get(ownProps, 'ip'),
            log: get(ownProps, 'log'),
        };
        dispatch(invalidateApiDataRequest('getBgpLogs', requestParams));
        dispatch(performApiRequest('getBgpLogs', requestParams));
    },
});

const enhance = compose<BgpLogsRendererProps, BgpLogsRendererInProps>(
    connect(null, mapDispatchToProps),
    withApiData({
        bgpLogsRequest: 'getBgpLogs'
    }, (props: BgpLogsRendererInProps) => ({
        bgpLogsRequest: {
            exchange: props.exchange,
            ip: props.ip,
            log: props.log,
        }
    })),
    withTranslation()
);

export const BgpLogsRendererEnhanced = enhance(BgpLogsRenderer);

// @ts-ignore Possible error in react-api-data type definitions
export default withTranslation()(BgpLogs);
