import React, { FunctionComponent } from 'react';
import { Input } from 'reactstrap';
import { FormikTouched, FormikErrors } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Exchanges } from 'constants/api';
import FormControl from './FormControl';

interface ExchangeFormikProps {
    name: string;
    errors: {[key: string]: string | FormikErrors<any> | undefined};
    touched: {[key: string]: boolean | FormikTouched<any> | undefined};
    exchanges: Exchanges | undefined;
    showErrorHint?: boolean;
}

interface ExchangeSelectProps {
    name: string;
    exchanges: Exchanges | undefined;
    defaultValue?: string;
    onChange?: any;
    onBlur?: any;
}

interface Options {
    exchanges: Exchanges | undefined;
}

const ExchangeOptions: FunctionComponent<Options & WithTranslation> = ({ exchanges, t }) => (
    <>
        <option disabled value={''}>{t('connection.selectExchange')}</option>
        {exchanges && exchanges.scopes.filter(x => x.current_scope).map(scope => (
            <optgroup key={scope.name} label={process.env.REACT_APP_EXCHANGE_NAME || scope.name.toUpperCase()}>
                {scope.exchanges.sort((a, b) => a.name.localeCompare(b.name)).map(exchange => (
                    exchange.active && <option key={exchange.uuid} value={exchange.short_name}>{exchange.name}</option>
                ))}
            </optgroup>
        ))}
    </>
);

const ExchangeOptionsEnhanced = withTranslation()(ExchangeOptions);

export const ExchangeSelect: FunctionComponent<ExchangeSelectProps> = ({ name, exchanges, onChange, onBlur, defaultValue = '' }) => (
    <Input
        type="select"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={defaultValue}
    >
        <ExchangeOptionsEnhanced exchanges={exchanges} />
    </Input>
);

export const ExchangeFormikSelect: FunctionComponent<ExchangeFormikProps> = ({ name, errors, touched, exchanges, showErrorHint = true }) => (
    <>
        <FormControl
            component="select"
            name={name}
            errors={errors}
            touched={touched}
            showErrorHint={showErrorHint}
        >
            <ExchangeOptionsEnhanced exchanges={exchanges} />
        </FormControl>
    </>
);
