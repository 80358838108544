import React, { FunctionComponent, useMemo } from 'react';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, LinkFull, NativeRouter, RouteServer, Vlan, Exchanges, PublicVlanItem } from 'constants/api';

// components
import PageLoading from 'components/utils/PageLoading';
import { NetworkRequestStatus, Roles } from 'constants/literals';
import { ApiDataBinding, withApiData } from 'react-api-data';
import VlanInfoCard from './VlanInfoCard';
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { Link } from 'react-router-dom';
import { getLinkToConnection, getLinkToContract } from 'utils/linksUtils';
import { Col, Row } from 'reactstrap';
import ColoredIconWidget from './ColoredIconWidget';

interface InProps {
    exchange: string;
    linkVlan: Vlan;
    userRoles: Roles[];
}

interface EnhanceProps {
    nativeRouters: ApiDataBinding<ApiResponse<{ native_routers: NativeRouter[] }>>;
    routeServers: ApiDataBinding<ApiResponse<{ route_servers: RouteServer[] }>>;
    linkRequest: ApiDataBinding<LinkFull>;
    vlanRequest: ApiDataBinding<ApiResponse<PublicVlanItem>>;
    exchangesData: Exchanges;
}

type VlanProps = InProps & EnhanceProps & WithTranslation;

const enhance = compose<VlanProps, InProps>(
    connect((state: ReduxState) => ({
        exchangesData: getAllExchangesSelector(state.apiData),
    })),
    withTranslation(),
    withApiData(
        {
            nativeRouters: 'getNativeRouters',
            routeServers: 'getRouteServers',
            linkRequest: 'getMemberLink',
            vlanRequest: 'getVlan',
        },
        (ownProps: VlanProps) => ({
            nativeRouters: { exchange: ownProps.exchange },
            routeServers: { exchange: ownProps.exchange },
            linkRequest: { linkId: ownProps.linkVlan.link_uuid, exchange: ownProps.exchange },
            vlanRequest: { vlanUuid: ownProps.linkVlan.vlan_uuid },
        })
    )
);

const LinkVlanInfo: FunctionComponent<VlanProps> = ({
    userRoles,
    exchange,
    exchangesData,
    linkRequest,
    linkVlan,
    vlanRequest,
    t,
    nativeRouters,
    routeServers,
}) => {
    const vlanHasNativeRouter = () => {
        return nativeRouters.data!.data!.native_routers.some((item) => item.vlan.uuid === linkVlan.vlan_uuid);
    };
    const vlanHasRouteServer = () => {
        return routeServers.data!.data!.route_servers.some((item) => item.vlan.uuid === linkVlan.vlan_uuid);
    };

    const exchangeObject = useMemo(() => {
        return exchangesData.scopes
            .flatMap((scope) => scope.exchanges)
            .find((item) => item.short_name.toUpperCase() === exchange.toUpperCase());
    }, [exchange, exchangesData.scopes]);

    if (
        nativeRouters.request.networkStatus !== NetworkRequestStatus.Success ||
        routeServers.request.networkStatus !== NetworkRequestStatus.Success ||
        linkRequest.request.networkStatus !== NetworkRequestStatus.Success ||
        vlanRequest.request.networkStatus !== NetworkRequestStatus.Success
    ) {
        return <PageLoading />;
    }

    const link = linkRequest.data!;
    const vlan = vlanRequest.data!.data!;

    return (
        <>
            <Row>
                <Col lg={6} xl={3}>
                    <ColoredIconWidget
                        label={t('connection.xmlIdLabel')}
                        value={linkVlan.xml_id}
                        color="primary"
                        icon="link"
                    />
                </Col>
                <Col lg={6} xl={3}>
                    <ColoredIconWidget label={t('common.handle')} value={link.handle} color="dark" icon="id-card" />
                </Col>
                <Col lg={6} xl={3}>
                    <ColoredIconWidget
                        label={t('connection.classificationLabel')}
                        value={'Vlan'}
                        color="info"
                        icon="network-wired"
                        capitalizeValue={true}
                    />
                </Col>
                <Col lg={6} xl={3}>
                    <ColoredIconWidget
                        label={t('common.location')}
                        value={link.location}
                        color="success"
                        icon="compass"
                    />
                </Col>
            </Row>
            <hr />
            <>
                <span>
                    <b className="ml-1"> {t('connection.managingContractName')} </b>
                </span>{' '}
                <Link to={getLinkToContract(exchange, link.contract_uuid)}>{link.contract_name}</Link>{' '}
                <b className="ml-1">
                    {t('common.link')}: <Link to={getLinkToConnection(exchange, link.uuid)}>{link.xml_id}</Link>{' '}
                </b>
            </>
            <hr />
            <VlanInfoCard
                link={link}
                linkVlan={linkVlan}
                vlan={vlan}
                key={linkVlan.uuid}
                exchange={exchangeObject!}
                userRoles={userRoles}
                vlanHasNativeRouter={vlanHasNativeRouter}
                vlanHasRouteServer={vlanHasRouteServer}
            />
        </>
    );
};

export default enhance(LinkVlanInfo);
