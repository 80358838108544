import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { object, number } from 'yup';
import { compose } from 'recompose';
import { ApiDataRequest, withApiData, ApiDataBinding } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, Asn, ReactApiDataActions } from 'constants/api';
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';

interface InputProps {
    contractUuid: string;
    organisationUuid: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    asnAddRequest: ApiDataBinding<ApiResponse<Asn>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps, InputProps>(
    withTranslation(),
    withApiData(
        {
            asnAddRequest: 'postAsn'
        }
    )
);
class AsnAddModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.asnAddRequest.request, this.props.asnAddRequest.request, {
            successMessage: this.props.t('notification.addAsnSuccessful'),
        });
    }

    handleOnSubmit = (values: any) => {
        const { asnAddRequest, apiDataActions, contractUuid, organisationUuid } = this.props;
        asnAddRequest
            .perform({ contract_id: contractUuid }, { ...values })
            .then((response) => {
                if (response.request.networkStatus === NetworkRequestStatus.Success) {
                    apiDataActions.invalidateCache('getOrganisation', {
                        uuid: organisationUuid,
                    });
                    apiDataActions.perform('getOrganisation', {
                        uuid: organisationUuid,
                    });
                }
            });
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        asn: '',
                        peeringpolicy_id: 1,
                        policyurl: ''
                    }}
                    onSubmit={this.handleOnSubmit}
                    validationSchema={object().shape({
                        asn: number().required(t('validation.requiredField'))
                    })}
                >
                    {({ values, errors, touched, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            handleSubmit={handleSubmit}
                            header={t('addAsnModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row required>
                                        <Col md="3">
                                            <Label htmlFor="asn">{t('addAsnModal.number')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="number"
                                                name="asn"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="peeringpolicy_id">{t('addAsnModal.peeringPolicy')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                component="select"
                                                name="peeringpolicy_id"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                <option value="1">{t('peeringPolicy.open')}</option>
                                                <option value="2">{t('peeringPolicy.closed')}</option>
                                                <option value="3">{t('peeringPolicy.caseByCase')}</option>
                                                <option value="4">{t('peeringPolicy.custom')}</option>
                                                <option value="5">{t('peeringPolicy.selective')}</option>
                                                <option value="6">{t('peeringPolicy.routeServerOnly')}</option>
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="policyurl">{t('addAsnModal.policyUrl')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="text"
                                                name="policyurl"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    disabled={!dirty}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.customer, Roles.ixaas_client]}>
                    <span data-test="asn-add-btn" className="text-primary cursor-pointer" onClick={this.toggle} title={t('addAsnModal.headerTitle')}>
                        <i className="fas fa-plus" /> {t('addAsnModal.headerTitle')}
                    </span>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(AsnAddModal);
