import React from 'react';
import memoizeOne from 'memoize-one';

// utils
import i18next from 'locales/i18next';
import { IpRange } from 'constants/api';
import { Link } from 'react-router-dom';
import { getLinkToIpRange, getLinkToVlan } from 'utils/linksUtils';
import IpRangeDeleteModal from 'components/modals/IpRangeDeleteModal';

export const getIpRangesTableColumns = memoizeOne((selectedExchange: string): any => {
    return [
        {
            dataField: 'value',
            text: i18next.t('ipRanges.value'),
            sort: true
        },
        {
            dataField: 'subnet_mask',
            text: i18next.t('ipRanges.subnetMask'),
            sort: true
        },
        {
            dataField: 'type',
            text: i18next.t('ipRanges.type'),
            sort: true
        },
        {
            dataField: 'description',
            text: i18next.t('ipRanges.description'),
            sort: true
        },
        {
            dataField: 'public',
            text: i18next.t('ipRanges.public'),
            sort: true
        },
        {
            dataField: 'vlan.number',
            text: i18next.t('ipRanges.vlan'),
            sort: true,
            formatter: (cell: any, row: IpRange) => (
                !row.vlan ? null : <Link to={getLinkToVlan(row.exchange.short_name, row.vlan.uuid)}>{row.vlan.number}</Link>
            )
        },
        {
            dataField: 'exchange',
            text: i18next.t('ipRanges.exchange'),
            formatter: (cell: any, row: IpRange) => (row.exchange.short_name)
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: 'Ip Range',
            formatter: (cell: any, row: IpRange) => (
                <Link to={getLinkToIpRange(row.id)}>{i18next.t('common.view')}</Link>
            ),
        },
        {
            dataField: 'remove',
            isDummyField: true,
            text: 'Remove',
            formatter: (cell: any, row: IpRange) => {
                return <IpRangeDeleteModal data-test="ipRange-delete-modal"  selectedExchange={selectedExchange} ipRangeId={row.id} />;
            }
        }
    ];
});
