import React, { FunctionComponent } from 'react';

import { LinkFull } from 'constants/api';
import QuickDebugWithDays, { createOutputRenderer } from './QuickDebugWithDays';

const OutputRenderer = createOutputRenderer({ endpointKey: 'getLinkFlapInformation' });

interface Props {
    link: LinkFull;
}

const LinkFlapInformation: FunctionComponent<Props> = ({ link }) => {
    return (
        <QuickDebugWithDays
            link={link}
            pathToTitle="quickdebug.linkFlapTitle"
            renderOutput={({ link, days }) => <OutputRenderer data-test="output-renderer" link={link} days={days} />}
        />
    );
};

export default LinkFlapInformation;
