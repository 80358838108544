import { compose } from 'recompose';
import { connect } from 'react-redux';
import { performApiRequest } from 'react-api-data/lib';
import get from 'lodash/get';

import ResourceTransitions, { CommonInProps, TransitionItemProp, DispatchProps } from './ResourceTransitions';
import { ResourceType } from 'constants/literals';
import { LinkPortCompact } from 'constants/api';

interface PortInProps extends CommonInProps {
    port: LinkPortCompact;
    primaryResource: boolean; // A primary resource (port) can not be (individually) deconfigured.
}

const portMapStateToProps = (dispatch: any, ownProps: PortInProps) => ({
    onConfirm: (transitionItem: TransitionItemProp) => {
        if (!transitionItem.endpointKey) {
            console.warn(`Endpoint is not set for ${transitionItem.transitionType}!`);
            return;
        }
        const requestParams: any = {
            resourceId: ownProps.resourceId,
            resourceName: ResourceType.port,
            exchange: ownProps.exchange,
            portId: get(ownProps, 'port.uuid')
        };

        dispatch(performApiRequest(transitionItem.endpointKey, requestParams));
    }
});

export default compose<PortInProps & DispatchProps, PortInProps>(
    connect(null, portMapStateToProps)
)(ResourceTransitions);
