import React, { Fragment, FunctionComponent } from 'react';
import { ApiDataBinding } from 'react-api-data/lib';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import get from 'lodash/get';

import { DebugData, LinkFull } from 'constants/api';
import AlertMessage from 'components/utils/AlertMessage';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';

interface Props {
    link: LinkFull;
    debugData: ApiDataBinding<DebugData>;
    refetchData?: () => void;
}

export const RefreshLogsBtn: FunctionComponent<{ onClick: any, buttonTitle: string }> = ({ onClick, buttonTitle }) => (
    <div className="text-right">
        <Button color="light" onClick={onClick}>
            {buttonTitle} <SyncButton />
        </Button>
    </div>
);

const QuickDebugTab: FunctionComponent<Props & WithTranslation> = ({ debugData, refetchData, t }) => (
    <RequestStatusRenderer
        data-test="request-status-renderer"
        request={debugData}
        success={() => {
            const data = get(debugData, 'data.data.debug_data');
            if (!data) {
                return <AlertMessage data-test="no-data-message" message={t('common.noData')} />;
            }
            return (
                <Fragment>
                    {refetchData && (
                        <RefreshLogsBtn
                            data-test="refresh-logs-btn"
                            onClick={refetchData}
                            buttonTitle={t('quickdebug.refreshLogsBtn')}
                        />
                    )}
                    <pre className="mt-3" data-test="json-data">{data}</pre>
                </Fragment>
            );
        }}
        failed={
            <Fragment>
                {refetchData && <RefreshLogsBtn data-test="refresh-logs-btn" onClick={refetchData} buttonTitle={t('quickdebug.refreshLogsBtn')} />}
                <AlertMessage data-test="failed-message" message={t('common.noData')} />
            </Fragment>
        }
    />
);

// @ts-ignore Possible error in react-api-data type definitions
export default withTranslation()(QuickDebugTab);
