import {
    Alert, Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

// constants
import { Router } from 'constants/api';
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';
import { hasSomeRole } from 'utils/userUtils';
import { getUserRolesSelector } from 'redux/apiData/apiDataSelectors';

interface InputProps {
    router: Router;
    resourceStatus: string;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    requestMode: ApiDataRequest;
    userRoles: Roles[];
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'putEnablePeeringRouteServer', {
        routerId: ownProps.router.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
    requestMode: getApiDataRequest(state.apiData, 'putPeeringRouteServerMode', {
        routerId: ownProps.router.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
    userRoles: getUserRolesSelector(state)
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            if (ownProps.router.secure_routeserver) {
                dispatch(performApiRequest('putPeeringRouteServerMode', {
                    routerId: ownProps.router.uuid,
                    exchange: ownProps.exchange,
                    linkId: ownProps.linkId
                }, { router: values }));
            } else {
                dispatch(performApiRequest('putEnablePeeringRouteServer', {
                    routerId: ownProps.router.uuid,
                    exchange: ownProps.exchange,
                    linkId: ownProps.linkId
                }, { filtering: values.peering_mode }));
            }
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class RsPeeringModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { request, requestMode, t } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            successMessage: t('notification.rsPeeringEnableSuccessful'),
        });
        this.handleFormSubmission(prevProps.requestMode, requestMode, {
            successMessage: t('notification.rsPeeringModeChangeSuccessful'),
        });
    }

    render() {
        const { request, onSubmit, router, t, userRoles } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ peering_mode: router.secure_mode || 'rpki+irrdb' }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={router.secure_routeserver ? t('rsPeeringModal.changeRsPeeringBtn') : t('rsPeeringModal.enableRsPeeringBtn')}
                            body={<>
                                <Alert color="warning">
                                    <strong>{t('common.warning')}</strong>
                                    <p>{t('rsPeeringModal.paragraph1')}</p>
                                    <p>{t('rsPeeringModal.paragraph2')}</p>
                                    <p>{t('rsPeeringModal.paragraph3')}</p>
                                    <ul>
                                        <Trans i18nKey="rsPeeringModal.issues" />
                                    </ul>
                                    <p>{t('rsPeeringModal.paragraph4')}</p>
                                    <p>{t('rsPeeringModal.paragraph5')}{' '}
                                        <a
                                            href={t('rsPeeringModal.rsFilteringUrl')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('common.here')}
                                        </a>.</p>
                                </Alert>
                                <FormGroup row className="mb-0">
                                    <Col md="3">
                                        <Label htmlFor="peering_mode">{t('rsPeeringModal.filteringLabel')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            data-test="peering-mode-select"
                                            component="select"
                                            name="peering_mode"
                                            id="peering_mode"
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="irrdb">{t('common.irrdb')}</option>
                                            <option value="rpki">{t('common.rpki')}</option>
                                            <option value="rpki+irrdb">
                                            {t('common.rpkiIrrdb')}
                                            </option>
                                            {hasSomeRole(userRoles, Roles.noc, Roles.admin) && (
                                                <option value="tag-only">{t('common.tagOnly')}</option>
                                            )}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={this.props.router.secure_mode === values.peering_mode && this.props.router.secure_routeserver}
                                />
                            }
                        />
                    )}
                </Formik>
                <EditBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    resourceStatus={this.props.resourceStatus}
                    roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.customer, Roles.ixaas_client]}
                >
                    <Tooltip
                        data-test="peering-edit-btn"
                        triggerBtn={
                            <i
                                className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {router.secure_routeserver ? t('rsPeeringModal.changeRsPeeringBtn') : t('rsPeeringModal.enableRsPeeringBtn')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(RsPeeringModal);
