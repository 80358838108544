import React, { FunctionComponent, useState, ReactNode } from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { StatsInterval, StatsType } from 'constants/literals';

interface Props {
    children: (options: {type: StatsType, interval: StatsInterval}) => ReactNode;
    displayType?: boolean;
}

const StatisticsWidget: FunctionComponent<Props & WithTranslation> = ({ children, t, displayType = true }) => {
    const [interval, setInterval] = useState(StatsInterval.Daily);
    const [type, setType] = useState(StatsType.Traffic);

    return (
        <>
            <Row>
                <Col xs={6}>
                    {displayType && (
                        <ButtonGroup>
                            <Button
                                color="primary"
                                size="sm"
                                outline
                                active={type === StatsType.Traffic}
                                onClick={() => setType(StatsType.Traffic)}
                            >
                                {t('stats.traffic')}
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                outline
                                active={type === StatsType.Error}
                                onClick={() => setType(StatsType.Error)}
                            >
                                {t('stats.errors')}
                            </Button>
                        </ButtonGroup>
                    )}
                </Col>
                <Col xs={6}>
                    <ButtonGroup className="float-right">
                        <Button
                            color="primary"
                            size="sm"
                            outline
                            active={interval === StatsInterval.Daily}
                            onClick={() => setInterval(StatsInterval.Daily)}
                        >
                            {t('stats.daily')}
                        </Button>
                        <Button
                            color="primary"
                            size="sm"
                            outline
                            active={interval === StatsInterval.Weekly}
                            onClick={() => setInterval(StatsInterval.Weekly)}
                        >
                            {t('stats.weekly')}
                        </Button>
                        <Button
                            color="primary"
                            size="sm"
                            outline
                            active={interval === StatsInterval.Monthly}
                            onClick={() => setInterval(StatsInterval.Monthly)}
                        >
                            {t('stats.monthly')}
                        </Button>
                        <Button
                            color="primary"
                            size="sm"
                            outline
                            active={interval === StatsInterval.Yearly}
                            onClick={() => setInterval(StatsInterval.Yearly)}
                        >
                            {t('stats.yearly')}
                        </Button>
                        <Button
                            color="primary"
                            size="sm"
                            outline
                            active={interval === StatsInterval.ThreeYears}
                            onClick={() => setInterval(StatsInterval.ThreeYears)}
                        >
                            {t('stats.3y')}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {children && children({ type, interval })}
        </>
    );
};

export default withTranslation()(StatisticsWidget);
