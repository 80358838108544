import React, { FunctionComponent, useEffect } from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data/lib';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import head from 'lodash/head';
import get from 'lodash/get';

// constants
import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

// utils
import { getIpAddresses } from 'utils/linksUtils';

// components
import AlertMessage from 'components/utils/AlertMessage';
import LoaderButton from 'components/utils/LoaderButton';

interface InProps {
    link: LinkFull;
}

interface EnhancedProps {
    selectedIP: string;
    setSelectedIP: (ip: string) => void;
    unblockBGPSession: (ip: string) => void;
    unblockBGPSessionRequest: ApiDataRequest;
}

type Props = InProps & WithTranslation & EnhancedProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    unblockBGPSessionRequest: getApiDataRequest(state.apiData, 'putUnblockBGPSession', {
        ip: get(ownProps, 'selectedIP'),
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    unblockBGPSession: (ip: string) => {
        dispatch(performApiRequest('putUnblockBGPSession', { ip }));
    }
});

const UnblockBGPSession: FunctionComponent<Props> = ({
    t, link, unblockBGPSession, unblockBGPSessionRequest, selectedIP, setSelectedIP
}) => {
    const ipAddresses = getIpAddresses(link);
    const firstIP = head(ipAddresses) || '';

    useEffect(() => {
        setSelectedIP(firstIP);
    }, [firstIP, setSelectedIP]);

    const onUnblockBtnClick = (): void => {
        if (!selectedIP) return;
        unblockBGPSession(selectedIP);
    };

    return (
        firstIP ? (
            <>
                <Label>{t('quickdebug.selectIP')}</Label>
                <Input
                    data-test="select-ip"
                    className="w-25"
                    type="select"
                    name="ip"
                    defaultValue={firstIP}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => setSelectedIP(event.currentTarget.value)}
                >
                    {ipAddresses.map((ip: string) => <option key={ip} value={ip}>{ip}</option>)}
                </Input>
                <LoaderButton
                    data-test="unblock-btn"
                    className="mt-2"
                    disabled={!selectedIP}
                    loading={get(unblockBGPSessionRequest, 'networkStatus') === 'loading'}
                    onClick={onUnblockBtnClick}
                >
                    {t('quickdebug.unblockBGPsessionsForIP', { ip: selectedIP })}
                </LoaderButton>
            </>
        ) : <AlertMessage data-test="no-ips-message" message={t('quickdebug.noIps')} />
    );
};

export default compose<Props, InProps>(
    withState('selectedIP', 'setSelectedIP', ''),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(UnblockBGPSession);