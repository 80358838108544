import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { PORT_SECURITY_URL, NOC_SUPPORT_EMAIL } from '../constants';

export const lookupCookie = 'appLanguage';
export const defaultLanguage = 'en';

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
.init({
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE || defaultLanguage,
    debug: process.env.NODE_ENV === 'development',
    detection: {
        order: ['localStorage', 'cookie'],
        lookupCookie,
        lookupLocalStorage: lookupCookie,
        caches: ['localStorage', 'cookie'],
    },
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        defaultVariables: {
            supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
            nocSupportEmail: NOC_SUPPORT_EMAIL,
            exchangeName: process.env.REACT_APP_EXCHANGE_NAME,
            escapedExchangeName: process.env.REACT_APP_EXCHANGE_NAME === 'CRM-IX' ? 'AMS-IX' : process.env.REACT_APP_EXCHANGE_NAME,
            portSecurityUrl: PORT_SECURITY_URL,
            asSpecificationUrl: process.env.REACT_APP_AS_SPECIFICATION_URL,
            rsFilteringUrl: process.env.REACT_APP_RS_FILTERING_URL,
        }
    },
    returnObjects: true,
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'li']
    },
    backend: {
        queryStringParams: { v: process.env.NODE_ENV === 'test' ? '1' : __webpack_hash__ } // use a hash value of the current build as the version so it doesn't need to be manually changed
    }
});

export default i18n;
