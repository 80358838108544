import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import React, { FunctionComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import size from 'lodash/size';
import { ApiDataBinding, withApiData, getResultData } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, RouterPeerings, Peering, LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';
import i18n from 'locales/i18next';
import { BgpRouterType } from '../../constants/literals';

// utils
import { getLinkToConnection } from 'utils/linksUtils';

// components
import ViewContainer from 'components/utils/ViewContainer';
import AlertMessage from 'components/utils/AlertMessage';
import PageLoading from 'components/utils/PageLoading';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import PeeringsList from 'components/cards/PeeringsList';

interface InProps {
    routerId: string;
    exchange: string;
    linkId: string;
}

interface ApiProps {
    routerPeerings: ApiDataBinding<ApiResponse<RouterPeerings>>;
    link?: LinkFull;
}

type PeeringViewProps = InProps & ApiProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    link: getResultData(state.apiData, 'getMemberLink', {
        linkId: get(ownProps, 'linkId'),
        exchange: ownProps.exchange.toUpperCase(),
    }),
});

const PeeringsWithNRView: FunctionComponent<PeeringViewProps> = ({ routerId, exchange, linkId, link, routerPeerings, t }) => {
    return (
        <ViewContainer>
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to={getLinkToConnection(exchange, linkId)}>{link ? link.xml_id : linkId}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{t('peering.pageTitle')}</BreadcrumbItem>
            </Breadcrumb>
            <Row className="justify-content-between">
                <Col>
                    <h2>
                        {t('peering.pageTitle')}
                        <SyncButton
                            className="ml-2"
                            title={t('peering.refreshBtn')}
                            onClick={routerPeerings.invalidateCache}
                        />
                    </h2>
                </Col>
            </Row>
            <hr />
            <PeeringsViewContent
                linkId={linkId}
                routerId={routerId}
                exchange={exchange}
                routerPeerings={routerPeerings}
                translate={t}
            />
        </ViewContainer>
    );
};

export const PeeringsViewContent: FunctionComponent<InProps & ApiProps & {translate: i18n.TFunction}> =
    ({ routerId, linkId, exchange, routerPeerings, translate }) => (
        <RequestStatusRenderer
            request={routerPeerings}
            loading={<PageLoading />}
            failedMessage={translate('peering.noPeerings')}
            success={() => {
                const peeringsData: Peering[] = get(routerPeerings, 'data.data.peerings');
                if (!size(peeringsData)) {
                    return <AlertMessage message={translate('peering.noPeerings')} />;
                }
                return (
                    <PeeringsList
                        peeringsData={peeringsData}
                        routerId={routerId}
                        exchange={exchange}
                        linkId={linkId}
                    />
                );
            }}
        />
    );

const bindApiCall = withApiData({
    routerPeerings: 'getRouterPeerings'
}, (ownProps: InProps) => ({
    routerPeerings: {
        routerId: ownProps.routerId,
        exchange: ownProps.exchange.toUpperCase(),
        peeringWith: BgpRouterType.NativeRouter
    }
}));

const enhance = compose<PeeringViewProps, InProps>(
    bindApiCall,
    connect(mapStateToProps),
    withTranslation()
);

export default enhance(PeeringsWithNRView);