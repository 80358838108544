import React from 'react';
import memoizeOne from 'memoize-one';

// utils
import i18next from 'locales/i18next';
import { Link } from 'react-router-dom';
import { Exchange } from 'constants/api';
import { getLinkToExchange } from 'utils/linksUtils';

export const getExchangesTableColumns = memoizeOne((): any => {
    return [
        {
            dataField: 'name',
            text: i18next.t('exchangesAndDataCenters.name'),
            sort: true,
        },
        {
            dataField: 'short_name',
            text: i18next.t('exchangesAndDataCenters.shortName'),
            sort: true,
        },
        {
            dataField: 'active',
            text: i18next.t('exchangesAndDataCenters.active'),
            sort: true,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: i18next.t('exchangesAndDataCenters.viewDetails'),
            formatter: (cell: any, row: Exchange) => (
                <Link to={getLinkToExchange(row.uuid)}>{i18next.t('common.view')}</Link>
            ),
        }
    ];
});
