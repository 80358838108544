import React, { FunctionComponent, memo } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, DropdownItem } from 'reactstrap';
import { ApiDataBinding, withApiData, invalidateApiDataRequest } from 'react-api-data/lib';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { compose } from 'recompose';
import ReactPlaceholder from 'react-placeholder';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import 'react-placeholder/lib/reactPlaceholder.css';

// constants
import { LinkPortCompact, LinkPortFull, LinkFull, ApiResponse } from 'constants/api';

// utils
import { refetchPort } from 'utils/apiUtils';
import { formatSpeed } from 'utils/formatUtils';
import { getPortName } from 'utils/commonUtils';

// components
import PortResourceTransitions from 'components/resourceTransitions/PortResourceTransitions';
import ResourceStatus from 'components/utils/ResourceStatus';
import { CenteredAlert } from 'components/styled';
import PortStatisticsModal from 'components/modals/PortStatisticsModal';
import SyncButton from 'components/utils/SyncButton';
import ContextMenuDropdown from 'components/utils/ContextMenuDropdown';
import PortMergeModal from 'components/modals/PortMergeModal';
import PortDeleteBtn from 'components/utils/PortDeleteBtn';
import ResourceUpdateStatus from '../utils/ResourceUpdateStatus';
import { LinkType, NetworkRequestStatus, Roles } from 'constants/literals';
import { Link } from 'react-router-dom';
import { getLinkToPort } from 'utils/linksUtils';
import CompositeRequest from 'utils/compositeRequest';
import RolesChecker from 'components/utils/RolesChecker';
import PortSnmpStatus from 'components/utils/PortSnmpStatus';

interface Props {
    link: LinkFull;
}

interface EnhanceProps {
    refetchAllPortsData: () => boolean;
}

const mapDispatchToProps = (dispatch: any, ownProps: Props) => ({
    refetchAllPortsData: () => {
        const exchange = ownProps.link.exchange.short_name;
        ownProps.link.ports.forEach((linkPort: LinkPortCompact) => {
            refetchPort(dispatch, linkPort.uuid, exchange);
            dispatch(invalidateApiDataRequest('getPortSnmpData', {portId: linkPort.port_id}));
        });
        return true;
    },
});

const PortInfo: FunctionComponent<Props & EnhanceProps> = ({link, refetchAllPortsData}) => {
    const {t} = useTranslation();
    const linkPorts: LinkPortCompact[] = link.ports;

    return (
        <>
            <h2>
                {t('port.portsTitle')}
                <SyncButton
                    data-test="ports-refresh-btn"
                    className="ml-2"
                    title={t('port.refreshBtn')}
                    onClick={refetchAllPortsData}
                />
            </h2>
            <hr/>
            {linkPorts && linkPorts.length === 0 && (
                <CenteredAlert data-test="no-ports-message" color="none">{t('port.noPortsFound')}</CenteredAlert>
            )}
            <Row>
                {linkPorts.sort((a, b) => a.portnumber.localeCompare(b.portnumber)).map(linkPort => (
                    <Col xl={6} key={linkPort.uuid}>
                        <PortDetailsWithData
                            linkPort={linkPort}
                            link={link}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};

interface DetailsInputProps {
    linkPort: LinkPortCompact;
    link: LinkFull;
}

interface ConnectDetailsProps {
    linkPortBinding: ApiDataBinding<ApiResponse<LinkPortFull>>;
}

type DetailsProps = DetailsInputProps & ConnectDetailsProps;

export const PortDetails: FunctionComponent<DetailsProps> = ({ linkPort, linkPortBinding, link }) => {
    const { t } = useTranslation();
    const exchange = link.exchange.short_name;
    const compositeRequest = new CompositeRequest(linkPortBinding.request);
    const loaded = compositeRequest.networkStatus === NetworkRequestStatus.Success;
    const linkPortFull = loaded ? linkPortBinding.data!.data! : undefined;
    const portName = getPortName(linkPort);

    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <ReactPlaceholder
                    type="text"
                    ready={loaded}
                    style={{width: '150px'}}
                    className="d-inline-block"
                    rows={1}
                    showLoadingAnimation
                >
                    {
                        loaded && (
                            <strong>
                                <Link
                                    to={getLinkToPort(exchange, `${linkPort.fqid}/${linkPort.portnumber}`)}>{portName}</Link>
                            </strong>
                        )
                    }
                </ReactPlaceholder>
                <div className="card-header-actions">
                    <ResourceStatus data-test="resource-status" status={linkPort.status}/>
                    <ContextMenuDropdown data-test="context-menu" className="ml-2"
                                         dropdownMenuProps={{right: true, persist: true}}>
                        {link.type !== LinkType.MonitorLink && (
                            <PortResourceTransitions
                                data-test="resource-transitions"
                                currentStatus={linkPort.status}
                                resourceId={linkPort.uuid}
                                primaryResource={linkPort.primary}
                                port={linkPort}
                                exchange={exchange}
                                renderTransitionBtn={(props) => (
                                    <DropdownItem toggle={false}
                                                  onClick={props.onClick}>{props.transitionName}</DropdownItem>
                                )}
                            />
                        )
                        }
                        <PortMergeModal
                            data-test="port-merge-modal"
                            portId={linkPort.uuid}
                            link={link}
                            portName={portName}
                            renderMergeButton={(props) => (
                                <DropdownItem toggle={false} onClick={props.onClick}>
                                    {t('mergePort.mergeBtn')}
                                </DropdownItem>
                            )}
                        />
                        <ResourceUpdateStatus
                            resourceId={linkPort.uuid}
                            resourceStatus={linkPort.status}
                            linkId={link.uuid}
                            exchange={link.exchange.short_name}
                            isDropdownItem={true}
                        />
                        <PortDeleteBtn
                            data-test="port-delete-btn"
                            port={linkPort}
                            linkId={link.uuid}
                            exchange={exchange}
                            isDropdownItem={true}
                        />
                    </ContextMenuDropdown>
                </div>
            </CardHeader>
            <CardBody>
                <PortStatisticsModal data-test="port-statistics-modal" port={linkPort} exchange={exchange}/>
                <br/>
                {linkPort.primary && (
                    <StyledBadge data-test="primary-badge" className="badge badge-info">
                        {t('common.primary')}
                    </StyledBadge>
                )}
                <br/>
                <PortTable size="sm" className="mb-0" borderless style={{tableLayout: 'fixed'}}>
                    <tbody>
                    {linkPortFull && linkPortFull.photonic_mapping && linkPortFull.photonic_mapping.length !== 0 &&
                        <tr>
                            <th>{t('port.etherPorts')}</th>
                            <td>
                                {linkPortFull.photonic_mapping.map((portMap: { switch_port: string; }) => (
                                    <StyledMap key={portMap.switch_port}>
                                        <Link
                                            to={getLinkToPort(exchange, portMap.switch_port)}>{portMap.switch_port}</Link>
                                        <br/>
                                    </StyledMap>
                                ))}
                            </td>
                        </tr>
                    }
                    <RolesChecker roles={[Roles.admin, Roles.noc, Roles.qnoc]}>
                        {loaded && linkPortFull && !linkPortFull.is_pxc && (
                            <tr>
                                <th>{t('port.snmpStatus')}</th>
                                <td>
                                    <PortSnmpStatus portId={linkPort.port_id} />
                                </td>
                            </tr>
                        )}
                    </RolesChecker>
                    <tr>
                        <th>{t('port.interfaceType')}</th>
                        <td>
                            <ReactPlaceholder
                                type="text"
                                ready={loaded}
                                style={{width: '100px'}}
                                rows={1}
                                showLoadingAnimation
                            >
                                <>{linkPortFull ? linkPortFull.interface_type :
                                    <span className="text-danger">{t('common.errorRetrievingData')}</span>}</>
                            </ReactPlaceholder>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('common.speed')}</th>
                        <td>
                            <ReactPlaceholder
                                type="text"
                                ready={loaded}
                                style={{width: '45px'}}
                                rows={1}
                                showLoadingAnimation
                            >
                                <>{linkPortFull ? formatSpeed(linkPortFull.speed) :
                                    <span className="text-danger">{t('common.errorRetrievingData')}</span>}</>
                            </ReactPlaceholder>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('port.odfPosition')}</th>
                        <td>
                            <ReactPlaceholder
                                type="text"
                                ready={loaded}
                                style={{width: '80px'}}
                                rows={1}
                                showLoadingAnimation
                            >
                                <>{linkPortFull ? linkPortFull.odf :
                                    <span className="text-danger">{t('common.errorRetrievingData')}</span>}</>
                            </ReactPlaceholder>
                        </td>
                    </tr>
                    </tbody>
                </PortTable>
            </CardBody>
        </Card>
    );
};

const PortDetailsWithData = compose<DetailsProps, DetailsInputProps>(
    withApiData(
        {
            linkPortBinding: 'getPort',
        },
        (ownProps: DetailsInputProps) => ({
            linkPortBinding: {
                portId: ownProps.linkPort.uuid,
                exchange: ownProps.link.exchange.short_name,
            },
        })
    ),
)(PortDetails);

const PortTable = styled(Table)`
  th {
    width: 150px;
  }
`;

const StyledBadge = styled.span`
  font-size: 90% !important;
  position: absolute;
  right: 15px;
`;

const StyledMap = styled.div`
  font-size: 12px;
`;

const enhancePortInfo = compose<Props & EnhanceProps, Props>(
    connect(null, mapDispatchToProps),
);

export default enhancePortInfo(memo(PortInfo, isEqual));
