import React from 'react';
import { Link } from 'react-router-dom';
import memoizeOne from 'memoize-one';

// utils
import { getLinkToConnection, getLinkToNativeRouter, getLinkToVlan} from 'utils/linksUtils';
import i18next from 'locales/i18next';

import { NativeRouter} from 'constants/api';
import NativeRouterDeleteModal from 'components/modals/NativeRouterDeleteModal';

export const getNativeRoutersTableColumns = memoizeOne((): any => {
    return [{
        dataField: 'name',
        text: i18next.t('nativeRouters.id'),
        sort: true,
    }, {
        dataField: 'description',
        text: i18next.t('nativeRouters.description'),
        sort: true,
    }, {
        dataField: 'ipv4_address',
        isDummyField: true,
        text: i18next.t('nativeRouters.Ipv4'),
        formatter: (cell: any, row: NativeRouter) => (
            <Link to={getLinkToConnection(row.exchange, row.link_ipv4_uuid)}>{row.peering_ipv4}</Link>
        )
    },  {
        dataField: 'ipv6_address',
        isDummyField: true,
        text: i18next.t('nativeRouters.Ipv6'),
        formatter: (cell: any, row: NativeRouter) => (
            <Link to={getLinkToConnection(row.exchange, row.link_ipv6_uuid)}>{row.peering_ipv6}</Link>
        )
    }, {
        dataField: 'as_number',
        text: i18next.t('nativeRouters.asnNumber'),
        sort: true,
    }, {
        dataField: 'vlan_number',
        text: i18next.t('nativeRouters.vlanNumber'),
        formatter: (cell: any, row: NativeRouter) => (
            !row.vlan.number ? null : <Link to={getLinkToVlan(row.exchange, row.vlan.uuid)}>{row.vlan.number}</Link>
        )
    }, {
        dataField: 'link',
        isDummyField: true,
        text: i18next.t('nativeRouters.link'),
        formatter: (cell: any, row: NativeRouter) => (
            <Link to={getLinkToNativeRouter(row.exchange, row.uuid)}>{i18next.t('common.view')}</Link>
        )
    }, {
        dataField: 'remove',
        isDummyField: true,
        text: i18next.t('nativeRouters.remove'),
        formatter: (cell: any, row: NativeRouter) => {
            return <NativeRouterDeleteModal data-test="user-delete-modal" exchange={row.exchange} uuid={row.uuid} />;
        }
    }];
});
