import React, { FunctionComponent, ReactNode } from 'react';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { TransitionsType, Roles } from 'constants/literals';

import RolesChecker from '../utils/RolesChecker';
import BaseTransitionModal from 'components/modals/BaseTransitionModal';

export interface InProps {
    currentStatus: string;
    primaryResource?: boolean;
    events?: TransitionsType[];
    renderTransitionBtn?: (props: {transitionName: string, onClick: () => void}) => ReactNode;
    onConfirm: any;
}

interface BaseTransitionInProps extends InProps {
    transitionType: TransitionsType;
    modalHeaderTitle: string;
    modalBodyTitle: string;
    transitionIconClass?: string;
    transitionName: string;
    roles?: Roles[];
    statuses?: string[];
}

interface WithTransitionType {
    status: string;
    transitionType: TransitionsType;
    events?: TransitionsType[];
    statuses?: string[];
}

/**
 * Checks given transition exists in the events array
 * If events are not provided then check available resource statuses
 */
const canApplyTransition = ({ status, transitionType, events, statuses }: WithTransitionType): boolean => {
    if (events) {
        return includes(events, transitionType);
    }
    return includes(statuses, status);
};

/**
 * Checks that transition is available for the resource by checking its events or status
 */
export const TransitionConditionalRender: FunctionComponent<WithTransitionType> = ({ status, transitionType, events, children, statuses }) => (
    canApplyTransition({ status, transitionType, events, statuses }) ? <>{children}</> : null
);

const BaseTransition: FunctionComponent<BaseTransitionInProps> = ({
    onConfirm, renderTransitionBtn, currentStatus, transitionType, modalHeaderTitle,
    modalBodyTitle, transitionName, transitionIconClass, statuses, roles, ...optionalProps
}) => {
    return (
        <RolesChecker data-test="transition-roles" roles={roles}>
            <TransitionConditionalRender
                data-test="transition-render"
                status={currentStatus}
                events={get(optionalProps, 'events')}
                transitionType={transitionType}
                statuses={statuses}
            >
                <BaseTransitionModal
                    data-test="base-transition-modal"
                    data-test-transition-modal={transitionType}
                    modalHeaderTitle={modalHeaderTitle}
                    modalBodyTitle={modalBodyTitle}
                    onConfirm={onConfirm}
                    transitionName={transitionName}
                    transitionIconClass={transitionIconClass}
                    renderTransitionBtn={renderTransitionBtn}
                />
            </TransitionConditionalRender>
        </RolesChecker>
    );
};

export default BaseTransition;
