import React, { FunctionComponent, ReactNode } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Exchanges } from 'constants/api';
import { ReduxState } from 'createStore';
import { OptionItem, StatsInterval, StatsType } from 'constants/literals';

// redux
import { updateAppPathAction } from 'redux/app/appActions';
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';
import usePreselectExchange from 'hooks/usePreselectExchange';

// components
import StatisticsWidget from 'components/utils/StatisticsWidget';
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import AggregateSelect from 'components/utils/AggregateSelect';
import AlertMessage from 'components/utils/AlertMessage';

interface InputProps {
    statsTitle: string;
    displayType?: boolean;
    aggregateOptions: OptionItem[];
    selectedExchangeAppPath: string;
    selectedAggregateAppPath: string;
    renderGraph: (options: {type: StatsType, interval: StatsInterval, selectedExchange: string, selectedAggregate: string}) => ReactNode;
}

interface ConnectProps {
    exchangesData: Exchanges;
    selectedExchange: string;
    selectedAggregate: string;
    changeExchange: (selectedExchange: string) => void;
    changeAggregate: (selectedAggregate: string) => void;
}

type TotalStatisticsProps = InputProps & ConnectProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: InputProps) => ({
    exchangesData: getAllExchangesSelector(state.apiData),
    selectedExchange: get(state, `app.${ownProps.selectedExchangeAppPath}`, ''),
    selectedAggregate: get(state, `app.${ownProps.selectedAggregateAppPath}`, ''),
});

const mapDispatchToProps = (dispatch: any, ownProps: InputProps) => ({
    changeExchange: (selectedExchange: string) => {
        dispatch(updateAppPathAction(ownProps.selectedExchangeAppPath, selectedExchange));
    },
    changeAggregate: (selectedAggregate: string) => {
        dispatch(updateAppPathAction(ownProps.selectedAggregateAppPath, selectedAggregate));
    }
});

const AggregateStatistics: FunctionComponent<TotalStatisticsProps> = ({
    exchangesData, selectedExchange, selectedAggregate, changeExchange, changeAggregate, t,
    aggregateOptions, renderGraph, statsTitle, displayType = true
}) => {

    const handleExchangeChange = (exchange: React.FormEvent<HTMLInputElement>) => {
        changeExchange(exchange.currentTarget.value);
    };

    const handleAggregateChange = (aggregate: React.FormEvent<HTMLInputElement>) => {
        changeAggregate(aggregate.currentTarget.value);
    };

    usePreselectExchange(selectedExchange, exchangesData, changeExchange);

    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <Row className="justify-content-between">
                    <Col sm="3">
                        <strong className="d-flex h-100 align-items-center justify-content-start">
                            {t(statsTitle)}
                        </strong>
                    </Col>
                    <Col sm="9">
                        <Row className="justify-content-end">
                            <Col sm="5">
                                <ExchangeSelect
                                    name="exchange"
                                    defaultValue={selectedExchange}
                                    exchanges={exchangesData}
                                    onChange={handleExchangeChange}
                                />
                            </Col>
                            <Col sm="5">
                                <AggregateSelect
                                    defaultValue={selectedAggregate}
                                    onChange={handleAggregateChange}
                                    options={aggregateOptions}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {!selectedExchange || !selectedAggregate ?
                    <AlertMessage message={t(!selectedExchange ? 'validation.exchangeNotSelected' : 'validation.aggregateNotSelected')} />
                : (
                    <StatisticsWidget displayType={displayType}>
                        {({ type, interval }) => (renderGraph({
                            type, interval, selectedExchange, selectedAggregate
                        }))}
                    </StatisticsWidget>
                )}
            </CardBody>
        </Card>
    );
};

export default compose<TotalStatisticsProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(AggregateStatistics);
