import React from 'react';
import { FormGroup } from "reactstrap";
import Col from 'reactstrap/lib/Col';
import Tooltip from 'components/utils/Tooltip';
import Label from 'reactstrap/lib/Label';
import StyledCheckbox from 'components/styled/StyledCheckbox';
import { useTranslation } from 'react-i18next';

interface Props {
    checked: boolean;
}

export default ({ checked  }: Props) => {
    const { t } = useTranslation();

    return (
        <FormGroup row>
            <Col md={3}>
                <Tooltip
                    triggerBtn={
                        <Label htmlFor="reserved_for_testing" className="mb-0">
                            <span className="mr-2">{t('contract.reservedForTesting')}</span>
                            <i className="fa fa-question-circle" />
                        </Label>
                    }
                >
                    {t('contract.reservedForTestingDescription')}
                </Tooltip>
            </Col>
            <Col md={9} className="d-flex align-items-center">
                <StyledCheckbox name="reserved_for_testing" checked={checked} />
                {checked && (
                    <span className="ml-2 badge badge-warning">{t('contract.reservedForTestingWarning')}</span>
                )}
            </Col>
        </FormGroup>
    );
};
