import React, { FunctionComponent } from 'react';

import BaseTransition, { InProps } from './BaseTransition';
import { TransitionsType, Roles } from 'constants/literals';

const TransitionDeconfigurationFailed: FunctionComponent<InProps> = (props) => {
    return (
        <BaseTransition
            transitionType={TransitionsType.deconfiguration_failed}
            roles={[Roles.admin, Roles.noc]}
            modalHeaderTitle="resourceTransitions.deconfigurationFailedHeaderTitle"
            modalBodyTitle="resourceTransitions.deconfigurationFailedBodyText"
            transitionName="resourceTransitions.deconfigurationFailed"
            transitionIconClass="fa-ban"
            { ...props }
        />
    );
};

export default TransitionDeconfigurationFailed;