import React, { FunctionComponent } from 'react';
import { AppHeader } from '@coreui/react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router';

// utils
import { getBaseRoutePath } from 'utils/commonUtils';
import { getLinkToAvailablePorts, getLinkToCustomers, getLinkToIpRanges, getLinkToSwitches, getLinkToRouteServers, getLinkToExchanges } from 'utils/linksUtils';
import { getLinkToVlans } from 'utils/linksUtils';
import { getLinkToNativeRouters } from 'utils/linksUtils';

// components
import Header from '../Header';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import ConnectionDetails from './ConnectionDetails';
import ContractDetails from './ContractDetails';
import CustomersView from './CustomersView';
import VlansView from './VlansView';
import VlanDetails from './VlanDetails';
import NativeRoutersView from './NativeRoutersView';
import NativeRouterDetails from './NativeRouterDetails';
import PeeringsWithRSView from './PeeringsWithRSView';
import PeeringsWithNRView from './PeeringWithNRView';
import AppUserAccess from 'components/AppUserAccess';
import AppBanner from 'components/AppBanner';
import SwitchesView from './SwitchesView';
import SwitchDetails from './SwitchDetails';
import IpRangesView from './IpRangesView';
import IpRangeDetails from './IpRangeDetails';
import LinkVlanDetails from './LinkVlanDetails';
import RouteServersView from './RouteServersView';
import RouteServerDetails from './RouteServerDetails';
import AvailablePortsView from './AvailablePortsView';
import PortDetails from './PortDetails';
import ExchangesView from './ExchangesView';
import ExchangeDetails from './ExchangeDetails';
import DataCenterDetails from './DataCenterDetails';
import { compose } from 'recompose';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { Contract } from '../../constants/api';
import OrganisationDetails from './OrganisationDetails';
import UserDetails from './UserDetails';
import LoggedInAsUserBanner from 'components/utils/LoggedInAsUserBanner';

interface EnhanceProps {
    contractData: ApiDataBinding<Contract>;
}

type HomeProps = EnhanceProps & RouteComponentProps;

export const Home: FunctionComponent<HomeProps> = (props) => {
    const basePath = getBaseRoutePath();

    return (
        <AppUserAccess location={props.location}>
            <AppHeader fixed>
                <Header />
            </AppHeader>
            <div className="app-body">
                {/*<AppSidebar fixed display="lg">*/}
                    {/*<AppSidebarHeader />*/}
                    {/*<AppSidebarForm />*/}
                    {/*<AppSidebarNav*/}
                        {/*navConfig={{*/}
                            {/*items: [{*/}
                                {/*name: 'Dashboard',*/}
                                {/*url: '/dashboard',*/}
                                {/*icon: 'fas fa-globe'*/}
                            {/*}, {*/}
                                {/*name: 'Test Connection',*/}
                                {/*url: '/connections/myabstract-mem-620',*/}
                                {/*icon: 'fas fa-link'*/}
                            {/*}, {*/}
                                {/*name: 'Google',*/}
                                {/*url: '/connections/nl/agg-20070305-001-3620',*/}
                                {/*icon: 'fas fa-link'*/}
                            {/*}]*/}
                        {/*}}*/}
                    {/*/>*/}
                    {/*<AppSidebarFooter />*/}
                    {/*<AppSidebarMinimizer />*/}
                {/*</AppSidebar>*/}
                <main className="main">
                    <LoggedInAsUserBanner />
                    <AppBanner />
                    <Switch>
                        {basePath &&
                            <Route
                                path="/"
                                exact
                                render={props => <Redirect to={basePath} />}
                            />
                        }
                        <Route
                            path={basePath}
                            exact
                            component={Dashboard}
                        />
                        <Route
                            path={`${basePath}/connections/:exchange/:linkId`}
                            exact
                            render={props => <ConnectionDetails {...props.match.params} />}
                        />
                        <Route
                            path={`${basePath}/link_vlans/:exchange/:linkVlanUuid`}
                            exact
                            render={props => <LinkVlanDetails {...props.match.params} />}
                        />
                        <Route
                            path={getLinkToCustomers()}
                            exact
                            component={CustomersView}
                        />
                        <Route
                            path={`${basePath}/customers/:exchange/:contractUuid`}
                            exact
                            component={ContractDetails}
                        />
                        <Route
                            path={getLinkToVlans()}
                            exact
                            component={VlansView}
                        />
                        <Route
                            path={`${basePath}/vlans/:exchange/:vlanUuid`}
                            exact
                            component={VlanDetails}
                        />
                        <Route
                            path={getLinkToNativeRouters()}
                            exact
                            component={NativeRoutersView}
                        />
                        <Route
                            path={`${basePath}/native_routers/:exchange/:nativeRouterUuid`}
                            exact
                            component={NativeRouterDetails}
                        />
                        <Route
                            path={getLinkToSwitches()}
                            exact
                            component={SwitchesView}
                        />
                        <Route
                            path={`${basePath}/switches/:exchange/:name`}
                            exact
                            component={SwitchDetails}
                        />
                        <Route
                            path={getLinkToIpRanges()}
                            exact
                            component={IpRangesView}
                        />
                        <Route
                            path={`${basePath}/ip_ranges/:id`}
                            exact
                            component={IpRangeDetails}
                        />
                        <Route
                            path={`${basePath}/ports/:exchange/:portId/port_details`}
                            exact
                            component={PortDetails}
                        />
                        <Route
                            path={getLinkToRouteServers()}
                            exact
                            component={RouteServersView}
                        />
                        <Route
                            path={`${basePath}/route_servers/:exchange/:routeServerUuid`}
                            exact
                            component={RouteServerDetails}
                        />
                        <Route
                            path={getLinkToAvailablePorts()}
                            exact
                            component={AvailablePortsView}
                        />
                        <Route
                            path={getLinkToExchanges()}
                            exact
                            component={ExchangesView}
                        />
                        <Route
                            path={`${basePath}/exchanges/:uuid`}
                            exact
                            component={ExchangeDetails}
                        />
                        <Route
                            path={`${basePath}/:exchange/dataCenters/:uuid`}
                            exact
                            component={DataCenterDetails}
                        />
                        <Route
                            path={`${basePath}/organisations/:organisationUuid`}
                            exact
                            render={props => <OrganisationDetails {...props.match.params} />}
                        />
                        <Route
                            path={`${basePath}/contracts/:exchange/:contractUuid/:linkId?`}
                            exact
                            render={props => <ContractDetails {...props.match.params} />}
                        />
                        <Route
                            path={`${basePath}/connections/:exchange/:linkId/connection-status`}
                            exact
                            render={props => <Redirect to={`${basePath}/connections/${props.match.params.exchange}/${props.match.params.linkId}`} />}
                        />
                        <Route
                            path={`${basePath}/peering/:routerId/:exchange/:linkId/rs`}
                            exact
                            render={props => <PeeringsWithRSView {...props.match.params}/>}
                        />
                        <Route
                            path={`${basePath}/peering/:routerId/:exchange/:linkId/nr`}
                            exact
                            render={props => <PeeringsWithNRView {...props.match.params}/>}
                        />
                        <Route
                            path={`${basePath}/users/:userId`}
                            exact
                            component={UserDetails}
                        />
                        <Route component={NotFound} path="*" />
                    </Switch>
                </main>
            </div>
        </AppUserAccess>
    );
};

export default compose<HomeProps, {}>(
    withApiData({ contract: 'getHomeContract' }, () => ({
        contract: {
            contractUuid: process.env.REACT_APP_HOME_CONTRACT_UUID!,
            exchange: process.env.REACT_APP_EXCHANGE_ID!,
        }
    }))
)(Home);
