import React, { FunctionComponent, useEffect } from 'react';
import { Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, Organisation, OrganisationContract, User } from 'constants/api';

// components
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import OrganisationUsersList from 'components/cards/OrganisationUsersList';
import OrganisationInfo from 'components/cards/OrganisationInfo';
import OrganisationCustomers from 'components/cards/OrganisationCustomers';
import CompositeRequest from 'utils/compositeRequest';

interface InProps {
    organisationUuid: string;
}

interface EnhanceProps {
    organisationRequest: ApiDataBinding<ApiResponse<Organisation>>;
    organisationCustomersRequest: ApiDataBinding<ApiResponse<{contracts: OrganisationContract[]}>>;
    organisationUsersRequest: ApiDataBinding<ApiResponse<{users: User[]}>>;
}

export type Props = InProps & EnhanceProps & WithTranslation;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            organisationRequest: 'getOrganisation',
            organisationCustomersRequest: 'getOrganisationContracts',
            organisationUsersRequest: 'getOrganisationUsers'
        },
        (ownProps) => ({
            organisationRequest: { uuid: ownProps.organisationUuid },
            organisationCustomersRequest: {
                organisationUuid: ownProps.organisationUuid,
                all_contracts: "true",
            },
            organisationUsersRequest: { 
                organisationUuid: ownProps.organisationUuid
            },
        })
    ),
    withTranslation()
);

export const OrganisationDetails: FunctionComponent<Props> = ({
    organisationRequest,
    organisationCustomersRequest,
    organisationUsersRequest,
    organisationUuid,
    t
}) => {

    useEffect(function fetchOrganisation() {
        refetchOrganisation();
    }, []);

    const refetchOrganisation = () => {
        organisationRequest.invalidateCache();
        organisationRequest.perform();
    };

    const refetchOrganisationContracts = () => {
        organisationCustomersRequest.invalidateCache();
        organisationCustomersRequest.perform();
    };

    const refetchOrganisationUsers = () => {
        organisationUsersRequest.invalidateCache();
        organisationUsersRequest.perform();
    };

    return (
        <ViewContainer>
            <Breadcrumb data-test="breadcrumb">
                <BreadcrumbItem active>{t('contract.orgName')}</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col lg={4}>
                    <Row>
                        <Col>
                            <h2>
                                {t('contract.orgName')}
                                <SyncButton
                                    data-test="refresh-organisation-btn"
                                    className="ml-2"
                                    title={t('contract.orgName')}
                                    onClick={refetchOrganisation}
                                />
                            </h2>
                        </Col>
                    </Row>
                    <hr />
                    <RequestStatusRenderer
                        request={new CompositeRequest(organisationRequest.request, organisationCustomersRequest.request)}
                        failedMessage={t('notification.unexpectedError')}
                        success={() => (
                            <OrganisationInfo
                                organisation={organisationRequest!.data!.data!}
                                contractUuid={organisationCustomersRequest.data!.data!.contracts[0].uuid}
                            />
                        )}
                    />
                </Col>
                <Col lg={8}>
                    <Row className="justify-content-between">
                        <Col>
                            <h2>
                                {t('contract.usersTitle')}
                                <SyncButton
                                    data-test="refresh-users-btn"
                                    className="ml-2"
                                    title={t('contract.refreshUsersBtn')}
                                    onClick={refetchOrganisationUsers}
                                />
                            </h2>
                        </Col>
                    </Row>
                    <hr />
                    <OrganisationUsersList
                        data-test="contract-users-list"
                        organisationUuid={organisationUuid}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h2>
                        {t('customers.pageTitle')}
                        <SyncButton
                            data-test="refresh-customers-btn"
                            className="ml-2"
                            title={t('customers.pageTitle')}
                            onClick={refetchOrganisationContracts}
                        />
                    </h2>
                    <hr />
                    <OrganisationCustomers
                        data-test="organisation-customers"
                        organisationUuid={organisationUuid}
                    />
                </Col>
            </Row>
        </ViewContainer>
    );
};

export default enhance(OrganisationDetails);
