import { FunctionComponent, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, DropdownItem } from 'reactstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import JSONPretty from 'react-json-prettify';
import { githubGist } from 'react-json-prettify/dist/themes';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import { withTranslation, WithTranslation } from 'react-i18next';

import { LinkFull, Vlan } from 'constants/api';
import { ConnectionStatus, LinkType, Roles, VlanStatus } from 'constants/literals';

import LagModal from 'components/modals/LagModal';
import LacpTimeoutModal from 'components/modals/LacpTimeoutModal';
import Exchange from 'components/utils/Exchange';
import BoolIcon from 'components/utils/BoolIcon';
import ContextMenuDropdown from 'components/utils/ContextMenuDropdown';
import MacAddressDeleteModal from 'components/modals/MacAddressDeleteModal';
import MacAddressChangeModal from 'components/modals/MacAddressChangeModal';
import MacAddressLinkAddModal from 'components/modals/MacAddressLinkAddModal';
import LinkResourceTransitions from 'components/resourceTransitions/LinkResourceTransitions';
import ResourceStatus from 'components/utils/ResourceStatus';
import QuickdebugModal from 'components/modals/QuickdebugModal';
import { StyledMac, DetailsTable } from 'components/styled';
import { BaseModalTemplate } from 'components/modals/BaseModalTemplate';

import { getStatusColor, formatSpeed } from 'utils/formatUtils';
import { getLinkToContract } from 'utils/linksUtils';
import { hasSomeRole, isIxaasClient } from 'utils/userUtils';
import SflowRankedModal from 'components/modals/SflowRankedModal';
import ResourceUpdateStatus from '../utils/ResourceUpdateStatus';
import LinkAddCommentModal from 'components/modals/LinkAddCommentModal';
import LinkIpForTestingModal from 'components/modals/LinkIpForTestingModal';
import DeleteLagModal from '../modals/DeleteLagModal';
import Tooltip from 'components/utils/Tooltip';
import LinkStatus from 'components/utils/LinkStatus';

interface Props {
    link: LinkFull;
    userRoles: Roles[];
}

const LinkInfo: FunctionComponent<Props & WithTranslation> = ({ link, t, userRoles }) => {
    const [jsonModalOpen, setJsonModalOpen] = useState(false);
    const toggleJsonModal = () => {
        setJsonModalOpen(!jsonModalOpen);
    };

    const getAsn = () => {
        for (const vlan of link.vlans) {
            for (const router of vlan.routers) {
                if (router.asn_number) {
                    return router.asn_number;
                }
            }
        }
        return null;
    };

    const colNum = 40;
    const asn = getAsn();
    const exchange = get(link, 'exchange.short_name');
    const linkId = get(link, 'uuid');
    const managingContractName = link.managing_contract_name ? link.managing_contract_name : link.contract_name;
    const managingContractUuid = link.managing_contract_uuid ? link.managing_contract_uuid : link.contract_uuid;
    const aggregateRateLimitedSpeed = link.vlans.filter((vlan) => vlan.status === VlanStatus.configured)
        .reduce<Vlan[]>((distinctVlansByQtag, vlan) => {
            if (!distinctVlansByQtag.some(v => v.qtag === vlan.qtag)) {
                distinctVlansByQtag.push(vlan);
            }
            return distinctVlansByQtag;
        }, [])
        .reduce((sum, vlan) => sum + vlan.speed, 0);

    const notDeconfiguredVlans = link.vlans.filter(vlan => vlan.status !== VlanStatus.deconfigured);
    const multipleLinkVlansWithSameVlan = (new Set(notDeconfiguredVlans.map(v => v.vlan_id))).size !== notDeconfiguredVlans.length;

    // Display quickdebug if:
    // user is ixaas_client and process.env.REACT_APP_IXAAS_CLIENT_SHOW_QUICKDEBUG === true
    // or user is not a customer, customer_reader or ixaas_client_reader
    const quickdebugIsVisible = isIxaasClient(userRoles) ?
        process.env.REACT_APP_IXAAS_CLIENT_SHOW_QUICKDEBUG === 'true' :
        !hasSomeRole(userRoles, Roles.customer, Roles.customer_reader, Roles.ixaas_client_reader);

    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <strong>{t('connection.linkDetailsTitle')}</strong>
                <div className="card-header-actions">
                    <ResourceStatus data-test="resource-status" status={link.status} />
                    <ContextMenuDropdown data-test="context-menu" className="ml-2" dropdownMenuProps={{ right: true, persist: true }}>
                        {!includes(['new', 'unconfigured', 'deconfigured'], link.status) && quickdebugIsVisible && (
                            <QuickdebugModal
                                data-test="quickdebug-modal"
                                link={link}
                                renderModalToggleBtn={(props) => (
                                    <DropdownItem toggle={false} onClick={props.onClick}>{t('connection.viewQuickDebug')}</DropdownItem>
                                )}
                            />
                        )}
                        {!hasSomeRole(userRoles, Roles.customer, Roles.customer_reader, Roles.ixaas_client, Roles.ixaas_client_reader) && (
                            <DropdownItem data-test="view-raw-json" onClick={toggleJsonModal}>{t('connection.viewRawJSON')}</DropdownItem>
                        )}
                        {link.type !== LinkType.MonitorLink &&
                            <LinkResourceTransitions
                                data-test="resource-transitions"
                                currentStatus={link.status}
                                resourceId={linkId}
                                events={get(link, 'transitions.events')}
                                linkId={linkId}
                                exchange={exchange}
                                renderTransitionBtn={(props) => (
                                    <DropdownItem toggle={false} onClick={props.onClick}>{props.transitionName}</DropdownItem>
                                )}
                            />
                        }
                        {hasSomeRole(userRoles, Roles.noc, Roles.admin) &&
                            link.status === ConnectionStatus.ready_for_testing && (
                                <LinkIpForTestingModal
                                    link={link}
                                    exchange={link.exchange.short_name}
                                    renderModalToggleBtn={(props) => (
                                        <DropdownItem toggle={false} onClick={props.onClick}>
                                            {t('resourceTransitions.testingWithDifferentIp')}
                                        </DropdownItem>
                                    )}
                                />
                            )}
                        <SflowRankedModal
                            link={link}
                            renderModalToggleBtn={(props) => (
                                <DropdownItem toggle={false} onClick={props.onClick}>{t('sflowStats.sflowRankedHeaderTitle')}</DropdownItem>
                            )}
                        />
                    </ContextMenuDropdown>
                    <BaseModalTemplate
                        data-test="json-modal"
                        isOpen={jsonModalOpen}
                        toggle={toggleJsonModal}
                        header={t('connection.rawLinkJsonTitle')}
                        body={<JSONPretty json={link} theme={githubGist} />}
                        footer={<Button color="light" onClick={toggleJsonModal}>{t('modal.closeBtn')}</Button>}
                    />
                </div>
            </CardHeader>
            <CardBody>
                <DetailsTable size="sm" borderless>
                    <tbody>
                    <tr>
                        <th>{t('common.exchange')}</th>
                        <td><Exchange data-test="exchange" shortName={exchange} /></td>
                    </tr>
                    {link.jira_ticket && hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.qnoc) && (
                        <tr>
                            <th>{t('connection.jiraTicket')}</th>
                            <td>
                                <a href={link.jira_ticket} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-jira" style={{ color: '#0052CC' }} />{' '}
                                    {link.jira_ticket.split('/').pop()}
                                </a>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th>{t('connection.contractName')}</th>
                        <td>
                            <Link to={getLinkToContract(exchange, link.contract_uuid, linkId)}>
                                {link.contract_name}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <th>{t('connection.managingContractName')}</th>
                        <td>
                            <Link to={getLinkToContract(exchange, managingContractUuid, linkId)}>
                                {managingContractName}
                            </Link>
                        </td>
                    </tr>
                    {(hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.qnoc) &&
                        link.priority_topology !== '' && link.priority_topology !== null) && (
                        <>
                            <tr>
                                <th>{t('connection.primaryTopologyLabel')}</th>
                                <td data-test="priority_topology">{link.priority_topology}</td>
                            </tr>
                            <tr>
                                <th style={{ verticalAlign: 'middle' }}>{t('connection.currentTopologyLabel')}</th>
                                <td>
                                    <LinkStatus
                                        data-test="link-status"
                                        link={link}
                                        userRoles={userRoles}
                                    />
                                </td>
                            </tr>
                        </>
                    )}
                    {!isNil(link.speed) && (
                        <tr>
                            <th>{t('connection.speed')}</th>
                            <td>{formatSpeed(link.speed)}</td>
                        </tr>
                    )}
                    {aggregateRateLimitedSpeed > 0 && (
                        <tr>
                            <th>{t('connection.rateLimitedSpeed')}</th>
                            <td data-test="rate-limited-speed">
                                {formatSpeed(aggregateRateLimitedSpeed)}
                                {!isNil(link.speed) && (
                                    <> ({(aggregateRateLimitedSpeed / link.speed * 100).toFixed(1)}%)</>
                                )}
                            </td>
                        </tr>
                    )}
                    {process.env.REACT_APP_SHOW_CLOUD === 'true' && (
                        <tr>
                            <th>{t('connection.cloudServices')}</th>
                            <td><BoolIcon value={false} /></td>
                        </tr>
                    )}
                    {link.classification !== 'virtual-link' && (
                        <tr>
                            <th>{t('connection.LAGMode')}</th>
                            <td>
                                <Badge color="success">{link.lag}</Badge>
                                {link.lag && link.ports.length === 1 && link.status === 'configured'
                                    && (<DeleteLagModal data-test="delete-lag-modal" link={link}/>)}
                                {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                                <LagModal data-test="lag-modal" link={link} />)}
                            </td>
                        </tr>
                    )}
                    {link.classification !== 'virtual-link' && link.lag === 'dynamic' && (
                        <tr>
                            <th>{t('connection.LACPTimeout')}</th>
                            <td>
                                <Badge color="success">{link.lacp_timeout && link.lacp_timeout}</Badge>
                                <LacpTimeoutModal data-test="lacp-modal" link={link} />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th rowSpan={Math.max(link.mac_addresses.length, 1)}>
                        {t('common.macAddresses')}
                        </th>
                        <td>
                            {link.mac_addresses.length > 0 && (
                                <div>
                                    <StyledMac
                                        data-test="mac-address-value"
                                    >
                                        {link.mac_addresses[0].value}
                                    </StyledMac>
                                    <Badge
                                        color={getStatusColor(link.mac_addresses[0].status)}
                                    >
                                        {link.mac_addresses[0].status}
                                    </Badge>
                                    {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                                        <>
                                            <MacAddressDeleteModal
                                                data-test="mac-delete-modal"
                                                address={link.mac_addresses[0]}
                                                linkId={linkId}
                                                exchange={exchange}
                                                resourceStatus={link.status}
                                            />
                                            <MacAddressChangeModal
                                                data-test="mac-change-modal"
                                                address={link.mac_addresses[0]}
                                                resourceStatus={link.status}
                                                linkId={linkId}
                                                exchange={exchange}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </td>
                        <td className="menu-column" style={{ paddingLeft: '17px' }}>
                            {link.mac_addresses.length > 0 && (
                                <ContextMenuDropdown
                                    data-test="context-menu"
                                    className="cursor-pointer fa-pull-right text-primary"
                                    dropdownMenuProps={{ right: true, persist: true }}
                                >
                                    <ResourceUpdateStatus
                                        data-test="mac-update-status"
                                        resourceId={link.mac_addresses[0].uuid}
                                        resourceStatus={link.mac_addresses[0].status}
                                        linkId={linkId}
                                        exchange={link.exchange.short_name}
                                        isDropdownItem={true}
                                    />
                                </ContextMenuDropdown>
                            )}
                        </td>
                    </tr>
                    {link.mac_addresses.slice(1).map((mac: any) => (
                        <tr key={mac.uuid}>
                            <td>
                                <StyledMac
                                    data-test="mac-address-value"
                                >
                                    {mac.value}
                                </StyledMac>
                                <Badge color={getStatusColor(mac.status)}>{mac.status}</Badge>
                                {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                                    <>
                                        <MacAddressDeleteModal
                                            data-test="mac-delete-modal"
                                            address={mac}
                                            linkId={linkId}
                                            exchange={exchange}
                                            resourceStatus={link.status}
                                        />
                                        <MacAddressChangeModal
                                            data-test="mac-change-modal"
                                            address={mac}
                                            resourceStatus={link.status}
                                            linkId={linkId}
                                            exchange={exchange}
                                        />
                                    </>)}
                            </td>
                            <td className="menu-column">
                                <ContextMenuDropdown
                                    data-test="context-menu"
                                    className="cursor-pointer fa-pull-right text-primary"
                                    dropdownMenuProps={{ right: true, persist: true }}
                                >
                                    <ResourceUpdateStatus
                                        data-test="mac-update-status"
                                        resourceId={mac.uuid}
                                        resourceStatus={mac.status}
                                        linkId={linkId}
                                        exchange={link.exchange.short_name}
                                        isDropdownItem={true}
                                    />
                                </ContextMenuDropdown>
                            </td>
                        </tr>
                    ))}
                    {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                        <tr>
                            <th/>
                            <td>
                                {multipleLinkVlansWithSameVlan ? (
                                    <Tooltip triggerBtn={
                                        <span className="badge badge-info">{t('connection.cantAddMacAddress')}</span>
                                    }>
                                        {t('connection.cantAddMacAddressExplanation')}
                                    </Tooltip>
                                ) : (
                                    <MacAddressLinkAddModal data-test="mac-add-modal" link={link} />
                                )}
                            </td>
                        </tr>
                    )}
                    {link.type === LinkType.MonitorLink && (
                        <>
                            <tr>
                                <th>{t('addMonitorLinkModal.trafficType')}</th>
                                <td>
                                    {link.traffic_type}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('addMonitorLinkModal.mepId')}</th>
                                <td>
                                    {link.attributes.mep_id}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('addMonitorLinkModal.snmpRo')}</th>
                                <td>
                                    {link.attributes.snmp_ro}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('addMonitorLinkModal.nidPollable')}</th>
                                <td>
                                    {String(!!link.attributes.nid_pollable)}
                                </td>
                            </tr>
                        </>
                    )}
                    <tr>
                        <th>{t('connection.displayErpOrderID')}</th>
                        <td>
                            {link.attributes.erp_order_identifier}
                        </td>
                    </tr>
                    {link.virtual_link_container && (
                        <tr>
                            <th>{t('connection.ResellerLinkID')}</th>
                            <td>
                                <Link to={`/connections/hk/${link.virtual_link_container}`}>{link.virtual_link_container}</Link>
                            </td>
                        </tr>
                    )}
                    {hasSomeRole(userRoles, Roles.noc, Roles.admin, Roles.ixaas_client, Roles.ixaas_client_reader, Roles.qnoc) &&
                            <tr>
                                <th>{t('connection.displayComment')}</th>
                                <td>
                                    {link.attributes.comment && link.attributes.comment.length > 0 && (
                                        <textarea
                                            readOnly={true}
                                            rows={link.attributes.comment.length / colNum}
                                            cols={colNum}
                                            defaultValue={link.attributes.comment}
                                        ></textarea>
                                    )}
                                    {hasSomeRole(userRoles, Roles.noc, Roles.admin, Roles.ixaas_client, Roles.qnoc) && (
                                        <LinkAddCommentModal
                                            data-test="comment-modal"
                                            link={link}
                                            exchange={exchange}
                                        />
                                    )}
                                </td>
                            </tr>
                    }
                    {link.sf_service_url && hasSomeRole(userRoles, Roles.noc, Roles.admin, Roles.qnoc) && (
                        <tr>
                            <th>{t('connection.salesforceUrl')}</th>
                            <td>
                                <>
                                    <a href={link.sf_service_url} target="_blank" rel="noopener noreferrer">
                                        <strong>{t('connection.salesforceServiceTitle')}</strong>
                                    </a>
                                </>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </DetailsTable>
            </CardBody>
        </Card>
    );
};

export default withTranslation()(LinkInfo);
