import React, { FunctionComponent } from 'react';
import { Badge, Card } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { LinkCompact, LinkFull } from 'constants/api';
import { formatSpeed, getStatusColor } from 'utils/formatUtils';
import { getLinkToConnection } from 'utils/linksUtils';
import InteractiveTable from 'components/utils/InteractiveTable';

interface InProps {
    links: LinkCompact[];
    speed: number;
}

type Props = InProps & WithTranslation & RouteComponentProps<{ exchange: string; }>;

const VirtualLinks: FunctionComponent<Props> = ({ links: tmpLinks, speed, match, t }) => {
    const links = tmpLinks.map(link => ({
        ...link,
        type: 'ams-ix',
        company: 'Dummy Company',
        speed: Math.round(Math.random() * 10) * 100,
        tag: Math.round(Math.random() * 10000),
        port: Math.round(Math.random() * 10000)
    }));

    const tableColumns = [{
        dataField: 'status',
        text: t('common.status'),
        sort: true,
        formatter: (cell?: string) => {
            return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
        }
    }, {
        dataField: 'type',
        isDummyField: true,
        text: '',
        formatter: () => <img src="/images/logo-compact.svg" style={{ width: '1em' }} alt="AMS-IX" />
    }, {
        dataField: 'company',
        text: t('common.company'),
        sort: true,
        copy: true
    }, {
        dataField: 'speed',
        text: t('common.speed'),
        sort: true,
        formatter: formatSpeed
    }, {
        dataField: 'tag',
        text: t('common.tag'),
        sort: true,
        copy: true
    }, {
        dataField: 'port',
        text: t('common.port'),
        sort: true,
        copy: true
    }, {
        dataField: 'link',
        isDummyField: true,
        text: '',
        align: 'right',
        formatter: (cell: any, row: LinkFull) => (
            <React.Fragment>
                <Link to="#" style={{ marginRight: '1em' }}>
                    <i className="fas fa-exchange-alt" />
                </Link>
                <Link to={getLinkToConnection(match.params.exchange, row.uuid)}>
                    <i className="fas fa-eye" />
                </Link>
            </React.Fragment>
        )
    }];

    let ordered = 0;
    let inProcess = 0;
    let active = 0;
    links.forEach(link => {
        switch (link.status) {
            case 'new':
            case 'reserved':
                ordered += link.speed;
                break;
            case 'assigned':
                inProcess += link.speed;
                break;
            case 'enabled':
                active += link.speed;
                break;
            default:
                break;
        }
    });
    const available = speed - ordered - inProcess - active;
    const overSubPercentage = available < 0 ? Math.round((-available) / speed * 100) : 0;

    return (
        <>
            <h2>{t('virtualLinks.title')}</h2>
            <hr />
            <Card className="card-accent-primary">
                <div className="brand-card-body">
                    <div>
                        <div className="text-value">{formatSpeed(ordered)}</div>
                        <div className="text-uppercase text-muted small">{t('virtualLinks.ordered')}</div>
                    </div>
                    <div>
                        <div className="text-value">{formatSpeed(inProcess)}</div>
                        <div className="text-uppercase text-muted small">{t('virtualLinks.inProgress')}</div>
                    </div>
                    <div>
                        <div className="text-value">{formatSpeed(active)}</div>
                        <div className="text-uppercase text-muted small">{t('virtualLinks.active')}</div>
                    </div>
                    <div>
                        {overSubPercentage ? (
                            <React.Fragment>
                                <div className="text-value text-danger">{formatSpeed(available)}</div>
                                <div className="text-uppercase small text-danger">{t('virtualLinks.oversubscribed')}
                                    ({overSubPercentage}%)
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="text-value">{formatSpeed(available)}</div>
                                <div className="text-uppercase text-muted small">{t('virtualLinks.available')}</div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <InteractiveTable keyField="xml_id" columns={tableColumns} data={links} paginationOptions={{ sizePerPage: 10 }} />
            </Card>
        </>
    );
};

export default compose<Props, InProps>(
    withRouter,
    withTranslation()
)(VirtualLinks);
