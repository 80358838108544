import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardImg,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from 'reactstrap';
import { getApiDataRequest, performApiRequest } from 'react-api-data';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Redirect, withRouter, RouteChildrenProps } from 'react-router';
import Helmet from 'react-helmet';
import { ApiDataRequest } from 'react-api-data/lib';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { AuthenticationToken } from 'constants/api';
import { FORGOT_PASSWORD_PATH } from 'constants/index';

// redux
import { updateAppPathAction } from 'redux/app/appActions';

// components
import LoaderButton from 'components/utils/LoaderButton';

interface Props {
    onSubmit: (values: any) => boolean;
    submitOtp: (values: any) => boolean;
    loginRequest?: ApiDataRequest;
    authenticationToken?: AuthenticationToken;
    needsOtp?: boolean;
    resetToken: () => void;
}

class Login extends Component<Props & WithTranslation & RouteChildrenProps, {}> {
    openForgotPasswordPage = () => {
        const { history } = this.props;

        history.push(FORGOT_PASSWORD_PATH);
    }

    componentDidUpdate(prevProps: Props) {
        const { loginRequest, t } = this.props;
        if (
            loginRequest &&
            prevProps.loginRequest !== loginRequest &&
            loginRequest.networkStatus === 'failed' &&
            get(loginRequest, 'response.status') !== 500
        ) {
            toast.error(t('login.error'));
        }
    }

    render() {
        const {
            authenticationToken,
            onSubmit,
            submitOtp,
            loginRequest,
            t,
            location,
            needsOtp,
            resetToken
        } = this.props;
        const userHasNoRoles = get(location.state, 'userHasNoRoles') === true;

        const loginFailed = get(loginRequest, 'networkStatus') === 'failed';
        const loginLoading = get(loginRequest, 'networkStatus') === 'loading';

        // Redirect back to '/' if the user is logged in but opened the login page
        if (authenticationToken && !userHasNoRoles && !needsOtp) {
            return <Redirect data-test="redirect" to={{ pathname: '/', state: null }} />;
        }

        return (
            <React.Fragment>
                <Helmet title="Login" />
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <Card className="p-4">
                                    <CardImg
                                        src="/images/logo.svg"
                                        alt="AMS-IX Logo"
                                        top
                                        style={{ padding: '0 15% 1rem 15%' }}
                                    />
                                    <hr />
                                    { !authenticationToken && (
                                        <CardBody >
                                            <Formik
                                                data-test="form"
                                                initialValues={{
                                                    username: '',
                                                    password: '',
                                                    grant_type: 'password'
                                                }}
                                                onSubmit={onSubmit}
                                            >
                                                {({
                                                      values,
                                                      errors,
                                                      touched,
                                                      handleChange,
                                                      handleBlur,
                                                      handleSubmit
                                                  }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <h1>{process.env.REACT_APP_APPLICATION_NAME} {t('login.title')}</h1>
                                                        <InputGroup className="mb-3">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-user" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                type="text"
                                                                placeholder={t('login.usernamePlaceholder')}
                                                                autoComplete="username"
                                                                name="username"
                                                                id="username"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                invalid={loginFailed}
                                                                autoFocus
                                                                required
                                                            />
                                                        </InputGroup>
                                                        <InputGroup className="mb-4">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-lock" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                type="password"
                                                                placeholder={t('login.passwordPlaceholder')}
                                                                autoComplete="current-password"
                                                                name="password"
                                                                id="password"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                invalid={loginFailed}
                                                                required
                                                            />
                                                        </InputGroup>
                                                        <Row>
                                                            <Col xs="6">
                                                                <LoaderButton
                                                                    data-test="login-submit-btn"
                                                                    type="submit"
                                                                    loading={loginLoading}
                                                                >
                                                                    {t('login.submitBtn')}
                                                                </LoaderButton>
                                                            </Col>
                                                            <Col xs="6" className="text-right">
                                                                <Button
                                                                    type="button"
                                                                    color="link"
                                                                    className="px-0"
                                                                    onClick={this.openForgotPasswordPage}
                                                                    data-test="forgot-password-btn"
                                                                >
                                                                    {t('login.forgotPassword')}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    )}
                                    {authenticationToken && authenticationToken.access_token.length > 0 && needsOtp && (
                                        <CardBody>
                                            <Formik
                                                data-test="form"
                                                initialValues={{
                                                    grant_type: 'otp',
                                                    otp_code: '',
                                                    access_token: authenticationToken.access_token
                                                }}
                                                onSubmit={submitOtp}
                                            >
                                                {({
                                                      values,
                                                      errors,
                                                      touched,
                                                      handleChange,
                                                      handleBlur,
                                                      handleSubmit
                                                  }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <h1>{process.env.REACT_APP_APPLICATION_NAME} MFA</h1>
                                                        <InputGroup className="mb-3">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    OTP
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                type="text"
                                                                pattern="\d*"
                                                                maxLength={6}
                                                                minLength={6}
                                                                placeholder={'OTP'}
                                                                autoComplete="One Time Password"
                                                                name="otp_code"
                                                                id="otp_code"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                invalid={loginFailed}
                                                                autoFocus
                                                                required
                                                            />
                                                        </InputGroup>
                                                        <Row>
                                                            <Col xs="9">
                                                                <LoaderButton
                                                                    data-test="login-submit-btn"
                                                                    type="submit"
                                                                    loading={loginLoading}
                                                                >
                                                                    Confirm OTP
                                                                </LoaderButton>
                                                            </Col>
                                                            <Col xs="3 text-align-right">
                                                                <Button
                                                                    data-test="cancel-btn"
                                                                    onClick={resetToken}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardBody>
                                    )}
                                    {process.env.REACT_APP_SHOW_POWERED_BY_AMSIX === 'true' && (
                                        <div className="d-flex justify-content-end">
                                            <CardImg
                                                src="/images/powered-by-ams-ix.png"
                                                alt="AMS-IX Logo"
                                                bottom
                                                style={{ width: '250px' }}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ReduxState) => ({
    authenticationToken: state.app.authenticationToken,
    loginRequest: getApiDataRequest(state.apiData, 'postLoginInfo'),
    loginRedirect: state.app.loginRedirect,
    needsOtp: state.app.needsOtp,
});

const mapDispatchToProps = (dispatch: any, ownProps: Props & RouteChildrenProps) => ({
    onSubmit: (values: { [field: string]: any }) => {
        ownProps.history.replace(ownProps.location.pathname, null);
        dispatch(performApiRequest('postLoginInfo', {}, values));
        return true;
    },
    resetToken: () => {
        dispatch(updateAppPathAction('authenticationToken', undefined));
    },
    submitOtp: (values: {[field: string]: any}) => {
        ownProps.history.replace(ownProps.location.pathname, null);
        dispatch(performApiRequest('otpConfirm', {}, values));
        return true;
    },
});

export default compose<WithTranslation & RouteChildrenProps & Props, Props>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    withRouter
)(Login);
