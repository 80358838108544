import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { performApiRequest } from 'react-api-data';
import { Roles, TransitionsType, VlanStatus, VlanUse } from 'constants/literals';
import { PublicVlanItem } from 'constants/api';
import { DropdownItem } from 'reactstrap';
import BaseTransition from 'components/resourceTransitions/BaseTransition';

type OutProps = {
    vlan: PublicVlanItem;
};

type Props = OutProps & PropsFromRedux;

function VlanResourceTransitions(props: Props) {
    const {
        vlan,
        configureVlan,
        deconfigureVlan,
        requestConfigurationSuccessful,
        requestConfigurationFailed,
        requestDeconfigurationSuccessful,
        requestDeconfigurationFailed,
    } = props;
    const { t } = useTranslation();
    const roles = [Roles.admin, Roles.noc];
    if (vlan.use === VlanUse.Customer) {
        roles.push(Roles.ixaas_client);
    }
    const transitions = [
        {
            type: TransitionsType.configure,
            name: 'configure',
            statuses: [VlanStatus.unconfigured],
            action: configureVlan,
        },
        {
            type: TransitionsType.configuration_successful,
            name: 'configurationSuccessful',
            statuses: [VlanStatus.configuring],
            action: requestConfigurationSuccessful,
        },
        {
            type: TransitionsType.configuration_failed,
            name: 'configurationFailed',
            statuses: [VlanStatus.configuring],
            action: requestConfigurationFailed,
        },
        {
            type: TransitionsType.deconfigure,
            name: 'deconfigure',
            statuses: [VlanStatus.configured],
            action: deconfigureVlan,
        },
        {
            type: TransitionsType.deconfiguration_successful,
            name: 'deconfigurationSuccessful',
            statuses: [VlanStatus.deconfiguring],
            action: requestDeconfigurationSuccessful,
        },
        {
            type: TransitionsType.deconfiguration_failed,
            name: 'deconfigurationFailed',
            statuses: [VlanStatus.deconfiguring],
            action: requestDeconfigurationFailed,
        },
    ];

    return (
        <>
            {transitions.map((transition) => (
                <BaseTransition
                    key={transition.type}
                    currentStatus={vlan.status}
                    transitionType={transition.type}
                    transitionName={transition.name}
                    statuses={transition.statuses}
                    roles={roles}
                    modalHeaderTitle={`vlanTransitions.${transition.name}HeaderTitle`}
                    modalBodyTitle={`vlanTransitions.${transition.name}BodyText`}
                    onConfirm={transition.action}
                    renderTransitionBtn={(props) => (
                        <DropdownItem onClick={props.onClick} toggle={false}>
                            {t(`vlanTransitions.${transition.name}`)}
                        </DropdownItem>
                    )}
                />
            ))}
        </>
    );
}

const mapDispatchToProps = (dispatch: any, ownProps: OutProps) => {
    const vlanUuid = ownProps.vlan.uuid;

    return {
        configureVlan: () => dispatch(performApiRequest('putVlanConfigure', { vlanUuid })),
        deconfigureVlan: () => dispatch(performApiRequest('putVlanDeconfigure', { vlanUuid })),
        requestConfigurationSuccessful: () =>
            dispatch(performApiRequest('putVlanConfigurationSuccessful', { vlanUuid })),
        requestConfigurationFailed: () => dispatch(performApiRequest('putVlanConfigurationFailed', { vlanUuid })),
        requestDeconfigurationSuccessful: () =>
            dispatch(performApiRequest('putVlanDeconfigurationSuccessful', { vlanUuid })),
        requestDeconfigurationFailed: () => dispatch(performApiRequest('putVlanDeconfigurationFailed', { vlanUuid })),
    };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// NOTE: We use compose here only to define prop types on the wrapped component
const enhance = compose<Props, OutProps>(connector);

export default enhance(VlanResourceTransitions);
