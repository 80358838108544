import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { ApiDataRequest, getApiDataRequest, performApiRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// constants
import { Exchanges, PublicVlanItem } from 'constants/api';
import { ReduxState } from 'createStore';
import { getVlansTableColumns } from 'constants/columns';
import { defaultPaginationOptions } from 'constants/pagination';
import usePreselectExchange from 'hooks/usePreselectExchange';
import i18n from 'locales/i18next';

// redux
import { updateAppPathAction } from 'redux/app/appActions';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';

// components
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import InteractiveTable from 'components/utils/InteractiveTable';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';

interface InProps {
    exchangesData: Exchanges;
    fetchIpRanges: (exchange: string) => void;
}

interface ConnectProps {
    vlansRequest: ApiDataRequest;
    selectedExchange: string;
    searchTerm: string;
    selectExchange: (selectedExchange: string) => void;
    updateSearchTerm: (searchTerm?: string) => void;
    fetchVlansRequest: (selectedExchange: string) => boolean;
}

type ComponentProps = InProps & ConnectProps & WithTranslation;

interface VlansTableProps {
    vlansRequest: ApiDataRequest;
    searchTerm?: string;
    exchange: string;
    t: i18n.TFunction;
}

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    selectedExchange: get(state, 'app.vlans.selectedExchange', ''),
    searchTerm: get(state, 'app.vlans.searchTerm', ''),
    vlansRequest: getApiDataRequest(state.apiData, 'getVlans', {
        exchange: get(state, 'app.vlans.selectedExchange', '')
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => {
    return {
        fetchVlansRequest: (selectedExchange: string) => {
            if (!selectedExchange) return true;
            dispatch(performApiRequest('getVlans', {
                exchange: selectedExchange
            }));
            return true;
        },
        selectExchange: (selectedExchange: string) => {
            ownProps.fetchIpRanges(selectedExchange);
            dispatch(updateAppPathAction('vlans.selectedExchange', selectedExchange));

        },
        updateSearchTerm: debounce((searchTerm: string = '') => {
            dispatch(updateAppPathAction('vlans.searchTerm', searchTerm));
        }, 500)
    };
};

const enhance = compose<ComponentProps, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);
const filterSearchData = memoizeOne((data: PublicVlanItem[], searchTerm: string): PublicVlanItem[] => {
    if (!searchTerm || !data) {
        return data;
    }
    return filter(data, (vlan: PublicVlanItem) => {
        return `${vlan.description} ${vlan.number}`.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    });
});

export const VlansTable: FunctionComponent<VlansTableProps> = ({ vlansRequest, searchTerm = '', exchange, t }) => (
    <RequestStatusRenderer
        request={vlansRequest}
        failedMessage={t('vlans.noVlans')}
        defaultMessage={t('validation.exchangeNotSelected')}
        success={() => {
            const vlansByExchange = get(vlansRequest, 'result.data.vlans');
            const vlansData = filterSearchData(vlansByExchange, searchTerm);
            const columns = getVlansTableColumns();
            return (
                <InteractiveTable
                    data-test="vlans-interactive-table"
                    classes="table-responsive-lg"
                    keyField="uuid"
                    columns={columns}
                    data={vlansData}
                    defaultSorted={[{
                        dataField: 'status',
                        order: 'asc',
                    }]}
                    paginationOptions={{
                        ...defaultPaginationOptions,
                        sizePerPageRenderer: sizePerPageRenderer('up'),
                    }}
                />
            );
        }}
    />
);

export const VlansList: FunctionComponent<ComponentProps> = ({
    exchangesData, fetchVlansRequest, vlansRequest,
    selectedExchange, selectExchange, t, searchTerm, updateSearchTerm, fetchIpRanges
}) => {
    const changeExchange = (exchangeValue: string) => {
        selectExchange(exchangeValue);
        fetchIpRanges(exchangeValue);
        fetchVlansRequest(exchangeValue);
    };

    const handleExchangeChange = (exchange: React.ChangeEvent<HTMLInputElement>) => {
        const exchangeValue = exchange.target.value;
        changeExchange(exchangeValue);
        updateSearchTerm(); // reset search term when Exchange had been changed
    };

    useEffect(() => {
        if (!!selectedExchange) {
            fetchVlansRequest(selectedExchange);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    usePreselectExchange(selectedExchange, exchangesData, changeExchange);

    return (
        <>
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between">
                        <Col xl={3}>
                            <Input
                                data-test="search-term-control"
                                className="form-control"
                                placeholder={t('table.searchInputPlaceholder')}
                                defaultValue={searchTerm}
                                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                    updateSearchTerm(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xl={3} className="text-right">
                            <ExchangeSelect
                                name="exchange"
                                defaultValue={selectedExchange}
                                exchanges={exchangesData}
                                onChange={handleExchangeChange}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <VlansTable
                        data-test="vlans-table"
                        vlansRequest={vlansRequest}
                        searchTerm={searchTerm}
                        exchange={selectedExchange}
                        t={t}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default enhance(VlansList);
