import React, { useMemo } from 'react';
import { Port } from 'constants/api';
import Select from 'react-select';

interface Props {
    ports: Port[];
    onChange: (port: Port) => void;
    className?: string;
}

interface Option {
    value: number;
    label: string;
}

function PortSelect({ ports, onChange, className }: Props) {
    const options = useMemo(() => {
        return ports.map((port) => ({
            label: `${port.fqid}/${port.portnumber}`,
            value: port.id,
        }));
    }, [ports]);

    function handleChange(option: Option) {
        const port = ports.find((port) => port.id === option.value);
        onChange(port!);
    }

    return (
        <Select
            options={options}
            onChange={handleChange}
            filterOption={(option: Option, inputValue: string) => option.label.includes(inputValue)}
            className={className}
        />
    );
}

export default PortSelect;
