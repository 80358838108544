import {
    Button,
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Field, Formik, FormikActions } from 'formik';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import RolesChecker from 'components/utils/RolesChecker';
import { CenteredAlert } from 'components/styled';
import { ApiResponse, Location as DataCenter, ReactApiDataActions, SpeedAndInterfaceTypes } from 'constants/api';
import SpeedSelect from 'components/utils/SpeedSelect';
import { BaseModalState } from './BaseModal';
import CloseButton from 'components/utils/CloseButton';
import styled from 'styled-components';

interface InputProps {
    dataCenterObject: DataCenter;
    handleSync: () => void;
    exchangeUuid: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    dataCenterRequest: ApiDataBinding<ApiResponse<DataCenter>>;
    apiDataActions: ReactApiDataActions;
    speedAndInterfaceTypes: ApiDataBinding<{data: {speed_and_interface_types: SpeedAndInterfaceTypes[]}}>;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

type Destroyable<T> = T & {
    id?: number;
    frontend_id?: number;
    _destroy?: boolean;
}

const enhance = compose<Props, InputProps & BaseFormModalProps>(
    withApiData({
        dataCenterRequest: 'putDataCenter',
        speedAndInterfaceTypes: 'getAvailableSpeedsAndInterfaceTypes'
    }),
    withTranslation()
);

interface State extends BaseModalState {
    showNewSpeedSelect: boolean;
}

type FormInitialValuesType = {
    long_name: string;
    short_name: string;
    active: boolean;
    speed_and_interface_types: SpeedAndInterfaceTypes[];
    country: string;
    city: string;
    address1: string;
    contact_name: string;
    contact_email: string;
    phone_number: string;
};

const generateId = (function () {
    let id = 1;
    return function () {
        return id++;
    };
})();

class DataCenterEditModal extends BaseFormModal<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showNewSpeedSelect: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        this.handleFormSubmission(prevProps.dataCenterRequest.request, this.props.dataCenterRequest.request, {
            pathToError: 'errorBody.errors',
            successMessage: this.props.t('dataCenterModal.editSuccessMessage'), 
        });
    }

    _handleClose = () => {
        this.setState({ showNewSpeedSelect: false });
        this.handleModalClose();
    };

   _handleOnSubmit = (values: any, formikBag: FormikActions<FormInitialValuesType>) => {
        const { dataCenterRequest, apiDataActions, handleSync, dataCenterObject, exchangeUuid } = this.props;
        
        const { speed_and_interface_types, ...params } = values;
        params['speed_and_interface_type_ids'] = values.speed_and_interface_types.map((speed: SpeedAndInterfaceTypes) => (speed.id.toString()));

        dataCenterRequest.perform({ locationUuid: dataCenterObject.uuid },{ location: params }).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                handleSync();
                apiDataActions.invalidateCache('getDataCenters');
                apiDataActions.invalidateCache('getExchange', {uuid: exchangeUuid});
                apiDataActions.perform('getExchange', {uuid: exchangeUuid});
            }
        });
    };

    _getSpeedAndInterfaceTypes = (selectedSpeeds: Destroyable<SpeedAndInterfaceTypes>[]) => {
        const availableSpeeds = this.props.speedAndInterfaceTypes!.data!;
        const speedValuesFromDB = availableSpeeds
        ? Array.from(
              new Set(
                  availableSpeeds!
                      .data!.speed_and_interface_types!.map((item: any) => item).sort((a, b) => (a.speed > b.speed ? 1 : -1))
              )
          )
        : [];

        const selectedSpeedsIds = selectedSpeeds.map((speed) => speed.id);
        return speedValuesFromDB.filter((speed) => !selectedSpeedsIds.includes(speed.id));
    };

    _removeResource = (resources: Destroyable<any>[], resource: Destroyable<any>) => {
        if (resource.id) {
            return resources.map((current) => {
                if (current.id === resource.id) {
                    return { ...current, _destroy: true };
                } else {
                    return current;
                }
            });
        } else if (resource.frontend_id) {
            return resources.filter((current) => current.frontend_id !== resource.frontend_id);
        } else {
            throw new Error('Cannot identify the resource');
        }
    };

    _filterRemoved = (resources: Destroyable<any>[]) => resources.filter(resource => !resource._destroy);

    render() {
        const { dataCenterObject, t } = this.props;

        return (
            <>
                <Formik
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            long_name: dataCenterObject.long_name,
                            short_name: dataCenterObject.short_name,
                            active: dataCenterObject.active,
                            speed_and_interface_types: dataCenterObject.speed_and_interface_types,
                            country: dataCenterObject.country,
                            city: dataCenterObject.city,
                            address1: dataCenterObject.address1,
                            contact_name: dataCenterObject.contact_name,
                            contact_email: dataCenterObject.contact_email,
                            phone_number: dataCenterObject.phone_number,
                        } as FormInitialValuesType
                    }
                    onSubmit={this._handleOnSubmit}
                >
                    {({ values, errors, isSubmitting, touched, handleSubmit, setFieldValue, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this._handleClose}
                            handleSubmit={handleSubmit}
                            header={t('dataCenterModal.headerTitleEdit')}
                            body={
                                <>
                                    <div>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="long_name">{t('dataCenterModal.longName')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="long_name" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="short_name">{t('dataCenterModal.shortName')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="short_name" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="active">{t('dataCenterModal.active')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field type="checkbox" name="active" checked={values.active} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="speed_and_interface_types">{t('dataCenterModal.speeds')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                {values.speed_and_interface_types.length > 0 && (
                                                    <div className="d-flex flex-column mb-2">
                                                        {values.speed_and_interface_types.map((speed) => (
                                                            <StyledMap
                                                                key={speed.id}
                                                                className="d-flex align-items-center mb-2"
                                                            >
                                                                <div style={{ width: 120 }}>{speed.name}</div>
                                                                <CloseButton
                                                                    onClick={() => {
                                                                        const newSpeeds = this._removeResource(
                                                                            values.speed_and_interface_types,
                                                                            speed
                                                                        );
                                                                        setFieldValue('speed_and_interface_types', this._filterRemoved(newSpeeds));
                                                                    }}
                                                                    className="ml-2"
                                                                />
                                                            </StyledMap>
                                                        ))}
                                                    </div>
                                                )}
                                                {!this.state.showNewSpeedSelect && (
                                                    <span
                                                        className="cursor-pointer text-primary"
                                                        onClick={() => this.setState({ showNewSpeedSelect: true })}
                                                    >
                                                        <i className="fas fa-plus" /> {t('dataCenterModal.addSpeed')}
                                                    </span>
                                                )}
                                                {this.state.showNewSpeedSelect && (
                                                    <div className="d-flex align-items-center justfy-content-start">
                                                        <SpeedSelect
                                                            speedAndInterfaceTypes={
                                                                this._getSpeedAndInterfaceTypes(values.speed_and_interface_types) || []
                                                            }
                                                            onChange={(speed) => {
                                                                const newSpeed = {
                                                                    frontend_id: generateId(),
                                                                    id: speed.id,
                                                                    name: speed.name,
                                                                    speed: speed.speed,
                                                                };
                                                                setFieldValue('speed_and_interface_types', [...values.speed_and_interface_types, newSpeed]);
                                                                this.setState({ showNewSpeedSelect: false });
                                                            }}
                                                            className="flex-grow-1"
                                                        />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => this.setState({ showNewSpeedSelect: false })}
                                                            className="ml-2"
                                                        >
                                                            {t('modal.cancelBtn')}
                                                        </Button>
                                                    </div>
                                                )}
                                            </Col>
                                        </FormGroup>
                                        <hr></hr>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="country">{t('dataCenterModal.country')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="country" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="city">{t('dataCenterModal.city')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="city" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="address">{t('dataCenterModal.address')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="address1" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <hr></hr>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="contact">{t('dataCenterModal.contactName')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="contact_name" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="email">{t('dataCenterModal.contactEmail')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="contact_email" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="phone">{t('dataCenterModal.phoneNumber')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="phone_number" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <CenteredAlert color="warning">
                                            {t('dataCenterModal.noteMandatoryFields')}
                                        </CenteredAlert>
                                    </div>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker roles={[Roles.admin, Roles.noc]}>
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('dataCenterModal.editBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

const StyledMap = styled.div`  
        font-size: 12px;
`;

export default enhance(DataCenterEditModal);
