import React from 'react';
import { FieldAttributes, Field } from "formik";
import styled from "styled-components";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

function Checkbox(props: Omit<FieldAttributes<any>, 'type'>) {
    return <Field type="checkbox" {...props} />;
}

export default styled(Checkbox)`
    width: 18px;
    height: 18px;
`;