import React, { ReactNode } from 'react';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { object, string } from 'yup';
import { ApiDataRequest, getApiDataRequest, performApiRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { ContractType, Roles } from 'constants/literals';

// validation
import { createLettersValidation, createPhoneNumberValidation } from '../../validation';

// hocs
import withUserRoles from '../../hocs/withUserRoles';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from 'components/modals/BaseFormModal';
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import PhoneFormControl from 'components/utils/PhoneFormControl';
import { isAdmin, isIxaasClient, isNoc } from '../../utils/userUtils';

interface InputProps {
    contractUuid: string;
    contractType: ContractType;
    exchange: string;
}

interface ConnectProps {
    onSubmit: (values: any) => boolean;
    submission: ApiDataRequest;
    userRoles: Roles[];
}

type ModalProps = InputProps & ConnectProps & BaseFormModalProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: ModalProps) => ({
    submission: getApiDataRequest(state.apiData, 'postUserForContract', {
        exchange: ownProps.exchange.toUpperCase(),
        contractUuid: ownProps.contractUuid,
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: ModalProps) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('postUserForContract', {
                exchange: ownProps.exchange.toUpperCase(),
                contractUuid: ownProps.contractUuid,
            }, { ...values, phone: values.phone.replace(/\s/g, '') }));
            return true;
        }
    };
};

class UserCustomerAddModal extends BaseFormModal<ModalProps> {

    componentDidUpdate(prevProps: Readonly<ModalProps>): void {
        this.handleFormSubmission(prevProps.submission, this.props.submission, {
            successMessage: this.props.t('notification.addUserSuccessful'),
            pathToError: 'errorBody.errors'
        });
    }

    render() {
        const { onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        phone: '',
                        username: '',
                        email: '',
                        role: Roles.customer,
                    }}
                    validationSchema={object().shape({
                        first_name: createLettersValidation(t('validation.firstNameInvalid')).required(t('validation.firstNameRequired')),
                        last_name: createLettersValidation(t('validation.lastNameInvalid')).required(t('validation.lastNameRequired')),
                        phone: createPhoneNumberValidation(t).required(t('validation.phoneNumberRequired')),
                        username: string().required(t('validation.userNameRequired')),
                        email: string().email(t('validation.emailInvalid')).required(t('validation.emailRequired')),
                    })}
                    onSubmit={onSubmit}
                    render={({ isSubmitting, handleSubmit, errors, touched, setFieldValue }) => (
                        <BaseFormModalTemplate
                            data-test="user-add-modal-tmpl"
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('contract.addUserTitle')}
                            body={<>
                                {this.renderControlRow({ name: 'first_name', label: 'common.firstName', touched, errors })}
                                {this.renderControlRow({ name: 'last_name', label: 'common.lastName', touched, errors })}
                                {this.renderControlRow({
                                    name: 'phone', label: 'common.phone', touched, errors,
                                    control: (
                                        <PhoneFormControl
                                            name="phone"
                                            errors={errors}
                                            touched={touched}
                                            setFieldValue={setFieldValue}
                                        />
                                    )
                                })}
                                {this.renderControlRow({ name: 'username', label: 'common.userName', touched, errors })}
                                {this.renderControlRow({ name: 'email', type: 'email', label: 'common.email', touched, errors })}
                                {this.renderControlRow(({
                                    name: 'role', label: 'common.role', touched, errors,
                                    control: (
                                        <FormControl
                                            component="select"
                                            name="role"
                                            errors={errors}
                                            touched={touched}
                                        >
                                            {this.getRolesForSelect().map((role) => (
                                                <option key={role} value={role}>
                                                    {t(`rolesMap.${role}`)}
                                                </option>
                                            ))}
                                        </FormControl>
                                    )
                                }))}
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                />
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.customer, Roles.noc, Roles.ixaas_client]}>
                    <Button
                        data-test="add-user-btn"
                        color="light"
                        onClick={this.toggle}
                    >
                        {t('contract.addUserTitle')}
                    </Button>
                </RolesChecker>
            </React.Fragment>
        );
    }

    getRolesForSelect(): Roles[] {
        const roles: Roles[] = [Roles.customer, Roles.customer_reader];
        const { userRoles, contractType } = this.props;

        if (isAdmin(userRoles) || isNoc(userRoles)) {
            roles.push(Roles.noc, Roles.qnoc, Roles.ixaas_client, Roles.ixaas_client_reader);
        }
        if (isAdmin(userRoles) && contractType === ContractType.Amsix) {
            roles.push(Roles.admin);
        }
        if (isIxaasClient(userRoles)) {
            roles.push(Roles.ixaas_client, Roles.ixaas_client_reader);
        }

        return roles;
    }

    private renderControlRow = ({
        name, type = 'text', errors, touched, label, control
    }: {
        name: string, type?: string, label: string, control?: ReactNode,
        errors: FormikErrors<FormikValues>, touched: FormikTouched<FormikValues>
    }): ReactNode => {
        return (
            <FormGroup row>
                <Col md={3}>
                    <Label htmlFor={name}>{this.props.t(label)}</Label>
                </Col>
                <Col md={9}>
                    {control ? (
                        control
                    ) : (
                        <FormControl
                            type={type}
                            name={name}
                            errors={errors}
                            touched={touched}
                        />
                    )}
                </Col>
            </FormGroup>
        );
    }
}

const enhance = compose<ConnectProps, InputProps & BaseFormModalProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    withUserRoles
);

export default enhance(UserCustomerAddModal);
