import {
    Button, Col, FormGroup, Label, Spinner
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router';
import {
    withApiData, ApiDataBinding
} from 'react-api-data';
import { object, string } from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Exchanges, ApiResponse, ContractCreateResponse } from 'constants/api';
import { Roles, NetworkRequestStatus, } from 'constants/literals';

// utils
import { ExchangeFormikSelect } from 'components/utils/ExchangeSelect';
import { getLinkToContract, getOnlyOneExchangeName } from 'utils/linksUtils';

// components
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import ReservedForTestingCheckbox from 'components/utils/ReservedForTestingCheckbox';

interface InProps {
    exchangesData: Exchanges;
}

interface EnhanceProps {
    postContract: ApiDataBinding<ApiResponse<ContractCreateResponse>>;
}

interface WithStateProps {
    targetExchange: string;
    setTargetExchange: (exchange: string) => void;
}

type Props = EnhanceProps & InProps & WithStateProps & BaseFormModalProps & RouteComponentProps & WithTranslation;

type FormInitialValuesType = {
    erp_customer_identifier: string;
    erp_customer_name: string;
    erp_contract_identifier: string;
    name: string;
    reserved_for_testing: boolean;
    exchange: string;
};

const enhance = compose<Props, InProps & BaseFormModalProps>(
    withApiData({ postContract: 'postContract' }),
    withRouter,
    withTranslation()
);

class ContractAddModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.postContract.request, this.props.postContract.request, {
            successMessage: this.props.t('notification.customerCreated')
        });
    }

    _handleSubmit = (values: FormInitialValuesType) => {
        const { postContract } = this.props;
        const { exchange, ...params } = values;

        postContract.perform({ exchange: exchange }, params).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                const contractUuid = response.data!.data!.id;
                this.props.history.push(getLinkToContract(exchange, contractUuid));
            }
        });
    };

    render() {
        const { exchangesData, t } = this.props;
        if (!exchangesData) return <Spinner data-test="spinner" />;

        return (
            <>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            erp_customer_identifier: '',
                            erp_contract_identifier: '',
                            erp_customer_name: '',
                            name: '',
                            exchange: getOnlyOneExchangeName(exchangesData) || '',
                            reserved_for_testing: false,
                        } as FormInitialValuesType
                    }
                    validationSchema={object().shape({
                        erp_customer_name: string().required(t('validation.erpCustomerNameRequired')),
                        exchange: string().required(t('validation.exchangeRequired'))
                    })}
                    onSubmit={this._handleSubmit}
                >
                    {({ values, handleSubmit, isSubmitting, errors, touched }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('customers.addCustomerTitle')}
                            body={<>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="erp_customer_name">{t('customers.erpCustomerName')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            type="text"
                                            name="erp_customer_name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="erp_customer_identifier">{t('customers.erpCustomerIdentifier')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            type="text"
                                            name="erp_customer_identifier"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="name">{t('customers.name')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            type="text"
                                            name="name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="erp_contract_identifier">{t('customers.erpContractIdentifier')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            type="text"
                                            name="erp_contract_identifier"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="exchange">{t('common.exchange')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <ExchangeFormikSelect 
                                            name="exchange"
                                            touched={touched}
                                            errors={errors}
                                            exchanges={exchangesData}
                                        />
                                    </Col>
                                </FormGroup>

                                <ReservedForTestingCheckbox checked={values.reserved_for_testing} />
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.ixaas_client]}>
                    <Button
                        data-test="add-customer-btn"
                        className="float-right"
                        color="light"
                        onClick={this.toggle}
                    >
                        {t('customers.addCustomerBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

export default enhance(ContractAddModal);
