import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';

// utils
import { getLinkToConnection, isConnectionDeletable } from 'utils/linksUtils';
import { formatSpeed, getStatusColor } from 'utils/formatUtils';

// components
import Exchange from 'components/utils/Exchange';

// constants
import { LinkFull } from 'constants/api';
import i18next from 'locales/i18next';
import ConnectionDeleteModal from 'components/modals/ConnectionDeleteModal';

interface ColumnsConfig {
    onLinkClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void | undefined;
    isStatusHidden?: boolean;
    isCloudHidden?: boolean;
}

export const getConnectionTableColumns = memoizeOne((invalidateData: () => void, config?: ColumnsConfig, extendColumns: any[] = []): any[] => {
    return [...[{
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (cell?: string) => {
            return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
        },
        hidden: get(config, 'isStatusHidden', false),
    }, {
        dataField: 'cloud',
        boolIcon: 'cloud',
        text: i18next.t('connection.cloudServices'),
        sort: false,
        align: 'center',
        headerAlign: 'center',
        hidden: get(config, 'isCloudHidden', false),
    }, {
        dataField: 'xml_id',
        text: i18next.t('connection.xmlIdLabel'),
        sort: true,
        copy: true
    }, {
        dataField: 'organisation_name',
        text: i18next.t('connection.organisation'),
        sort: false,
        copy: true,
    }, {
        dataField: 'speed',
        text: i18next.t('common.speed'),
        sort: false,
        formatter: formatSpeed
    }, {
        dataField: 'classification',
        text: i18next.t('connection.classificationLabel'),
        sort: true,
        copy: true,
    }, {
        dataField: 'location',
        text: i18next.t('common.location'),
        sort: false,
        copy: true
    }, {
        dataField: 'exchange.short_name',
        text: i18next.t('common.exchange'),
        sort: false,
        formatter: (cell: any) => <Exchange shortName={cell} />
    }, {
        dataField: 'link',
        isDummyField: true,
        text: i18next.t('common.link'),
        formatter: (_: any, row: LinkFull) => (
            <Link
                to={getLinkToConnection(row.exchange.short_name, row.uuid)}
                onClick={config && isFunction(config.onLinkClick) ? config.onLinkClick : undefined}
            >
                {i18next.t('common.view')}
            </Link>
        )
    },{
        dataField: 'remove',
        isDummyField: true,
        text: i18next.t('common.remove'),
        formatter: (_: any, row: LinkFull) => {
            if(isConnectionDeletable(row.status)) {
                return (
                    <ConnectionDeleteModal
                        uuid={row.uuid}
                        xmlId={row.xml_id}
                        exchange={row.exchange.short_name}
                        invalidateData={invalidateData}
                    />
                );
            }
        }
    }, {
        dataField: 'contract_name',
        text: i18next.t('contract.contractTitle'),
        hidden: true
    }, {
        dataField: 'handle',
        text: i18next.t('common.handle'),
        hidden: true
    }], ...extendColumns];
}, isEqual);
