import React from 'react';
import { useTranslation } from 'react-i18next';
import SyncButton from 'components/utils/SyncButton';

interface Props {
    children: string;
    onSync: () => void;
}

function TabTitle({ children, onSync }: Props) {
    const { t } = useTranslation();

    return (
        <h2>
            <span>
                {children}
                <SyncButton
                    data-test="refresh-btn"
                    className="ml-2"
                    title={t('globalSearch.refreshButton')}
                    onClick={onSync}
                />
            </span>
        </h2>
    );
}

export default TabTitle;
