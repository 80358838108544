import React from 'react';
import { PortDetails, ApiResponse, Event } from "constants/api";
import { ApiDataBinding, withApiData } from "react-api-data";
import { compose } from "recompose";
import { FunctionComponent } from "react";
import ActivityLog from "components/cards/ActivityLog";
import { Roles } from "constants/literals";

interface InProps {
    port: PortDetails;
    userRoles: Roles[];
}

interface EnhanceProps {
    portEventsRequest: ApiDataBinding<ApiResponse<{ events: Event[] }>>;
}

type Props = InProps & EnhanceProps;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            portEventsRequest: 'getPortEvents'
        },
        (ownProps: InProps) => ({
            portEventsRequest: { portId: ownProps.port.id, per_page: 10 }
        })
    )
);

const PortActivityLog: FunctionComponent<Props> = ({ portEventsRequest, userRoles }) => {
    return (<ActivityLog eventsApiBinding={portEventsRequest} userRoles={userRoles} />);
};

export default enhance(PortActivityLog);
