import { FunctionComponent, useState } from 'react';
import { Button, Card, CardHeader } from 'reactstrap';
import React from 'react';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Event, ApiResponse } from 'constants/api';
import { NetworkRequestStatus, Roles } from 'constants/literals';
import { hasSomeRole, isIxaasClient } from 'utils/userUtils';

import { BaseModalLarge } from 'components/styled';
import Tooltip from 'components/utils/Tooltip';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import StyledCopyIcon from '../styled/StyledCopyIcon';

import InteractiveRemoteTable from 'components/utils/InteractiveRemoteTable';
import { ApiDataBinding } from 'react-api-data';
import { get } from 'lodash';

interface Props {
    eventsApiBinding: ApiDataBinding<ApiResponse<{ events: Event[] }>>;
    userRoles: Roles[];
}

const ActivityLog: FunctionComponent<Props & WithTranslation> = ({ eventsApiBinding, t, userRoles }) => {
    const [activityModalOpen, setActivityModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [selectedLinkEvent, setSelectedLinkEvent] = useState<Event | null>(null);

    const toggleActivityLogModal = (linkEvent?: Event) => {
        if (!linkEvent) {
            setActivityModalOpen(false);
        } else {
          setSelectedLinkEvent(linkEvent);
          setActivityModalOpen(!activityModalOpen);
        }
    };

    const toggleErrorModal = (linkEvent?: Event) => {
        if (!linkEvent) {
          setErrorModalOpen(false)
        } else {
          setSelectedLinkEvent(linkEvent)
          setErrorModalOpen(!errorModalOpen)
        }
    }

    const copyBodyValue = (body: string) => {
        if (copy(body.trim())) {
            toast.success(
                <>{t('table.copyToClipboard')}<br/>{body.trim().substr(0, 100)}...</>,
                { autoClose: 2000 }
            );
        }
    }

    const isLogIconVisible = hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.qnoc) ||
                            (isIxaasClient(userRoles) && process.env.REACT_APP_IXAAS_CLIENT_SHOW_LOGS === 'true');

    const columnOptions = [
        {
            dataField: 'id',
            text: t('activityLog.idColumn'),
            hidden: true
        },
        {
            dataField: 'created_at',
            text: t('activityLog.timeColumn'),
            sort: true,
            style: {
                whiteSpace: 'nowrap'
            },
            formatter: (cell: string) => {
                return moment(cell).format('YYYY-MM-DD HH:mm:ss');
            }
        }, 
        {
            dataField: 'creator_username',
            text: t('activityLog.creatorColumn'),
            sort: true,
            style: {
                whiteSpace: 'nowrap'
            },
        }, 
        {
            dataField: 'activity',
            text: t('activityLog.typeColumn'),
        }, 
        {
            dataField: 'log',
            text: '',
            formatter: (_cell: string, row: Event) => {
                if (isLogIconVisible) {
                    return (
                        <div>
                            {row.error_message && (
                                <Tooltip
                                    triggerBtn={<i className="fas fa-minus-circle cursor-pointer text-danger mr-2" onClick={() => toggleErrorModal(row)} />}
                                >
                                    {t('activityLog.viewError')}
                                </Tooltip>
                            )}
                    {row.log && (
                        <Tooltip
                            data-test="view-log-tooltip"
                            triggerBtn={<i className="fas fa-eye cursor-pointer text-primary" onClick={() => toggleActivityLogModal(row)} />}
                        >
                            {t('activityLog.viewLog')}
                        </Tooltip>
                    )}
                </div>
                    );
                }
            }
        }
    ];

    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <strong>{t('activityLog.headerTitle')}</strong>
            </CardHeader>
            <InteractiveRemoteTable
                data-test="activity-table"
                keyField="id"
                data={get(eventsApiBinding, 'data.data.events', [])}
                columns={columnOptions}
                paginationOptions={{
                    showTotal: false,
                    hideSizePerPage: false,
                    page: get(eventsApiBinding, 'data.meta.page', 1),
                    sizePerPage: get(eventsApiBinding, 'data.meta.page_size', 10),
                    totalSize: get(eventsApiBinding, 'data.meta.total_items', 0),
                }}
                defaultSorted={{ dataField: 'created_at', order: 'desc' }}
                loading={eventsApiBinding.request.networkStatus === NetworkRequestStatus.Loading}
                fetchPage={(pageNumber, pageSize, sortField, sortOrder) => eventsApiBinding.perform({ page: pageNumber, per_page: pageSize, sort: sortField, order: sortOrder })}
            />
            <BaseModalLarge
                data-test="activity-modal"
                isOpen={activityModalOpen}
                toggle={() => toggleActivityLogModal()}
                header={<>
                    {t('activityLog.logModalTitle')} "{selectedLinkEvent ? selectedLinkEvent.activity : ''}" {
                        selectedLinkEvent &&
                        selectedLinkEvent.log &&
                        <StyledCopyIcon
                            className="fas fa-copy ml-2"
                            color="light"
                            onClick={() => copyBodyValue(`${selectedLinkEvent.log}`)}
                        />
                    }
                </>}
                body={<pre>{selectedLinkEvent && selectedLinkEvent.log && selectedLinkEvent.log.trim()}</pre>}
                footer={<Button color="light" onClick={() => toggleActivityLogModal()}>{t('modal.closeBtn')}</Button>}
            />
            <BaseModalLarge
                isOpen={errorModalOpen}
                toggle={() => toggleErrorModal()}
                header={t('activityLog.errorModalTitle')}
                body={selectedLinkEvent && selectedLinkEvent.error_message}
                footer={<Button color="light" onClick={() => toggleErrorModal()}>{t('modal.closeBtn')}</Button>}
            />
        </Card>

    );
};

export default withTranslation()(ActivityLog);
