import React, { FunctionComponent, useState } from 'react';
import { User, ApiResponse, ReactApiDataActions } from 'constants/api';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { Roles, NetworkRequestStatus } from 'constants/literals';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import { FormGroup } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import { object, string } from 'yup';
import StyledCheckbox from 'components/styled/StyledCheckbox';
import withUserRoles from 'hocs/withUserRoles';
import { hasRole } from 'utils/userUtils';
import withUser from 'hocs/withUser';

interface InProps {
    user: User;
}

interface EnhanceProps {
    userUpdateRequest: ApiDataBinding<ApiResponse<User>>;
    apiDataActions: ReactApiDataActions;
    userRoles: Roles[]
    currentUser: User;
}

type Props = InProps & EnhanceProps;

const enhance = compose<Props, InProps>(
    withApiData({ userUpdateRequest: 'putUser' }),
    withUserRoles,
    withUser
);

interface FormValues {
    first_name: string;
    last_name: string;
    email: string;
    active: boolean;
    mfa_enabled: boolean;
    role: Roles;
}

const UserEditModal: FunctionComponent<Props> = ({ currentUser, user, userRoles, userUpdateRequest, apiDataActions }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const onSubmit = (values: FormValues, setSubmitting: (isSubmitting: boolean) => void) => {
        const { role, ...valuesWithoutRole } = values;

        userUpdateRequest.perform(
            {
                userId: user.uuid
            },
            {
                user: { ...valuesWithoutRole, roles: [role] }
            }
        ).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getUser', { userId: user.uuid });
            } else {
                setSubmitting(false);
            }
        });
    };

    const selectableRoles = (): Roles[] => {
        let roles: Roles[] = Object.values(Roles);

        if (hasRole(userRoles, Roles.noc)) roles = roles.filter(role => role !== Roles.admin);
        if (hasRole(userRoles, Roles.qnoc)) roles = roles.filter(role => ![Roles.admin, Roles.noc].includes(role));
        if (hasRole(userRoles, Roles.ixaas_client)) roles = roles.filter(role => ![Roles.admin, Roles.qnoc, Roles.noc].includes(role));

        return roles;
    };

    return (
        <>
            <Formik
                initialValues={{
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    active: user.active,
                    mfa_enabled: user.mfa_enabled,
                    role: user.roles[0]
                } as FormValues}
                validationSchema={object().shape({
                    email: string().email(t('validation.emailInvalid'))
                })}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values, setSubmitting);
                }}
            >
                {({ errors, touched, isSubmitting, dirty, handleSubmit, values }) => (
                    <BaseFormModalTemplate
                        isOpen={isOpen}
                        toggle={toggle}
                        handleSubmit={handleSubmit}
                        header={t('userEditModal.headerTitle')}
                        body={
                            <>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="first_name">{t('common.firstName')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="last_name">{t('common.lastName')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="email">{t('common.email')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            type="email"
                                            name="email"
                                            id="email"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>

                                {currentUser.uuid !== user.uuid && (
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="role">{t('common.role')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                component="select"
                                                name="role"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                {selectableRoles().map(role => (
                                                    <option key={role} value={role}>
                                                        {t(`rolesMap.${role}`)}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                )}

                                <FormGroup row>
                                    <Col md="3">
                                        <Label className="mb-0" htmlFor="active">{t('common.active')}</Label>
                                    </Col>
                                    <Col md="9" className="d-flex align-items-center">
                                        <StyledCheckbox name="active" checked={values.active} />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Col md="3">
                                        <Label className="mb-0" htmlFor="mfa_enabled">{t('userDetails.mfaEnabled')}</Label>
                                    </Col>
                                    <Col md="9" className="d-flex align-items-center">
                                        {/* NOTE: Don't allow enabling someone's MFA outside the 'Setup MFA' feature */}
                                        <StyledCheckbox name="mfa_enabled" checked={values.mfa_enabled} disabled={!user.mfa_enabled} />
                                    </Col>
                                </FormGroup>
                            </>
                        }
                        footer={
                            <FormModalButtons
                                loading={isSubmitting}
                                toggle={toggle}
                                disabled={!dirty}
                            />
                        }
                    />
                )}
            </Formik>

            <Tooltip
                triggerBtn={
                    <i
                        className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                        onClick={toggle}
                    />
                }
            >
                {t('userEditModal.headerTitle')}
            </Tooltip>
        </>
    );
};

export default enhance(UserEditModal);
