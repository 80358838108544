import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Vlan } from 'constants/api';
import { Roles } from 'constants/literals';

// hocs
import withUserRoles from 'hocs/withUserRoles';

// components
import PageLoading from 'components/utils/PageLoading';
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import LinkVlanInfo from 'components/cards/LinkVlanInfo';

interface InputProps {
    exchange: string;
    linkVlanUuid: string;
}

interface Props extends InputProps, WithTranslation {
    linkVlanRequest: ApiDataBinding<Vlan>;
    userRoles: Roles[];
}

const enhance = compose<Props, InputProps>(
    withApiData(
        {
            linkVlanRequest: 'getLinkVlan',
        },
        (ownProps: Props) => ({
            linkVlanRequest: {
                linkVlanUuid: ownProps.linkVlanUuid,
                exchange: ownProps.exchange.toUpperCase(),
            },
        })
    ),
    withTranslation(),
    withUserRoles
);

export const LinkVlanDetails: FunctionComponent<Props> = ({
    linkVlanRequest,
    t,
    userRoles,
    exchange,
    linkVlanUuid,
}) => {
    return (
        <>
            <RequestStatusRenderer
                data-test="request-renderer"
                request={linkVlanRequest}
                defaultComponent={null}
                loading={<PageLoading />}
                failedMessage={t('connection.failedMessage')}
                success={() => {

                    const linkVlan = linkVlanRequest.data!;

                    return (
                        <>
                            <Helmet title={linkVlanUuid} />
                            <ViewContainer data-test="view-container">
                                <h2>
                                    {t('linkVlan.title')}
                                    <SyncButton
                                        data-test="refresh-btn"
                                        className="ml-2"
                                        title={t('connection.refreshConnectionBtn')}
                                        onClick={linkVlanRequest.invalidateCache}
                                    />
                                </h2>
                                <LinkVlanInfo
                                    data-test="vlan-info"
                                    linkVlan={linkVlan}
                                    exchange={exchange}
                                    userRoles={userRoles}
                                />
                            </ViewContainer>
                        </>
                    );
                }}
            />
        </>
    );
};

// @ts-ignore Possible error in react-api-data type definitions
export default enhance(LinkVlanDetails);
