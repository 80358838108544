import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull, ApiResponse, ReactApiDataActions } from 'constants/api';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import classNames from 'classnames';
import { IpRangeType, NetworkRequestStatus } from 'constants/literals';
import { validateIpAddress } from 'components/utils/IpValidator';

interface InputProps {
    exchange: string;
    link: LinkFull;
    renderModalToggleBtn?: (props: {onClick: () => void}) => ReactNode;
}

interface EnhanceProps {
    requestTesting: ApiDataBinding<ApiResponse<LinkFull>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    withApiData({ requestTesting: 'putResourceRequestTestingWithDifferentIp' }),
    withTranslation()
);

class LinkIpForTestingModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { t } = this.props;
        this.handleFormSubmission(prevProps.requestTesting.request, this.props.requestTesting.request, {
            successMessage: t('notification.ipAddressForTestingSuccessful'),
        });
    }

    _handleOnSubmit = (values: { ip: string }) => {
        const { requestTesting, apiDataActions, link } = this.props;
        requestTesting.perform({ resourceId: link.uuid }, values).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getMemberLink', {
                    linkId: link.uuid,
                    exchange: link.exchange.short_name
                });
            }
        });
    }

    render() {
        const { renderModalToggleBtn, t } = this.props;

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={{ ip: '' }}
                    onSubmit={this._handleOnSubmit}
                >
                    {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('ipAddress.headerTitle')}
                            body={
                                <FormGroup row className="mb-0">
                                    <Col md="3">
                                        <Label htmlFor="ip">{t('connection.ipAddressForTest')}</Label>
                                    </Col>
                                    <Col md="10">
                                        <FormControl
                                            type="text"
                                            name="ip"
                                            id="ip"
                                            errors={errors}
                                            touched={touched}
                                            placeholder="xxx.xxx.xxx.xxx"
                                        />
                                    </Col>
                                </FormGroup>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!validateIpAddress(values.ip, IpRangeType.Ipv4Range)}
                                />
                            }
                        />
                    )}
                </Formik>
                {renderModalToggleBtn ? (
                    renderModalToggleBtn({ onClick: this.toggle })
                ) : (
                    <Tooltip
                        triggerBtn={
                            <i
                                className={classNames(['fas fa-chart-bar cursor-pointer text-primary'])}
                                onClick={this.toggle}
                            />
                        }
                    />
                )}
            </React.Fragment>
        );
    }
}

export default enhance(LinkIpForTestingModal);
