import { withApiData, ApiDataBinding } from "react-api-data";
import { WithTranslation, withTranslation } from "react-i18next";
import BaseFormModal, { BaseFormModalProps } from "./BaseFormModal";
import { compose } from "recompose";
import React from "react";
import { BaseModalTemplate, FormModalButtons } from "./BaseModalTemplate";
import Alert from "reactstrap/lib/Alert";
import { get } from "lodash";
import Tooltip from "components/utils/Tooltip";
import { ContractItem, ApiResponse, ReactApiDataActions } from "constants/api";
import { NetworkRequestStatus } from "constants/literals";

interface InProps {
    contract: ContractItem;
    exchange: string;
    organisationUuid?: string;
}

interface EnhanceProps {
    archiveContract: ApiDataBinding<ApiResponse<ContractItem>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InProps & WithTranslation & BaseFormModalProps;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            archiveContract: 'archiveContract'
        }
    ),
    withTranslation()
);

class ContractArchiveModal extends BaseFormModal<Props> {
    onSubmit = () => {
        const { archiveContract, exchange, contract, organisationUuid, apiDataActions } = this.props;
        archiveContract.perform({
                exchange: exchange.toUpperCase(),
                contractId: contract.uuid,
            }).then((response) => {
                if (response.request.networkStatus === NetworkRequestStatus.Success && organisationUuid) {
                    apiDataActions.invalidateCache('getOrganisationContracts', {
                        organisationUuid: organisationUuid,
                        all_contracts: 'true'
                    });
                }
            });
    };

    render() {
        const { archiveContract, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('archiveContractModal.headerTitle')}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('archiveContractModal.warningMessage')}</p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(archiveContract, 'request.networkStatus') === NetworkRequestStatus.Loading}
                            toggle={this.toggle}
                            onSubmit={this.onSubmit}
                        />
                    }
                />
                {!this.props.contract.is_active &&
                    <i className="fas fa-trash mt-1 text-primary cursor-pointer" onClick={this.toggle}></i>
                }

                {this.props.contract.is_active &&
                    <Tooltip triggerBtn={<i className="fas fa-trash mt-1 text-muted"></i>}>{t('archiveContractModal.cantArchive')}</Tooltip>
                }
            </React.Fragment>
        );
    }
}

export default enhance(ContractArchiveModal);
