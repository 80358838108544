import { SnmpData } from "constants/api";
import { SnmpStatus } from "constants/literals";

export const getPortSnmpStatus = (snmpData: SnmpData[]) => {
    const statuses = snmpData.map((data) => data.status);

    if (statuses.includes(SnmpStatus.Up)) {
        return SnmpStatus.Up;
    } else if (statuses.includes(SnmpStatus.Disabled)) {
        return SnmpStatus.Disabled;
    } else {
        return SnmpStatus.Down;
    }
};
