import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Exchanges } from 'constants/api';

// redux
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';

// components

import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import ExchangesList from 'components/cards/ExchangesList';
import ExchangeAddModal from 'components/modals/ExchangeAddModal';

interface ApiProps {
    exchangesData: Exchanges;
    exchangesRequest: ApiDataBinding<{ data: Exchanges }>;
}

type ComponentProps = ApiProps & WithTranslation;

const enhance = compose<ComponentProps, {}>(
    withApiData({
        exchangesRequest: 'getAllExchanges'
    }),
    connect((state: ReduxState) => ({
        exchangesData: getAllExchangesSelector(state.apiData),
    })),
    withTranslation()
);

const ExchangesView: FunctionComponent<ComponentProps> = ({ exchangesData, exchangesRequest, t }) => {
    return (
        <>
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            <span>
                                {t('exchangesAndDataCenters.pageTitle')}
                                <SyncButton
                                    data-test="exchanges-refresh-btn"
                                    className="ml-2"
                                    title={t('exchangesAndDataCenters.refreshButton')}
                                    onClick={() => exchangesRequest.invalidateCache()}
                                />
                            </span>
                            <ExchangeAddModal/>
                        </h2>
                        <hr/>
                        <ExchangesList />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default enhance(ExchangesView);
