import React, { FunctionComponent, useMemo } from 'react';
import { compose } from 'recompose';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

// components
import AlertMessage from 'components/utils/AlertMessage';
import InteractiveTable from 'components/utils/InteractiveTable';

// constants
import { defaultPaginationOptions } from 'constants/pagination';
import { ApiResponse, Organisation, Port, Switch } from 'constants/api';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { debounce, get } from 'lodash';
import { updateAppPathAction } from 'redux/app/appActions';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import CompositeRequest from 'utils/compositeRequest';
import memoizeOne from 'memoize-one';
import i18next from 'locales/i18next';
import { Link } from 'react-router-dom';
import { getLinkToConnection, getLinkToPort } from 'utils/linksUtils';

interface InProps {
    switchData: Switch;
}

interface ConnectProps {
    searchTerm: string;
    portByUse: string;
    updateSearchTerm: (value: string) => void;
    updatePortByUse: (value: string) => void;
}

interface EnhanceProps {
    ports: ApiDataBinding<Port[]>;
    organisations: ApiDataBinding<ApiResponse<{ organisations: Organisation[] }>>;
}

type SwitchPortsProps = InProps & ConnectProps & EnhanceProps & WithTranslation;

interface TableData {
    id: string;
    scope: string;
    type: string | null;
    speed: number;
    links: {
        uuid: string;
        xmlId: string;
        exchange: string;
        organisationName: string;
    }[];
}

interface PortsTableProps {
    ports: Port[];
    portSwitch: string;
    organisations: Organisation[];
    exchange: string;
    searchTerm: string;
    portByUse: string;
}

function filterTableData(dataArray: TableData[], searchTerm: string) {
    if (searchTerm.length === 0) {
        return dataArray;
    }
    const normalizedSearchTerm = searchTerm.toLowerCase();

    return dataArray.filter(
        (data) =>
            data.id.toLowerCase().includes(normalizedSearchTerm) ||
            data.links.some(
                (link) =>
                    link.xmlId.includes(normalizedSearchTerm) ||
                    link.organisationName.toLowerCase().includes(normalizedSearchTerm)
            )
    );
}

function buildTableData(ports: Port[], organisations: Organisation[], exchange: string) {
    function findOrganisation(uuid: string) {
        return organisations.find((org) => org.uuid === uuid);
    }

    return ports.map((port) => {
        let links = [];
        if (port.link) {
            const organisation = findOrganisation(port.link.organisation_uuid);
            links.push({
                uuid: port.link.uuid,
                xmlId: port.link.xml_id,
                exchange: exchange,
                organisationName: organisation!.name,
            });
        } else if (port.links) {
            port.links.forEach((link) => {
                const organisation = findOrganisation(link.organisation_uuid);
                links.push({
                    uuid: link.uuid,
                    xmlId: link.xml_id,
                    exchange: exchange,
                    organisationName: organisation!.name,
                });
            });
        }
        return {
            id: `${port.fqid}/${port.portnumber}`,
            scope: port.scope,
            type: port.interface_type,
            speed: port.speed,
            links,
        };
    });
}

const getSwitchPortsTableColumns = memoizeOne((exchange: string, portSwitch: string): any => {
    return [
        {
            dataField: 'id',
            text: i18next.t('ports.id'),
            sort: true,
            formatter: (cell: any, row: Port) => (
                <Link to={getLinkToPort(exchange, cell)}>{row.id}</Link>
            )
        },
        {
            dataField: 'scope',
            text: i18next.t('ports.scope'),
            sort: true,
        },
        {
            dataField: 'type',
            text: i18next.t('ports.type'),
            sort: true,
        },
        {
            dataField: 'speed',
            text: i18next.t('ports.speed'),
            sort: true,
        },
        {
            dataField: 'links',
            text: i18next.t('ports.links'),
            sort: true,
            formatter: (links: TableData['links']) => {
                return links.map((link) => (
                    <span key={link.xmlId} className="mr-2">
                        <Link to={getLinkToConnection(link.exchange, link.uuid)}>{link.xmlId}</Link>
                        <span className="ml-2">({link.organisationName})</span>
                    </span>
                ));
            },
        },
    ];
});

function PortsTable(props: PortsTableProps) {
    const { ports, organisations, exchange, searchTerm, portSwitch } = props;
    const { t } = useTranslation();
    const tableData = useMemo(() => buildTableData(ports, organisations, exchange), [ports, organisations, exchange]);

    if (ports.length === 0) {
        return <AlertMessage data-test="no-ports-message" message={t('ports.noPorts')} />;
    } else {
        return (
            <InteractiveTable
                data-test="ports-table"
                classes="table-responsive-lg"
                keyField="id"
                columns={getSwitchPortsTableColumns(exchange, portSwitch)}
                data={filterTableData(tableData, searchTerm)}
                defaultSorted={[
                    {
                        dataField: 'id',
                        order: 'asc',
                    },
                ]}
                paginationOptions={{
                    ...defaultPaginationOptions,
                    sizePerPageRenderer: sizePerPageRenderer('up'),
                }}
            />
        );
    }
}

export const SwitchPorts: FunctionComponent<SwitchPortsProps> = ({
    ports,
    organisations,
    switchData,
    searchTerm,
    portByUse,
    updateSearchTerm,
    updatePortByUse,
    t,
}) => {

    const getPortbyUse = (byUse: string) => {
        if (ports.data && ports.data.length > 0) {
            switch (byUse) {
                case t('switches.all'):
                    return ports.data;
                case t('switches.free'):
                    return (ports.data.filter((port) => !port.links && !port.link && port.selectable));
                case t('switches.inUse'):
                    return (ports.data.filter((port) => port.links || port.link));
                default:
                    return ports.data;
            }
        }
        return ports.data!;
    };
    const compositeRequest = new CompositeRequest(ports.request, organisations.request);
    const portsFilterOptions = [t('switches.all'), t('switches.free'), t('switches.inUse')];
    
    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <Row className="justify-content-end">
                    <Col xl={3}>
                        <Input
                            type="select"
                            name="portByUse"
                            defaultValue={portByUse}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                updatePortByUse(event.currentTarget.value);
                            }}
                        >
                            {portsFilterOptions.map((option: string) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col xl={3}>
                        <Input
                            data-test="search-term-control"
                            className="form-control"
                            name="searchTerm"
                            defaultValue={searchTerm}
                            placeholder={t('table.searchInputPlaceholder')}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                updateSearchTerm(event.currentTarget.value);
                            }}
                        />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <RequestStatusRenderer
                    request={compositeRequest}
                    failedMessage={t('notification.unexpectedError')}
                    success={() => (
                        <PortsTable
                            exchange={switchData.exchange}
                            ports={getPortbyUse(portByUse)}
                            organisations={organisations.data!.data!.organisations}
                            searchTerm={searchTerm}
                            portByUse={portByUse}
                            portSwitch={switchData.name}
                        />
                    )}
                />
            </CardBody>
        </Card>
    );
};

export default compose<SwitchPortsProps, InProps>(
    connect(
        (state: ReduxState) => ({
            searchTerm: get(state, 'app.ports.searchTerm', ''),
            portByUse: get(state, 'app.ports.portByUse', 'All'),
        }),
        (dispatch) => ({
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('ports.searchTerm', searchTerm));
            }, 500),
            updatePortByUse: debounce((portByUse: string) => {
                dispatch(updateAppPathAction('ports.portByUse', portByUse));
            }, 500),
        })
    ),
    withApiData({ ports: 'getSwitchPorts', organisations: 'getOrganisations' }, (ownProps) => ({
        ports: { switchId: ownProps.switchData.id, exchange: ownProps.switchData.exchange },
    })),
    withTranslation()
)(SwitchPorts);
