/**
 * Define duration in milloseconds
 */
const CACHEDURATION_1HOUR = 60 * 60 * 1000;
const CACHEDURATION_2HOURS = 60 * 60 * 2 * 1000;
const CACHEDURATION_5MIN = 60 * 5 * 1000;
export const CACHEDURATION_NONE = 1000; // need this value to prevent multiple requests

interface CacheConfig {
    cacheDurationDefault: number;
    endpoints?: {
        [key: string]: number;
    };
}

const cacheConfig: CacheConfig = {
    cacheDurationDefault: Number(process.env.REACT_APP_CACHE_DURATION_DEFAULT) || CACHEDURATION_1HOUR,
    endpoints: {
        getMemberLink: CACHEDURATION_NONE,
        getLinkStatus: CACHEDURATION_NONE,
        getPrefixLimit: CACHEDURATION_NONE,
        getAllMemberLinks: CACHEDURATION_2HOURS,
        getPortStats: CACHEDURATION_5MIN,
        getLinkStats: CACHEDURATION_5MIN,
        getTotalStats: CACHEDURATION_5MIN,
        getSflowStats: CACHEDURATION_5MIN,
        getRankedSflow: CACHEDURATION_5MIN,
        getSflowAggregatedStats: CACHEDURATION_5MIN,
        getLogout: CACHEDURATION_NONE,
        getPeersList: CACHEDURATION_5MIN,
        getExternalURL: CACHEDURATION_5MIN,
        getUser: CACHEDURATION_NONE,
        getIpRanges: CACHEDURATION_1HOUR,
        getContract: CACHEDURATION_NONE,
        getOrganisationContracts: CACHEDURATION_NONE,
        getNativeRouters: CACHEDURATION_NONE,
        getRouteServers: CACHEDURATION_NONE,
        getOrganisation: CACHEDURATION_NONE,
        getVlans: CACHEDURATION_NONE,
        getFreeIpv6: CACHEDURATION_NONE,
        getPortDetails: CACHEDURATION_NONE,
        getLinkEvents: CACHEDURATION_NONE,
        getVlanEvents: CACHEDURATION_NONE,
        getPortEvents: CACHEDURATION_NONE,
        getContractVirtualLinkVlans: CACHEDURATION_NONE,
        getIps: CACHEDURATION_NONE,
        getPortSnmpData: CACHEDURATION_5MIN,
        getContractLinks: CACHEDURATION_NONE,
        getAllLinks: CACHEDURATION_NONE
    }
};

export default cacheConfig;
