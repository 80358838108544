import React, { FunctionComponent, useState } from "react";
import { BaseModalTemplate, FormModalButtons } from "./BaseModalTemplate";
import Alert from "reactstrap/lib/Alert";
import { useTranslation } from "react-i18next";
import { LinkFull, ApiResponse, ReactApiDataActions } from "constants/api";
import { LinkSwapStatus, NetworkRequestStatus } from "constants/literals";
import { ApiDataBinding, withApiData } from "react-api-data";
import { compose } from "recompose";
import Tooltip from "components/utils/Tooltip";

interface InProps {
    link: LinkFull;
    swapToStatus: LinkSwapStatus;
}

interface EnhanceProps {
    swapLinkRequest: ApiDataBinding<ApiResponse<{}>>;
    apiDataActions: ReactApiDataActions;
}

type Props = InProps & EnhanceProps;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            swapLinkRequest: 'putSwapLink'
        },
        (ownProps: InProps) => ({
            swapLinkRequest: {
                linkId: ownProps.link.uuid,
                exchange: ownProps.link.exchange.short_name
            }
        })
    )
);

const LinkSwapModal: FunctionComponent<Props> = ({ link, swapToStatus, swapLinkRequest, apiDataActions }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onSubmit = () => {
        swapLinkRequest.perform();
        apiDataActions.invalidateCache('getLinkStatus', {
            linkId: link.uuid,
            exchange: link.exchange.short_name
        });
    };

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <BaseModalTemplate
                isOpen={isOpen}
                toggle={toggle}
                header={t('linkSwapModal.headerTitle')}
                body={
                    <Alert color="warning" className="mb-0">
                        <strong>{t('common.warning')}</strong>
                        <p>{t('linkSwapModal.warningMessage', { status: swapToStatus })}</p>
                    </Alert>
                }
                footer={
                    <FormModalButtons
                        loading={swapLinkRequest.request.networkStatus === NetworkRequestStatus.Loading}
                        toggle={toggle}
                        onSubmit={onSubmit}
                    />
                }
            />

            <Tooltip
                triggerBtn={
                    <i className="fas fa-sync-alt text-primary cursor-pointer" onClick={toggle} />
                }
            >
                {t('linkSwapModal.swapButtonTooltip', { status: swapToStatus })}
            </Tooltip>
        </>
    );
};

export default enhance(LinkSwapModal);
