import React from 'react';
import { connect } from 'react-redux';
import { Roles } from '../constants/literals';
import { ReduxState } from '../createStore';
import { getUserRolesSelector } from '../redux/apiData/apiDataSelectors';

interface StateProps {
    userRoles: Roles[];
}

function withUserRoles<Props>(WrappedComponent: React.ComponentType<any>): React.ComponentType<Props> {
    const mapStateToProps = (state: ReduxState): StateProps => ({
        userRoles: getUserRolesSelector(state) || []
    });

    return connect(mapStateToProps)(WrappedComponent);
}

export default withUserRoles;
