import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiDataRequest, ApiDataBinding, getApiDataRequest, performApiRequest } from 'react-api-data';
import { FormGroup, Col, Label, Button, Alert } from 'reactstrap';
import { Formik, Field } from 'formik';
import { PublicVlanItem } from 'constants/api';
import { IpRangeType, Roles, VlanLanType, VlanUse } from 'constants/literals';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import { hasSomeRole, isIxaasClient } from 'utils/userUtils';
import withUserRoles from 'hocs/withUserRoles';

interface InputProps {
    vlan: PublicVlanItem;
}

interface EnhanceProps {
    // tslint:disable-next-line:no-any
    onSubmit: (values: any) => undefined;
    submission: ApiDataRequest;
    vlanData: ApiDataBinding<PublicVlanItem>;
    userRoles: Roles[];
}

type Props = EnhanceProps & InputProps & BaseFormModalProps & WithTranslation;

class VlanEditModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>) {
        this.handleFormSubmission(prevProps.submission, this.props.submission, {
            successMessage: this.props.t('vlanEditModal.successMessage'),
        });
    }

    isVisible() {
        const { vlan, userRoles } = this.props;
        return (
            hasSomeRole(userRoles, Roles.admin, Roles.noc) ||
            (vlan.use === VlanUse.Customer && isIxaasClient(userRoles))
        );
    }

    render() {
        const { vlan, onSubmit, userRoles, t } = this.props;
        const vlanHasIpv4Range = vlan.ip_ranges.filter(ipRange => {
            return ipRange.type === IpRangeType.Ipv4Range;
          });

        return (
            <>
                <Formik
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        description: vlan.description,
                        number: vlan.number,
                        lan_type: vlan.lan_type || VlanLanType.PeeringLan,
                        use: vlan.use || VlanUse.Customer,
                        public: vlan.public,
                        cpu_protection: vlan.cpu_protection,
                        auto_assign_ips: vlan.auto_assign_ips
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, isSubmitting, touched, handleSubmit }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('vlanEditModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="description">{t('vlanModal.description')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="description" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="number">{t('vlanModal.number')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                name="number"
                                                type="number"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="lan_type">{t('vlanModal.lanType')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                component="select"
                                                name="lan_type"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                {Object.values(VlanLanType).map((lanType) => (
                                                    <option key={lanType} value={lanType}>
                                                        {lanType}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="use">{t('vlanModal.use')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                component="select"
                                                name="use"
                                                errors={errors}
                                                touched={touched}
                                                disabled={isIxaasClient(userRoles)}
                                            >
                                                {Object.values(VlanUse).map((use) => (
                                                    <option key={use} value={use}>
                                                        {use}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="public">{t('vlanModal.public')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <Field type="checkbox" name="public" checked={values.public} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="cpu_protection">{t('vlanModal.cpuProtection')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <Field
                                                type="checkbox"
                                                name="cpu_protection"
                                                checked={values.cpu_protection}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="auto_assign_ips">{t('vlanModal.autoAssignIps')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <Field
                                                type="checkbox"
                                                name="auto_assign_ips"
                                                checked={values.auto_assign_ips}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {values.auto_assign_ips && vlanHasIpv4Range.length === 0 && (
                                        <Alert color="danger">{t('vlanModal.autoAssignIpsWarning')}</Alert>
                                    )}
                                </>
                            }
                            footer={<FormModalButtons loading={isSubmitting} toggle={this.toggle} />}
                        />
                    )}
                </Formik>
                {this.isVisible() && (
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('vlanEditModal.editBtn')}
                    </Button>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    submission: getApiDataRequest(state.apiData, 'putVlan', { vlanUuid: ownProps.vlan.uuid })
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => ({
    onSubmit: (data: Partial<PublicVlanItem>) => {
        dispatch(performApiRequest('putVlan', { vlanUuid: ownProps.vlan.uuid },
            { exchange: ownProps.vlan.exchange, vlan: data }));
    },
});

const enhance = compose<EnhanceProps, InputProps & BaseFormModalProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    withUserRoles
);

export default enhance(VlanEditModal);
