import { Alert, Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { number, object } from 'yup';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// utils
import { createTagValidation } from 'validation/index';

// constants
import { ApiResponse, Vlan } from 'constants/api';
import { NOC_SUPPORT_EMAIL, TAG_MAX_VALUE, TAG_MIN_VALUE } from 'constants/index';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';
import FormControl from 'components/utils/FormControl';
import { LinkVlanMode, Roles } from 'constants/literals';
import { hasSomeRole } from 'utils/userUtils';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { getUserRolesSelector } from 'redux/apiData/apiDataSelectors';

interface InputProps {
    vlan: Vlan;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: () => boolean;
    tagChangeRequest: ApiDataBinding<ApiResponse<Vlan>>;
    userRoles: Roles[];
}

export type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const enhance = compose<Props, InputProps>(
    withTranslation(),
    connect((state: ReduxState) => ({
        userRoles: getUserRolesSelector(state),
    })),
    withApiData({
        tagChangeRequest: 'postLinkVlanTag',
    })
);

class LinkVlanTagModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { tagChangeRequest, t } = this.props;
        this.handleFormSubmission(prevProps.tagChangeRequest.request, tagChangeRequest.request, {
            successMessage: t('notification.tagLinkVlanSuccessful'),
        });
    }

    _handleOnSubmit = (values: any) => {
        const { tagChangeRequest, vlan, exchange, linkId } = this.props;
        if (values.mode === LinkVlanMode.Tagged) delete values.inner_qtag;

        // remove unused param
        const { mode, ...params } = values;
        tagChangeRequest.perform({ vlanId: vlan.uuid, exchange: exchange, linkId: linkId }, params);
    };

    render() {
        const { vlan, t } = this.props;

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={{ qtag: vlan.qtag, inner_qtag: vlan.inner_qtag, mode: vlan.mode }}
                    validationSchema={object().shape({
                        qtag: createTagValidation(t).required(t('validation.vlanTagRequired')),
                        inner_qtag: number().when('mode', {
                            is: (mode) => mode === LinkVlanMode.Dual,
                            then: createTagValidation(t).required(t('validation.vlanInnerTagRequired')),
                            otherwise: () => null
                        }),
                    })}
                    onSubmit={this._handleOnSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('tagVlanModal.headerTitle')}
                            body={
                                <>
                                    <Alert color="warning">
                                        <strong>{t('common.warning')}</strong>
                                        <p>
                                            {t('tagVlanModal.warningMessage')}(
                                            <a href={`mailto:${NOC_SUPPORT_EMAIL}`}>{NOC_SUPPORT_EMAIL}</a>).
                                        </p>
                                    </Alert>

                                    <FormGroup row>
                                        {hasSomeRole(this.props.userRoles, Roles.noc, Roles.admin) && (
                                            <>
                                                <Col md={1}>
                                                    <Label htmlFor="mode">{t('common.mode')}</Label>
                                                </Col>
                                                <Col md={5}>
                                                    <FormControl
                                                        component="select"
                                                        name="mode"
                                                        errors={errors}
                                                        touched={touched}
                                                    >
                                                        <>
                                                            {[LinkVlanMode.Tagged, LinkVlanMode.Dual].map(
                                                                (tag: string) => (
                                                                    <option value={tag} key={tag}>
                                                                        {tag}
                                                                    </option>
                                                                )
                                                            )}
                                                        </>
                                                    </FormControl>
                                                </Col>
                                            </>
                                        )}
                                        <>
                                            <Col md={1}>
                                                <div id="qtagLabel">
                                                    <Label htmlFor="qtag">{t('common.qtag')}</Label>
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <div id="qtag">
                                                    <FormControl
                                                        type="number"
                                                        name="qtag"
                                                        min={TAG_MIN_VALUE}
                                                        max={TAG_MAX_VALUE}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                            </Col>
                                        </>
                                    </FormGroup>
                                    {values.mode === LinkVlanMode.Dual && (
                                        <>
                                            <FormGroup row>
                                                {hasSomeRole(this.props.userRoles, Roles.noc, Roles.admin) && (
                                                    <Col md={6}></Col>
                                                )}
                                                <Col md={1}>
                                                    <div id="innerQtagLabel">
                                                        <Label htmlFor="inner_qtag">{t('common.innerTag')}</Label>
                                                    </div>
                                                </Col>
                                                <Col md={5}>
                                                    <div id="inner_qtag">
                                                        <FormControl
                                                            type="number"
                                                            name="inner_qtag"
                                                            min={TAG_MIN_VALUE}
                                                            max={TAG_MAX_VALUE}
                                                            errors={errors}
                                                            touched={touched}
                                                            disabled={
                                                                !hasSomeRole(
                                                                    this.props.userRoles,
                                                                    Roles.noc,
                                                                    Roles.admin
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                        </>
                                    )}
                                </>
                            }
                            footer={<FormModalButtons loading={isSubmitting} toggle={this.toggle} disabled={!dirty} />}
                        />
                    )}
                </Formik>
                <EditBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    resourceStatus={vlan.status}
                    roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.customer, Roles.ixaas_client]}
                >
                    <Tooltip
                        data-test="toggle-btn-tooltip"
                        triggerBtn={
                            <i
                                className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('tagVlanModal.headerTitle')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(LinkVlanTagModal);
