import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import { compose } from 'recompose';

import { ApiResponse, TrafficStats, StatsData } from 'constants/api';
import ResourceGraph, { ResourceGraphProps } from './ResourceGraph';
import { NetworkRequestStatus } from 'constants/literals';
import { formatBps } from 'utils/formatUtils';
import Table from 'reactstrap/lib/Table';

interface InProps extends ResourceGraphProps {
    exchange: string;
    aggregate: string;
}

interface ApiProps {
    statsData: ApiDataBinding<ApiResponse<TrafficStats>>;
}

type Props = InProps & ApiProps;

interface TrafficInfoProps {
    trafficStats: StatsData;
}

const TrafficPeakAndAverage: FunctionComponent<TrafficInfoProps> = ({ trafficStats }) => {
    if (!trafficStats.meta_data) return (<></>);

    return (
        <div className="d-flex flex-column align-items-center">
            <Table className="w-auto table-borderless table-sm">
                <tbody>
                    <tr>
                        {trafficStats.meta_data.avg_in && (
                            <>
                                <td>Average in:</td>
                                <td className="pr-5">{formatBps(trafficStats.meta_data.avg_in)}</td>
                            </>
                        )}
                        {trafficStats.meta_data.avg_out && (
                            <>
                                <td>Average out:</td>
                                <td>{formatBps(trafficStats.meta_data.avg_out)}</td>
                            </>
                        )}
                    </tr>

                    <tr>
                        {trafficStats.meta_data.max_in && (
                            <>
                                <td>Peak in:</td>
                                <td>{formatBps(trafficStats.meta_data.max_in)}</td>
                            </>
                        )}
                        {trafficStats.meta_data.max_out && (
                            <>
                                <td>Peak out:</td>
                                <td>{formatBps(trafficStats.meta_data.max_out)}</td>
                            </>
                        )}
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

const TotalGraph: FunctionComponent<Props> = ({ statsData, interval, type }) => {
    return (
        <>
            <ResourceGraph
                statsData={statsData}
                interval={interval}
                type={type}
            />

            {statsData.request.networkStatus === NetworkRequestStatus.Success && (
                <>
                    <hr />
                    <TrafficPeakAndAverage
                        trafficStats={(statsData.data!.data!.traffic_statistics as any)[interval][type]}
                    />
                </>
            )}
        </>
    );
};

const enhance = compose<Props, InProps>(
    withApiData(
        { statsData: 'getTotalStats' },
        (ownProps: Props) => ({
            statsData: {
                aggregate: ownProps.aggregate,
                exchange: ownProps.exchange,
                interval: ownProps.interval
            }
        })
    )
);

export default enhance(TotalGraph);
