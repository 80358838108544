import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FormikErrors, FormikTouched, FormikValues, Formik } from 'formik';
import filter from 'lodash/filter';
import size from 'lodash/size';
import classNames from 'classnames';
import styled from 'styled-components';

import FormControl from './FormControl';

interface Props {
    name: string;
    type?: string;
    options?: string[];
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    setFieldValue: typeof Formik.prototype.setFieldValue;
    handleChange: typeof Formik.prototype.handleChange;
    handleBlur: typeof Formik.prototype.handleBlur;
}

interface State {
    isOpen: boolean;
    filteredOptions?: string[];
    isReadOnly: boolean;
}

const StyledDropdownMenu = styled(DropdownMenu)`
    max-height: 295px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
`;

class SearchDropdown extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
            filteredOptions: props.options,
            isReadOnly: true,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.options !== this.props.options) {
            this.setState({ filteredOptions: this.props.options });
        }
    }

    render() {
        const { name, type, errors, touched } = this.props;
        const { isOpen, filteredOptions, isReadOnly } = this.state;
        const hasFilteredOptions: boolean = !!size(filteredOptions);

        return (
            <>
                <Dropdown
                    isOpen={isOpen}
                    toggle={this.toggle}
                >
                    <DropdownToggle tag="div" className="search-dropdown-toggle">
                        <FormControl
                            className="bg-white"
                            name={name}
                            type={type}
                            errors={errors}
                            touched={touched}
                            onChange={this.onChange}
                            showErrorHint={!isOpen || !hasFilteredOptions}
                            // onFocus, onBlur, readOnly are workaround for disabling input's autocomplete
                            // since autocomplete="off/no/false" seems not to be working anymore
                            readOnly={isReadOnly}
                            onFocus={this.toggleReadOnly}
                            onBlur={this.onBlur}
                        />
                    </DropdownToggle>
                    <StyledDropdownMenu
                        className={classNames({
                            'd-none': !hasFilteredOptions
                        })}
                    >
                        <>
                            {filteredOptions && hasFilteredOptions && filteredOptions.map((option: string) => (
                                <DropdownItem key={option} onClick={() => this.onDropdownItemClick(option)}>{option}</DropdownItem>
                            ))}
                        </>
                    </StyledDropdownMenu>
                </Dropdown>
            </>
        );
    }

    onDropdownItemClick = (option: string) => {
        this.props.setFieldValue(this.props.name, option);
        this.setState(prevState => ({
            filteredOptions: this.props.options,
        }));
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleChange(event);
        this.setState({
            filteredOptions: this.filterOptions(event.target.value, this.props.options)
        });
    }

    onBlur = (event: React.FocusEvent<any>) => {
        this.props.handleBlur(event);
        this.toggleReadOnly();
    }

    private toggle = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    private filterOptions = (searchTerm: string, options?: string[]): string[] | undefined => {
        if (!searchTerm || !size(options)) {
            return options;
        }
        return filter(options, (option: string) => String(option).indexOf(searchTerm) !== -1);
    }

    private toggleReadOnly = () => {
        setTimeout(() => {
            this.setState((state, props) => {
                return { isReadOnly: !state.isReadOnly }
            });
        }, 100);
    }
}

export default SearchDropdown;
