import React, { FunctionComponent, useRef } from 'react';
import { Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { AsYouType } from 'libphonenumber-js';

import FormControl from './FormControl';

interface Props {
    name?: string;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    setFieldValue: typeof Formik.prototype.setFieldValue;
}

const PhoneFormControl: FunctionComponent<Props> = ({ name = 'phone', errors, touched, setFieldValue }) => {
    const phoneFormatter = useRef<AsYouType>(new AsYouType());

    return (
        <FormControl
            name={name}
            errors={errors}
            touched={touched}
            placeholder="+X XXX XXX XXXX"
            onFocus={(event: React.FocusEvent<any>) => {
                if (!event.currentTarget.value) {
                    setFieldValue(name, '+');
                }
            }}
            onChange={(event: React.ChangeEvent<any>) => {
                phoneFormatter.current.reset();
                const formattedValue = phoneFormatter.current.input(event.currentTarget.value) || '+';
                setFieldValue(name, formattedValue);
            }}
        />
    );
};

export default PhoneFormControl;