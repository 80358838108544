import React, { FunctionComponent, useMemo } from 'react';
import { compose } from 'recompose';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

// components
import AlertMessage from 'components/utils/AlertMessage';
import InteractiveTable from 'components/utils/InteractiveTable';

// constants
import { defaultPaginationOptions } from 'constants/pagination';
import { Exchange, Location } from 'constants/api';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { debounce, get } from 'lodash';
import { updateAppPathAction } from 'redux/app/appActions';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import memoizeOne from 'memoize-one';
import i18next from 'locales/i18next';
import { Link } from 'react-router-dom';
import { getLinkToDataCenter } from 'utils/linksUtils';
import { StyledTable } from 'components/styled';

interface InProps {
    exchange: Exchange;
}

interface ConnectProps {
    searchTerm: string;
    updateSearchTerm: (value: string) => void;
}

interface EnhanceProps {
    dataCenters: ApiDataBinding<Exchange>;
}

type ExchangeDataCentersProps = InProps & ConnectProps & EnhanceProps & WithTranslation;

interface TableData {
    uuid: string;
    short_name: string;
    long_name: string;
    active: boolean;
}

interface DataCentersTableProps {
    dataCenters: Location[];
    exchange: string;
    searchTerm: string;
}

function filterTableData(dataArray: TableData[], searchTerm: string) {
    if (searchTerm.length === 0) {
        return dataArray;
    }
    const normalizedSearchTerm = searchTerm.toLowerCase();
    return dataArray.filter(
        (data) =>
            data.short_name.toLowerCase().includes(normalizedSearchTerm)
    );
}

function buildTableData(locations: Location[]) {
    return locations.map((location) => {
        return {
            uuid: location.uuid,
            short_name: location.short_name,
            long_name: location.long_name,
            active: location.active
        };
    });
}

const getExchangeDataCentersTableColumns = memoizeOne((exchange: string): any => {
    return [
        {
            dataField: 'uuid',
            text: i18next.t('exchangesAndDataCenters.uuid'),
            sort: true,
            hidden: true
        },
        {
            dataField: 'short_name',
            text: i18next.t('exchangesAndDataCenters.dataCenterShortName'),
            sort: true,
        },
        {
            dataField: 'long_name',
            text: i18next.t('exchangesAndDataCenters.dataCenterLongName'),
            sort: true,
        },
        {
            dataField: 'active',
            text: i18next.t('exchangesAndDataCenters.dataCenterActive'),
            sort: true,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: i18next.t('exchangesAndDataCenters.viewDetails'),
            formatter: (cell: any, row: Exchange) => (
                <Link to={getLinkToDataCenter(row.uuid, exchange)}>{i18next.t('common.view')}</Link>
            ),
        }
    ];
});

function DataCentersTable(props: DataCentersTableProps) {
    const { dataCenters, exchange, searchTerm  } = props;
    const { t } = useTranslation();
    const tableData = useMemo(() => buildTableData(dataCenters), [dataCenters]);

    if (dataCenters.length === 0) {
        return <AlertMessage data-test="no-dataCenters-message" message={t('exchangesAndDataCenters.noDataCentersInExchange')} />;
    } else {
        return (
            <StyledTable className="animated fadeIn table-responsive-lg">
                <InteractiveTable
                    data-test="dataCenters-table"
                    classes="table-responsive-lg"
                    keyField="short_name"
                    columns={getExchangeDataCentersTableColumns(exchange)}
                    data={filterTableData(tableData, searchTerm)}
                    defaultSorted={[
                        {
                            dataField: 'long_name',
                            order: 'asc',
                        },
                    ]}
                    paginationOptions={{
                        ...defaultPaginationOptions,
                        sizePerPageRenderer: sizePerPageRenderer('up'),
                    }}
                />
            </StyledTable>
        );
    }
}

export const ExchangeDataCenters: FunctionComponent<ExchangeDataCentersProps> = ({
    dataCenters,
    exchange,
    searchTerm,
    updateSearchTerm,
    t,
}) => {
   
    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <Row className="justify-content-left">
                    <Col xl={3}>
                        <Input
                            data-test="search-term-control"
                            className="form-control"
                            name="searchTerm"
                            defaultValue={searchTerm}
                            placeholder={t('table.searchInputPlaceholder')}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                updateSearchTerm(event.currentTarget.value);
                            }}
                        />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <RequestStatusRenderer
                    request={dataCenters.request}
                    failedMessage={t('notification.unexpectedError')}
                    success={() => (
                        <DataCentersTable
                            dataCenters={dataCenters.data!.locations}
                            exchange={exchange.short_name}
                            searchTerm={searchTerm}
                        />
                    )}
                />
            </CardBody>
        </Card>
    );
};

export default compose<ExchangeDataCentersProps, InProps>(
    connect(
        (state: ReduxState) => ({
            searchTerm: get(state, 'app.dataCenters.searchTerm', ''),
        }),
        (dispatch) => ({
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('dataCenters.searchTerm', searchTerm));
            }, 500),
        })
    ),
    withApiData({ dataCenters: 'getExchange'}, (ownProps) => ({
        dataCenters: { uuid: ownProps.exchange.uuid },
    })),
    withTranslation()
)(ExchangeDataCenters);
