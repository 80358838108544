import React from 'react';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { Button, FormGroup, Label } from 'reactstrap';
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import { TrafficTypes } from 'constants/literals';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { ApiResponse, LinkFull, ReactApiDataActions, Exchanges } from 'constants/api';
import { Location } from 'constants/api';
import { getLinkToConnection } from 'utils/linksUtils';
import { RouteComponentProps, withRouter } from 'react-router';
import StyledCheckbox from 'components/styled/StyledCheckbox';

interface InProps {
    contractUuid: string;
    exchange: string;
}

interface EnhanceProps {
    createLink: ApiDataBinding<ApiResponse<LinkFull>>;
    exchanges: ApiDataBinding<Exchanges>;
    apiDataActions: ReactApiDataActions;
}

type Props = InProps & EnhanceProps & BaseFormModalProps & WithTranslation & RouteComponentProps;

const enhance = compose<Props, InProps>(
    withTranslation(),
    withRouter,
    withApiData({ createLink: 'postMonitorLink', exchanges: 'getAllExchanges' })
);

interface FormData {
    location_uuid: string | null;
    xml_id: string;
    traffic_type: TrafficTypes;
    mep_id: string;
    snmp_ro: string;
    nid_pollable: boolean;
}

class MonitorLinkAddModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.createLink.request, this.props.createLink.request, {
            successMessage: this.props.t('notification.createConnectionSuccessful'),
        });
    }

    protected afterSuccessCallback = () => {
        const { exchange, contractUuid, createLink, apiDataActions, history } = this.props;
        const link = createLink.data!.data!;
        const target = getLinkToConnection(exchange, link.uuid);
        apiDataActions.invalidateCache('getContractLinks', { contractUuid, exchange: exchange.toUpperCase() });
        history.push(target);
    };

    _submit = (values: FormData) => {
        const { contractUuid, createLink } = this.props;
        createLink.perform({}, { ...values, contract_uuid: contractUuid });
    };

    get _exchange() {
        const { exchanges, exchange } = this.props;
        return exchanges
            .data!.scopes.flatMap((scope) => scope.exchanges)
            .find((e) => e.short_name === exchange.toUpperCase())!;
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Formik
                    initialValues={{
                        location_uuid: '',
                        xml_id: '',
                        traffic_type: TrafficTypes.Internal,
                        mep_id: '',
                        snmp_ro: '',
                        nid_pollable: false,
                    }}
                    onSubmit={this._submit}
                    ref={(ref) => (this.formik = ref)}
                >
                    {({ errors, touched, isSubmitting, handleSubmit }) => {
                        return (
                            <BaseFormModalTemplate
                                isOpen={this.state.isOpen}
                                toggle={this.toggle}
                                handleSubmit={handleSubmit}
                                onClosed={this.handleModalClose}
                                header={t('addMonitorLinkModal.headerTitle')}
                                body={
                                    <>
                                        <FormGroup>
                                            <Label>{t('common.exchange')}</Label>
                                            <FormControl
                                                name="exchange"
                                                value={this._exchange.name}
                                                errors={errors}
                                                touched={touched}
                                                readOnly={true}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{this.props.t('common.location')}</Label>
                                            <LocationSelect
                                                name="location_uuid"
                                                locations={this._exchange.locations}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{t('addMonitorLinkModal.xmlId')}</Label>
                                            <FormControl
                                                name="xml_id"
                                                maxLength={100}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{t('addMonitorLinkModal.trafficType')}</Label>
                                            <FormControl
                                                component="select"
                                                name="traffic_type"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                {Object.values(TrafficTypes).map((trafficType) => (
                                                    <option key={trafficType} value={trafficType}>
                                                        {trafficType}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{t('addMonitorLinkModal.mepId')}</Label>
                                            <FormControl
                                                name="mep_id"
                                                maxLength={100}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{t('addMonitorLinkModal.snmpRo')}</Label>
                                            <FormControl
                                                name="snmp_ro"
                                                maxLength={100}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </FormGroup>
                                        <FormGroup className="d-flex flex-row align-items-center">
                                            <Label className="mb-0">{t('addMonitorLinkModal.nidPollable')}</Label>
                                            <StyledCheckbox name="nid_pollable" className="ml-3" />
                                        </FormGroup>
                                    </>
                                }
                                footer={<FormModalButtons loading={isSubmitting} toggle={this.toggle} />}
                            />
                        );
                    }}
                </Formik>
                <Button className="float-right" color="light" onClick={this.toggle}>
                    {t('addMonitorLinkModal.addBtn')}
                </Button>
            </>
        );
    }
}

interface LocationSelectProps {
    locations: Location[];
    name: string;
    errors: FormikErrors<FormData>;
    touched: FormikTouched<FormData>;
}

function LocationSelect({ locations, name, errors, touched }: LocationSelectProps) {
    const { t } = useTranslation();

    return (
        <FormControl component="select" name={name} disabled={locations.length === 0} errors={errors} touched={touched}>
            <>
                <option disabled value={''}>
                    {t('connection.selectLocation')}
                </option>
                {locations
                    .sort((a: Location, b: Location) => a.description.localeCompare(b.description))
                    .map((location: Location) => (
                        <option key={location.uuid} value={location.uuid}>
                            {location.description}
                        </option>
                    ))}
            </>
        </FormControl>
    );
}

export default enhance(MonitorLinkAddModal);
