import { FunctionComponent } from 'react';
import React from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import { compose } from 'recompose';
import { Spinner } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Router, SflowUrl } from 'constants/api';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';

interface InputProps {
    router: Router;
    sflowRequest: ApiDataBinding<SflowUrl>;
    exchange: string;
}

type Props = InputProps & WithTranslation;

const connectApiData = withApiData(
    {
        sflowRequest: 'getSflowURL'
    },
    (ownProps: Props) => ({
        sflowRequest: {
            exchange: ownProps.exchange,
            ip: ownProps.router.ip
        }
    })
);

const SflowButton: FunctionComponent<Props> = ({ sflowRequest, t }) => (
    <RequestStatusRenderer
        request={sflowRequest}
        loading={<Spinner type="grow" color="primary" className="float-right mt-1" size="sm" />}
        success={
            <a
                href={get(sflowRequest, 'data.sflow_url')}
                target="_blank"
                rel="noopener noreferrer"
                className="float-right"
                title={t('vlan.viewSflowIcon')}
            >
                <i className="fas fa-chart-bar float-right mt-1" />
            </a>
        }
        defaultComponent={<i className="fa fa-chart-bar text-muted float-right mt-1" title={t('vlan.viewSflowError')} />}
    />
);

const enhance = compose<InputProps, {}>(
    connectApiData,
    withTranslation()
);

// @ts-ignore Possible error in react-api-data type definitions
export default enhance(SflowButton);
