import React, { ReactNode } from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { FormGroup, Col, Label, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import pick from 'lodash/pick';
import get from 'lodash/get';
import size from 'lodash/size';
import compact from 'lodash/compact';
import toString from 'lodash/toString';
import head from 'lodash/head';
import { object, string, mixed } from 'yup';
import classNames from 'classnames';

// constants
import { ReduxState } from 'createStore';
import { StatsCounterType, OptionItem, DirectionType } from 'constants/literals';
import { LinkFull } from 'constants/api';

// utils
import { getIpAddresses, getAsn } from 'utils/linksUtils';

// components
import { BaseFormModalTemplate } from 'components/modals/BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from 'components/modals/BaseFormModal';
import { BaseModalState } from './BaseModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import SflowRankedResults from 'components/cards/SflowRankedResults';
import LoaderButton from 'components/utils/LoaderButton';

interface InProps {
    link: LinkFull;
    iconClasses?: string;
    renderModalToggleBtn?: (props: {onClick: () => void}) => ReactNode;
}

interface EnhanceProps {
    onSubmit: (values: any) => void;
    request: ApiDataRequest;
}

interface WithStateProps {
    searchValues: any;
    setSearchValues: (searchValues: any) => void;
}

type Props = EnhanceProps & InProps & WithStateProps & BaseFormModalProps & WithTranslation;

const directions: OptionItem[] = [
    {
        title: 'stats.input',
        value: DirectionType.input
    },
    {
        title: 'stats.output',
        value: DirectionType.output
    },
    {
        title: 'stats.total',
        value: DirectionType.total
    },
];

const timePeriods = [1, 4, 12, 24, 48, 72, 720, 2160, 8760];

const counters: OptionItem[] = [
    {
        title: 'stats.bps',
        value: StatsCounterType.bps,
    },
    {
        title: 'stats.pps',
        value: StatsCounterType.pps,
    },
];

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'getRankedSflow', ownProps.searchValues)
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            const requestParams: any = pick(values, ['lookup', 'interval', 'counter', 'direction']);
            requestParams.exchange = get(ownProps, 'link.exchange.short_name');
            ownProps.setSearchValues(requestParams);
            dispatch(performApiRequest('getRankedSflow', requestParams));
        }
    };
};

const enhance = compose<EnhanceProps, InProps>(
    withState('searchValues', 'setSearchValues', {}),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class SflowRankedModal extends BaseFormModal<Props, BaseModalState> {

    componentDidUpdate(prevProps: Props, prevState: BaseModalState) {
        const { request } = this.props;
        const requestStatus = get(request, 'networkStatus');
        if (this.formik && prevProps.request !== request && (requestStatus === 'success' || requestStatus === 'failed')) {
            this.formik.setSubmitting(false);
        }
        if (prevState.isOpen && !this.state.isOpen) {
            this.props.setSearchValues({});
        }
    }

    render() {
        const { onSubmit, t, request, iconClasses, renderModalToggleBtn, link } = this.props;
        const ipsAndAsnOptions: string[] = compact([...getIpAddresses(link), toString(getAsn(link))]);
        const generatePeriodDisplayLabels = (time: number) => {
            const days = time / 24;
            const isItYear = (days === 365);
            const isItDay = time % 24 === 0 && days >= 1 && !isItYear;

            return isItYear ? '1 year' : (isItDay ? `${time / 24} day(s)` : `${time} hour(s)`);
        };

        return (
            <>
                <Formik
                    data-test="form"
                    ref={formik => this.formik = formik}
                    initialValues={{
                        // 24 hrs is the default value on the cgi script
                        interval: timePeriods[3],
                        counter: StatsCounterType.bps,
                        direction: DirectionType.total,
                        lookup: head(ipsAndAsnOptions),
                    }}
                    validationSchema={object().shape({
                        interval: string(),
                        counter: string(),
                        direction: string(),
                        lookup: mixed().required(t('validation.requiredField'))
                    })}
                    onSubmit={onSubmit}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status, errors, touched, setFieldValue }) => (
                        <BaseFormModalTemplate
                            modalClassName="sflow-ranked-modal"
                            data-test="sflow-modal-template"
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            handleSubmit={handleSubmit}
                            onClosed={this.handleModalClose}
                            header={t('sflowStats.sflowRankedHeaderTitle')}
                            body={<>
                                <div className="alert alert-info">
                                    <p className="mb-0">{t('sflowStats.sflowRankedStatsExplanation')}</p>
                                </div>
                                <FormGroup row>
                                    <Col md={4}>
                                        <Label>{t('sflowStats.selectDirection')}</Label>
                                        <FormControl
                                            component="select"
                                            name="direction"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectDirection')}</option>
                                            {directions.map((option: OptionItem) => (
                                                <option key={option.value} value={option.value}>{t(option.title)}</option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('sflowStats.selectPeriod')}</Label>
                                        <FormControl
                                            component="select"
                                            name="interval"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectPeriod')}</option>
                                            {timePeriods.map((time: number) => (
                                                <option key={time} value={time}>
                                                    {generatePeriodDisplayLabels(time)}
                                                </option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('sflowStats.selectCounter')}</Label>
                                        <FormControl
                                            component="select"
                                            name="counter"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectCounter')}</option>
                                            {counters.map((option: OptionItem) => (
                                                <option key={option.value} value={option.value}>{t(option.title)}</option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={6}>
                                        <Label>{t('sflowStats.selectIpOrAsn')}</Label>
                                        <br />
                                        <FormControl
                                            data-test="ips-and-asn-dropdown"
                                            component="select"
                                            name="lookup"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectIpOrAsn')}</option>
                                            {ipsAndAsnOptions && ipsAndAsnOptions.map((option: string) => (
                                                <option data-test="ip-asn-option" key={option} value={option}>{option}</option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="text-right">
                                    <LoaderButton
                                        data-test="submit-btn"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={isSubmitting || !!size(errors)}
                                    >
                                        {t('common.view')}
                                    </LoaderButton>
                                </FormGroup>
                                <SflowRankedResults
                                    data-test="sflow-results"
                                    request={request}
                                    scrollElementName="sflow-ranked-modal"
                                />
                            </>}
                            footer={<>
                                <Button color="light" onClick={this.toggle} disabled={isSubmitting}>
                                    {t('modal.closeBtn')}
                                </Button>
                            </>}
                        />
                    )}
                </Formik>
                {renderModalToggleBtn ?
                    renderModalToggleBtn({ onClick: this.toggle }) : (
                        <Tooltip
                            triggerBtn={
                                <i
                                    className={classNames([
                                        'fas fa-chart-bar cursor-pointer text-primary',
                                        iconClasses,
                                    ])}
                                    onClick={this.toggle}
                                />
                            }
                        >
                            {t('sflowStats.sflowRankedHeaderTitle')}
                        </Tooltip>
                    )}
            </>
        );
    }
}

export default enhance(SflowRankedModal);
