import BaseFormModal, {BaseFormModalProps } from "./BaseFormModal";
import { withTranslation, WithTranslation } from "react-i18next";
import { ApiDataRequest, getApiDataRequest, performApiRequest } from "react-api-data/lib";
import React, { ReactNode } from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import { BaseFormModalTemplate, FormModalButtons } from "./BaseModalTemplate";
import { Col, FormGroup, Label } from "reactstrap";
import FormControl from "../utils/FormControl";
import { compose } from "recompose";
import { connect } from "react-redux";
import { ReduxState } from "../../createStore";
import Tooltip from "../utils/Tooltip";
import classNames from "classnames";

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    submission: ApiDataRequest;
    request: ApiDataRequest;
    resourceStatus: string;
    uuid: string;
    exchange: string;
}

interface InputProps {
    email: string|undefined;
    uuid: string;
    exchange: string;
    renderModalToggleBtn?: (props: {onClick: () => void}) => ReactNode;
}

type Props = EnhanceProps & WithTranslation & BaseFormModalProps & InputProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    submission: getApiDataRequest(state.apiData, 'putUserSettings', {
        userId: ownProps.uuid,
        exchange: ownProps.exchange
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('putUserSettings', {
                userId: ownProps.uuid,
                exchange: ownProps.exchange
            }, { ...values }));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class UserUpdateModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.submission, this.props.submission, {
            successMessage: this.props.t('notification.updateUserSuccessful'),
        });
    }

    render() {
        const { onSubmit, t, renderModalToggleBtn } = this.props;

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ password: '', password_confirmation: '' }}
                    onSubmit={onSubmit}
                    validationSchema={object().shape({
                        password: string()
                            .min(7, t('validation.passwordCharacters'))
                            .notOneOf([ref('username')], t('validation.passwordMatchUserName'))
                            .required(t('validation.passwordRequired')),
                        password_confirmation: string()
                            .oneOf([ref('password'), null], t('validation.passwordsMatch'))
                            .required(t('validation.passwordConfirm'))
                    })}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('userUpdateModal.headerTitle')}
                            body={<>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="password">{t('common.password')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl name="password" type="password" errors={errors} touched={touched} showErrorHint={true} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="password_confirmation">{t('common.confirmPassword')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl name="password_confirmation" type="password" errors={errors} touched={touched} />
                                    </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                {renderModalToggleBtn ?
                    renderModalToggleBtn({ onClick: this.toggle }) : (
                        <Tooltip
                            triggerBtn={
                                <i
                                    className={classNames([
                                        'fas fa-chart-bar cursor-pointer text-primary',

                                    ])}
                                    onClick={this.toggle}
                                />
                            }
                        >
                            <i className="fas fa-edit" /> {t('header.settings')}
                        </Tooltip>
                    )}
            </React.Fragment>
        );
    }
}

export default enhance(UserUpdateModal);

