import { ApiDataState } from 'react-api-data';

import { AuthenticationToken, LinkFull } from 'constants/api';

export interface AppState {
    authenticationToken?: AuthenticationToken;
    impersonatorToken?: AuthenticationToken;
    needsOtp?: boolean;
    mfaQrCode?: string;
    loginRedirect?: string;
    linkHistory: LinkFull[];
    customers: {
        selectedExchange: string;
        searchTerm: string;
    };
    vlans: {
        selectedExchange?: string;
        searchTerm?: string;
    };
    switches: {
        selectedExchange?: string;
        searchTerm?: string;
    };
    routeServers: {
        selectedExchange?: string;
        searchTerm?: string;
    };
    ports: {
        searchTerm?: string;
        portByUse?: string;
    };
    ipRanges: {
        selectedExchange?: string;
        searchTerm?: string;
    };
    availablePorts: {
        selectedExchange?: string;
        selectedLocation?: {uuid: string, description: string};
    };
    locationHistory: string[];
    stats?: {
        selectedExchange?: string;
        selectedAggregate?: string;
        sflow?: {
            selectedExchange?: string;
            selectedAggregate?: string;
        }
    };
    exchanges: {
        searchTerm?: string;
    };
    provisioning: {
        debugProvisioningMode?: boolean;
    },
    appLanguage?: string;
}

export const defaultState: AppState = {
    linkHistory: [],
    customers: {
        selectedExchange: '',
        searchTerm: '',
    },
    vlans: {
        selectedExchange: '',
        searchTerm: '',
    },
    switches: {
        selectedExchange: '',
        searchTerm: '',
    },
    routeServers: {
        selectedExchange: '',
        searchTerm: '',
    },
    ports: {
        searchTerm: '',
        portByUse: 'All'
    },
    ipRanges: {
        selectedExchange: '',
        searchTerm: '',
    },
    availablePorts: {
        selectedExchange: '',
        selectedLocation: {uuid: '', description: ''}
    },
    locationHistory: [],
    stats: {
        sflow: {},
    },
    exchanges: {
        searchTerm: '',
    },
    provisioning: {
        debugProvisioningMode: false,
    },
    appLanguage: 'en',
};

export const defaultApiDataState: ApiDataState = {
    globalConfig: {},
    endpointConfig: {},
    requests: {},
    entities: {}
};
