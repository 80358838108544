import {
    Alert
} from 'reactstrap';
import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';

interface InputProps {
    uuid: string;
    exchange: string;
    description: string;
    number: number;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    resourceStatus: string;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'deleteVlan', {
        vlanUuid: get(ownProps, 'uuid'),
        exchange: get(ownProps, 'exchange').toUpperCase()
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            dispatch(performApiRequest('deleteVlan', {
                vlanUuid: get(ownProps, 'uuid'),
                exchange: get(ownProps, 'exchange').toUpperCase()
            }));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class VlanDeleteModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { request, t } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            pathToError: 'errorBody.message',
            successMessage: t('notification.deleteVlanSuccessful')
        });
    }

    render() {
        const { request, onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('deleteVlanItemModal.headerTitle')}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('deleteVlanItemModal.warningMessage', { description: this.props.description, number: this.props.number })}</p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.ixaas_client]}>
                    <span
                        data-test="Vlan-delete-btn"
                        className="fas fa-trash fa-pull-left mt-1 text-primary cursor-pointer"
                        onClick={this.toggle}
                        title={t('deleteVlanItemModal.headerTitle')}
                    />
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(VlanDeleteModal);
