import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import {
    ApiDataBinding,
    withApiData
} from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Organisation, ApiResponse, ReactApiDataActions } from 'constants/api';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import { hasSomeRole } from '../../utils/userUtils';
import { Roles, NetworkRequestStatus } from '../../constants/literals';

// hocs
import withUserRoles from 'hocs/withUserRoles';

interface InProps {
    organisation: Organisation;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    organisationUpdateRequest: ApiDataBinding<ApiResponse<Organisation>>;
    apiDataActions: ReactApiDataActions;
    userRoles: Roles[];
}

type Props = EnhanceProps & InProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps, InProps>(
    withApiData(
        { organisationUpdateRequest: 'putOrganisation' }
    ),
    withTranslation(),
    withUserRoles
);

class OrganisationEditModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.organisationUpdateRequest.request, this.props.organisationUpdateRequest.request, {
            successMessage: this.props.t('notification.editOrganisationSuccessful')
        });
    }

    _handleOnSubmit = (values: any) => {
        const { organisation, organisationUpdateRequest, apiDataActions } = this.props;
        organisationUpdateRequest.perform(
            {
                uuid: organisation.uuid,
            }, 
            {
                organisation: {
                    name: values.organisation_name,
                    erp_customer_identifier: values.erp_customer_identifier
                }
            }).then((response) => {
                if (response.request.networkStatus === NetworkRequestStatus.Success) {
                    apiDataActions.invalidateCache('getOrganisation',
                        {
                            uuid: organisation.uuid
                        }
                    );
                    apiDataActions.perform('getOrganisation', {
                        uuid: organisation.uuid
                    });
                }
            }
        );
    };

    render() {
        const { userRoles, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        organisation_name: this.props.organisation.name || '',
                        erp_customer_identifier: this.props.organisation.erp_customer_identifier || ''
                    }}
                    validationSchema={object().shape({
                        organisation_name: string().required(t('validation.requiredField'))
                    })}
                    onSubmit={this._handleOnSubmit}
                >
                    {({ errors, touched, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('editOrganisationModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="organisation_name">{t('editOrganisationModal.organisationName')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="text"
                                                name="organisation_name"
                                                id="organisation_name"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="erp_customer_identifier">{t('editOrganisationModal.erpCustomerId')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="text"
                                                name="erp_customer_identifier"
                                                id="erp_customer_identifier"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                {
                    hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.ixaas_client) && (
                        <Tooltip
                            data-test="edit-organisation-btn"
                            triggerBtn={
                                <i
                                    className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                    onClick={this.toggle}
                                />
                            }
                        >
                            {t('editOrganisationModal.headerTitle')}
                        </Tooltip>
                    )
                }
            </React.Fragment>
        );
    }
}

export default enhance(OrganisationEditModal);
