import React, { FunctionComponent, useState } from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { Button, DropdownItem } from 'reactstrap';
import includes from 'lodash/includes';

import { BaseModalTemplate } from '../modals/BaseModalTemplate';

interface InProps {
    onDelete: () => void;
    isDropdownItem?: boolean;
    resourceName: string;
    resourceStatus: string;
}

const ResourceDeleteBtn: FunctionComponent<InProps & WithTranslation> = ({ onDelete, isDropdownItem = false, resourceName, resourceStatus, t }) => {
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const toggleConfirmModal = (): void => {
        setConfirmModalOpen(!isConfirmModalOpen);
    };

    const onDeleteClick = () => {
        toggleConfirmModal();
        onDelete();
    };

    if (!includes(['unconfigured', 'deconfigured'], resourceStatus)) {
        return null;
    }

    return (
        <>
            {isDropdownItem ? (
                    <DropdownItem
                        onClick={toggleConfirmModal}
                    >
                        {t('common.delete')}
                    </DropdownItem>
                ) : (
                    <Button
                        data-test="delete-btn"
                        color="primary"
                        onClick={toggleConfirmModal}
                    >
                        {t('common.delete')}
                    </Button>
                )
            }
            <BaseModalTemplate
                data-test="confirm-delete-modal"
                isOpen={isConfirmModalOpen}
                toggle={toggleConfirmModal}
                header={t('connection.confirmDeletionTitle')}
                body={
                    <Trans i18nKey={'connection.deletionConfirmation'}>
                        <strong>{{ name: resourceName }}</strong>
                    </Trans>
                }
                footer={<>
                    <Button color="primary" data-test="confirm-delete-btn" onClick={onDeleteClick}>{t('modal.confirmBtn')}</Button>
                    <Button color="light" data-test="cancel-delete-btn" onClick={toggleConfirmModal}>{t('modal.closeBtn')}</Button>
                </>}
            />
        </>
    );
};

export default withTranslation()(ResourceDeleteBtn);