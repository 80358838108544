import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import get from 'lodash/get';

// constants
import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';

interface InputProps {
    link: LinkFull;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'putLacpTimeout', {
        linkId: get(ownProps, 'link.uuid'),
        exchange: get(ownProps, 'link.exchange.short_name'),
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('putLacpTimeout', {
                linkId: ownProps.link.uuid,
                exchange: ownProps.link.exchange.short_name,
            }, values));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class LacpTimeoutModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            pathToError: 'response.lacp_timeout.0',
            successMessage: this.props.t('notification.changeLacpSuccessfull'),
        });
    }

    render() {
        const { onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ lacp_timeout: this.props.link.lacp_timeout || 'short' }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('lacpTimeoutModal.headerTitle')}
                            body={
                                <FormGroup row className="mb-0">
                                    <Col md="3">
                                        <Label htmlFor="lacp_timeout">{t('lacpTimeoutModal.lacpInputLabel')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            component="select"
                                            name="lacp_timeout"
                                            id="lacp_timeout"
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="short">{t('lacpTimeoutModal.short')}</option>
                                            <option value="long">{t('lacpTimeoutModal.long')}</option>
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={this.props.link.lacp_timeout === values.lacp_timeout}
                                />
                            }
                        />
                    )}
                </Formik>
                <EditBtnConditionalRenderer data-test="edit-btn-renderer" resourceStatus={this.props.link.status} anyStatus={true}>
                    <Tooltip
                        data-test="change-lacp-btn"
                        triggerBtn={
                            <i
                                className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('lacpTimeoutModal.headerTitle')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(LacpTimeoutModal);
