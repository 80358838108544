import React, { FunctionComponent } from 'react';

import { Roles } from 'constants/literals';
import { hasSomeRole } from 'utils/userUtils';
import withUserRoles from 'hocs/withUserRoles';

interface InProps {
    roles?: Roles[];
    children?: React.ReactNode;
}

interface ConnectProps {
    userRoles: Roles[];
}

type Props = InProps & ConnectProps;

/**
 * Check user has given roles and renders children
 */
const RolesChecker: FunctionComponent<Props> = ({ userRoles, roles, children }) => (
    (!roles || hasSomeRole(userRoles, ...roles)) ? <>{children}</> : null
);

export default withUserRoles<InProps>(RolesChecker);