import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, Contract, ReactApiDataActions } from 'constants/api';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import { hasSomeRole } from '../../utils/userUtils';
import { NetworkRequestStatus, Roles } from '../../constants/literals';

// hocs
import withUserRoles from 'hocs/withUserRoles';
import ReservedForTestingCheckbox from 'components/utils/ReservedForTestingCheckbox';
import Row from 'reactstrap/lib/Row';

interface InProps {
    exchange: string;
    contract: Contract;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    contractUpdateRequest: ApiDataBinding<ApiResponse<Contract>>;
    apiDataActions: ReactApiDataActions;
    userRoles: Roles[];
}

type Props = EnhanceProps & InProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps, InProps>(
    withApiData(
        { contractUpdateRequest: 'putContract' }
    ),
    withTranslation(),
    withUserRoles
);

class ContractEditModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.contractUpdateRequest.request, this.props.contractUpdateRequest.request, {
            successMessage: this.props.t('notification.editOrganisationSuccessful')
        });
    }

    _handleOnSubmit = (values: any) => {
        const { contract, exchange, contractUpdateRequest, apiDataActions } = this.props;
        contractUpdateRequest.perform(
            {
                uuid: contract.uuid,
                exchange: exchange.toUpperCase()
            }, 
            {
                contract: {
                    contractname: values.contract_name,
                    erp_contract_identifier: values.erp_contract_identifier,
                    reserved_for_testing: values.reserved_for_testing,
                    peering_db_id: values.peering_db_id,
                    peering_db_sales_email: values.peering_db_sales_email,
                }
            }).then((response) => {
                if (response.request.networkStatus === NetworkRequestStatus.Success) {
                    apiDataActions.invalidateCache('getContract',
                        {
                            exchange: exchange.toUpperCase(),
                            contractUuid: contract.uuid
                        }
                    );
                }
            }
        );
    };

    render() {
        const { userRoles, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        contract_name: this.props.contract.contractname || '',
                        erp_contract_identifier: this.props.contract.erp_contract_identifier || '',
                        reserved_for_testing: this.props.contract.reserved_for_testing || false,
                        peering_db_id: this.props.contract.peering_db_id || '',
                        peering_db_sales_email: this.props.contract.peering_db_sales_email || '',
                    }}
                    validationSchema={object().shape({
                        contract_name: string().required(t('validation.requiredField'))
                    })}
                    onSubmit={this._handleOnSubmit}
                >
                    {({ values, errors, touched, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('editContractModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="contract_name">{t('editContractModal.contractName')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="text"
                                                name="contract_name"
                                                id="contract_name"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="erp_contract_identifier">{t('editContractModal.erpContractId')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="text"
                                                name="erp_contract_identifier"
                                                id="erp_contract_identifier"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="peering_db_id">{t('contract.peeringDbId')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="text"
                                                name="peering_db_id"
                                                id="peering_db_id"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="peering_db_sales_email">{t('contract.peeringDbSalesEmail')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                type="email"
                                                name="peering_db_sales_email"
                                                id="peering_db_sales_email"
                                                errors={errors}
                                                touched={touched}
                                            />

                                            <p className="mb-0 mt-1">{t('editContractModal.modifyPeeringDbSalesEmailNote')}</p>
                                        </Col>
                                    </FormGroup>

                                    <ReservedForTestingCheckbox checked={values.reserved_for_testing} />
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                {
                    hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.ixaas_client) && (
                        <Tooltip
                            data-test="edit-contract-btn"
                            triggerBtn={
                                <i
                                    className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                    onClick={this.toggle}
                                />
                            }
                        >
                            {t('editContractModal.headerTitle')}
                        </Tooltip>
                    )
                }
            </React.Fragment>
        );
    }
}

export default enhance(ContractEditModal);
