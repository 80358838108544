import { Alert, Badge, Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import React, { FunctionComponent, useState } from 'react';
import size from 'lodash/size';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { BgpAttribute, Peering, RouterPeering } from '../../constants/api';
import i18n from '../../locales/i18next';

// components
import PeeringAttributesUpdateModal from '../modals/PeeringAttributesUpdateModal';
import { getLinkToRsPeering } from '../../utils/linksUtils';
import { Link } from 'react-router-dom';

interface InProps {
    peeringsData: Peering[];
    routerId: string;
    linkId: string;
    exchange: string;
}

type ComponentProps = InProps & WithTranslation;

export const PeeringsList: FunctionComponent<ComponentProps> = ({ peeringsData, routerId, exchange, linkId, t }) => {
    return (
        <>
            {peeringsData.map((peering: Peering, index: number) => {
                return (
                    <PeeringCard
                        key={peering.id}
                        peeringData={peering}
                        routerId={routerId}
                        exchange={exchange}
                        index={index + 1}
                        linkId={linkId}
                        translate={t}
                    />
                );
            })}
        </>
    );
};

interface PeeringCardProps {
    peeringData: Peering;
    index: number;
    routerId: string;
    linkId: string;
    exchange: string;
    translate: i18n.TFunction;
}

export const PeeringCard: FunctionComponent<PeeringCardProps> = ({ peeringData, routerId, linkId, exchange, translate }) => {

    const [isOpen, setModalOpen] = useState(false);
    const updatePeeringClickHandler = () => {
        setModalOpen(true);
    };

    const toggleModal = () => {
        setModalOpen(!isOpen);
    };

    const linkToRsPeering = () => (
        <Link
            data-test="second-router-connection-link"
            to={getLinkToRsPeering(peeringData.second_router.id, exchange, linkId)}
            children={<>{peeringData.second_router.id}</>}
        />
    );

    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <strong>{translate('peering.peeringWith')} {linkToRsPeering()}</strong>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xl={6}>
                        <RouterCard
                            routerNumberName={translate('peering.firstRouter')}
                            routerData={peeringData.first_router}
                            translate={translate}
                        />
                    </Col>
                    <Col xl={6}>
                        <RouterCard
                            routerNumberName={translate('peering.secondRouter')}
                            routerData={peeringData.second_router}
                            translate={translate}
                        />
                    </Col>
                </Row>
                <BGPAttributesCard
                    data-test="bgp-attributes-card"
                    bgpAttributes={peeringData.bgp_attributes}
                    onUpdatePeeringClick={updatePeeringClickHandler}
                    translate={translate}
                />
                <PeeringAttributesUpdateModal
                    data-test="bgp-attributes-modal"
                    bgpAttributes={peeringData.bgp_attributes}
                    peeringId={peeringData.id}
                    routerId={routerId}
                    exchange={exchange}
                    toggle={toggleModal}
                    isOpen={isOpen}
                />
            </CardBody>
        </Card>
    );
};

interface RouterCardProps {
    routerData: RouterPeering;
    routerNumberName: string;
    translate: i18n.TFunction;
}

const RouterCard: FunctionComponent<RouterCardProps> = ({ routerData, routerNumberName, translate }) => {
    return (
        <Card className="card-accent-primary">
            <CardHeader>{routerNumberName} {routerData.is_routeserver &&
                <Badge className="float-right mt-1" color="success">
                    {translate('common.routeServer')}
                </Badge>}
                {routerData.is_native_router &&
                    <Badge className="float-right mt-1" color="success">
                        {translate('common.nativeRouter')}
                    </Badge>}
            </CardHeader>
            <CardBody>
                <Table className="mb-0">
                    <tbody>
                    <tr>
                        <th>{translate('peering.routeIP')}:</th>
                        <td>{routerData.ip || '-'}</td>
                    </tr>
                    <tr>
                        <th>{translate('peering.companyName')}:</th>
                        <td>{routerData.company_name || '-'}</td>
                    </tr>
                    <tr>
                        <th>{translate('common.asn')}:</th>
                        <td>{routerData.asn || '-'}</td>
                    </tr>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

interface BGPAttributesCardProps {
    bgpAttributes: BgpAttribute[];
    onUpdatePeeringClick: () => void;
    translate: i18n.TFunction;
}

export const BGPAttributesCard: FunctionComponent<BGPAttributesCardProps> = ({ bgpAttributes, onUpdatePeeringClick, translate }) => {
    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                    <div>{translate('peering.peeringAttributes')}</div>
                    <Button
                        data-test="update-peering-btn"
                        color="primary"
                        onClick={onUpdatePeeringClick}
                    >
                        {translate('peering.updatePeeringBtn')}
                    </Button>
                </div>
            </CardHeader>
            <CardBody>
                <Table className="mb-0">
                    <thead>
                    <tr>
                        <th>{translate('peering.name')}</th>
                        <th>{translate('peering.value')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bgpAttributes && bgpAttributes.map((bgpAttribute: BgpAttribute) => (
                        <tr key={bgpAttribute.name}>
                            <td>{bgpAttribute.name}</td>
                            <td>{bgpAttribute.value}</td>
                        </tr>
                    ))}
                    {!size(bgpAttributes) && (
                        <tr>
                            <td colSpan={100} className="text-center font-italic">
                                <Alert data-test="no-bgp-attributes-message" color="none" className="text-center font-italic">
                                    {translate('peering.noPropertiesSet')}
                                </Alert>;
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default withTranslation()(PeeringsList);
