import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Store } from 'redux';
import Helmet from 'react-helmet';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist/es/types';
import { ToastContainer } from 'react-toastify';
import { History, Location } from 'history';

import 'react-virtualized/styles.css';
import '../scss/style.scss';

import Home from 'components/routes/Home';
import Login from 'components/routes/Login';
import ForgotPassword from './routes/ForgotPassword';
import ResetPassword from './routes/ResetPassword';
import CompleteRegistration from './routes/CompleteRegistration';

import { updateLocationHistoryAction } from 'redux/app/appActions';
import { getBaseRoutePath } from 'utils/commonUtils';
import { COMPLETE_REGISTRATION_PATH, FORGOT_PASSWORD_PATH, RESET_PASSWORD_PATH } from 'constants/index';

interface Props {
    store: Store<any>;
    persistor: Persistor;
    history: History;
}

const App = ({ store, persistor, history }: Props) => {
    const basePath = getBaseRoutePath();

    useEffect(() => {
        // store user's browser history
        store.dispatch(updateLocationHistoryAction(window.location.pathname));
        history.listen((location: Location, action: string) => {
            store.dispatch(updateLocationHistoryAction(location.pathname));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Fragment>
                    <Helmet
                        titleTemplate={`%s - ${process.env.REACT_APP_APPLICATION_NAME}`}
                        defaultTitle={process.env.REACT_APP_APPLICATION_NAME}
                    />
                    <ToastContainer/>
                    <Switch>
                        <Route component={Login} path={`${basePath}/login`} exact />
                        <Route component={ForgotPassword} path={FORGOT_PASSWORD_PATH} exact />
                        <Route component={ResetPassword} path={RESET_PASSWORD_PATH} exact />
                        <Route component={CompleteRegistration} path={COMPLETE_REGISTRATION_PATH} exact />
                        <Route component={Home} path="/" />
                    </Switch>
                </Fragment>
            </PersistGate>
        </Provider>
    );
};

export default App;
