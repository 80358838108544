import React, { FunctionComponent, useEffect } from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data/lib';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { OrganisationContract } from 'constants/api';
import get from 'lodash/get';

interface InProps {
    onChange: (erp_contract_identifier: string) => void;
    organisationId: number;
    organisationUuid: string;
    value: string | null;
    asnNumber: number | null;
}

type ContractsSelectProps = InProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    organisationContractsRequest: ApiDataBinding<OrganisationContract[]>;
}

const enhance = compose<ContractsSelectProps, InProps>(
    withApiData({ organisationContractsRequest: 'getOrganisationContracts' }, 
    (ownProps: InProps) => ({
        organisationContractsRequest: {
            organisationUuid: ownProps.organisationUuid
        },
    })),
    withTranslation()
);

interface OptionType {
    label: string;
    value: string | null;
}

const ContractsSelect: FunctionComponent<ContractsSelectProps> = ({
    organisationContractsRequest,
    onChange,
    value,
    asnNumber,
    t,
}) => {
    const options = get(organisationContractsRequest, 'data.data.contracts', [])
        .map((contract: OrganisationContract) => ({
            label: contract.number + ' -> ' + contract.erp_contract_identifier,
            value: contract.erp_contract_identifier,
        })).concat([{ label: t('common.none'), value: '' }]);

    const optChanged = (e: any) => {
        onChange(e.value);
    };
    const filterOption = (option: OptionType, inputValue: string): boolean =>
        (option.label.toString().match(inputValue) || option.label.toString().match(t('common.none')) || []).length > 0;

    useEffect(() => {
       onChange('');
    }, [asnNumber]);

    const selectedOption = options.find((o: { value: string; }) => o.value === value);

    return (
        <Select
            options={options}
            onChange={optChanged}
            filterOption={filterOption}
            isLoading={organisationContractsRequest.request.networkStatus === 'loading'}
            value={selectedOption}
        />
    );
};

// @ts-ignore
export default enhance(ContractsSelect);
