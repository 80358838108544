import React from 'react';
import { FieldAttributes } from "formik";
import styled from "styled-components";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

function Icon(props: Omit<FieldAttributes<any>, 'type'>) {
    return <i {...props} />;
}

export default styled(Icon)`
    width: 18px;
    height: 18px;
    opacity: .5;
    &:hover {
        opacity: 1;
    }
`;
