import React from 'react';
import { PerPageDropdown } from 'components/utils/PerPageDropdown';
import { LinkPortCompact } from 'constants/api';

export const getBaseRoutePath = (): string => {
    return process.env.REACT_APP_EXCHANGE_NAME ? `/${process.env.REACT_APP_EXCHANGE_NAME}` : '';
};

export const getPortName = (port: LinkPortCompact): string => `${port.portnumber}@${port.fqid}`;

export const sizePerPageRenderer = (direction?: 'up' | 'down') => {
    return ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }: any): any => {
        return (
            <PerPageDropdown
                direction={direction}
                options={options}
                currSizePerPage={currSizePerPage}
                onSizePerPageChange={onSizePerPageChange}
            />
        );
    };
};
