import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import {
    ApiDataBinding,
    withApiData,
    invalidateApiDataRequest,
    performApiRequest,
    getResultData
} from 'react-api-data';
import { PublicVlanItem, ApiResponse, IpRange, Event } from 'constants/api';
import { IpRangeType } from 'constants/literals';
import { Card, CardHeader, CardBody, Row, Col, Alert } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import Exchange from 'components/utils/Exchange';
import ResourceStatus from 'components/utils/ResourceStatus';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import ContextMenuDropdown from 'components/utils/ContextMenuDropdown';
import BoolIcon from 'components/utils/BoolIcon';
import VlanEditModal from 'components/modals/VlanEditModal';
import VlanResourceTransitions from 'components/resourceTransitions/VlanResourceTransitions';
import VlanLinks from 'components/cards/VlanLinks';

import { ReduxState } from '../../createStore';
import get from 'lodash/get';

import ActivityLog from 'components/cards/ActivityLog';
import { Roles } from 'constants/literals';
// hocs
import withUserRoles from 'hocs/withUserRoles';

interface VlanDetailsProps {
    vlan: PublicVlanItem;
    vlanEventsRequest: ApiDataBinding<ApiResponse<{ events: Event[] }>>;
    refetchVlan: () => undefined;
    fetchIpRanges: (exchange: string) => undefined;
    ipRanges: IpRange[];
    selectedExchange: string;
    userRoles: Roles[];
    refetchVlanLinks: () => undefined;
}

function VlanDetails(props: VlanDetailsProps) {
    const { vlan, vlanEventsRequest, refetchVlanLinks, refetchVlan, fetchIpRanges, ipRanges, userRoles } = props;
    const { t } = useTranslation();
    const showAutoAssignIpsWarning =
        vlan.auto_assign_ips && !vlan.ip_ranges.some((ipRange) => ipRange.type === IpRangeType.Ipv4Range);

    useEffect(() => {
        if (!ipRanges || ipRanges.length === 0) {
            fetchIpRanges(vlan.exchange);
        }
    }, [fetchIpRanges, ipRanges, vlan]);

    return (
        <>
            <Helmet title={vlan.description} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('vlanDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('vlanDetails.refreshDataBtn')}
                                onClick={refetchVlan}
                            />
                            <VlanEditModal vlan={vlan}/>
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('vlanDetails.vlanDetailsTitle')}</strong>
                                <div className="card-header-actions">
                                    <ResourceStatus status={vlan.status} />
                                    <ContextMenuDropdown
                                        dropdownMenuProps={{ right: true, persist: true }}
                                        className="ml-2"
                                    >
                                        <VlanResourceTransitions vlan={vlan} />
                                    </ContextMenuDropdown>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('vlanDetails.exchange')}</th>
                                            <td>
                                                <Exchange shortName={vlan.exchange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.description')}</th>
                                            <td>{vlan.description}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.number')}</th>
                                            <td>{vlan.number}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.public')}</th>
                                            <td>
                                                <BoolIcon value={vlan.public} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.lanType')}</th>
                                            <td>{vlan.lan_type || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.use')}</th>
                                            <td>{vlan.use || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.cpuProtection')}</th>
                                            <td>
                                                <BoolIcon value={vlan.cpu_protection} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.autoAssignIps')}</th>
                                            <td>
                                                <BoolIcon value={vlan.auto_assign_ips} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('vlanDetails.ipRanges')}</th>
                                            <td>
                                                {vlan.ip_ranges.map((range) => {
                                                    return (
                                                        <div key={range.value}>
                                                            {range.value}/{range.netmask}
                                                        </div>
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    </tbody>

                                </DetailsTable>
                                {showAutoAssignIpsWarning && (
                                    <Alert color="danger">{t('vlanModal.autoAssignIpsWarning')}</Alert>
                                )}
                            </CardBody>
                        </Card>
                            <ActivityLog
                                data-test="activity-log"
                                eventsApiBinding={vlanEventsRequest}
                                userRoles={userRoles}
                            />
                        <h2>
                            {t('contract.connectionsTitle')}
                            <SyncButton
                                data-test="refresh-btn"
                                className="ml-2"
                                title={t('connection.refreshConnectionBtn')}
                                onClick={refetchVlanLinks}
                            />
                        </h2>
                        <hr/>
                        <VlanLinks vlanUuid={vlan.uuid}/>
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type RouteProps = RouteComponentProps<{ vlanUuid: string }>;

interface ApiProps {
    vlanRequest: ApiDataBinding<ApiResponse<PublicVlanItem>>;
    vlanEventsRequest: ApiDataBinding<ApiResponse<{ events: Event[] }>>;
    refetchVlan: () => undefined;
    fetchIpRanges: (exchange: string) => undefined;
    refetchVlanLinks: () => undefined;
    ipRanges: IpRange[];
    selectedExchange: string;
    userRoles: Roles[];
}

type WrapperProps = ApiProps & RouteProps;

function VlanDetailsWrapper(props: WrapperProps) {
    const { vlanRequest, vlanEventsRequest, refetchVlanLinks, refetchVlan, ipRanges, fetchIpRanges, selectedExchange, userRoles } = props;
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={vlanRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() =>
                <VlanDetails
                    vlan={vlanRequest.data!.data!}
                    vlanEventsRequest={vlanEventsRequest}
                    refetchVlanLinks={refetchVlanLinks}
                    refetchVlan={refetchVlan}
                    fetchIpRanges={() => fetchIpRanges(selectedExchange)}
                    ipRanges={ipRanges}
                    selectedExchange={selectedExchange}
                    userRoles={userRoles}
                />}
        />
    );
}

const connectApiData = withApiData({
    vlanRequest: 'getVlan',
    vlanEventsRequest: 'getVlanEvents'
}, (ownProps: RouteProps) => {
    const vlanUuid = ownProps.match.params.vlanUuid;
    return {
        vlanRequest: { vlanUuid },
        vlanEventsRequest: { vlanUuid, per_page: 10 },
    };
});

const mapDispatchToProps = (dispatch: any, ownProps: WrapperProps) => ({
    refetchVlan: () => {
        const params = { vlanUuid: ownProps.match.params.vlanUuid };
        dispatch(invalidateApiDataRequest('getVlan', params));
        dispatch(performApiRequest('getVlan', params));
    },
    fetchIpRanges: (exchange: string) => {
        if (!exchange) {
            return;
        }
        dispatch(performApiRequest('getIpRanges', { exchange, selectable: 'true' }));
    },
    refetchVlanLinks: () => {
        dispatch(invalidateApiDataRequest('getVlanLinks', { vlanUuid: ownProps.match.params.vlanUuid }));
    }
});
const mapStateToProps = (state: ReduxState) => ({
    ipRanges: getResultData(
        state.apiData,
        'getIpRanges',
        { exchange: get(state, 'app.vlans.selectedExchange', ''), selectable: 'true' }
    ),
    selectedExchange: get(state, 'app.vlans.selectedExchange', '')
});

const enhance = compose<WrapperProps, RouteProps>(connectApiData, connect(mapStateToProps, mapDispatchToProps), withUserRoles);

export default enhance(VlanDetailsWrapper);
