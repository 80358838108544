import React, { FunctionComponent, useState, useCallback, ReactNode } from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { Col, Row, Input, Label } from 'reactstrap';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import { compose } from 'recompose';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isNaN from 'lodash/isNaN';
import toNumber from 'lodash/toNumber';

import { LinkFull, DebugData } from 'constants/api';
import AlertMessage from 'components/utils/AlertMessage';
import QuickDebugTab from './QuickDebugTab';

interface InProps {
    link: LinkFull;
    defaultDays?: string;
    pathToTitle?: string;
    renderOutput: (props: { link: LinkFull, days: number }) => ReactNode;
}

interface OutputRendererInProps {
    days: number;
    link: LinkFull;
}

interface EnhanceProps {
    debugData: ApiDataBinding<DebugData>;
}

type OutputRendererProps = OutputRendererInProps & EnhanceProps;

export const createOutputRenderer = ({ endpointKey }: { endpointKey: string }) => {
    const enhance = compose<OutputRendererProps, OutputRendererInProps>(
        withApiData({
            debugData: endpointKey,
        }, (props: OutputRendererInProps) => ({
            debugData: {
                linkId: get(props, 'link.uuid'),
                days: props.days,
            }
        })),
    );
    return enhance(({ link, debugData }) => <QuickDebugTab link={link} debugData={debugData} />);
};

const DEFAULT_DAYS = '3';

const QuickDebugWithDays: FunctionComponent<InProps & WithTranslation> = ({ link, t, defaultDays = DEFAULT_DAYS, renderOutput, pathToTitle }) => {
    const [days, changeDays] = useState(defaultDays);
    const daysToNumber = toNumber(days);
    const debouncedChangeDays = useCallback(debounce(changeDays, 500), []);

    return (
        <>
            <p><Trans i18nKey={pathToTitle || 'quickdebug.withDaysTitle'} /></p>
            <Row>
                <Col className="d-flex align-items-center" md={2}>
                    <Label className="mb-0 mr-3"><strong>{t('quickdebug.days')}</strong></Label>
                    <Input
                        data-test="days-select"
                        type="number"
                        name="days"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => debouncedChangeDays(event.currentTarget.value)}
                        defaultValue={defaultDays}
                    />
                </Col>
            </Row>
            {!daysToNumber || isNaN(daysToNumber) || daysToNumber < 0 ? (
                <AlertMessage data-test="days-invalid" message={t('validation.dayNotSet')} />
            ) : (
                renderOutput({ link, days: daysToNumber })
            )}
        </>
    );
};

// @ts-ignore Possible error in react-api-data type definitions
export default withTranslation()(QuickDebugWithDays);