import React, { FunctionComponent, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Input } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18next from 'i18next';

interface Props extends WithTranslation {
    keyField: string;
    columns: object[];
    data?: any[];
    paginationOptions?: { [x: string]: any };
    search?: boolean;
    noPadding?: boolean;
    tableWrapperClassName?: string;

    [x: string]: any;
}

const customSortCaret = (order?: string) => {
    if (order) {
        return <i className={`fas fa-sort-${order === 'asc' ? 'up' : 'down'} fa-pull-right custom-sort-caret`} />;
    }

    return <i className="fas fa-sort fa-pull-right text-light custom-sort-caret" />;
};

const boolFormatter = (cell: boolean) => (
    <i className={`fas fa-${cell ? 'check' : 'times'} text-${cell ? 'success' : 'danger'}`} />
);

export const paginationTotalRenderer = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
        {i18next.t('table.totalRows')} { from } {i18next.t('table.to')} { to } {i18next.t('table.of')} { size }
    </span>
);

export function columnsFactory(columns: object[]) {
    return columns.map((col: any) => {
        if (col.sort) {
            col.sortCaret = customSortCaret;
        }

        if (col.boolIcon) {
            col.text = '';
            col.headerFormatter = () => <i className={`fas fa-${col.boolIcon}`} />;
            col.formatter = boolFormatter;
        }

        if (col.copy) {
            col.events = {
                onClick: (_e: any, column: any, _colIndex: number, row: any, _rowIndex: number) => {
                    if (copy(row[column.dataField])) {
                        toast.success(
                            <>{i18next.t('table.copyToClipboard')}<br/>{row[column.dataField]}</>,
                            { autoClose: 2000 }
                        );
                    }
                },
            };
            col.style = () => ({
                cursor: 'copy'
            });
        }

        return col;
    });
}

const defaultProps = {
    bordered: false,
    hover: true,
    defaultSortDirection: 'asc',
    bootstrap4: true
};

const defaultPaginationOptions = {
    sizePerPage: 5,
    paginationSize: 3,
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    withFirstAndLast: false
};

const InteractiveTable: FunctionComponent<Props> = ({
    columns, paginationOptions, data, keyField, search, noPadding, searchTerm, updateSearchTerm, t, tableWrapperClassName, ...otherProps
}) => {
    const processedColumns = useMemo(() => columnsFactory(columns), [columns]);
    const mergedProps = { ...defaultProps, ...otherProps }
    const mergedPaginationOptions = {
        ...defaultPaginationOptions,
        paginationTotalRenderer,
        ...paginationOptions
    };

    return (
        <StyleContainer addPadding={!noPadding}>
            <ToolkitProvider
                columns={processedColumns}
                data={data}
                keyField={keyField}
                search={{
                    defaultSearch: searchTerm
                }}
            >
                {(props: any) => (
                    <>
                        {search && (
                            <Input
                                className="global-search-field"
                                bsSize="lg"
                                placeholder={t('table.searchInputPlaceholder')}
                                style={{ marginBottom: '1rem' }}
                                value={searchTerm}
                                onChange={e => {
                                    const elem = e.target as HTMLInputElement;
                                    updateSearchTerm(elem.value);
                                    props.searchProps.onSearch(elem.value);
                                }}
                            />
                        )}
                        <div className={tableWrapperClassName}>
                            <BootstrapTable
                                pagination={paginationFactory(mergedPaginationOptions)}
                                {...props.baseProps}
                                {...mergedProps}
                            />
                        </div>
                    </>
                )}
            </ToolkitProvider>
        </StyleContainer>
    );
};

export const StyleContainer = styled.div<{ addPadding?: boolean }>`
  th.sortable {
    cursor: pointer;
    
    :hover {
      color: #f47f22;
    }
  }
  .react-bootstrap-table-pagination-total {
    display: block;
    padding-top: 0.5em;
    padding-left: ${props => props.addPadding ? '0.75rem' : 0};
  }
  .react-bootstrap-table-pagination {
    padding-left: ${props => props.addPadding ? '0.75rem' : 0};
    padding-right: ${props => props.addPadding ? '0.75rem' : 0};
  }
  .react-bs-table-no-data {
    text-align: center;
    font-style: italic;
  }
`;

export default withTranslation()(InteractiveTable);
