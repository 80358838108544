import { Alert, Col, FormGroup, Label, Card } from 'reactstrap';
import React from 'react';
import { ErrorMessage, Formik, FormikActions, FormikErrors } from 'formik';
import { compose } from 'recompose';
import { ApiDataBinding, ApiDataRequest, withApiData } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, IpAddress, LinkFull, PublicVlanItem, ReactApiDataActions, Router, Vlan, MacAddress } from 'constants/api';
import { IpRangeType, LinkVlanMode, NetworkRequestStatus, LinkType } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import Ipv4Select from 'components/utils/Ipv4Select';
import AsnSelectContract from 'components/utils/AsnSelectContract';
import AsnSelectAll from 'components/utils/AsnSelectAll';
import { object } from 'yup';
import { createIpv6Validation } from 'validation';
import { TAG_MIN_VALUE, TAG_MAX_VALUE } from 'constants/index';
import Badge from 'reactstrap/lib/Badge';
import { getStatusColor } from 'utils/formatUtils';

interface InputProps {
    linkVlan: Vlan;
    vlan: PublicVlanItem;
    link: LinkFull;
    ipv4Routers: Router[];
    ipv6Routers: Router[];
    renderButton: (renderProps: { onClick: () => void }) => void;
}

interface EnhanceProps {
    linkVlanDbUpdate: ApiDataBinding<ApiResponse<Vlan>>;
    ipv6Create: ApiDataBinding<ApiResponse<IpAddress>>;
    ipv6Request: ApiDataBinding<IpAddress>;
    apiDataActions: ReactApiDataActions;  
}

type LinkVlanProps = EnhanceProps & InputProps & BaseFormModalProps & WithTranslation;

const enhance = compose<LinkVlanProps & WithTranslation, InputProps>(
    withTranslation(),
    withApiData(
        {
            linkVlanDbUpdate: 'putLinkVlan',
            ipv6Create: 'postIpv6',
            ipv6Request: 'getFreeIpv6',
        },
        (ownProps: InputProps) => ({
            ipv6Request: {
                exchange: ownProps.link.exchange.short_name,
                vlan: ownProps.linkVlan.vlan_id,
                asn: NaN
            },
        })
    )
);

type FormInitialValuesType = {
    xml_id: string;
    speed: number;
    mode: LinkVlanMode;
    qtag: string | null;
    inner_qtag: string | null;
    jira_ticket: string;
    as_number: number;
    asn_id: number;
    ipv4_ids: (number | null)[];
    ipv6_ips: string[];
    mac_addresses: MacAddress[];
};

type IpRouterUpdateParams = {
    id?: number;
    ip_id?: number;
    _destroy?: boolean;
};

type MacAddressesUpdateParams = {
    id?: number;
    value?: string;
    status?: string;
    _destroy?: boolean;
};

type LinkVlanUpdateParams = {
    xml_id?: string;
    speed?: number;
    mode?: LinkVlanMode;
    tag?: string | null;
    inner_tag?: string | null;
    jira_ticket?: string;
    asn_id?: number | null;
    ipv4_routers?: IpRouterUpdateParams[];
    ipv6_routers?: IpRouterUpdateParams[];
    mac_addresses?: MacAddressesUpdateParams[];
};

class LinkVlanEditDatabaseModal extends BaseFormModal<LinkVlanProps> {
    componentDidUpdate(prevProps: Readonly<LinkVlanProps>): void {
        this.handleFormSubmission(prevProps.linkVlanDbUpdate.request, this.props.linkVlanDbUpdate.request, {
            successMessage: this.props.t('linkVlanEditDbModal.successMessage'),
        });
    }

    _handleOnSubmit = async (values: FormInitialValuesType, formikBag: FormikActions<FormInitialValuesType>) => {
        const { linkVlanDbUpdate, apiDataActions, link, linkVlan } = this.props;
        try {
            const currentJiraTicket = linkVlan.jira_ticket === null ? null : linkVlan.jira_ticket!.split('/').slice(-1)![0];
            const currentRateLimit = linkVlan.rate_limiter!.output.limit ? (linkVlan.rate_limiter!.output.limit / 1000000) : 0;

            let params: LinkVlanUpdateParams = {}
            if (values.mode === LinkVlanMode.Untagged) { 
                values.inner_qtag = null;
                values.qtag = null;
            }
            if (values.mode === LinkVlanMode.Tagged) { 
                values.inner_qtag = null;
            }
            if (values.asn_id !== linkVlan.asn_id) params.asn_id = values.asn_id;
            if (values.mode !== linkVlan.mode) params.mode = values.mode;
            if (values.qtag !== linkVlan.qtag) params.tag = values.qtag;
            if (values.inner_qtag !== linkVlan.inner_qtag) params.inner_tag = values.inner_qtag;
            if (values.jira_ticket !== currentJiraTicket) params.jira_ticket = values.jira_ticket;
            if (values.xml_id !== linkVlan.xml_id) params.xml_id = values.xml_id;

            if (values.speed !== currentRateLimit)
                params.speed = values.speed ? values.speed : 0;

            const ipv4RouterChanges = this._getIpv4RouterUpdateParams(values);
            if (ipv4RouterChanges) {
                params.ipv4_routers = ipv4RouterChanges;
            }
            const ipv6RouterChanges = await this._getIpv6RouterUpdateParams(values);
            if (ipv6RouterChanges) {
                params.ipv6_routers = ipv6RouterChanges;
            }

            const macAddressChanges = this._getMacAddressesUpdateParams(values);
            if (macAddressChanges) {
                params.mac_addresses = macAddressChanges;
            }

            const binding = await linkVlanDbUpdate
                .perform({ uuid: linkVlan.uuid, exchange: link.exchange.short_name }, { link_vlan: params })
            if (binding.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getMemberLink', {
                    linkId: link.uuid,
                    exchange: link.exchange.short_name,
                });
            }
        } catch(request) {
            this.showErrorMessage(request as ApiDataRequest);
            formikBag.setSubmitting(false);
            formikBag.setStatus('error');
        }
    }

    _getIpv6RouterUpdateParams = async (values: FormInitialValuesType): Promise<IpRouterUpdateParams[]>  => {
        const { ipv6Routers } = this.props;
        const asnId = values.asn_id;
        const ipv6RouterIpIds = ipv6Routers.map((router) => router.ip_id);

        let updateParams = [];

        for (const router of ipv6Routers) {
            // router ip doesn't exist anymore, destroy it
            if (!values.ipv6_ips.includes(router.ip)) {
                updateParams.push({
                    id: router.id,
                    _destroy: true
                });
            }
        }

        for (const ipv6Ip of values.ipv6_ips) {
            if (ipv6Ip) {
                const ipv6 = await this._fetchIpV6Address(ipv6Ip, asnId);
                if (ipv6) {
                    // existing ipv6, ignore
                    if (ipv6RouterIpIds.includes(ipv6.id)) {
                        continue;
                    }

                    // new ipv6, add it
                    updateParams.push({ ip_id: ipv6.id });
                }
            }
        }

        return updateParams;
    }

    _getIpv4RouterUpdateParams = (values: FormInitialValuesType): IpRouterUpdateParams[] => {
        const { ipv4Routers } = this.props;
        const ipv4RouterIpIds = ipv4Routers.map((router) => router.ip_id);

        let updateParams = [];

        for (const router of ipv4Routers) {
            // router ip doesn't exist anymore, destroy it
            if (!values.ipv4_ids.includes(router.ip_id)) {
                updateParams.push({
                    id: router.id,
                    _destroy: true
                });
            }
        }

        for (const ipv4Id of values.ipv4_ids) {
            if (ipv4Id) {
                // existing ipv4, ignore
                if (ipv4RouterIpIds.includes(ipv4Id)) {
                    continue;
                }

                // new ipv4, add it
                updateParams.push({ ip_id: ipv4Id });
            }
        }

        return updateParams;
    }

    _fetchIpV6Address = async (ipValue: string, asnId: number) => {
        const { ipv6Create, link, linkVlan } = this.props;
        const binding = await ipv6Create.perform(
            { exchange: link.exchange.short_name },
            { ipv6: { ip: ipValue, asn_id: asnId, vlan_uuid: linkVlan.vlan_uuid } }
        )
        if (binding.request.networkStatus === NetworkRequestStatus.Success) {
            return binding.data!.data;
        } else {
            throw binding.request;
        }
    }

    _setIpv6AddressFromAsn = (asn: number, setValue: (field: string, value: any) => void) => {
        const { ipv6Request, link, linkVlan } = this.props;
        if (!this._ipv6Range) return;

        if (asn) {
            ipv6Request.perform({
                exchange: link.exchange.short_name,
                vlan: linkVlan.vlan_id,
                asn: asn
            }).then(binding => {
                if (binding.request.networkStatus === NetworkRequestStatus.Success) {
                    setValue('ipv6_ips[0]', binding.data!.ip);
                } else {
                    this.showErrorMessage(binding.request);
                }
            });
        } else {
            setValue('ipv6_ips[0]', '');
        }
    };

    _addNewRouterPair = (values: FormInitialValuesType, setValue: (field: string, value: any) => void) => {
        setValue('ipv4_ids', [...values.ipv4_ids, 0]);
        setValue('ipv6_ips', [...values.ipv6_ips, '']);
    };

    _removeRouterPair = (index: number, values: FormInitialValuesType, setValue: (field: string, value: any) => void) => {
        setValue('ipv4_ids', this._removeElementAtIndex(values.ipv4_ids, index));
        setValue('ipv6_ips', this._removeElementAtIndex(values.ipv6_ips, index));
    };

    _removeElementAtIndex = <T,>(array: Array<T>, index: number) => {
        return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
    };

    _ipPairs = (ipv4_ids: (number | null)[], ipv6_ips: string[]): (string | number | null)[][] => {
        const maxLength = Math.max(ipv4_ids.length, ipv6_ips.length);
        const pairs: (string | number | null)[][] = [];

        for (let i = 0; i < maxLength; i++) {
            pairs.push([ipv4_ids[i] || null, ipv6_ips[i] || null]);
        }

        return pairs;
    }

    _setIpv4Address = (index: number, ip_id: number | null, setValue: (field: string, value: any) => void) => {
        setValue(`ipv4_ids[${index}]`, ip_id);
    };

    _setIpv6Address = (index: number, ip: string, setValue: (field: string, value: any) => void) => {
        setValue(`ipv6_ips[${index}]`, ip);
    };

    _setMacAddress = (index: number, mac_address: string, setValue: (field: string, value: any) => void) => {
        setValue(`mac_addresses[${index}].value`, mac_address);
    };

    _addNewMacAddress = (values: FormInitialValuesType, setValue: (field: string, value: any) => void) => {
        setValue('mac_addresses', [...values.mac_addresses, { value: '', status: 'configured' }]);
    };

    _removeMacAddress = (index: number, values: FormInitialValuesType, setValue: (field: string, value: any) => void) => {
        setValue('mac_addresses', this._removeElementAtIndex(values.mac_addresses, index));
    };

    _getMacAddressesUpdateParams = (values: FormInitialValuesType): MacAddressesUpdateParams[]  => {
        const macAddresses = this.props.linkVlan.mac_addresses;;

        let updateParams: MacAddressesUpdateParams[] = [];

        const deletedMacAddresses = macAddresses.filter(m => !values.mac_addresses.includes(m));
        const addedMacAddresses = values.mac_addresses.filter(m => !macAddresses.includes(m));

        updateParams.push(...deletedMacAddresses.map(macAddress => ({
            id: macAddress.id,
            value: macAddress.value,
            status: macAddress.status,
            _destroy: true
        })));

        updateParams.push(...addedMacAddresses.map(macAddress => ({
            value: macAddress.value,
            status: macAddress.status,
        })));

        return updateParams;
    }

    _canAddRouterPair = (values: FormInitialValuesType) => {
        const { link } = this.props;

        return (values.ipv4_ids.length < 1 && values.ipv6_ips.length < 1) || link.type === LinkType.MonitorLink;
    };

    get _ipv6Range() {
        return this.props.vlan.ip_ranges.find(range => range.type === IpRangeType.Ipv6Range);
    }

    render() {
        const { link, linkVlan, ipv4Routers, ipv6Routers, vlan, renderButton, t } = this.props;
        const initJiraTicket = linkVlan.jira_ticket === null ? '' : linkVlan.jira_ticket!.split('/').slice(-1)![0];
        const initialAsn = { asn_id: linkVlan.asn_id, as_number: linkVlan.as_number };
        const rateLimit = linkVlan.rate_limiter!.output.limit ? (linkVlan.rate_limiter!.output.limit / 1000000) : 0;

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            xml_id: linkVlan.xml_id,
                            speed: rateLimit,
                            mode: linkVlan.mode,
                            qtag: linkVlan.qtag,
                            inner_qtag: linkVlan.inner_qtag,
                            jira_ticket: initJiraTicket,
                            as_number: initialAsn.as_number,
                            asn_id: initialAsn.asn_id,
                            ipv4_ids: ipv4Routers.map((router) => router.ip_id),
                            ipv6_ips: ipv6Routers.map((router) => router.ip),
                            mac_addresses: linkVlan.mac_addresses,
                        } as FormInitialValuesType
                    }
                    onSubmit={this._handleOnSubmit}
                    validate={(values) => {
                        const errors: FormikErrors<FormInitialValuesType> = {};
                        if (values.asn_id && values.ipv4_ids.length === 0 && values.ipv6_ips.length === 0) {
                           errors.asn_id = t('linkVlanEditDbModal.invalidAsnMessage');
                        }
                        return errors;
                    }}
                    validationSchema={object().shape({
                        ipv6_ip: createIpv6Validation(t),
                    })}
                >
                    {({ handleSubmit, setValues, setFieldValue, handleReset, isSubmitting, values, errors, touched, dirty }) => (
                        <BaseFormModalTemplate
                            data-test="link-editdb-modal-tmpl"
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            handleSubmit={handleSubmit}
                            header={t('linkVlanEditDbModal.headerTitle')}
                            onClosed={handleReset}
                            body={
                                <>
                                    <Alert color="warning">{t('linkVlanEditDbModal.warningMessage')}</Alert>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="vlan_description">
                                                {t('linkVlanEditDbModal.vlanDescription')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="text"
                                                name="vlan_description"
                                                errors={errors}
                                                touched={touched}
                                                readOnly={true}
                                                value={vlan.description}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="xml_id">
                                                {t('linkVlanEditDbModal.linkVlanName')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="text"
                                                name="xml_id"
                                                errors={errors}
                                                touched={touched}
                                                readOnly={false}
                                                defaultValue={linkVlan.xml_id}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="speed">{t('linkVlanEditDbModal.rateLimit')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="number"
                                                name="speed"
                                                errors={errors}
                                                touched={touched}
                                                readOnly={false}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="mode">{t('common.tag')}</Label>
                                        </Col>
                                        <Col md={4}>
                                            <FormControl
                                                component="select"
                                                name="mode"
                                                errors={errors}
                                                touched={touched}
                                                onChange={e => {
                                                    const mode = e.target.value;
                                                    setFieldValue('mode', mode);
                                                    if (mode === LinkVlanMode.Untagged) {
                                                        setFieldValue('qtag', null);
                                                    } else {
                                                        setFieldValue('qtag', linkVlan.qtag);
                                                    }
                                                }}
                                            >
                                                {Object.values(LinkVlanMode).map((mode: string) => (
                                                    <option key={mode} value={mode}>
                                                        {mode}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                        {(values.mode === LinkVlanMode.Tagged || values.mode === LinkVlanMode.Dual) && (
                                            <>
                                                <Col md={1}>
                                                    <div id="qtagLabel">
                                                        <Label htmlFor="qtag">{t('common.qtag')}</Label>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div id="qtag">
                                                        <FormControl
                                                            type="number"
                                                            name="qtag"
                                                            min={TAG_MIN_VALUE}
                                                            max={TAG_MAX_VALUE}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </FormGroup>
                                    {values.mode === LinkVlanMode.Dual && (
                                        <>
                                            <FormGroup row>
                                                <Col md={7}></Col>
                                                <Col md={1}>
                                                    <div id="innerQtagLabel">
                                                        <Label htmlFor="inner_qtag">{t('common.innerTag')}</Label>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div id="inner_qtag">
                                                        <FormControl
                                                            type="number"
                                                            name="inner_qtag"
                                                            min={TAG_MIN_VALUE}
                                                            max={TAG_MAX_VALUE}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                        </>
                                    )}
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="jira_ticket">{t('linkVlanEditDbModal.jiraTicket')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="text"
                                                name="jira_ticket"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="asn_id">{t('addVlanModal.asnSelect')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            {linkVlan.is_virtual ? (
                                                <AsnSelectAll
                                                    onChange={(asnNumber, _organisationId, _organisationUuid, asnId) => {
                                                        setValues({ ...values, as_number: asnNumber, asn_id: asnId });
                                                        this._setIpv6AddressFromAsn(asnNumber, setFieldValue);
                                                    }}
                                                    defaultValue={values.as_number}
                                                />
                                            ) : (
                                                <AsnSelectContract
                                                    onChange={(asnNumber, asnId) => {
                                                        setValues({ ...values, as_number: asnNumber, asn_id: asnId });
                                                        this._setIpv6AddressFromAsn(asnNumber, setFieldValue);
                                                    }}
                                                    value={values.as_number}
                                                    contractUuid={link.contract_uuid}
                                                    exchange={link.exchange.short_name}
                                                />
                                            )}
                                            <ErrorMessage name="asn_id">
                                                {msg => <div className="invalid-feedback d-block">{msg}</div>}
                                            </ErrorMessage>
                                            {values.asn_id === null && values.ipv6_ips.length > 0 && (
                                                <div className="invalid-feedback d-block">
                                                    <i className="fas fa-exclamation-triangle mr-2" />{t('linkVlanEditDbModal.ipv6WillBeRemovedWarningMessage')}
                                                </div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            {t('common.routers')}
                                        </Col>
                                        <Col md={9}>
                                            {this._ipPairs(values.ipv4_ids, values.ipv6_ips).map(([ipv4_id, ipv6_ip]: (string | number | null)[], index) => (
                                                <Card className="bg-light mb-3 p-2" key={[ipv4_id,  ipv6_ip].join()}>
                                                    <div className="row justify-content-between m-0">
                                                        <Label>{t('linkVlanEditDbModal.ipv4')}</Label>
                                                        <small
                                                            className="text-danger cursor-pointer"
                                                            onClick={() => this._removeRouterPair(index, values, setFieldValue)}
                                                        >
                                                            <i className="fas fa-trash" />
                                                        </small>
                                                    </div>
                                                    <Ipv4Select
                                                        exchange={link.exchange.short_name}
                                                        vlanNumber={linkVlan.vlan_id}
                                                        onChange={(value) => this._setIpv4Address(index, value, setFieldValue)}
                                                        value={ipv4_id as (number | null)}
                                                    />
                                                    <Label className="mt-3">{t('linkVlanEditDbModal.ipv6')}</Label>
                                                    <FormControl
                                                        type="text"
                                                        name="ipv6_ip"
                                                        value={ipv6_ip as string}
                                                        onChange={(event) => this._setIpv6Address(index, event.target.value, setFieldValue)}
                                                        errors={errors}
                                                        touched={touched}
                                                        disabled={!this._ipv6Range}
                                                    />
                                                    {!this._ipv6Range && (
                                                        <small className="form-text text-muted">
                                                            {t('linkVlanEditDbModal.noIpv6RangeMessage', { vlan: vlan.description })}
                                                        </small>
                                                    )}
                                                </Card>
                                            ))}

                                            {this._canAddRouterPair(values) && (
                                                <span className="cursor-pointer text-primary" onClick={() => this._addNewRouterPair(values, setFieldValue)}>
                                                    <i className="fas fa-plus" /> {t('linkVlanEditDbModal.addRouterPair')}
                                                </span>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            {t('common.macAddresses')}
                                        </Col>
                                        <Col md={9}>
                                            {values.mac_addresses.map((mac_address, index) => (
                                                <Card key={index} className="bg-light mb-3 p-2">
                                                    <div className="row justify-content-between m-0 align-items-center">
                                                        <div className="row align-items-center m-0">
                                                            <FormControl
                                                                type="text"
                                                                name="mac_address"
                                                                className="w-auto mr-2"
                                                                value={mac_address.value}
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={(event) => this._setMacAddress(index, event.target.value, setFieldValue)}
                                                                placeholder={t('linkVlanEditDbModal.macAddressPlaceholder')}
                                                            />
                                                            <Badge color={getStatusColor(mac_address.status)}>{mac_address.status}</Badge>
                                                        </div>
                                                        <small
                                                            className="text-danger cursor-pointer"
                                                            onClick={() => this._removeMacAddress(index, values, setFieldValue)}
                                                        >
                                                            <i className="fas fa-trash" />
                                                        </small>
                                                    </div>
                                                </Card>
                                            ))}

                                            <span className="cursor-pointer text-primary" onClick={() => this._addNewMacAddress(values, setFieldValue)}>
                                                <i className="fas fa-plus" /> {t('linkVlanEditDbModal.addMacAddress')}
                                            </span>
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                {renderButton({ onClick: this.toggle })}
            </React.Fragment>
        );
    }
}

export default enhance(LinkVlanEditDatabaseModal);
