import React from 'react';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { FormGroup, Col, Label, Button } from 'reactstrap';
import { Field, Formik } from 'formik';
import { IpRouterType, NetworkRequestStatus, Roles, VlanLanType } from 'constants/literals';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import RolesChecker from 'components/utils/RolesChecker';
import AsnSelectAll from 'components/utils/AsnSelectAll';
import VlanSelectAll from 'components/utils/VlanSelectAll';
import RoutersSelect from 'components/utils/RoutersSelect';
import { ApiResponse, Organisation, ReactApiDataActions, RouteServer } from 'constants/api';
import { CenteredAlert } from 'components/styled';

interface InputProps {
    routeServer: RouteServer;
    organisation: Organisation;
}

interface EnhanceProps {
    updateRouteServer: ApiDataBinding<ApiResponse<RouteServer>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InputProps & BaseFormModalProps & WithTranslation;

const enhance = compose<Props, InputProps & BaseFormModalProps>(
    withApiData({
        updateRouteServer: 'putRouteServer',
    }),
    withTranslation()
);

type FormInitialValuesType = {
    description: string;
    rs_id: string;
    rs_type: string;
    management_address: string;
    vlan_number: number;
    ipv4_router_uuid: string | null;
    ipv6_router_uuid: string | null;
    as_number: number;
    organisation_id: number | null;
    exchange: string;
    bgp_community_routing_policies: number;
    irrdb_based_routing_policies: number;
    rpki_prefix_tagging: number;
    irrdb_prefix_tagging: number;
    max_prefix_ipv4: number;
    max_prefix_ipv6: number;
};

class RouteServerEditModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { updateRouteServer } = this.props;
        this.handleFormSubmission(prevProps.updateRouteServer.request, updateRouteServer.request, {
            successMessage: this.props.t('routeServerEditModal.successMessage')
        });
    };

    _handleSubmit = (values: FormInitialValuesType) => {
        const { updateRouteServer, routeServer, apiDataActions } = this.props;
        updateRouteServer.perform(
                { routeServerUuid: routeServer.uuid },{ route_server: values }
        ).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getRouteServer', { routeServerUuid: routeServer.uuid });
                apiDataActions.perform('getRouteServer', { routeServerUuid: routeServer.uuid });
            }
        });
    };

    render() {
        const { routeServer, organisation, t } = this.props;

        const validateInputs = (data: any) => {
            return (
                !data.description ||
                !data.rs_id ||
                !data.rs_type ||
                !data.management_address ||
                !data.vlan_number ||
                !data.as_number ||
                (!data.ipv4_router_uuid && !data.ipv6_router_uuid) ||
                !data.max_prefix_ipv4 ||
                !data.max_prefix_ipv6
            );
        };

        return (
            <>
                <Formik
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            description: routeServer.description,
                            rs_id: routeServer.name,
                            rs_type: routeServer.type,
                            management_address: routeServer.management_address,
                            vlan_number: routeServer.vlan.number,
                            ipv4_router_uuid: routeServer.ipv4_router_uuid,
                            ipv6_router_uuid: routeServer.ipv6_router_uuid,
                            as_number: routeServer.as_number,
                            organisation_id: organisation.id,
                            bgp_community_routing_policies: routeServer.policy_modes.bgp_communities,
                            irrdb_based_routing_policies: routeServer.policy_modes.irrdb_policy,
                            rpki_prefix_tagging: routeServer.prefix_validation.rpki_validation,
                            irrdb_prefix_tagging: routeServer.prefix_validation.irrdb_validation,
                            max_prefix_ipv4: routeServer.max_prefix_ipv4,
                            max_prefix_ipv6: routeServer.max_prefix_ipv6,
                        } as FormInitialValuesType
                    }
                    onSubmit={this._handleSubmit}
                >
                    {({ values, setValues, setFieldValue, errors, isSubmitting, touched, handleSubmit }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('routeServerEditModal.headerTitle')}
                            body={
                                <>
                                    <div>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="description">{t('routeServerModal.description')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="description" errors={errors} touched={touched} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="rs_id">{t('routeServerModal.id')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="rs_id" errors={errors} touched={touched} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="rs_type">{t('routeServerModal.type')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="rs_type" errors={errors} touched={touched} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="management_address">
                                                    {t('routeServerModal.managementAddress')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl
                                                    name="management_address"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="as_number">{t('addVlanModal.asnSelect')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <AsnSelectAll
                                                    organisationId={organisation.id}
                                                    onChange={(asn) => {
                                                        setValues({ ...values, as_number: asn });
                                                    }}
                                                    defaultValue={routeServer.as_number}
                                                    defaultLabel={
                                                        routeServer.as_number
                                                            ? routeServer.as_number + ',  ' + organisation.name
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="vlan_number">{t('addVlanModal.vlanSelect')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <VlanSelectAll
                                                    exchange={routeServer.exchange}
                                                    excludeVlanType={[VlanLanType.PeerToPeer]}
                                                    onChange={(vlan) => {
                                                        setValues({ ...values, vlan_number: vlan });
                                                    }}
                                                    defaultLabel={routeServer.vlan.description}
                                                    defaultValue={routeServer.vlan.number}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="Ipv4">{t('routeServerEditModal.Ipv4')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <RoutersSelect
                                                    exchange={routeServer.exchange}
                                                    type={IpRouterType.Ipv4Router}
                                                    asnNumber={values.as_number}
                                                    organisationId={organisation.id}
                                                    vlanNumber={values.vlan_number}
                                                    bgpRouterUuid={routeServer.uuid}
                                                    onChange={(routerUuid) =>
                                                        setFieldValue('ipv4_router_uuid', routerUuid)
                                                    }
                                                    value={values.ipv4_router_uuid}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="Ipv6">{t('routeServerEditModal.Ipv6')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <RoutersSelect
                                                    exchange={routeServer.exchange}
                                                    type={IpRouterType.Ipv6Router}
                                                    asnNumber={values.as_number ? values.as_number : 0}
                                                    organisationId={organisation.id}
                                                    vlanNumber={values.vlan_number}
                                                    bgpRouterUuid={routeServer.uuid}
                                                    onChange={(routerUuid) =>
                                                        setFieldValue('ipv6_router_uuid', routerUuid)
                                                    }
                                                    value={values.ipv6_router_uuid}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="bgp_community_routing_policies">
                                                    {t('routeServerDetails.bgpCommunityRoutingPolicies')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field
                                                    type="checkbox"
                                                    name="bgp_community_routing_policies"
                                                    checked={values.bgp_community_routing_policies}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="irrdb_based_routing_policies">
                                                    {t('routeServerDetails.irrdbBasedRoutingPolicies')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field
                                                    type="checkbox"
                                                    name="irrdb_based_routing_policies"
                                                    checked={values.irrdb_based_routing_policies}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="rpki_prefix_tagging">
                                                    {t('routeServerDetails.rpkiPrefixTagging')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field
                                                    type="checkbox"
                                                    name="rpki_prefix_tagging"
                                                    checked={values.rpki_prefix_tagging}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="irrdb_prefix_tagging">
                                                    {t('routeServerDetails.irrdbPrefixTagging')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field
                                                    type="checkbox"
                                                    name="irrdb_prefix_tagging"
                                                    checked={values.irrdb_prefix_tagging}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="max_prefix_ipv4">
                                                    {t('routeServerDetails.maxPrefixIPv4')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl
                                                    name="max_prefix_ipv4"
                                                    type="number"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="max_prefix_ipv6">
                                                    {t('routeServerDetails.maxPrefixIPv6')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl
                                                    name="max_prefix_ipv6"
                                                    type="number"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <CenteredAlert color="warning">
                                            {t('routeServerModal.noteMandatoryFields')}
                                        </CenteredAlert>
                                    </div>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={validateInputs(values)}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker roles={[Roles.admin, Roles.noc]}>
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('routeServerEditModal.editBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

export default enhance(RouteServerEditModal);

