import {
    Alert
} from 'reactstrap';
import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';

interface InputProps {
    routerUuid: string;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    resourceStatus: string;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'putDeleteRouterFromLink', {
        routerId: ownProps.routerUuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
    // resourceStatus: get(ownProps, 'vlan.status')
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            dispatch(performApiRequest('putDeleteRouterFromLink', {
                routerId: ownProps.routerUuid,
                exchange: ownProps.exchange,
                linkId: ownProps.linkId
            }));
            return false;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class RouterDeleteModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { request, t } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            successMessage: t('notification.deleteRouterFromLinkSuccessful'),
            errorMessage: t('notification.deleteRouterFromLinkFailed'),
        });
    }

    render() {
        const { request, onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('deleteRouterModal.headerTitle')}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('deleteRouterModal.warningMessage')}</p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.qnoc]}>
                    <span data-test="router-delete-btn" className="fas fa-trash fa-pull-right mt-1 text-primary cursor-pointer" 
                          onClick={this.toggle} title={t('deleteRouterModal.headerTitle')}>                    
                    </span>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(RouterDeleteModal);
