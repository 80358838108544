import React, { FunctionComponent } from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data/lib';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContractItem } from 'constants/api';
import get from 'lodash/get';
import { NetworkRequestStatus } from '../../constants/literals';
import Spinner from 'reactstrap/lib/Spinner';

interface InProps {
    onChange: (contractUuid: string) => void;
    organisationUuid: string;
    value: string;
}

type ContractsSelectProps = InProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    organisationContractsRequest: ApiDataBinding<ContractItem[]>;
}

const enhance = compose<ContractsSelectProps, InProps>(
    withApiData({ organisationContractsRequest: 'getContractsInOrganisation' }, (ownProps) => ({
        organisationContractsRequest: { organisationUuid: ownProps.organisationUuid, all_contracts: 'true' },
    })),
    withTranslation()
);

interface OptionType {
    label: string;
    value: string;
}

const OrganisationContractSelect: FunctionComponent<ContractsSelectProps> = ({ organisationContractsRequest, onChange, value, t }) => {

    if (organisationContractsRequest.request.networkStatus !== NetworkRequestStatus.Success) {
        return <Spinner type="grow" color="primary" />;
    }

    const options = get(organisationContractsRequest, 'data.data.contracts', []).map((contract: ContractItem) => ({
        label: contract.number + ' -> ' + contract.name,
        value: contract.uuid,
    }));

    const optChanged = (e: any) => {
        onChange(e.value);
    };

    const selectedOption = options.find((o: { value: string }) => o.value === value);

    return (
        <Select
            options={options}
            onChange={optChanged}
            isLoading={false}
            defaultValue={selectedOption}
        />
    );
};

// @ts-ignore
export default enhance(OrganisationContractSelect);
