import React, { FunctionComponent } from 'react';

interface Props {
    value?: boolean;
}

const BoolIcon: FunctionComponent<Props> = ({ value }) => {
    const icon = value ? 'check' : 'times';
    const color = value ? 'success' : 'danger';

    return <i className={`fas fa-${icon} text-${color}`} />;
};

export default BoolIcon;