import { compose } from 'recompose';
import { connect } from 'react-redux';
import { performApiRequest } from 'react-api-data/lib';

import ResourceTransitions, { CommonInProps, TransitionItemProp, DispatchProps } from './ResourceTransitions';
import { ResourceType } from 'constants/literals';

interface LinkVlanInProps extends CommonInProps {
    linkId: string;
}

const linkVlanMapStateToProps = (dispatch: any, ownProps: LinkVlanInProps) => ({
    onConfirm: (transitionItem: TransitionItemProp) => {
        if (!transitionItem.endpointKey) {
            console.warn(`Endpoint is not set for ${transitionItem.transitionType}!`);
            return;
        }
        const requestParams: any = {
            resourceId: ownProps.resourceId,
            resourceName: ResourceType.link_vlan,
            exchange: ownProps.exchange,
            linkId: ownProps.linkId
        };

        dispatch(performApiRequest(transitionItem.endpointKey, requestParams));
    }
});

export default compose<LinkVlanInProps & DispatchProps, LinkVlanInProps>(
    connect(null, linkVlanMapStateToProps),
)(ResourceTransitions);
