import React, { FunctionComponent } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

// constants
import { Organisation } from 'constants/api';

// components
import AsnAddModal from 'components/modals/AsnAddModal';
import OrganisationEditModal from '../modals/OrganisationEditModal';

interface InProps {
    organisation: Organisation;
    contractUuid: string;
}

type Props = InProps & WithTranslation;

const OrganisationInfo: FunctionComponent<Props> = ({ organisation, contractUuid, t }) => {
    return (
        <>
            <Helmet title={organisation.name} />
            <Card className="card-accent-primary">
                <CardHeader>
                    <strong>{t('common.details')}</strong>
                </CardHeader>
                <CardBody>
                    <OrganisationInfoTable size="sm" borderless>
                        <tbody>
                            <tr>
                                <th>{t('contract.orgName')}</th>
                                <td>{organisation.name}
                                <OrganisationEditModal
                                        organisation={organisation}
                                        data-test="edit-organisation-modal"
                                    />
                                </td>
                            </tr>
                            {organisation.erp_customer_identifier && (
                                <tr>
                                    <th>{t('contract.orgIdentifier')}</th>
                                    <td>{organisation.erp_customer_identifier}</td>
                                </tr>
                            )}
                            <tr>
                                <th rowSpan={Math.max(organisation.asns.length, 1)}>{t('contract.asNumbers')}</th>
                                <td>
                                    {organisation.asns.length > 0 ? (
                                        <div>
                                            <span data-test="contract-asn">{organisation.asns[0].number}</span>{' '}
                                        </div>
                                    ) : (
                                        <AsnAddModal contractUuid={contractUuid} organisationUuid={organisation.uuid}/>
                                    )}
                                </td>
                            </tr>
                            {organisation.asns.slice(1).map((asn) => (
                                <tr key={asn.id}>
                                    <td>
                                        <span data-test="contract-asn">{asn.number}</span>{' '}
                                    </td>
                                </tr>
                            ))}
                            {organisation.asns.length > 0 && (
                                <tr>
                                    <th />
                                    <td>
                                        <AsnAddModal
                                            data-test="asn-add-modal"
                                            contractUuid={contractUuid}
                                            organisationUuid={organisation.uuid}
                                        />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th />
                            </tr>
                        </tbody>
                    </OrganisationInfoTable>
                </CardBody>
            </Card>
        </>
    );
};

const OrganisationInfoTable = styled(Table)`
    th {
        width: 10em;
    }
`;

export default withTranslation()(OrganisationInfo);
