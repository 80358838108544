import React, { FunctionComponent, useState, useEffect } from 'react';
import { performApiRequest, getApiDataRequest, ApiDataRequest } from 'react-api-data/lib';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Input, Row, Col } from 'reactstrap';
import { compose, withState } from 'recompose';
import head from 'lodash/head';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { ReduxState } from 'createStore';
import { LinkFull } from 'constants/api';
import { getIpAddresses } from 'utils/linksUtils';
import AlertMessage from 'components/utils/AlertMessage';
import LoaderButton from 'components/utils/LoaderButton';
import IpAddressOptions from 'components/utils/IpAddressOptions';

interface InProps {
    link: LinkFull;
}

interface EnhanceProps {
    getPrefixLimit: (ip: string) => void;
    changePrefixLimit: (ip: string, prefixLimit: string) => void;
    selectedIP: string;
    setSelectedIP: (ip: string) => void;
    getPrefixLimitRequest: ApiDataRequest;
    changePrefixLimitRequest: ApiDataRequest;
}

type Props = InProps & WithTranslation & EnhanceProps;

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    getPrefixLimitRequest: getApiDataRequest(state.apiData, 'getPrefixLimit', {
        ip: get(ownProps, 'selectedIP'),
    }),
    changePrefixLimitRequest: getApiDataRequest(state.apiData, 'putChangePrefixLimit', {
        ip: get(ownProps, 'selectedIP'),
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    getPrefixLimit: (ip: string) => {
        dispatch(performApiRequest('getPrefixLimit', { ip }));
    },
    changePrefixLimit: (ip: string, prefix: string) => {
        dispatch(performApiRequest('putChangePrefixLimit', { ip }, { prefix }));
    },
});

const BgpSessionPrefixLimit: FunctionComponent<Props> = ({
    t, link, getPrefixLimit, setSelectedIP, selectedIP, getPrefixLimitRequest,
    changePrefixLimitRequest, changePrefixLimit
}) => {
    const [newPrefixLimit, setNewPrefixLimit] = useState('');
    const ipAddresses = getIpAddresses(link);
    const firstIP = head(ipAddresses) || '';
    const getPrefixLimitLoading = get(getPrefixLimitRequest, 'networkStatus') === 'loading';
    const changePrefixLimitRequestLoading = get(changePrefixLimitRequest, 'networkStatus') === 'loading';

    const onGetPrefixLimitBtnClick = (): void => {
        if (!selectedIP) return;
        getPrefixLimit(selectedIP);
    };

    const onChangePrefixLimitBtnClick = (): void => {
        if (!selectedIP || !newPrefixLimit) return;
        changePrefixLimit(selectedIP, newPrefixLimit);
    };

    useEffect(() => {
        setSelectedIP(firstIP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentPrefixLimit = get(getPrefixLimitRequest, 'result.data.limit');
        if (currentPrefixLimit) {
            setNewPrefixLimit(currentPrefixLimit);
        }
    }, [getPrefixLimitRequest]);

    return (
        firstIP ? (
            <>
                <Row className="mb-2">
                    <Col md={3}>
                        <Input
                            data-test="select-ip"
                            type="select"
                            name="ip"
                            defaultValue={firstIP}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => setSelectedIP(event.currentTarget.value)}
                        >
                            <IpAddressOptions link={link} />
                        </Input>
                    </Col>
                    <Col>
                        <LoaderButton
                            data-test="get-prefix-limit-btn"
                            className="mt-2 mt-md-0"
                            disabled={!selectedIP}
                            loading={getPrefixLimitLoading}
                            onClick={onGetPrefixLimitBtnClick}
                        >
                            {t('quickdebug.getPrefixLimit')}
                        </LoaderButton>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Input
                            data-test="input-prefix-limit"
                            type="number"
                            name="prefixLimit"
                            placeholder={t('quickdebug.enterPrefixLimit')}
                            defaultValue={newPrefixLimit}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => setNewPrefixLimit(event.currentTarget.value)}
                        />
                    </Col>
                    <Col>
                        <LoaderButton
                            data-test="change-prefix-limit-btn"
                            className="mt-2 mt-md-0"
                            disabled={!newPrefixLimit}
                            loading={changePrefixLimitRequestLoading}
                            onClick={onChangePrefixLimitBtnClick}
                        >
                            {t('quickdebug.changePrefixLimit')}
                        </LoaderButton>
                    </Col>
                </Row>
            </>
        ) : <AlertMessage data-test="no-ips-message" message={t('quickdebug.noIps')} />
    );
};

export default compose<Props, InProps>(
    withState('selectedIP', 'setSelectedIP', ''),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(BgpSessionPrefixLimit);