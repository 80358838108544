import { Alert } from 'reactstrap';
import React from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';
import { ApiResponse, NativeRouter, ReactApiDataActions } from 'constants/api';

interface InputProps {
    uuid: string;
    exchange: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    resourceStatus: string;
    deleteNR: ApiDataBinding<ApiResponse<NativeRouter>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const enhance = compose<Props, InputProps & BaseFormModalProps>(
    withApiData(
        {
            deleteNR: 'deleteNativeRouter',
        },
        (ownProps: InputProps) => ({
            deleteNR: { nativeRouterUuid: ownProps.uuid, exchange: ownProps.exchange },
        })
    ),
    withTranslation()
);

class NativeRouterDeleteModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { deleteNR, t } = this.props;
        this.handleFormSubmission(prevProps.deleteNR.request, deleteNR.request, {
            successMessage: t('notification.deleteNativeRouterSuccessful'),
            errorMessage: t('notification.deleteNativeFailed'),
        });
    }

    _handleSubmit = () => {
        const { exchange, deleteNR, apiDataActions } = this.props;
        deleteNR.perform().then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getNativeRouters', { exchange: exchange });
                apiDataActions.perform('getNativeRouters', { exchange: exchange });
            }
        });
    };

    render() {
        const { deleteNR, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('deleteNativeRouterItemModal.headerTitle')}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('deleteNativeRouterItemModal.warningMessage')}</p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(deleteNR.request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={this._handleSubmit}
                        />
                    }
                />
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc]}>
                    <span
                        data-test="NativeRouter-delete-btn"
                        className="fas fa-trash fa-pull-left mt-1 text-primary cursor-pointer"
                        onClick={this.toggle}
                        title={t('deleteNativeRouterItemModal.headerTitle')}
                    ></span>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(NativeRouterDeleteModal);
