import { Badge, Button, Col, FormGroup, Label } from 'reactstrap';
import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { compose } from 'recompose';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull, MacAddress, LinkPortCompact, Port, ApiResponse } from 'constants/api';
import { Roles, ConnectionNewStatuses, LagType, LacpTimeout, Status, ConnectionStatus, LinkType, TrafficTypes } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import { BaseModalState } from './BaseModal';
import { createMacAddressValidation } from 'validation';
import { pick } from 'lodash';
import { getStatusColor } from 'utils/formatUtils';
import PortSelect from 'components/utils/PortSelect';
import CloseButton from 'components/utils/CloseButton';
import MacAddressInput from 'components/utils/MacAddressInput';
import StyledCheckbox from 'components/styled/StyledCheckbox';
import ContractsSelectAll from 'components/utils/ContractsSelectAll';
import OrganisationContractsSelect from 'components/utils/OrganisationContractsSelect';

interface InputProps {
    link: LinkFull;
    exchange: string;
}

interface EnhanceProps {
    updateLink: ApiDataBinding<ApiResponse<LinkFull>>;
    freePorts: ApiDataBinding<Port[]>;
    userRoles: Roles[];
}

type Props = EnhanceProps & InputProps & BaseFormModalProps & WithTranslation;

type Destroyable<T> = T & {
    id?: number;
    frontend_id?: number;
    _destroy?: boolean;
}

type LinkPort = LinkPortCompact & { frontend_id?: number };

type FormValuesType = {
    status: ConnectionStatus;
    lag: LagType | null;
    lacp_timeout: LacpTimeout | null;
    jira_ticket: string | null;
    ports: Destroyable<LinkPort>[];
    primary_port_id: number | null;
    mac_addresses: Destroyable<MacAddress>[];
    autonegotiation: string;
    tpid: string;
    priority_topology: string;
    traffic_type: TrafficTypes;
    managing_contract_uuid: string;
    contract_uuid: string;
    settings: {
        mep_id: string;
        snmp_ro: string;
        nid_pollable: boolean;
        comment: string;
    }
};

interface State extends BaseModalState {
    showNewMacAddressInput: boolean;
    showNewPortSelect: boolean;
}

const enhance = compose<Props, InputProps & BaseFormModalProps>(
    withApiData(
        {
            updateLink: 'putLink',
            freePorts: 'getFreePorts',
        },
        (ownProps: InputProps) => ({
            freePorts: {
                exchange: ownProps.exchange,
                for_monitor_links: String(ownProps.link.type === LinkType.MonitorLink),
            },
        })
    ),
    withTranslation()
);

const generateId = (function () {
    let id = 1;
    return function () {
        return id++;
    };
})();

const MAX_INPUT_LENGTH = 50;

class LinkEditDatabaseModal extends BaseFormModal<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showNewMacAddressInput: false,
            showNewPortSelect: false,
        };
    }

    componentDidMount() {
        this.props.freePorts.invalidateCache();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.updateLink.request, this.props.updateLink.request, {
            successMessage: this.props.t('connectionEditDbModal.successMessage'),
        });
    }

    _handleSubmit = (values: FormValuesType) => {
        const { link, updateLink } = this.props;
        updateLink.perform(
            {
                linkId: link.uuid,
                exchange: link.exchange.short_name,
            },
            {
                link: {
                    ...values,
                    lag: values.lag || null,
                    mac_addresses: values.mac_addresses.map((mac: Destroyable<MacAddress>) =>
                        pick(mac, ['id', 'value', 'status', '_destroy'])
                    ),
                    ports: values.ports.map((port: Destroyable<LinkPortCompact>) =>
                        pick(port, ['id', 'port_id', 'status', '_destroy'])
                    ),
                },
            }
        );
    };

    _handleClose = () => {
        this.setState({ showNewMacAddressInput: false, showNewPortSelect: false });
        this.handleModalClose();
    };

    _getFreePorts = (selectedPorts: Destroyable<LinkPortCompact>[]) => {
        const ports = this.props.freePorts.data || [];
        const selectedPortsIds = selectedPorts.map((linkPort) => linkPort.port_id);
        return ports.filter((port) => !selectedPortsIds.includes(port.id));
    };

    _removeResource = (resources: Destroyable<any>[], resource: Destroyable<any>) => {
        if (resource.id) {
            return resources.map((current) => {
                if (current.id === resource.id) {
                    return { ...current, _destroy: true };
                } else {
                    return current;
                }
            });
        } else if (resource.frontend_id) {
            return resources.filter((current) => current.frontend_id !== resource.frontend_id);
        } else {
            throw new Error('Cannot identify the resource');
        }
    };

    _updateResource = (resources: LinkPort[], resource: LinkPort, changes: Partial<LinkPort>) => {
        const updatedResource = { ...resource, ...changes };
        if (resource.id) {
            return resources.map((current) => (current.id === resource.id ? updatedResource : current));
        } else if (resource.frontend_id) {
            return resources.map((current) =>
                current.frontend_id === resource.frontend_id ? updatedResource : current
            );
        } else {
            throw new Error('Cannot identify the resource');
        }
    };

    _filterRemoved = (resources: Destroyable<any>[]) => resources.filter(resource => !resource._destroy);

    render() {
        const { link, t } = this.props;
        const { showNewMacAddressInput, showNewPortSelect } = this.state;
        const availableStatuses = Object.keys(ConnectionNewStatuses);

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            status: link.status,
                            jira_ticket: link.jira_ticket == null ? '' : link.jira_ticket!.split('/').slice(-1)![0],
                            lag: link.lag || '',
                            lacp_timeout: link.lacp_timeout,
                            ports: link.ports,
                            primary_port_id:
                                link.primary_port_id || (link.ports.length > 0 ? link.ports[0].port_id : null),
                            mac_addresses: link.mac_addresses,
                            autonegotiation: link.autonegotiation,
                            tpid: link.tpid == null ? '' : link.tpid,
                            priority_topology: link.priority_topology == null ? '' : link.priority_topology,
                            traffic_type: link.traffic_type,
                            managing_contract_uuid: link.managing_contract_uuid
                                ? link.managing_contract_uuid
                                : link.contract_uuid,
                            contract_uuid: link.contract_uuid,
                            settings: {
                                mep_id: link.attributes.mep_id,
                                snmp_ro: link.attributes.snmp_ro,
                                nid_pollable: link.attributes.nid_pollable,
                                comment: link.attributes.comment,
                            },
                        } as FormValuesType
                    }
                    onSubmit={this._handleSubmit}
                >
                    {({ handleSubmit, setFieldValue, isSubmitting, values, errors, touched }) => (
                        <BaseFormModalTemplate
                            data-test="link-editdb-modal-tmpl"
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this._handleClose}
                            handleSubmit={handleSubmit}
                            header={t('connectionEditDbModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="consuming_contract_uuid">{t('connection.contractName')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <OrganisationContractsSelect
                                                organisationUuid={link.organisation_uuid}
                                                onChange={(contractUuid) => setFieldValue('contract_uuid', contractUuid)}
                                                value={link.contract_uuid}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="managing_contract_uuid">{t('connection.managingContractName')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <ContractsSelectAll
                                                onChange={(contractUuid) =>
                                                    setFieldValue('managing_contract_uuid', contractUuid)
                                                }
                                                value={
                                                    link.managing_contract_uuid
                                                        ? link.managing_contract_uuid
                                                        : link.contract_uuid
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="status">{t('connectionEditDbModal.status')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                component="select"
                                                name="status"
                                                errors={errors}
                                                touched={touched}
                                                showErrorHint={false}
                                            >
                                                {availableStatuses.map((status: string) => (
                                                    <option value={status} key={status}>
                                                        {status}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="jira_ticket">{t('connectionEditDbModal.jiraTicket')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="text"
                                                name="jira_ticket"
                                                errors={errors}
                                                touched={touched}
                                                maxLength={MAX_INPUT_LENGTH}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="lag">{t('connectionEditDbModal.lagMode')}</Label>
                                        </Col>
                                        <Col md="9">
                                            <FormControl
                                                component="select"
                                                name="lag"
                                                errors={errors}
                                                touched={touched}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const lag = e.target.value;
                                                    let lacpTimeout =
                                                        lag === LagType.dynamic ? LacpTimeout.short : null;
                                                    setFieldValue('lag', lag);
                                                    setFieldValue('lacp_timeout', lacpTimeout);
                                                }}
                                            >
                                                <option value="">{t('lagModal.none')}</option>
                                                <option value={LagType.static}>{t('lagModal.static')}</option>
                                                <option value={LagType.dynamic}>{t('lagModal.dynamic')}</option>
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    {values.lag === LagType.dynamic && (
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="lacp_timeout">
                                                    {t('connectionEditDbModal.lacpTimeout')}
                                                </Label>
                                            </Col>
                                            <Col md="9">
                                                <FormControl
                                                    component="select"
                                                    name="lacp_timeout"
                                                    errors={errors}
                                                    touched={touched}
                                                >
                                                    <option value={LacpTimeout.short}>
                                                        {t('lacpTimeoutModal.short')}
                                                    </option>
                                                    <option value={LacpTimeout.long}>
                                                        {t('lacpTimeoutModal.long')}
                                                    </option>
                                                </FormControl>
                                            </Col>
                                        </FormGroup>
                                    )}
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="ports">{t('connectionEditDbModal.ports')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            {this._filterRemoved(values.ports).length > 0 && (
                                                <div className="d-flex flex-column mb-2">
                                                    {this._filterRemoved(values.ports).map((port) => (
                                                        <div
                                                            key={port.id || port.frontend_id}
                                                            className="d-flex align-items-center mb-2"
                                                        >
                                                            <div style={{ width: 180 }}>
                                                                {port.fqid}/{port.portnumber}
                                                            </div>
                                                            <FormControl
                                                                component="select"
                                                                name="port_status"
                                                                value={port.status}
                                                                onChange={(event) => {
                                                                    const newPorts = this._updateResource(
                                                                        values.ports,
                                                                        port,
                                                                        { status: event.target.value }
                                                                    );
                                                                    setFieldValue('ports', newPorts);
                                                                }}
                                                                errors={errors}
                                                                touched={touched}
                                                                style={{ width: 150 }}
                                                            >
                                                                {Object.values(Status).map((resourceStatus) => (
                                                                    <option key={resourceStatus} value={resourceStatus}>
                                                                        {resourceStatus}
                                                                    </option>
                                                                ))}
                                                            </FormControl>
                                                            <CloseButton
                                                                onClick={() => {
                                                                    const newPorts = this._removeResource(
                                                                        values.ports,
                                                                        port
                                                                    );
                                                                    setFieldValue('ports', newPorts);
                                                                    const availablePorts =
                                                                        this._filterRemoved(newPorts);
                                                                    const isPrimaryPortRemoved = !availablePorts
                                                                        .map((linkPort) => linkPort.port_id)
                                                                        .includes(values.primary_port_id);
                                                                    if (isPrimaryPortRemoved) {
                                                                        const newPrimaryPort =
                                                                            availablePorts.length > 0
                                                                                ? availablePorts[0].port_id
                                                                                : null;
                                                                        setFieldValue(
                                                                            'primary_port_id',
                                                                            newPrimaryPort
                                                                        );
                                                                    }
                                                                }}
                                                                className="ml-2"
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {!showNewPortSelect && (
                                                <span
                                                    className="cursor-pointer text-primary"
                                                    onClick={() => this.setState({ showNewPortSelect: true })}
                                                >
                                                    <i className="fas fa-plus" /> {t('connectionEditDbModal.addPort')}
                                                </span>
                                            )}
                                            {showNewPortSelect && (
                                                <div className="d-flex align-items-center justfy-content-start">
                                                    <PortSelect
                                                        ports={this._getFreePorts(values.ports) || []}
                                                        onChange={(port) => {
                                                            const newPort = {
                                                                frontend_id: generateId(),
                                                                port_id: port.id,
                                                                status: Status.configured,
                                                                fqid: port.fqid,
                                                                portnumber: port.portnumber,
                                                            };
                                                            setFieldValue('ports', [...values.ports, newPort]);
                                                            if (!values.primary_port_id) {
                                                                setFieldValue('primary_port_id', port.id);
                                                            }
                                                            this.setState({ showNewPortSelect: false });
                                                        }}
                                                        className="flex-grow-1"
                                                    />
                                                    <Button
                                                        color="primary"
                                                        onClick={() => this.setState({ showNewPortSelect: false })}
                                                        className="ml-2"
                                                    >
                                                        {t('modal.cancelBtn')}
                                                    </Button>
                                                </div>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    {this._filterRemoved(values.ports).length > 1 && (
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="primary_port_id">
                                                    {t('connectionEditDbModal.primaryPort')}
                                                </Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl
                                                    component="select"
                                                    name="primary_port_id"
                                                    errors={errors}
                                                    touched={touched}
                                                >
                                                    {this._filterRemoved(values.ports).map((port) => (
                                                        <option key={port.port_id} value={port.port_id}>
                                                            {port.fqid}/{port.portnumber}
                                                        </option>
                                                    ))}
                                                </FormControl>
                                            </Col>
                                        </FormGroup>
                                    )}
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="mac_addresses">
                                                {t('connectionEditDbModal.macAddresses')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            {this._filterRemoved(values.mac_addresses).length > 0 && (
                                                <div className="d-flex flex-column mb-2">
                                                    {this._filterRemoved(values.mac_addresses).map((mac) => {
                                                        return (
                                                            <div key={mac.value} className="d-flex align-items-center">
                                                                <div style={{ width: 180 }}>{mac.value}</div>
                                                                <div style={{ width: 82 }}>
                                                                    <Badge color={getStatusColor(mac.status)}>
                                                                        {mac.status}
                                                                    </Badge>
                                                                </div>
                                                                <CloseButton
                                                                    onClick={() => {
                                                                        const newMacAddresses = this._removeResource(
                                                                            values.mac_addresses,
                                                                            mac
                                                                        );
                                                                        setFieldValue('mac_addresses', newMacAddresses);
                                                                    }}
                                                                    className="ml-2"
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            {!showNewMacAddressInput && (
                                                <span
                                                    className="cursor-pointer text-primary"
                                                    onClick={() => this.setState({ showNewMacAddressInput: true })}
                                                >
                                                    <i className="fas fa-plus" /> {t('addMacAddressModal.headerTitle')}
                                                </span>
                                            )}
                                            {showNewMacAddressInput && (
                                                <MacAddressForm
                                                    onAdd={(value) => {
                                                        const newMacAddresses = [
                                                            ...values.mac_addresses,
                                                            {
                                                                frontend_id: generateId(),
                                                                value: value,
                                                                status: 'configured',
                                                            },
                                                        ];
                                                        setFieldValue('mac_addresses', newMacAddresses);
                                                    }}
                                                    onClose={() => this.setState({ showNewMacAddressInput: false })}
                                                    forbiddenValues={values.mac_addresses.map((mac) => mac.value)}
                                                />
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="options">{t('connectionEditDbModal.options')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <Label htmlFor="Autonegotiation">
                                                {t('connectionEditDbModal.autonegotiation')}
                                            </Label>
                                            <FormControl
                                                component="select"
                                                name="autonegotiation"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                {['on', 'off'].map((a: string) => (
                                                    <option key={a} value={a}>
                                                        {a}
                                                    </option>
                                                ))}
                                            </FormControl>
                                            <br></br>
                                            <Label htmlFor="tpid">
                                                {t('connectionEditDbModal.tagProtocolIdentifier')}
                                            </Label>
                                            <FormControl
                                                type="text"
                                                name="tpid"
                                                errors={errors}
                                                touched={touched}
                                                maxLength={MAX_INPUT_LENGTH}
                                            />
                                            <br></br>
                                            <Label htmlFor="priority_topology">
                                                {t('connectionEditDbModal.priorityTopology')}
                                            </Label>
                                            <FormControl
                                                type="text"
                                                name="priority_topology"
                                                errors={errors}
                                                touched={touched}
                                                maxLength={MAX_INPUT_LENGTH}
                                            />
                                            {link.type === LinkType.MonitorLink && (
                                                <>
                                                    <br />
                                                    <FormGroup>
                                                        <Label>{t('addMonitorLinkModal.trafficType')}</Label>
                                                        <FormControl
                                                            component="select"
                                                            name="traffic_type"
                                                            errors={errors}
                                                            touched={touched}
                                                        >
                                                            {Object.values(TrafficTypes).map((trafficType) => (
                                                                <option key={trafficType} value={trafficType}>
                                                                    {trafficType}
                                                                </option>
                                                            ))}
                                                        </FormControl>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{t('addMonitorLinkModal.mepId')}</Label>
                                                        <FormControl
                                                            name="settings.mep_id"
                                                            maxLength={100}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>{t('addMonitorLinkModal.snmpRo')}</Label>
                                                        <FormControl
                                                            name="settings.snmp_ro"
                                                            maxLength={100}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="d-flex flex-row align-items-center">
                                                        <Label className="mb-0">{t('addMonitorLinkModal.nidPollable')}</Label>
                                                        <StyledCheckbox name="settings.nid_pollable" className="ml-3" defaultChecked={link.attributes.nid_pollable} />
                                                    </FormGroup>
                                                </>
                                            )}
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            footer={<FormModalButtons loading={isSubmitting} toggle={this.toggle} />}
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.ixaas_client]}>
                    <Button data-test="link-editdb-btn" className="float-right" color="light" onClick={this.toggle}>
                        {t('connection.editConnectionBtn')}
                    </Button>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

interface MacAddressFormProps {
    forbiddenValues: string[];
    onAdd: (value: string) => void;
    onClose: () => void;
}

function MacAddressForm({ forbiddenValues, onAdd, onClose }: MacAddressFormProps) {
    const [value, setValue] = useState('');
    const { t } = useTranslation();
    const validator = useMemo(() => createMacAddressValidation(t).required(t('validation.requiredField')), [t]);
    const isForbiddenValue = forbiddenValues.includes(value);

    function handleAdd() {
        onAdd(value);
        onClose();
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex flex-column flex-grow-1">
                <MacAddressInput value={value} onChange={(newValue) => setValue(newValue)} className="form-control" />
                {isForbiddenValue && (
                    <span className="small text-danger mt-1">{t('connectionEditDbModal.takenMacAddressError')}</span>
                )}
            </div>
            <Button
                color="primary"
                onClick={handleAdd}
                disabled={!validator.isValidSync(value) || isForbiddenValue}
                className="ml-2"
            >
                {t('modal.addBtn')}
            </Button>
            <Button color="primary" onClick={onClose} className="ml-2">
                {t('modal.cancelBtn')}
            </Button>
        </div>
    );
}

export default enhance(LinkEditDatabaseModal);
