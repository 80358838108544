import React, { FunctionComponent, useEffect, useCallback, useMemo } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { debounce, get } from 'lodash';
import usePreselectExchange from 'hooks/usePreselectExchange';
import { WithTranslation, useTranslation } from 'react-i18next';

// components
import Helmet from 'react-helmet';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import ContractAddModal from 'components/modals/ContractAddModal';
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import InteractiveRemoteTable from 'components/utils/InteractiveRemoteTable';
import withUserRoles from 'hocs/withUserRoles';

// redux
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';
import { compose } from 'recompose';
import { updateAppPathAction } from 'redux/app/appActions';

// constants
import { Exchanges, ContractItem, ApiResponse } from 'constants/api';
import { NetworkRequestStatus, Roles } from 'constants/literals';
import { getCustomersTableColumns } from 'constants/columns';
import AlertMessage from 'components/utils/AlertMessage';

const SIZE_PER_PAGE = 10;
const DEFAULT_SORT_FIELD = 'name';
const DEFAULT_SORT_ORDER = 'asc';

interface ConnectProps {
    contractsRequest: ApiDataBinding<ApiResponse<{ contracts: ContractItem[] }>>;
    exchangesData: Exchanges;
    selectedExchange: string;
    selectExchange: (selectedExchange: string) => void;
    searchTerm: string;
    updateSearchTerm: (searchTerm: string) => void;
    userRoles: Roles[];
}

type ComponentProps = ConnectProps & WithTranslation;

const enhance = compose<ComponentProps, {}>(
    withApiData({ contractsRequest: 'getContracts' }),
    withUserRoles,
    connect(
        (state: ReduxState) => ({
            searchTerm: state.app.customers.searchTerm,
            selectedExchange: state.app.customers.selectedExchange,
            exchangesData: getAllExchangesSelector(state.apiData),
        }),
        (dispatch) => ({
            selectExchange: (selectedExchange: string) => {
                dispatch(updateAppPathAction('customers.selectedExchange', selectedExchange));
            },
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('customers.searchTerm', searchTerm));
            }, 500),
        })
    )
);

function getNameOfSortByField(fieldName: string) {
    switch (fieldName) {
        case 'is_active':
            return 'status';
        case 'name':
            return 'contractname';
        default:
            return fieldName;
    }
}

const CustomersView: FunctionComponent<ComponentProps> = ({
    exchangesData,
    contractsRequest,
    selectedExchange,
    selectExchange,
    searchTerm,
    updateSearchTerm,
    userRoles,
}) => {
    const { t } = useTranslation();
    const tableColumns = useMemo(
        () => getCustomersTableColumns(userRoles, selectedExchange),
        [userRoles, selectedExchange]
    );
    const fetchContracts = useCallback(
        (exchange: string, pageNumber: number, sortField: string, sortOrder: string, searchString: string) => {
            contractsRequest.invalidateCache();
            contractsRequest.perform({
                exchange: exchange,
                page: pageNumber,
                per_page: SIZE_PER_PAGE,
                sort: getNameOfSortByField(sortField),
                order: sortOrder,
                search_for: searchString
            });
        }, 
        [contractsRequest.invalidateCache, contractsRequest.perform]
    )

    usePreselectExchange(selectedExchange, exchangesData, selectExchange);
    useEffect(() => {
        if (!selectedExchange) return;

        const previousRequestParams = contractsRequest.request.params;
        if (
            !previousRequestParams ||
            previousRequestParams.exchange !== selectedExchange ||
            previousRequestParams.search_for !== searchTerm
        ) {
            let sortField = DEFAULT_SORT_FIELD;
            let sortOrder = DEFAULT_SORT_ORDER;
            if (previousRequestParams) {
                sortField = previousRequestParams.sort as string;
                sortOrder = previousRequestParams.order as string;
            }
            fetchContracts(selectedExchange, 1, sortField, sortOrder, searchTerm);
        }
    }, [fetchContracts, contractsRequest, selectedExchange, searchTerm]);

    return (
        <>
            <Helmet title="Customers" />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            <span>
                                {t('customers.pageTitle')}
                                <SyncButton
                                    data-test="customers-refresh-btn"
                                    className="ml-2"
                                    title={t('customers.refreshButton')}
                                    onClick={() => {
                                        contractsRequest.invalidateCache();
                                        contractsRequest.perform(contractsRequest.request.params);
                                    }}
                                />
                            </span>
                            <ContractAddModal exchangesData={exchangesData} />
                        </h2>
                        <hr/>
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <Row className="justify-content-between">
                                    <Col xl={3}>
                                        <Input
                                            data-test="search-term-control"
                                            className="form-control"
                                            placeholder={t('table.searchInputPlaceholder')}
                                            defaultValue={searchTerm}
                                            onChange={(event) => updateSearchTerm(event.target.value)}
                                        />
                                    </Col>
                                    <Col xl={3} className="text-right">
                                        <ExchangeSelect
                                            name="exchange"
                                            defaultValue={selectedExchange}
                                            exchanges={exchangesData}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => selectExchange(event.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {selectedExchange ? (
                                    <InteractiveRemoteTable
                                        keyField="uuid"
                                        columns={tableColumns}
                                        data-test="customers-interactive-table"
                                        data={get(contractsRequest, 'data.data.contracts', [])}
                                        defaultSorted={{ dataField: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER}}
                                        loading={contractsRequest.request.networkStatus === NetworkRequestStatus.Loading}
                                        fetchPage={(pageNumber, _pageSize, sortField, sortOrder) =>
                                            fetchContracts(selectedExchange, pageNumber, sortField, sortOrder, searchTerm)
                                        }
                                        paginationOptions={{
                                            page: get(contractsRequest, 'data.meta.page', 1),
                                            sizePerPage: get(contractsRequest, 'data.meta.page_size', SIZE_PER_PAGE),
                                            totalSize: get(contractsRequest, 'data.meta.total_items')
                                        }}
                                    />
                                ) : (
                                    <AlertMessage message={t('validation.exchangeNotSelected')} />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default enhance(CustomersView);
