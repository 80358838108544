import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { ApiDataRequest, performApiRequest, getApiDataRequest, EndpointParams } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull, Vlan } from 'constants/api';
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';
import AsnSelectAll from 'components/utils/AsnSelectAll';
import AsnSelectContract from 'components/utils/AsnSelectContract';
import { hasSomeRole } from 'utils/userUtils';
import { getUserRolesSelector } from 'redux/apiData/apiDataSelectors';
import { BaseModalState } from './BaseModal';

interface InputProps {
    link: LinkFull;
    linkVlan: Vlan;
    defaultAsn: number;
    endpointKey: string,
    endpointParams: EndpointParams;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    userRoles: Roles[];
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps & RouteComponentProps<{
    exchange: string;
}>;

type State = BaseModalState & {
    thirdParty: boolean;
};

const mapStateToProps = (state: ReduxState, ownProps: Props) => {
    return {
        request: getApiDataRequest(state.apiData, ownProps.endpointKey, ownProps.endpointParams),
        userRoles: getUserRolesSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            const params = values;
            dispatch(performApiRequest(ownProps.endpointKey, ownProps.endpointParams, params));
            return true;
        }
    };
};

const enhance = compose<Props, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

type FormInitialValuesType = {
    as_number: number | null,
    organisation_id: number | null
}

class AsnModal extends BaseFormModal<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ...this.state,
            thirdParty: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            pathToError: 'response.asn.0',
            successMessage: this.props.t('notification.changeAsnSuccessful'),
        });
    }

    render() {
        const { defaultAsn, link, linkVlan, onSubmit, t } = this.props;

        const thirdPartyChanged = () => {
            this.setState((state: Readonly<State>) => ({ thirdParty: !state.thirdParty }));
        };

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ as_number: defaultAsn, organisation_id: null } as FormInitialValuesType}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, setValues, handleSubmit, isSubmitting }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            header={t('changeAsnModal.headerTitle')}
                            handleSubmit={handleSubmit}
                            onClosed={this.handleModalClose}
                            body={
                                <>
                                {hasSomeRole(this.props.userRoles, Roles.noc, Roles.admin, Roles.ixaas_client) &&
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="third_party">{t('asns.thirdParty')}</Label>
                                        </Col>
                                        <Col md={1}>
                                            <FormControl
                                                type="checkbox"
                                                name="third_party"
                                                checked={this.state.thirdParty}
                                                onChange={thirdPartyChanged}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                }
                                <FormGroup row>
                                <Col md={3}>
                                    <Label htmlFor="as_number">{t('addVlanModal.asnSelect')}</Label>
                                </Col>
                                <Col md={9}>
                                    {this.state.thirdParty ?
                                        <AsnSelectAll
                                            defaultValue={defaultAsn}
                                            onChange={(asnNumber, organisationId) => setValues({ as_number: asnNumber, organisation_id: organisationId })}
                                        />
                                        :
                                        <AsnSelectContract
                                            value={defaultAsn}
                                            onChange={(asnNumber) => setValues({ ...values, as_number: asnNumber })}
                                            contractUuid={linkVlan.contract_uuid}
                                            exchange={linkVlan.exchange}
                                        />
                                    }
                                </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    disabled={false}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                </Formik>
                <EditBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    resourceStatus={link.status}
                    roles={[Roles.admin, Roles.customer, Roles.ixaas_client]}
                >
                    <Tooltip
                        data-test="edit-btn-tooltip"
                        triggerBtn={<i className="fas fa-edit fa-pull-right mt-1 cursor-pointer text-primary" onClick={this.toggle} />}
                    >
                        {t('changeAsnModal.headerTitle')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(AsnModal);
