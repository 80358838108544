import React, { Component, ReactNode } from 'react';
import { compose, branch } from 'recompose';
import get from 'lodash/get';
import size from 'lodash/size';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router'

// constants
import { Exchanges, ApiResponse } from 'constants/api';
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';
import { COMPLETE_REGISTRATION_PATH, FORGOT_PASSWORD_PATH, RESET_PASSWORD_PATH } from 'constants/index';

// selectors
import { accessTokenSelector, userUuidSelector } from 'redux/app/appSelectors';
import { getFirstExchangeShortNameSelector, getUserEmailSelector } from 'redux/apiData/apiDataSelectors';

// utils
import { getBaseRoutePath } from 'utils/commonUtils';

// componentns
import PageLoading from 'components/utils/PageLoading';
import { updateAppPathAction } from 'redux/app/appActions';

type UserApiBinding = ApiDataBinding<ApiResponse<{ roles: Roles[] }>>;


function isAuthenticationRequired(path: string) {
    return ![FORGOT_PASSWORD_PATH, RESET_PASSWORD_PATH, COMPLETE_REGISTRATION_PATH].includes(path);
}

interface Props extends WithTranslation {
    accessToken: string;
    exchangeName: string;
    userEmail: string;
    userUuid: string;
    userApiBinding: UserApiBinding;
    exchangesApiBinding: ApiDataBinding<Exchanges>;
    loginRedirect?: string;

    updateLoginRedirect: (path: string) => void;
}

interface WrappedComponentProps {
    location: RouteComponentProps['location']; 
}

type EnhancedProps = Props & WrappedComponentProps;

class AppUserAccess extends Component<EnhancedProps, {}> {

    constructor(props: Readonly<EnhancedProps>) {
        super(props);

        const { loginRedirect, updateLoginRedirect, accessToken } = this.props;

        if (!accessToken && loginRedirect !== window.location.pathname) {
            updateLoginRedirect(window.location.pathname);
        }
    }

    componentDidMount() {
        this.showAccountNotExistError();
    }

    componentDidUpdate() {
        this.showAccountNotExistError();
    }

    render() {
        const { accessToken, userApiBinding, exchangesApiBinding, children, location } = this.props;

        if (!isAuthenticationRequired(location.pathname)) {
            return <>{children}</>; 
        }

        if (!accessToken) {
            return this.renderRedirect();
        }

        if (this.isApiBindingFailed(exchangesApiBinding)) {
            return this.renderRedirect();
        }

        if (this.isApiBindingLoading(exchangesApiBinding) || this.isApiBindingLoading(userApiBinding)) {
            return this.renderPageLoading();
        }

        if (!this.userHasRoles(userApiBinding) || this.isApiBindingFailed(userApiBinding)) {
            return this.renderRedirect({ state: { userHasNoRoles: true } });
        }

        return <>{children}</>;
    }

    private renderRedirect({ pathname, state }: { pathname?: string, state?: any } = {}): ReactNode {
        const basePath = getBaseRoutePath();
        return (
            <Redirect
                data-test="user-redirect"
                to={{
                    pathname: `${basePath}/${pathname || 'login'}`,
                    state
                }}
            />
        );
    }

    private renderPageLoading(): ReactNode {
        return <PageLoading data-test="loader" />;
    }

    private isApiBindingLoading(apiBinding: ApiDataBinding<any>): boolean {
        const apiStatus = this.getApiBindingNetworkStatus(apiBinding);
        return !apiBinding || apiStatus === 'loading' || apiStatus === 'ready';
    }

    private isApiBindingFailed(apiBinding: ApiDataBinding<any>): boolean {
        return this.getApiBindingNetworkStatus(apiBinding) === 'failed';
    }

    private isApiBindingSuccess(apiBinding: ApiDataBinding<any>): boolean {
        return this.getApiBindingNetworkStatus(apiBinding) === 'success';
    }

    private userHasRoles(userApiBinding: UserApiBinding): boolean {
        return size(get(userApiBinding, 'data.data.roles')) > 0;
    }

    private getApiBindingNetworkStatus(apiBinding: ApiDataBinding<any>): string {
        return get(apiBinding, 'request.networkStatus');
    }

    private showAccountNotExistError(): void {
        const { userApiBinding, t, userEmail } = this.props;
        if (this.isApiBindingSuccess(userApiBinding) && !this.userHasRoles(userApiBinding)) {
            toast.error(t('login.accountNotExist', { userEmail }));
        }
    }
}

const mapStateToProps = (state: ReduxState, props: Props) => ({
    accessToken: accessTokenSelector(state),
    exchangeName: getFirstExchangeShortNameSelector(state.apiData),
    userEmail: getUserEmailSelector(state),
    userUuid: userUuidSelector(state),
    loginRedirect: state.app.loginRedirect,
});

const mapDispatchToProps = (dispatch: any) => ({
    updateLoginRedirect: (path: string) => {
        dispatch(updateAppPathAction('loginRedirect', path));
    },
});

const withExchangesApiBinding = branch(
    (props: Props) => {
        const hasAccessToken = !!get(props, 'accessToken');
        return hasAccessToken;
    },
    withApiData({ exchangesApiBinding: 'getAllExchanges' })
);

// call getUser API only after we get exchanges and user uuid
const withUserApiBinding = branch(
    (props: Props) => {
        return !!get(props, 'exchangeName') && !!get(props, 'userUuid');
    },
    withApiData({
        userApiBinding: 'getUser'
    }, (props: Props) => ({
        userApiBinding: {
            userId: get(props, 'userUuid'),
            exchange: props.exchangeName
        }
    }))
);

export default compose<EnhancedProps, WrappedComponentProps>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    withExchangesApiBinding,
    withUserApiBinding
)(AppUserAccess);
