import React, { FunctionComponent, ReactNode } from 'react';
import { ApiDataRequest, ApiDataBinding } from 'react-api-data';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import CenteredSpinner from './CenteredSpinner';
import AlertMessage from './AlertMessage';
import CompositeRequest from 'utils/compositeRequest';

type ReactFunc = () => ReactNode;

interface Props {
    request?: ApiDataRequest | ApiDataBinding<any> | CompositeRequest;
    loading?: ReactNode;
    success: ReactNode | ReactFunc;
    failedMessage?: string | ReactNode;
    failed?: ReactNode | ReactFunc;
    defaultMessage?: string | ReactNode;
    defaultComponent?: ReactNode;
}

const RequestStatusRenderer: FunctionComponent<Props> = ({ request, loading, success, failedMessage, failed, defaultMessage, defaultComponent }) => {
    switch (get(request, 'request.networkStatus', get(request, 'networkStatus'))) {
        case 'loading':
            return loading ? <>{loading}</> : <CenteredSpinner data-test="request-spinner" />;
        case 'success':
            return isFunction(success) ? <>{success()}</> : <>{success}</>;
        case 'failed':
            if (failedMessage) {
                return <AlertMessage data-test="request-failed-message" message={failedMessage} />;
            }
            return isFunction(failed) ? <>{failed()}</> : <>{failed}</>;
        default:
            if (defaultMessage) {
                return <AlertMessage data-test="request-default-message" message={defaultMessage} />;
            }
            return <>{defaultComponent}</>;

    }
};

export default RequestStatusRenderer;
