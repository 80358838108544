import React, { Component, Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { ApiDataState, getEntity } from 'react-api-data/lib';
import { locationSchema } from 'constants/endpointConfig';
import { Location } from 'constants/api';
import { Formik, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ReduxState } from 'createStore';
import FormControl from './FormControl';

interface InProps {
    handleBlur: typeof Formik.prototype.handleBlur;
    locationUuid: string | null;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    onChange: (speedAndInterfaceTypes: string[]) => void;
}

interface EnhanceProps {
    apiData: ApiDataState;
}

type Props = InProps & EnhanceProps & WithTranslation;

class PortDataFormGroup extends Component<Props & WithTranslation> {
    state = {
        ports: [],
    };

    updateFieldValue = () => {
        const portValues = this.state.ports.map((port: any) => {
            return port.value;
        });

        this.props.onChange(portValues);
    }

    handleChange = (event: any) => {
        const id = event.nativeEvent.target.selectedIndex;
        const label = event.nativeEvent.target[id].text;
        const targetValue = event.target.value;

        this.setState({ ports: [{ label, value: targetValue }] }, (this.updateFieldValue));

        event.target.value = '';
    }

    handleRemove = (key: number) => {
        this.setState({ ports: this.state.ports.filter((_, i) => i !== key) }, (this.updateFieldValue));
    }

    render() {
        const { handleBlur, locationUuid, errors, touched, t } = this.props;
        return (
            <Fragment>
                <Label>{t('port.portType')}</Label>

                {this.state.ports.length !== 0 &&
                    <Fragment>
                        <ul>
                            {this.state.ports && this.state.ports.map((port: any, i: number) =>
                                <li key={i}>
                                    {port.label}
                                    <span
                                        className="cursor-pointer text-primary"
                                        onClick={() => this.handleRemove(i)}
                                    >
                                        <i className="fas fa-times" />
                                    </span>
                                </li>
                            )}
                        </ul>
                    </Fragment>
                }

                <FormGroup>
                    <FormControl
                        component="select"
                        name="speedAndInterfaceTypes"
                        errors={errors}
                        touched={touched}
                        onChange={this.handleChange}
                        disabled={!locationUuid}
                        onBlur={handleBlur}
                    >
                        {locationUuid ? (
                            <>
                                <option disabled value={''}>{t('port.selectPortType')}</option>
                                {(getEntity(this.props.apiData, locationSchema, locationUuid) as Location).speed_and_interface_types.map(type => (
                                    <option key={type.uuid} value={type.uuid}>{type.name}</option>
                                ))}
                            </>
                        ) : (
                            <option>{t('connection.selectLocationOptions')}</option>
                        )}
                    </FormControl>
                </FormGroup>
            </Fragment>
        );
    }
}

const enhance = compose<Props, InProps>(
    withTranslation(),
    connect((state: ReduxState) => ({
        apiData: state.apiData,
    }))
);

// @ts-ignore
export default enhance(PortDataFormGroup);
