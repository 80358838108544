import {
    Alert, Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { object } from 'yup';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';
import classnames from 'classnames';

// utils
import { createMacAddressValidation } from 'validation/index';

// constants
import { MacAddress } from 'constants/api';
import { ReduxState } from 'createStore';
import { NOC_SUPPORT_EMAIL, PORT_SECURITY_URL } from 'constants/index';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControlError from 'components/utils/FormControlError';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';

interface InputProps {
    address: MacAddress;
    resourceStatus: string;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'putMacAddress', {
        addressId: ownProps.address.id,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('putMacAddress', {
                addressId: ownProps.address.id,
                exchange: ownProps.exchange,
                linkId: ownProps.linkId
            }, values));
            return false;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class MacAddressChangeModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { t } = this.props;
        this.handleFormSubmission(prevProps.request, this.props.request, {
            errorMessage: `${t('common.macAddress')} ${get(this.formik, 'state.values.mac_address')}`,
            successMessage: this.props.t('notification.changeMacSuccessful'),
        });
    }

    render() {
        const { onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ mac_address: '' }}
                    onSubmit={onSubmit}
                    validationSchema={object().shape({
                        mac_address: createMacAddressValidation(t).required(t('validation.requiredField')),
                    })}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            data-test="mac-change-modal-tmpl"
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={('changeMacAddressModal.headerTitle')}
                            body={<>
                                <Alert color="warning">
                                    <strong>{t('changeMacAddressModal.warning')}</strong>
                                    <p>{t('changeMacAddressModal.paragraph1')}
                                        <a
                                            href={PORT_SECURITY_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {PORT_SECURITY_URL}
                                        </a>
                                    </p>
                                    <p>{t('changeMacAddressModal.paragraph2')}</p>
                                    <ul>
                                        <li>{t('changeMacAddressModal.option1')}
                                        </li>
                                        <li>{t('changeMacAddressModal.option2')}
                                        </li>
                                    </ul>
                                    <p>{t('changeMacAddressModal.paragraph3')}</p>
                                    <p>{t('changeMacAddressModal.paragraph4')}</p>
                                    <p>{t('changeMacAddressModal.paragraph5')} <a href={`mailto:${NOC_SUPPORT_EMAIL}`}>{NOC_SUPPORT_EMAIL}</a>
                                    </p>
                                </Alert>
                                <FormGroup row className="mb-0">
                                    <Col md="3">
                                        <Label htmlFor="mac_address">{t('common.macAddress')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <TextMask
                                            Component={InputAdapter}
                                            mask={[/[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, '.', /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, '.', /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i]}
                                            guide={false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="mac_address"
                                            id="mac_address"
                                            value={values.mac_address}
                                            className={classnames({
                                                'form-control': true,
                                                'is-invalid': errors.mac_address && touched.mac_address
                                            })}
                                        />
                                        <FormControlError
                                            controlName="mac_address"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                {process.env.REACT_APP_SHOW_CHANGE_MAC === 'true' && (
                    <EditBtnConditionalRenderer data-test="edit-btn-renderer" resourceStatus={this.props.resourceStatus}>
                        <Tooltip
                            data-test="mac-change-btn"
                            triggerBtn={<i className="fas fa-edit fa-pull-right mt-1 cursor-pointer text-primary" onClick={this.toggle} />}
                        >
                            {t('changeMacAddressModal.headerTitle')}
                        </Tooltip>
                    </EditBtnConditionalRenderer>
                )}
            </React.Fragment>
        );
    }
}

export default enhance(MacAddressChangeModal);
