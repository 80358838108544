import { useEffect } from 'react';

import { getOnlyOneExchangeName } from 'utils/linksUtils';
import { Exchanges } from 'constants/api';

const usePreselectExchange = (selectedExchange: string, exchangesData: Exchanges, changeExchange: (exchange: string) => void) => {
    useEffect(() => {
        if (!selectedExchange) {
            // Automatically select exchange if there is only one exchange in the data
            const exchangeName = getOnlyOneExchangeName(exchangesData);
            if (exchangeName) {
                changeExchange(exchangeName);
            }
        }
    }, [selectedExchange, exchangesData, changeExchange]);
};

export default usePreselectExchange;
