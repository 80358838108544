import { NetworkRequestStatus } from 'constants/literals';
import { ApiDataRequest } from 'react-api-data';

class CompositeRequest {
    requests: ApiDataRequest[];

    constructor(...requests: ApiDataRequest[]) {
        this.requests = requests;
    }

    get networkStatus() {
        if (this.requests.some((request) => request.networkStatus === NetworkRequestStatus.Failed)) {
            return NetworkRequestStatus.Failed;
        } else if (
            this.requests.some(
                (request) =>
                    request.networkStatus === NetworkRequestStatus.Loading ||
                    request.networkStatus === NetworkRequestStatus.Ready
            )
        ) {
            return NetworkRequestStatus.Loading;
        } else {
            return NetworkRequestStatus.Success;
        }
    }
}

export default CompositeRequest;
