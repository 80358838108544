import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from 'reactstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { performApiRequest } from 'react-api-data';

// components
import LoaderButton from 'components/utils/LoaderButton';

interface SubmitData {
    email: string;
}

interface Props {
    onSubmit: (values: SubmitData) => boolean;
}

function ForgotPassword(props: Props) {
    const { t } = useTranslation();
    const { onSubmit } = props;

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <Card className="p-4">
                            <CardBody>
                                <CardTitle tag="h1">{t('forgotPassword.title')}</CardTitle>
                                <CardSubtitle className="mb-2 text-muted">{t('forgotPassword.subtitle')}</CardSubtitle>
                                <Formik
                                    data-test="form"
                                    initialValues={{ email: '' }}
                                    onSubmit={onSubmit}
                                >
                                    {({ handleChange, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fas fa-envelope" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="email"
                                                    placeholder={t('forgotPassword.emailPlaceholder')}
                                                    autoComplete="email"
                                                    name="email"
                                                    id="email"
                                                    onChange={handleChange}
                                                    autoFocus
                                                    required
                                                />
                                            </InputGroup>
                                            <Row>
                                                <Col xs="6">
                                                    <LoaderButton
                                                        data-test="forgotPassword-submit-btn"
                                                        type="submit"
                                                        loading={false}
                                                    >
                                                        {t('forgotPassword.submitBtn')}
                                                    </LoaderButton>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    onSubmit: (data: SubmitData) => dispatch(performApiRequest('postResetPassword', {}, data))
})

export default connect<Props>(null, mapDispatchToProps)(ForgotPassword);
