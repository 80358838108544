import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { ApiResponse, Location as DataCenter, Exchanges } from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import BoolIcon from 'components/utils/BoolIcon';
import SwitchesList from 'components/cards/SwitchesList';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';
import { updateAppPathAction } from 'redux/app/appActions';
import DataCenterEditModal from 'components/modals/DataCenterEditModal';
import styled from 'styled-components';

interface DataCenterDetailsProps {
    dataCenterObject: DataCenter;
    handleSync: () => void;
    exchangesData: Exchanges;
    selectExchange: (selectedExchange: string) => void; 
}

function DataCenterDetails(props: DataCenterDetailsProps) {
    const { dataCenterObject, handleSync, exchangesData, selectExchange } = props;
    const { t } = useTranslation();

    useEffect(() => {
        selectExchange(dataCenterObject.exchange);
    }, [dataCenterObject, selectExchange]);

    const getExchangeUuid = (exchangeShortName: string) => {
        return exchangesData.scopes
            .flatMap((scope) => scope.exchanges)
            .find((exchange) => exchange.short_name === exchangeShortName)!.uuid;
    };

    return (
        <>
            <Helmet title={dataCenterObject.long_name} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('dataCenterDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('dataCenterDetails.refreshButton')}
                                onClick={handleSync}
                            />
                            <DataCenterEditModal
                                dataCenterObject={dataCenterObject}
                                handleSync={handleSync}
                                exchangeUuid={getExchangeUuid(dataCenterObject.exchange)}
                            />
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('dataCenterDetails.dataCenterDetailsTitle')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('dataCenterDetails.exchange')}</th>
                                            <td>{dataCenterObject.exchange}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.longName')}</th>
                                            <td>{dataCenterObject.long_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.shortName')}</th>
                                            <td>{dataCenterObject.short_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.active')}</th>
                                            <td>
                                                <BoolIcon value={dataCenterObject.active} />
                                            </td>
                                        </tr>
                                        {dataCenterObject.speed_and_interface_types &&
                                            dataCenterObject.speed_and_interface_types.length !== 0 && (
                                                <tr>
                                                    <th>Speeds</th>
                                                    <td>
                                                        {dataCenterObject.speed_and_interface_types.map(
                                                            (speed: { name: string }) => (
                                                                <StyledMap key={speed.name}>
                                                                    {speed.name}
                                                                    <br />
                                                                </StyledMap>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        <hr></hr>
                                        <tr>
                                            <th>{t('dataCenterDetails.country')}</th>
                                            <td>{dataCenterObject.country}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.city')}</th>
                                            <td>{dataCenterObject.city}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.address1')}</th>
                                            <td>{dataCenterObject.address1}</td>
                                        </tr>
                                        <hr></hr>
                                        <tr>
                                            <th>{t('dataCenterDetails.contactName')}</th>
                                            <td>{dataCenterObject.contact_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.contactEmail')}</th>
                                            <td>{dataCenterObject.contact_email}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('dataCenterDetails.phoneNumber')}</th>
                                            <td>{dataCenterObject.phone_number}</td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>{t('dataCenterDetails.switches')}</h2>
                        <hr />
                        <SwitchesList exchangesData={exchangesData} dataCenter={dataCenterObject} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type DataCenterProps = RouteComponentProps<{ uuid: string, exchange: string }>;

interface ApiProps {
    dataCenterRequest:  ApiDataBinding<ApiResponse<DataCenter>>;
    exchangesData: Exchanges;
    selectExchange: (selectedExchange: string) => void;
}

type WrapperProps = ApiProps & DataCenterProps;

function DataCenterDetailsWrapper(props: WrapperProps) {
    const { dataCenterRequest, exchangesData, selectExchange } = props;
    const { t } = useTranslation();

    const handleSync = () => {
        dataCenterRequest.invalidateCache();
    };

    return (
        <RequestStatusRenderer
            request={dataCenterRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => (
                <DataCenterDetails
                    dataCenterObject={dataCenterRequest.data!.data!}
                    handleSync={handleSync}
                    exchangesData={exchangesData}
                    selectExchange={selectExchange}

                />
            )}
        />
    );
}

const enhance = compose<WrapperProps, DataCenterProps>(
    withApiData(
        {
            dataCenterRequest: 'getDataCenter'
        },
        (ownProps: WrapperProps) => ({
            dataCenterRequest: {
                uuid: ownProps.match.params.uuid
            }
        })
    ),
    connect(
        (state: ReduxState) => ({
            exchangesData: getAllExchangesSelector(state.apiData)
        }),
        (dispatch) => ({
            selectExchange: (selectedExchange: string) => {
                dispatch(updateAppPathAction('switches.selectedExchange', selectedExchange));
            }
        })
    ),
    withTranslation()
);

const StyledMap = styled.div`  
        font-size: 12px;
`;

export default enhance(DataCenterDetailsWrapper);


