export enum StatsInterval {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
    ThreeYears = '3y',
    All = 'all',
}

export enum StatsType {
    Traffic = 'regular',
    Error = 'error',
}

export enum StatsCounterType {
    bps = 'bps',
    pps = 'pps',
    sps = 'sps',
    percent = '%',
}

export enum SflowStatsType {
    ethertype = 'ethertype',
    framesize = 'framesize',
    as2as = 'as2as',
    ip2ip = 'ip2ip',
}

export enum SflowPeerType {
    as2as = 'as2as',
    ip2ip = 'ip2ip',
}

export enum Roles {
    admin = 'admin',
    noc = 'noc',
    qnoc = 'qnoc',
    ixaas_client = 'ixaas_client',
    customer = 'customer',
    ixaas_client_reader = 'ixaas_client_reader',
    customer_reader = 'customer_reader'
}

export enum ResourceType {
    link = 'link',
    link_vlan = 'link_vlan',
    port = 'port',
}

export enum TransitionsType {
    configure = 'configure',
    configuration_successful = 'configuration_successful',
    configuration_failed = 'configuration_failed',
    deconfigure = 'deconfigure',
    deconfiguration_successful = 'deconfiguration_successful',
    deconfiguration_failed = 'deconfiguration_failed',
    request_testing = 'request_testing',
}

export interface OptionItem {
    title: string;
    value: string;
}

export enum Status {
    unconfigured = 'unconfigured',
    configuring = 'configuring',
    configured = 'configured',
    deconfiguring = 'deconfiguring',
    deconfigured = 'deconfigured',
}

export enum ConnectionStatus {
    new = 'new',
    pending = 'pending',
    reserved = 'reserved',
    disconnecting = 'disconnecting',
    disconnected = 'disconnected',
    terminated = 'terminated',
    assigned = 'assigned',
    cancelled = 'cancelled',
    enabled = 'enabled',
    archived = 'archived',
    unconfigured = 'unconfigured',
    ready_for_testing = 'ready_for_testing',
    configuring = 'configuring',
    configured = 'configured',
    deconfiguring = 'deconfiguring',
    deconfigured = 'deconfigured',
    reconfiguring = 'reconfiguring',
    testing = 'testing',
}

export enum ConnectionNewStatuses {
    new = 'new',
    unconfigured = 'unconfigured',
    ready_for_testing = 'ready_for_testing',
    configured = 'configured',
    deconfigured = 'deconfigured'
}

export enum VlanStatus {
    unconfigured = 'unconfigured',
    configuring = 'configuring',
    configured = 'configured',
    deconfiguring = 'deconfiguring',
    deconfigured = 'deconfigured',
}

export enum LinkVlanStatus {
    unconfigured = 'unconfigured',
    configuring = 'configuring',
    configured = 'configured',
    deconfiguring = 'deconfiguring',
    deconfigured = 'deconfigured',
    reconfiguring = 'reconfiguring',
    quarantined = 'quarantined',
    moving_to_quarantine = 'moving_to_quarantine',
    moving_to_production = 'moving_to_production',
}

export enum SwitchStatus {
    active = 'active',
    inactive = 'inactive'
}

export enum DirectionType {
    input = 'in',
    output = 'out',
    total = 'total',
}

export enum VlanLanType {
    Cloud = 'cloud',
    PeerToPeer = 'p2p',
    MpToMp = 'mp2mp',
    PeerToMp = 'p2mp',
    PeeringLan = 'peering_lan',
}

export enum VlanUse {
    Customer = 'customer',
    Quarantine = 'quarantine',
    Monitor = 'monitor',
    Backbone = 'backbone',
}

export enum IpRangeType {
    Ipv4Range = 'Ipv4Range',
    Ipv6Range = 'Ipv6Range'
}

export enum IpRouterType {
    Ipv4Router = 'Ipv4Router',
    Ipv6Router = 'Ipv6Router'
}

export enum NetworkRequestStatus {
    Ready = 'ready',
    Loading = 'loading',
    Failed = 'failed',
    Success = 'success'
}

export enum LagType {
    static = 'static',
    dynamic = 'dynamic'
}

export enum LacpTimeout {
    short = 'short', 
    long = 'long'
}

export enum TrafficTypes {
    Internal = 'internal',
    Backbone = 'backbone',
    Management = 'management',
    Monitor = 'monitor',
}

export enum LinkType {
    IpxLink = 'IpxLink',
    MemberLink = 'MemberLink',
    MonitorLink = 'MonitorLink',
    PartnerLink = 'PartnerLink',
    ResellerBaseLink = 'ResellerBaseLink',
    TransportLink = 'TransportLink',
    VirtualLink = 'VirtualLink',
    VirtualTransportLink = 'VirtualTransportLink',
}

export enum LinkVlanMode {
    Untagged = 'untagged',
    Tagged = 'tagged',
    Dual = 'dual'
}

export enum ContractStatus {
    Archived = 'archived',
    Active = 'active',
    Inactive = 'inactive',
}

export enum ContractType {
    Agent = 'Agent',
    Amsix = 'Amsix',
    AmsixPartner = 'AmsixPartner',
    Colocation = 'Colocation',
    Customer = 'Customer',
    Erp = 'Erp',
    Hosting = 'Hosting',
    MegaPort = 'MegaPort',
    Member = 'Member',
    Partner = 'Partner',
    Referer = 'Referer',
    ResellerPlus = 'ResellerPlus',
    Software = 'Software',
    Transport = 'Transport'
}

export enum PortType {
    ALL = 'All',
    T = 'T',
    LX = 'LX',
    LR = 'LR',
    LR4 = 'LR4',
    SX = 'SX',
    SR4 = 'SR4',
    ZRD = 'ZRD',
    LR4_2KM_BIDI = 'LR4-2KM-BIDI',
    RS232 = 'RS232',
    ER = 'ER',
    TX = 'TX',
    SR = 'SR',
    DWDM = 'DVDM',
    LR4_2KM_BID = 'LR4-2KM-BID',
    I0x10_2km = '10x10-2km',
    LHA = 'LHA',
    CWDM = 'CWDM'
}

export enum PortSpeed {
    ALL = 'All',
    GE_1 = '1000',
    GE_10 = '10000',
    GE_100 = '100000'
}

export enum SnmpStatus {
    Disabled = 'disabled',
    Up = 'up',
    Down = 'down',
}

export enum BgpRouterType {
    RouteServer = 'RouteServer',
    NativeRouter = 'NativeRouter'
}

export enum LinkSwapStatus {
    PRIMARY = 'PRIMARY',
    BACKUP = 'BACKUP'
}

export enum searchByOptions {
    none = '',
    contract_name = 'Contract name',
    contract_handle = 'Contract handle',
    contract_id = 'Contract UUID',
    asn = 'ASN number',
    xml_id = 'Link XML ID',
    uuid = 'Link UUID',
    status = 'Status',
    link_vlan_xml_id = 'Link VLAN XML ID',
    link_vlan_uuid = 'Link VLAN UUID',
    vlan_number = 'VLAN number',
    vlan_uuid = 'VLAN UUID',
    mac_address = 'MAC address',
    port = 'Port',
    link_port_uuid = 'Link port UUID',
    router_ipv4_ipv6 = 'Router ipv4/ipv6'
}
