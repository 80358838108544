import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from 'constants/literals';

// constants
import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';

interface InputProps {
    exchange: string;
    link: LinkFull;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'putLinkComment', {
        exchange: ownProps.exchange,
        linkId: ownProps.link.uuid,
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('putLinkComment', {
                exchange: ownProps.exchange,
                linkId: ownProps.link.uuid,
            }, values));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class LinkAddCommentModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            pathToError: 'errorBody.message',
            errorMessage: this.props.t('notification.addCommentFailed'),
            successMessage: this.props.t('notification.addCommentSuccessful'),
        });
    }

    render() {
        const { onSubmit, link, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ comment: get(link, 'attributes.comment', '') }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('comment.headerTitle')}
                            body={
                                <FormGroup row className="mb-0">
                                    <Col md="2">
                                        <Label htmlFor="comment">{t('connection.displayComment')}</Label>
                                    </Col>
                                    <Col md="10">
                                        <FormControl
                                            component="textarea"
                                            style={{ height: 120 }}
                                            name="comment"
                                            id="comment"
                                            errors={errors}
                                            touched={touched}
                                            hidden={false}
                                        />
                                    </Col>
                                </FormGroup>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={this.props.link.attributes.comment === values.comment}
                                />
                            }
                        />
                    )}
                </Formik>
                <EditBtnConditionalRenderer data-test="edit-btn-renderer" roles={[Roles.noc, Roles.admin, Roles.ixaas_client]} resourceStatus={this.props.link.status} anyStatus={true}>
                    <Tooltip
                        data-test="change-comment-btn"
                        triggerBtn={
                            <i
                                className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('comment.headerTitle')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(LinkAddCommentModal);
