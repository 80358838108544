import {
    Button, Col, FormGroup, Label
} from 'reactstrap';
import React from 'react';
import { Field, Formik, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { ApiDataState, withApiData, ApiDataBinding } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { ApiResponse, Exchanges, IpRange, ReactApiDataActions } from 'constants/api';
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';

import VlanSelectAll from 'components/utils/VlanSelectAll';
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';

interface InProps {
    ipRange: IpRange
}

interface EnhanceProps {
    onSubmit: () => boolean;
    ipRanges: IpRange[];
    apiData: ApiDataState;
    userRoles: Roles[];
    exchangesData: Exchanges;
    ipRangeUpdate: ApiDataBinding<ApiResponse<IpRange>>;
    ipRangeRequest: ApiDataBinding<IpRange>;
    apiDataActions: ReactApiDataActions;
}

interface WithStateProps {
    targetExchange: string;
    setTargetExchange: (exchange: string) => void;
}

type Props = EnhanceProps & InProps & BaseFormModalProps & RouteComponentProps & WithTranslation & WithStateProps;

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    exchangesData: getAllExchangesSelector(state.apiData),
});

const enhance = compose<EnhanceProps & WithTranslation, InProps>(
    connect(mapStateToProps, null),
    withState('targetExchange', 'setTargetExchange', 'nl'),
    withTranslation(),
    withApiData(
        {
            ipRangeUpdate: 'putIpRange',
            ipRangeRequest: 'getIpRange'
        },
        (ownProps: InProps) => ({
            ipRangeRequest: {
                id: ownProps.ipRange.id
            }
        })
    )
);

type FormInitialValuesType = {
    description: string;
    vlan_number: number;
    public: boolean;
    exchange: string;
};

class IpRangeEditModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.ipRangeUpdate.request, this.props.ipRangeUpdate.request, {
            successMessage: this.props.t('ipRangeEditModal.successMessage'),
        });
    }

    _handleOnSubmit = (values: any, formikBag: FormikActions<FormInitialValuesType>) => {
        const { ipRangeUpdate, apiDataActions, setTargetExchange, ipRange } = this.props;
        const { exchange, ...params } = values;
        setTargetExchange(values.exchange);
        ipRangeUpdate.perform({ id: ipRange.id }, { exchange: values.exchange, ip_range: params }).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getIpRanges', { exchange: values.exchange });
                apiDataActions.invalidateCache('getIpRange', { id: ipRange.id });
                apiDataActions.invalidateCache('getVlans', { exchange: values.exchange });
                apiDataActions.perform('getVlans', { exchange: values.exchange });           
            }
        });
    };

    render() {
        const { ipRange, targetExchange, t } = this.props;

        return (
            <>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            description: ipRange.description,
                            vlan_number: ipRange.vlan ? ipRange.vlan.number : NaN,
                            public: ipRange.public,
                            exchange: ipRange.exchange.short_name ? ipRange.exchange.short_name : targetExchange,
                        } as FormInitialValuesType
                    }
                    onSubmit={this._handleOnSubmit}
                >
                    {({ handleSubmit, isSubmitting, errors, setValues, touched, values, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('ipRangeEditModal.headerTitle')}
                            body={
                                <>
                                    <div>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="description">{t('ipRangeModal.description')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl
                                                    type="text"
                                                    name="description"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="vlan_number">{t('ipRangeModal.vlanSelect')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <VlanSelectAll
                                                    exchange={values.exchange ? values.exchange : targetExchange}
                                                    excludeVlanType={['p2p']}
                                                    onChange={(vlan) => {
                                                        setValues({ ...values, vlan_number: vlan });
                                                    }}
                                                    defaultLabel={ipRange.vlan ? ipRange.vlan.description : ''}
                                                    defaultValue={ipRange.vlan ? ipRange.vlan.number : NaN}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="public">{t('ipRangeModal.public')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field type="checkbox" name="public" checked={values.public} />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.ixaas_client]}>
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('ipRangeEditModal.addBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

export default enhance(IpRangeEditModal);


