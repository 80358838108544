import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Helmet from 'react-helmet';

// components

import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import AvailablePortsList from 'components/cards/AvailablePortsList';
import i18next from 'i18next';

const AvailablePortsView: FunctionComponent<{}> = () => {

    const [syncList, setSyncList] = useState(false);

    return (
        <>
            <Helmet title="Available Ports" />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            <span>
                                {i18next.t('availablePorts.pageTitle')}
                                <SyncButton
                                    data-test="availablePorts-refresh-btn"
                                    className="ml-2"
                                    title={i18next.t('availablePorts.refreshButton')}
                                    onClick={() => {
                                        setSyncList(!syncList);
                                    }}
                                />
                            </span>
                        </h2>
                        <hr />
                        <AvailablePortsList syncList={syncList} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default AvailablePortsView;
