import React, { FunctionComponent } from 'react';
import { compose } from 'recompose';
import { ApiDataBinding, withApiData } from 'react-api-data';
import get from 'lodash/get';
import size from 'lodash/size';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Accounts, Account } from 'constants/api';
import { getcontractUsersTableColumns } from 'constants/columns';
import { defaultPaginationOptions } from 'constants/pagination';

// components
import { StyledTable } from 'components/styled';
import InteractiveTable from 'components/utils/InteractiveTable';
import AlertMessage from 'components/utils/AlertMessage';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';

// hocs
import withUserRoles from 'hocs/withUserRoles';
import { Roles } from '../../constants/literals';

interface InProps {
    contractUuid: string;
    exchange: string;
}

interface ApiProps {
    contractAccountsRequest: ApiDataBinding<Accounts>;
}

interface EnhanceProps {
    userRoles: Roles[];
}

type Props = InProps & ApiProps & EnhanceProps & WithTranslation;

const connectApiData = withApiData({
    contractAccountsRequest: 'getContractAccounts'
},
(ownProps: InProps) => ({
    contractAccountsRequest: {
        contractUuid: ownProps.contractUuid,
    }
}));

const ContractAccountsListContainer: FunctionComponent<Props> = ({ contractAccountsRequest, t, exchange, contractUuid, userRoles }) => (
    <RequestStatusRenderer
        request={contractAccountsRequest}
        failedMessage={t('contract.noUsers')}
        success={() => {
            const accounts = get(contractAccountsRequest, 'data.data.accounts');
            if (!accounts || !size(accounts)) {
                return <AlertMessage data-test="no-users-message" message={t('contract.noUsers')} />;
            }
            const contractUsersColumns = getcontractUsersTableColumns(
                exchange,
                contractUuid,
                userRoles,
                contractAccountsRequest.invalidateCache
            );
            return (
                <ContractAccountsList
                    data-test="contract-users-list"
                    accounts={accounts}
                    exchange={exchange}
                    contractUuid={contractUuid}
                    tableColumns={contractUsersColumns}
                    tablePagination={{
                        ...defaultPaginationOptions,
                        sizePerPage: 5,
                        paginationSize: 5,
                        hideSizePerPage: true,
                    }}
                />
            );
        }}
    />
);

interface ContractAccountsListProps {
    accounts: Account[];
    tableColumns: any[];
    exchange: string;
    contractUuid: string;
    tablePagination: {[key: string]: any};
}

export const ContractAccountsList: FunctionComponent<ContractAccountsListProps> = ({ accounts, tableColumns, tablePagination }) => {
    return (
        <StyledTable className="animated fadeIn">
            <InteractiveTable
                keyField="uuid"
                columns={tableColumns}
                data={accounts}
                defaultSorted={[{
                    dataField: 'uuid',
                    order: 'asc',
                }]}
                paginationOptions={tablePagination}
            />
       </StyledTable>
    );
};

export default compose<Props, InProps>(
    connectApiData,
    withTranslation(),
    withUserRoles
)(ContractAccountsListContainer);
