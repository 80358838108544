import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import { compose } from 'recompose';
import { BannerApi } from 'constants/api';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

interface ConnectProps {
    debugModeState: boolean;
}

interface EnhanceProps {
    bannerData: ApiDataBinding<{ data: BannerApi }>;
}

type Props = EnhanceProps & ConnectProps;

const enhance = compose<Props, {}>(
    withApiData({
        bannerData: 'getBanner',
    }),
    connect(
        (state: ReduxState) => ({
            debugModeState: get(state, 'app.provisioning.debugProvisioningMode', false)
        })
    )
);

const AppBanner: FunctionComponent<Props> = ({ debugModeState, bannerData }) => {
    const banner = bannerData.data;
    const { t } = useTranslation();
    return (
        <>
            {banner && banner.data && banner.data.show_banner && <Alert color="info">{banner.data.banner_text}</Alert>}
            {debugModeState && <Alert color="danger" className='text-center'>{t('debugProvisioning.warningMessage')}</Alert>}
        </>
    );
};

export default enhance(AppBanner);
