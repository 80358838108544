import React, { FunctionComponent, memo, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Options, SeriesAreasplineOptions, SeriesSplineOptions } from 'highcharts';
import styled from 'styled-components';
import { StatsData } from 'constants/api';
import { StatsCounterType, StatsInterval } from 'constants/literals';
import { CenteredAlert } from 'components/styled';
import get from 'lodash/get';
import set from 'lodash/set';
import nth from 'lodash/nth';
import values from 'lodash/values';
import omit from 'lodash/omit';
import size from 'lodash/size';

import pipe from 'lodash/fp/pipe';
import reduce from 'lodash/fp/reduce';
import max from 'lodash/fp/max';

import parse from 'color-parser';
import memoizeOne from 'memoize-one';
import ReactResizeDetector from 'react-resize-detector';

interface Props {
    data: StatsData;
    counter: StatsCounterType;
    interval: StatsInterval;
    simple?: boolean;
    height: number;
    noDataMessage?: string;
    graphOptions?: Options;
    seriesType?: string;
    step?: number;
}

const calculateDivision = (num: number) => {
    if (num > 1000000000000) {
        return {
            divisor: 1000000000000,
            suffix: 'Tbps'
        };
    } else if (num > 1000000000) {
        return {
            divisor: 1000000000,
            suffix: 'Gbps'
        };
    } else if (num > 1000000) {
        return {
            divisor: 1000000,
            suffix: 'Mbps'
        };
    } else if (num > 1000) {
        return {
            divisor: 1000,
            suffix: 'Kbps'
        };
    }

    return {
        divisor: 1,
        suffix: 'bps'
    };
};

const findMax = memoizeOne((data: any): number => {
    return pipe(
        reduce((result: any, item: any) => {
            result.push(max(values(omit(item, 'time'))));
            return result;
        }, []),
        max,
    )(data) as number;
});

const dateFormatFunctionsMap: { [key in StatsInterval]: (date: Date) => string } = {
    [StatsInterval.Daily]: (date) =>
        date.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }),
    [StatsInterval.Weekly]: (date) =>
        date.toLocaleString(undefined, { weekday: 'short', hour: 'numeric', minute: 'numeric' }),
    [StatsInterval.Monthly]: (date) =>
        date.toLocaleString(undefined, {
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        }),
    [StatsInterval.Yearly]: (date) =>
        date.toLocaleString(undefined, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }),
    [StatsInterval.ThreeYears]: (date) =>
        date.toLocaleString(undefined, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }),
    [StatsInterval.All]: (date) =>
        date.toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }),
};

function formatTimestamp(timestamp: number, interval: StatsInterval): string {
    const dateFormatFn = dateFormatFunctionsMap[interval];
    return dateFormatFn(new Date(timestamp * 1000));
}

const Graph: FunctionComponent<Props> = ({
    data,
    height,
    simple,
    noDataMessage,
    counter,
    interval,
    graphOptions,
    seriesType,
    step,
}) => {
    const graphRef = useRef(null);
    let divisor = 1;
    let suffix: string = counter;
    let series: Array<SeriesAreasplineOptions | SeriesSplineOptions> = [];
    const dataSeries = get(data, 'data');

    if (!size(dataSeries) || !data.meta_data) {
        return <CenteredAlert color="none" style={{ height }}>{noDataMessage || 'No data available'}</CenteredAlert>;
    }

    const seriesKeys = Object.keys(data.meta_data.legend);

    if (counter === StatsCounterType.bps) {
        const division = calculateDivision(findMax(dataSeries) || 0);
        divisor = division.divisor;
        suffix = division.suffix;
    }

    // @ts-ignore
    series = seriesKeys.map((key, index) => ({
        name: get(data, `meta_data.legend.${key}.long`, key),
        type: seriesType ? seriesType : (index === 0 ? 'areaspline' : 'spline'),
        data: dataSeries.map((item: any) => (
            {
                x: get(item, 'time') * 1000,
                y: (get(item, key) || 0) / divisor,
                custom: {
                    date: formatTimestamp(get(item, 'time'), interval)
                },
            }
        )),
        visible: true
    }));

    const primaryRgb = parse(getComputedStyle(document.body).getPropertyValue('--primary').trim()) || { r: 255, g: 255, b: 255 };
    const gradientStart = `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0.5)`;
    const gradientEnd = `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0)`;

    const options: Options = {
        chart: {
            type: 'areaspline',
            height,
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            animation: false,
            margin: simple ? 0 : undefined
        },
        colors: [
            'var(--blue)',
            'var(--yellow)',
            'var(--teal)',
            'var(--pink)',
            'var(--gray)',
            'var(--primary)',
            'var(--purple)',
            'var(--danger)',
        ],
        title: {
            text: undefined
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: !simple
        },
        xAxis: {
            visible: !simple,
            type: 'datetime',
            minPadding: 0,
            maxPadding: 0,
            labels: {
                style: {
                    color: 'var(--black)'
                }
            },
            // set the step if it's defined
            ...(step) && { tickInterval: step }
        },
        yAxis: {
            visible: !simple,
            labels: {
                format: `{value} ${suffix}`,
                style: {
                    color: 'var(--black)'
                }
            },
            title: {
                text: null
            },
            startOnTick: false,
            endOnTick: false
        },
        tooltip: {
            shared: false,
            valueDecimals: divisor > 1 ? 3 : 0,
            backgroundColor: 'rgba(0,0,0,0)',
            headerFormat: '',
            pointFormat: `<div>
                            <div>
                                <span class="tooltip-amount">{point.y}</span> <span class="tooltip-unit">${suffix}</span>
                            </div>
                            <span class="tooltip-date">{point.custom.date}</span>
                        </div>`,
            borderWidth: 0,
            useHTML: true,
            shadow: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.8,
                states: {
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
                softThreshold: true,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, gradientStart],
                        [1, gradientEnd]
                    ]
                }
            },
            series: {
                marker: {
                    states: {
                        hover: {
                            radius: 5,
                            fillColor: '#ffffff',
                            lineColor: '#000000',
                            lineWidth: 3
                        }
                    },
                    symbol: 'circle'
                },
                animation: false,
                enableMouseTracking: !simple,
                turboThreshold: 0
            }
        },
        series
    };

    const mergedOptions = { ...options, ...graphOptions };

    return (
        <>
            <ReactResizeDetector
                handleWidth
                refreshMode="debounce"
                refreshRate={300}
                onResize={(width: any) => {
                    if (get(graphRef, 'current.chart')) {
                        // @ts-ignore
                        graphRef.current.chart.reflow();
                    }
                }}
            />
            <StyledGraph>
                <HighchartsReact ref={graphRef} highcharts={Highcharts} options={mergedOptions} />
            </StyledGraph>
        </>
    );
};

const StyledGraph = styled.div`
    .tooltip-amount {
        font-size: 16px;
        text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
    }
    
    .tooltip-unit {
        font-size: 10px;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }

    .tooltip-date {
        font-size: 14px;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    }
`;

export default memo(Graph);
