import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ApiDataBinding, withApiData, invalidateApiDataRequest, performApiRequest } from 'react-api-data';
import { ApiResponse, Contract, NativeRouter } from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import Exchange from 'components/utils/Exchange';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { Link } from 'react-router-dom';
import { getLinkToConnection, getLinkToVlan } from 'utils/linksUtils';
import NativeRouterEditModal from 'components/modals/NativeRouterEditModal';
import { ReduxState } from '../../createStore';
import { getHomeContractSelector } from '../../redux/apiData/apiDataSelectors';
import { get } from 'lodash';

interface NativeRouterDetailsProps {
    nativeRouter: NativeRouter;
    refetchNativeRouter: () => undefined;
    contract: Contract;
}

function NativeRouterDetails(props: NativeRouterDetailsProps) {
    const { nativeRouter, refetchNativeRouter, contract } = props;
    const { t } = useTranslation();
    const organisation = get(contract, 'organisation');

    return (
        <>
            <Helmet title={nativeRouter.description} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('nativeRouterDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('nativeRouterDetails.refreshButton')}
                                onClick={refetchNativeRouter}
                            />
                            {organisation && <NativeRouterEditModal nativeRouter={nativeRouter} organisation={organisation}/>}
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('nativeRouterDetails.nativeRouterDetailsTitle')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('nativeRouterDetails.exchange')}</th>
                                            <td>
                                                <Exchange shortName={nativeRouter.exchange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.description')}</th>
                                            <td>{nativeRouter.description}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.id')}</th>
                                            <td>{nativeRouter.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.type')}</th>
                                            <td>{nativeRouter.type}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.peeringPrefixV4')}</th>
                                            <td>{nativeRouter.peering_prefix_v4}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.peeringPrefixV6')}</th>
                                            <td>{nativeRouter.peering_prefix_v6}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.asnNumber')}</th>
                                            <td>{nativeRouter.as_number}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.managementAddress')}</th>
                                            <td>{nativeRouter.management_address}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.Ipv4')}</th>
                                            <td><Link to={getLinkToConnection(nativeRouter.exchange, nativeRouter.link_ipv4_uuid)}>{nativeRouter.peering_ipv4}</Link></td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.Ipv6')}</th>
                                            <td><Link to={getLinkToConnection(nativeRouter.exchange, nativeRouter.link_ipv6_uuid)}>{nativeRouter.peering_ipv6}</Link></td>
                                        </tr>
                                        <tr>
                                            <th>{t('nativeRouterDetails.vlanNumber')}</th>
                                            <td><Link to={getLinkToVlan('row.exchange', nativeRouter.vlan.uuid)}>{nativeRouter.vlan.number}</Link></td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type RouteProps = RouteComponentProps<{ nativeRouterUuid: string }>;

interface ApiProps {
    nativeRouterRequest: ApiDataBinding<ApiResponse<NativeRouter>>;
    refetchNativeRouter: () => undefined;
    contract: Contract;
}

type WrapperProps = ApiProps & RouteProps;

function NativeRouterDetailsWrapper(props: WrapperProps) {
    const { nativeRouterRequest, refetchNativeRouter, contract } = props;
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={nativeRouterRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => <NativeRouterDetails nativeRouter={nativeRouterRequest.data!.data!} refetchNativeRouter={refetchNativeRouter} contract={contract}/>}
        />
    );
}

const connectApiData = withApiData({ nativeRouterRequest: 'getNativeRouter' }, (ownProps: RouteProps) => ({
    nativeRouterRequest: {
        nativeRouterUuid: ownProps.match.params.nativeRouterUuid,
    },
}));

const mapStateToProps = (state: ReduxState, ownProps: {}) => ({
    contract: getHomeContractSelector(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: WrapperProps) => ({
    refetchNativeRouter: () => {
        const params = { nativeRouterUuid: ownProps.match.params.nativeRouterUuid };
        dispatch(invalidateApiDataRequest('getNativeRouter', params));
        dispatch(performApiRequest('getNativeRouter', params));
    },
});

const enhance = compose<WrapperProps, RouteProps>(connectApiData, connect(mapStateToProps, mapDispatchToProps));

export default enhance(NativeRouterDetailsWrapper);


