import React, { FunctionComponent } from 'react';
import { Row, Spinner } from 'reactstrap';
import styled from 'styled-components';

const PageLoading: FunctionComponent = () => {
    return (
        <Row className="justify-content-center align-items-center">
            <LargeSpinner type="grow" color="primary"/>
        </Row>
    );
};

export const LargeSpinner = styled(Spinner)`
  width: 10vh;
  height: 10vh;
  margin-top: 35vh;
`;

export default PageLoading;