import React, { FunctionComponent, ReactNode } from 'react';

// constants
import { TransitionsType } from 'constants/literals';

// components
import TransitionConfigure from './TransitionConfigure';
import TransitionDeconfigure from './TransitionDeconfigure';
import TransitionConfigurationSuccessful from './TransitionConfigurationSuccessful';
import TransitionConfigurationFailed from './TransitionConfigurationFailed';
import TransitionDeconfigurationSuccessful from './TransitionDeconfigurationSuccessful';
import TransitionDeconfigurationFailed from './TransitionDeconfigurationFailed';
import TransitionRequestTesting from './TransitionRequestTesting';

export interface TransitionItemProp {
    transitionType: TransitionsType;
    component: FunctionComponent<any>;
    endpointKey: string;
}

const transitionsList: TransitionItemProp[] = [
    {
        transitionType: TransitionsType.configure,
        component: TransitionConfigure,
        endpointKey: 'putResourceConfigure',
    },
    {
        transitionType: TransitionsType.deconfigure,
        component: TransitionDeconfigure,
        endpointKey: 'putResourceDeconfigure',
    },
    {
        transitionType: TransitionsType.configuration_successful,
        component: TransitionConfigurationSuccessful,
        endpointKey: 'putResourceConfigurationSuccessful',
    },
    {
        transitionType: TransitionsType.deconfiguration_successful,
        component: TransitionDeconfigurationSuccessful,
        endpointKey: 'putResourceDeconfigurationSuccessful',
    },
    {
        transitionType: TransitionsType.configuration_failed,
        component: TransitionConfigurationFailed,
        endpointKey: 'putResourceConfigurationFailed',
    },
    {
        transitionType: TransitionsType.deconfiguration_failed,
        component: TransitionDeconfigurationFailed,
        endpointKey: 'putResourceDeconfigurationFailed',
    },
    {
        transitionType: TransitionsType.request_testing,
        component: TransitionRequestTesting,
        endpointKey: 'putResourceRequestTesting',
    },
];

export interface CommonInProps {
    currentStatus: string;
    resourceId: string;
    exchange: string;
    renderTransitionBtn?: (props: {transitionName: string, onClick: () => void}) => ReactNode;
}

export interface DispatchProps {
    onConfirm: (transitionItem: TransitionItemProp) => void;
}

type InProps = CommonInProps & DispatchProps & any;

const ResourceTransitions: FunctionComponent<InProps> = ({ onConfirm, renderTransitionBtn, ...resourceProps }) => (
    <>
        {transitionsList.map((transitionItem: TransitionItemProp) => {
            const TransitionComponent = transitionItem.component;
            return (
                <TransitionComponent
                    data-test="transition-item"
                    data-test-transition={transitionItem.transitionType}
                    key={transitionItem.transitionType}
                    onConfirm={() => onConfirm(transitionItem)}
                    renderTransitionBtn={renderTransitionBtn}
                    { ...resourceProps }
                />
            );
        })}
    </>
);

export default ResourceTransitions;