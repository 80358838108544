import { createReducer } from 'redux-act';
import clone from 'lodash/clone';
import set from 'lodash/set';
import get from 'lodash/get';

import { AppState } from 'appState';
import { updateLocationHistoryAction, changeLanguageAction, updateAppPathAction, archiveLinkAction } from './appActions';

export const appReducer = createReducer({
    [updateLocationHistoryAction]: (state: AppState, payload: string) => {
        if (!state.locationHistory) {
            state.locationHistory = [];
        }
        // store maximum 10 last locations
        const newLocationHistory = (state.locationHistory).slice(
            state.locationHistory.length === 10 ? 1 : 0
        );
        newLocationHistory.push(payload);
        return {
            ...state,
            locationHistory: newLocationHistory
        };
    },
    [changeLanguageAction]: (state: AppState, language: string) => {
        return {
            ...state,
            appLanguage: language
        };
    },
    [updateAppPathAction]: (state: AppState, payload: {path: string, data: any}) => {
        const draftState: any = { ...state };
        const pathParts = payload.path.split('.');
        // shallow clone state pieces
        pathParts.reduce((fullPath: string, pathItem: string) => {
            const statePath = fullPath ? `${fullPath}.${pathItem}` : pathItem;
            set(draftState, statePath, clone(get(draftState, statePath)));
            return statePath;
        }, '');
        set(draftState, payload.path, payload.data);
        return draftState;
    },
    [archiveLinkAction]: (state: AppState, payload: { linkUuid: string }) => {
        return {
            ...state,
            linkHistory: state.linkHistory.filter(link => link.uuid !== payload.linkUuid)
        };
    }
});
