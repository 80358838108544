import React, { FunctionComponent, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

interface Props {
    direction?: 'up' | 'down';
    options: any;
    currSizePerPage: number;
    onSizePerPageChange: any;
}

export const PerPageDropdown: FunctionComponent<Props> = ({ direction, options, currSizePerPage, onSizePerPageChange}) => {
    const [isOpen, setOpen] = useState(false);
    const toggle = () => { setOpen(!isOpen); };
    return (
        <Dropdown isOpen={isOpen} toggle={toggle} direction={direction}>
            <DropdownToggle
                caret
                className="border-primary"
            >
                {currSizePerPage}
            </DropdownToggle>
            <DropdownMenu>
                {options.map((option: any) => (
                    <DropdownItem
                        key={ option.page }
                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            onSizePerPageChange(option.page);
                        }}
                    >
                        {option.page}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};