import { RouteComponentProps } from 'react-router';
import memoizeOne from 'memoize-one';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import pick from 'lodash/pick';
import size from 'lodash/size';
import includes from 'lodash/includes';
import toNumber from 'lodash/toNumber';
import isNaN from 'lodash/isNaN';
import { invalidateApiDataRequest, performApiRequest, getEntity, getResultData } from 'react-api-data/lib';

import { LinkFull, Exchanges, Vlan, RateLimiter } from 'constants/api';
import { linkSchema } from 'constants/endpointConfig';
import { ConnectionStatus, Roles } from 'constants/literals';

import { updateMemberLinkAction, updateLinkEntityAction } from 'redux/apiData/apiDataActions';
import { isAdmin, hasSomeRole } from './userUtils';
import { getBaseRoutePath } from './commonUtils';

export const getLinkSchemaId = (exchange: string, linkId: string): string => `${(exchange || '').toLowerCase()}|${linkId}`;

export const getLinkToConnection = (exchangeShortName: string, linkId: string): string => {
    return `${getBaseRoutePath()}/connections/${(exchangeShortName || '').toLowerCase()}/${linkId}`;
};

export const getLinkToLinkVlan = (exchangeShortName: string, linkVlanUuid: string): string => {
    return `${getBaseRoutePath()}/link_vlans/${(exchangeShortName || '').toLowerCase()}/${linkVlanUuid}`;
};

export const getLinkToRsPeering = (routerUuid: string, exchangeShortName: string, linkId: string | number): string => {
    return `${getBaseRoutePath()}/peering/${routerUuid}/${exchangeShortName}/${linkId}/rs`;
};

export const getLinkToNrPeering = (routerUuid: string, exchangeShortName: string, linkId: string | number): string => {
    return `${getBaseRoutePath()}/peering/${routerUuid}/${exchangeShortName}/${linkId}/nr`;
};

export const getExchangeFromParams = (props: RouteComponentProps<{ exchange: string }>): string => {
    return get(props, 'match.params.exchange', '').toUpperCase();
};

export const getLinkToOrganisation = (organisationUuid: string): string => {
    return `${getBaseRoutePath()}/organisations/${organisationUuid}`;
};

export const getLinkToContract = (exchangeShortName: string, contractUuid: string | number, linkId?: string): string => {
    return `${getBaseRoutePath()}/contracts/${(exchangeShortName || '').toLowerCase()}/${contractUuid}${linkId ? '/' + linkId : ''}`;
};

export const getLinkToVlan = (exchangeShortName: string, vlanUuid: string | number): string => {
    return `${getBaseRoutePath()}/vlans/${(exchangeShortName || '').toLowerCase()}/${vlanUuid}`;
};

export const getLinkToCustomers = (): string => {
    return `${getBaseRoutePath()}/customers`;
};

export const getLinkToHomeContract = (exchange: string, contractUuid: string ): string => {
    return `${getBaseRoutePath()}/contracts/${(exchange || '').toLowerCase()}/${contractUuid}`;
};

export const getLinkToVlans = (): string => {
    return `${getBaseRoutePath()}/vlans`;
};

export const getLinkToNativeRouter = (exchangeShortName: string, routerUuid: string | number): string => {
    return `${getBaseRoutePath()}/native_routers/${(exchangeShortName || '').toLowerCase()}/${routerUuid}`;
};

export const getLinkToNativeRouters = (): string => {
    return `${getBaseRoutePath()}/native_routers`;
};

export const getLinkToSwitches = (): string => {
    return `${getBaseRoutePath()}/switches`;
};

export const getLinkToAvailablePorts = (): string => {
    return `${getBaseRoutePath()}/free_ports`;
};

export const getLinkToSwitch = (exchangeShortName: string, name: string): string => {
    return `${getBaseRoutePath()}/switches/${(exchangeShortName || '').toLowerCase()}/${name}`;
};

export const getLinkToIpRanges = (): string => {
    return `${getBaseRoutePath()}/ip_ranges`;
};

export const getLinkToIpRange = (id: number): string => {
    return `${getBaseRoutePath()}/ip_ranges/${id}`;
};

export const getLinkToRouteServers = (): string => {
    return `${getBaseRoutePath()}/route_servers`;
};

export const getLinkToRouteServer = (exchangeShortName: string, routerUuid: string | number): string => {
    return `${getBaseRoutePath()}/route_servers/${(exchangeShortName || '').toLowerCase()}/${routerUuid}`;
};

export const getLinkToPort = (exchangeShortName: string, portId: string): string => {
    const port = portId.split('/').join('%2F');
    return `${getBaseRoutePath()}/ports/${(exchangeShortName || '').toLowerCase()}/${port}/port_details`;
};

export const getLinkToExchanges = (): string => {
    return `${getBaseRoutePath()}/exchanges`;
};

export const getLinkToExchange = (exchangeUuid: string): string => {
    return `${getBaseRoutePath()}/exchanges/${exchangeUuid}`;
};

export const getLinkToDataCenter = (dataCenterUuid: string, exchange: string): string => {
    return `${getBaseRoutePath()}/${exchange}/dataCenters/${dataCenterUuid}`;
};

export const getLinkToUser = (userId: string): string => {
    return `${getBaseRoutePath()}/users/${userId}`;
};

export const refetchMemberLink = (dispatch: any, requestParams: {linkId: string, exchange: string}): void => {
    const params = {
        linkId: get(requestParams, 'linkId'),
        exchange: get(requestParams, 'exchange', '').toUpperCase(),
    };
    dispatch(invalidateApiDataRequest('getMemberLink', params));
    dispatch(performApiRequest('getMemberLink', params));
};

export const updateMemberLink = (newLink: LinkFull, dispatch: any, getState: any, requestParams: {linkId: string, exchange: string}): void => {
    const params = {
        linkId: get(requestParams, 'linkId'),
        exchange: get(requestParams, 'exchange', '').toUpperCase(),
    };
    const currentLink = getResultData(getState().apiData, 'getMemberLink', params);
    const updatedLink = Object.assign({}, currentLink, newLink);
    dispatch(updateMemberLinkAction(updatedLink));
};

export const refetchLinkContract = (dispatch: any, requestParams: {contractUuid: string, exchange: string}): void => {
    const params = pick(requestParams, ['contractUuid', 'exchange']);
    dispatch(invalidateApiDataRequest('getContract', params));
    dispatch(performApiRequest('getContract', params));
};

export const updateLinkEntity = (newLink: LinkFull, dispatch: any, getState: any, params: {linkId: string, exchange: string}): void => {
    const exchange = get(params, 'exchange', '');
    const linkId = get(params, 'linkId', '');

    const linkEntity = getEntity(getState().apiData, linkSchema, getLinkSchemaId(exchange, linkId));
    const updatedLink = Object.assign({}, linkEntity, newLink);
    dispatch(updateLinkEntityAction(updatedLink));
};

export const isConfigurable = (resourceStatus: string): boolean => (
    !includes(['deconfigured', 'reconfiguring', 'deconfiguring', 'unconfigured', 'configuring'], resourceStatus)
);

export const isConnectionDeletable = (connectionStatus: ConnectionStatus) => (
    [
        ConnectionStatus.new,
        ConnectionStatus.pending,
        ConnectionStatus.disconnected,
        ConnectionStatus.terminated,
        ConnectionStatus.cancelled,
        ConnectionStatus.archived,
        ConnectionStatus.unconfigured,
        ConnectionStatus.deconfigured
    ].includes(connectionStatus)
);

/**
 * Edit button is hidden for resources that are not in a configured state
 * Applicable for all roles, except `admin`
 * Not available even for `admin` if resource has deconfigured/reconfiguring/deconfiguring status 
 */
export const isEditBtnShown = (resourceStatus: string, userRoles: Roles[], requiredRoles?: Roles[], anyStatus?: boolean): boolean => {
    if (!isConfigurable(resourceStatus)) {
        return false;
    } else if (isAdmin(userRoles)) {
        return true;
    } else if (anyStatus && hasSomeRole(userRoles, Roles.admin, Roles.noc)) {
        return true;
    } else if (resourceStatus === 'configured') {
        if (!requiredRoles) {
            return true;
        }
        return hasSomeRole(userRoles, ...requiredRoles);
    } else {
        return false;
    }
};

/**
 * DeleteRL button is hidden for resources that do not have a RL
 * Applicable for all roles, except `admin`, `ixaas_client` and `noc`
 */
export const isDeleteRateLimiterBtnShown = (userRoles: Roles[], rateLimiter?: RateLimiter): boolean => {
    if (rateLimiter && hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.ixaas_client)) {
        return true;
    } else {
        return false;
    }
};

/**
 * Returns exchange if there is only one exchange in the data
 * Otherwise returns undefined
 */
export const getOnlyOneExchangeName = (exchangesData: Exchanges): string | void => {
    if (!exchangesData) {
        return undefined;
    }
    const scopesList = get(exchangesData, 'scopes');
    const firstScopeExchanges = get(scopesList, '[0].exchanges');
    if (size(firstScopeExchanges) === 1) {
        return get(firstScopeExchanges, '[0].short_name');
    }
    return undefined;
};

export const getIpAddresses = memoizeOne((link: LinkFull): string[] => {
    const ips: string[] = reduce(link.vlans, (result: string[], vlan: Vlan) => {
        const routerIps = map(vlan.routers, 'ip');
        return [...result, ...routerIps];
    }, []);
    return uniq(ips);
});

export const getAsn = memoizeOne((link: LinkFull): number | undefined => (
    get(link, 'vlans[0].routers[0].asn_number'))
);

export const isAsn = (value: string | number): boolean => (
    !isNaN(toNumber(value))
);
