import React, { FunctionComponent } from 'react';

import { getStatusColor } from 'utils/formatUtils';

import { LargeBadge } from 'components/styled';

interface Props {
    status: string;
    className?: string;
}

const ResourceStatus: FunctionComponent<Props> = ({ status, className }) => {
    return (
        <LargeBadge
            color={getStatusColor(status)}
            className={`capitilize-first-letter ${className}`}
        >
            {status.replace(/_/g, ' ')}
        </LargeBadge>
    );
};

export default ResourceStatus;
