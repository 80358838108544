import { Formik } from 'formik';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { ApiDataRequest } from 'react-api-data';

import BaseModal, { BaseModalState } from './BaseModal';
import { formatRequestErrors } from 'utils/formatUtils';

export type FormStatus = 'success' | 'error' | undefined;

interface DefaultReturnData {
    formStatus: FormStatus;
}

export interface CloseModalReturnData extends DefaultReturnData {
    [key: string]: any;
}

export interface BaseFormModalProps {
    onClosed?: (data: CloseModalReturnData) => void;
}

export interface BaseFormModalSettings {
    closeAfterSuccess?: boolean; // Set true if you want to close modal after successful request
    resetFormOnClose?: boolean; // Set true if you want to reset form data after modal close
}

interface FormSubmissionOptions {
    pathToError?: string;
    errorMessage?: string;
    successMessage?: string; // Message that is displayed in toast after success
}

const defaultFormModalSettings: BaseFormModalSettings = {
    closeAfterSuccess: true,
    resetFormOnClose: true,
};

class BaseFormModal<T extends BaseFormModalProps, S extends BaseModalState = BaseModalState> extends BaseModal<T, S> {
    // define in the child
    protected formik: any;
    protected afterSuccessCallback?: any;
    protected settings: BaseFormModalSettings = defaultFormModalSettings;

    constructor(props: any) {
        super(props);
        this.settings = { ...defaultFormModalSettings, ...this.settings };
    }

    protected handleModalClose = (): void => {
        if (isFunction(this.props.onClosed)) {
            this.props.onClosed(this.returnModalOnCloseArgs());
        }
        if (this.settings.resetFormOnClose && isFunction(get(this, 'formik.resetForm'))) {
            (this.formik as Formik).resetForm();
        }
    }

    /**
     * Returns any data, that is being passed to the props.onClosed function
     */
    protected returnModalOnCloseArgs(): any {
        return {
            formStatus: get(this, 'formik.state.status')
        };
    }

    /**
     * Common request submission handling
     * Show toast after success and close modal
     * Show toast error after fail
     * Close modal after success, if closeAfterSuccess is true
     * Call afterSuccessCallback funciton if it is defined
     */
    protected handleFormSubmission(
        prevPropsSubmission: ApiDataRequest | undefined, submission: ApiDataRequest | undefined, options?: FormSubmissionOptions
    ): void {
        if (!prevPropsSubmission || !submission || !submission.response || submission === prevPropsSubmission) {
            return;
        }
        if (submission.networkStatus === 'success') {
            if (this.formik) {
                this.formik.setStatus('success');
                this.formik.setSubmitting(false);
            }
            if (options && options.successMessage) {
                toast.success(options.successMessage);
            }
            if (isFunction(this.afterSuccessCallback)) {
                this.afterSuccessCallback();
            }
            if (this.settings.closeAfterSuccess) {
                this.toggle();
            }
        } else if (submission.networkStatus === 'failed') {
            if (this.formik) {
                this.formik.setStatus('error');
                this.formik.setSubmitting(false);
            }
            this.showErrorMessage(submission, options);
        }
    }

    protected showErrorMessage(request: ApiDataRequest, options?: { pathToError?: string, errorMessage?: string }) {
        if (!request || get(request, 'response.status') === 500) {
            // don't show this pop-up because we have standard message for 500 errors
            return;
        }
        const errors = formatRequestErrors(request, get(options, 'pathToError'));
        if (errors) {
            const message = get(options, 'errorMessage');
            toast.error(`${message ? message : ''} ${errors}`.trim());
        }
    }
}

export default BaseFormModal;
