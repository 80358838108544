import React, { FunctionComponent } from 'react';
import { compose } from 'recompose';
import { ApiDataBinding, withApiData } from 'react-api-data';
import get from 'lodash/get';
import size from 'lodash/size';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { User } from 'constants/api';
import { defaultPaginationOptions } from 'constants/pagination';

// components
import { StyledTable } from 'components/styled';
import InteractiveTable from 'components/utils/InteractiveTable';
import AlertMessage from 'components/utils/AlertMessage';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';

// hocs
import { Roles } from '../../constants/literals';
import { getOrganisationUsersTableColumns } from 'constants/columns/organisationUsersTableColumns';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { debounce, filter } from 'lodash';
import { updateAppPathAction } from 'redux/app/appActions';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import memoizeOne from 'memoize-one';

interface InProps {
    organisationUuid: string;
}
interface ApiProps {
    organisationUsersRequest: ApiDataBinding<User[]>;
}

interface EnhanceProps {
    userRoles: Roles[];
}
interface ConnectProps {
    searchTerm: string;
    updateSearchTerm: (value: string) => void;
}

type Props = InProps & ConnectProps & ApiProps & EnhanceProps & WithTranslation;

const enhance = compose<Props, InProps>(
    connect(
        (state: ReduxState) => ({
            searchTerm: get(state, 'app.organisationUsers.searchTerm', ''),
        }),
        (dispatch) => ({
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('organisationUsers.searchTerm', searchTerm));
            }, 500),
        })
    ),
    withApiData({
        organisationUsersRequest: 'getOrganisationUsers',
    },
    (ownProps: InProps) => ({
        organisationUsersRequest: {
            organisationUuid: ownProps.organisationUuid,
        }
    })),
    withTranslation()
);

const filterSearchData = memoizeOne((data: User[], searchTerm: string): User[] => {
    if (!searchTerm) {
        return data;
    }
    
    return filter(data, (row: User) => {
        return (
            `${row.username} ${row.email}`
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) !== -1
        );
    });
});


const OrganisationUsersListContainer: FunctionComponent<Props> = ({
    organisationUsersRequest,
    t,
    organisationUuid,
    searchTerm,
    updateSearchTerm,
    userRoles,
}) => (
    <Card className="card-accent-primary">
        <CardHeader>
            <Row className="justify-content-left">
                <Col xl={3}>
                    <Input
                        data-test="search-term-control"
                        className="form-control"
                        name="searchTerm"
                        defaultValue={searchTerm}
                        placeholder={t('table.searchInputPlaceholder')}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            updateSearchTerm(event.currentTarget.value);
                        }}
                    />
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <RequestStatusRenderer
                request={organisationUsersRequest}
                failedMessage={t('contract.noUsers')}
                success={() => {
                    const users = get(organisationUsersRequest, 'data.data.users');

                    if (!size(users)) {
                        return <AlertMessage data-test="no-users-message" message={t('contract.noUsers')} />;
                    }
                    const organisationUsersColumns = getOrganisationUsersTableColumns(
                        organisationUuid,
                        userRoles,
                        organisationUsersRequest.invalidateCache
                    );
                    return (
                        <OrganisationUsersList
                            data-test="contract-users-list"
                            users={users}
                            searchTerm={searchTerm}
                            organisationUuid={organisationUuid}
                            tableColumns={organisationUsersColumns}
                            tablePagination={{
                                ...defaultPaginationOptions,
                                sizePerPage: 5,
                                paginationSize: 5,
                                hideSizePerPage: true,
                            }}
                        />
                    );
                }}
            />
        </CardBody>
    </Card>
);

interface OrganisationUsersListProps {
    users: User[];
    searchTerm: string;
    tableColumns: any[];
    organisationUuid: string;
    tablePagination: {[key: string]: any};
}

export const OrganisationUsersList: FunctionComponent<OrganisationUsersListProps> = ({ users, searchTerm, tableColumns, tablePagination }) => {
    return (
        <StyledTable className="animated fadeIn">
            <InteractiveTable
                keyField="username"
                columns={tableColumns}
                data={filterSearchData(users, searchTerm)}
                defaultSorted={[{
                    dataField: 'username',
                    order: 'asc',
                }]}
                paginationOptions={tablePagination}
            />
       </StyledTable>
    );
};

export default enhance(OrganisationUsersListContainer);
