import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import memoizeOne from 'memoize-one';

// utils
import { getStatusColor } from 'utils/formatUtils';
import { getLinkToVlan} from 'utils/linksUtils';
import i18next from 'locales/i18next';
import VlanDeleteModal from 'components/modals/VlanDeleteModal';

import { PublicVlanItem } from 'constants/api';
import { VlanStatus } from 'constants/literals';

export const getVlansTableColumns = memoizeOne((): any => {
    return [{
        dataField: 'status',
        text: i18next.t('common.status'),
        sort: true,
        formatter: (cell?: string) => {
            return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
        }
    }, {
        dataField: 'description',
        text: i18next.t('vlans.vlanDescription'),
        sort: true,
    }, {
        dataField: 'use',
        text: i18next.t('vlans.vlanUse'),
        sort: true,
    },{
        dataField: 'lan_type',
        text: i18next.t('vlans.lanType'),
        sort: true,
    },{
        dataField: 'number',
        text: i18next.t('vlans.vlanNumber'),
        sort: true,
    }, {
        dataField: 'ip_ranges',
        text: i18next.t('vlans.vlanIpRanges'),
        sort: true,
        formatter: (cell: PublicVlanItem['ip_ranges']) => {
            return cell.map(ipRange => `${ipRange.value}/${ipRange.netmask}`).join(' ');
        }
    }, {
        dataField: 'link',
        isDummyField: true,
        text: 'Link',
        formatter: (cell: any, row: PublicVlanItem) => (
            !row.exchange ? null : <Link to={getLinkToVlan(row.exchange, row.uuid)}>{i18next.t('common.view')}</Link>
        )
    }, {
        dataField: 'remove',
        isDummyField: true,
        text: 'Remove',
        formatter: (cell: any, row: PublicVlanItem) => {
            return ![VlanStatus.unconfigured, VlanStatus.deconfigured].includes(row.status) ? null : (
                <VlanDeleteModal
                    data-test="user-delete-modal"
                    exchange={row.exchange}
                    uuid={row.uuid}
                    description={row.description}
                    number={row.number}
                />
            );
        },
    }];
});
