import React, { FunctionComponent, ReactNode } from 'react';
import { Alert } from 'reactstrap';
import classnames from 'classnames';

interface Props {
    message: string | ReactNode;
    color?: string;
    className?: string;
}

const AlertMessage: FunctionComponent<Props> = ({ color = 'none', message, className }) => (
    <Alert color={color} className={classnames(['text-center font-italic', className])}>{message}</Alert>
);

export default AlertMessage;