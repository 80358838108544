import React, { FunctionComponent } from 'react';

import { Roles } from 'constants/literals';
import { isEditBtnShown } from 'utils/linksUtils';
import withUserRoles from 'hocs/withUserRoles';

interface InProps {
    resourceStatus: string;
    roles?: Roles[];
    anyStatus?: boolean;
}

interface ConnectProps {
    userRoles: Roles[];
}

type Props = InProps & ConnectProps;

/**
 * Renders children according to the resource status and user roles
 */
const EditBtnConditionalRenderer: FunctionComponent<Props> = ({ resourceStatus, userRoles, roles, anyStatus, children }) => (
    isEditBtnShown(resourceStatus, userRoles, roles, anyStatus) ? <>{children}</> : null
);

export default withUserRoles<InProps>(EditBtnConditionalRenderer);
