import { compose } from 'recompose';
import { connect } from 'react-redux';
import { performApiRequest } from 'react-api-data/lib';

import ResourceTransitions, { CommonInProps, TransitionItemProp, DispatchProps } from './ResourceTransitions';
import { ResourceType, TransitionsType } from 'constants/literals';

interface LinkInProps extends CommonInProps {
    linkId: string;
    events: TransitionsType[];
}

const linkMapStateToProps = (dispatch: any, ownProps: LinkInProps) => ({
    onConfirm: (transitionItem: TransitionItemProp) => {
        if (!transitionItem.endpointKey) {
            console.warn(`Endpoint is not set for ${transitionItem.transitionType}!`);
            return;
        }
        const requestParams: any = {
            resourceId: ownProps.resourceId,
            resourceName: ResourceType.link,
            exchange: ownProps.exchange,
            linkId: ownProps.linkId
        };

        dispatch(performApiRequest(transitionItem.endpointKey, requestParams));
    }
});

export default compose<LinkInProps & DispatchProps, LinkInProps>(
    connect(null, linkMapStateToProps),
)(ResourceTransitions);