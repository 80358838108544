import React, { FunctionComponent } from 'react';
import { ApiDataRequest } from 'react-api-data/lib';
import get from 'lodash/get';
import max from 'lodash/max';
import min from 'lodash/min';
import mean from 'lodash/mean';
import find from 'lodash/find';
import round from 'lodash/round';
import size from 'lodash/size';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Options } from 'highcharts';
import { Card, CardBody, Table } from 'reactstrap';
import i18n from 'locales/i18next';

import { StatsInterval, StatsCounterType, SflowStatsType } from 'constants/literals';

import Graph from './Graph';
import { CenteredAlert } from 'components/styled';
import CenteredSpinner from '../utils/CenteredSpinner';
import RequestStatusRenderer from '../utils/RequestStatusRenderer';

export interface SflowGraphInProps {
    interval: StatsInterval;
    counter: StatsCounterType;
    sflowType: SflowStatsType;
    height?: number;
    simple?: boolean;
    displayLegend?: boolean;
}

type Props = SflowGraphInProps & WithTranslation & {
    statsRequest: ApiDataRequest;
};

interface StatisticsLegendInProps {
    request: ApiDataRequest;
    interval: StatsInterval;
    counter: StatsCounterType;
    t: i18n.TFunction;
}

const stackingGraphOptions: Options = {
    plotOptions: {
        areaspline: {
            stacking: 'percent',
        }
    }
};

const isEtherOrFrame = (sflowType: SflowStatsType): boolean => sflowType === SflowStatsType.framesize || sflowType === SflowStatsType.ethertype;

const renderLegendValue = (value: number | undefined, counter: StatsCounterType) => <>{value ? round(value, 1) : 0} {counter}</>;

const StatisticsLegend: FunctionComponent<StatisticsLegendInProps> = React.memo(({ request, interval, counter, t }) => {
    if (get(request, 'networkStatus') !== 'success') {
        return null;
    }
    const metaData = get(request, `result.data.sflow.${interval}.meta_data`);
    const seriesData = get(request, `result.data.sflow.${interval}.data`);
    if (!metaData || !size(seriesData)) {
        return null;
    }
    const endSerie = find(seriesData, ['time', +metaData.end]);
    const legendKeys = Object.keys(metaData.legend);

    return (
        <Card className="card-accent-primary">
            <CardBody>
                <Table className="mb-0">
                    <tbody>
                        <tr>
                            <th className="border-top-0">&nbsp;</th>
                            <th className="border-top-0">Cur</th>
                            <th className="border-top-0">Avg</th>
                            <th className="border-top-0">Max</th>
                            <th className="border-top-0">Min</th>
                        </tr>
                        {legendKeys.map((legendKey: string) => {
                            const series = seriesData.map((dataItem: any) => get(dataItem, legendKey));
                            const maxValue: number | undefined = max(series);
                            const minValue: number | undefined = min(series);
                            const avgValue = mean(series);
                            const curValue = get(endSerie, legendKey);
                            const legendKeyLabel = get(metaData, `legend.${legendKey}.long`);
                            return (
                                <tr key={legendKey}>
                                    <th>{legendKeyLabel}</th>
                                    <td>{renderLegendValue(curValue, counter)}</td>
                                    <td>{renderLegendValue(avgValue, counter)}</td>
                                    <td>{renderLegendValue(maxValue, counter)}</td>
                                    <td>{renderLegendValue(minValue, counter)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
});

const ErrorAlert: FunctionComponent<{ height: number, t: i18n.TFunction }> = ({ height, t }) => (
    <CenteredAlert color="none" style={{ height }} className="text-danger">
        {t('stats.errorLoading')}
    </CenteredAlert>
);

const SflowGraph: FunctionComponent<Props> = ({
    statsRequest, height = 400, interval, counter, sflowType, simple, t, displayLegend = true
}) => (
    <RequestStatusRenderer
        request={statsRequest}
        failed={<ErrorAlert height={height} t={t} />}
        loading={
            <CenteredAlert color="none" style={{ height, margin: 0 }}>
                <CenteredSpinner />
            </CenteredAlert>
        }
        defaultComponent={<CenteredAlert color="none" style={{ height }}>{t('common.noData')}</CenteredAlert>}
        success={() => {
            const responseData = get(statsRequest, `result.data.sflow.${interval}`);
            if (!responseData) {
                return <ErrorAlert height={height} t={t} />;
            }
            return (
                <>
                    <Graph
                        data={responseData}
                        height={height}
                        counter={isEtherOrFrame(sflowType) ? StatsCounterType.percent : counter}
                        simple={simple}
                        noDataMessage={t('common.noData')}
                        seriesType={isEtherOrFrame(sflowType) ? 'areaspline' : undefined}
                        graphOptions={isEtherOrFrame(sflowType) ? stackingGraphOptions : undefined}
                        interval={interval}
                    />
                    {displayLegend &&
                        <StatisticsLegend
                            request={statsRequest}
                            interval={interval}
                            counter={isEtherOrFrame(sflowType) ? StatsCounterType.percent : counter}
                            t={t}
                        />
                    }
                </>
            );
        }}
    />
);

export default withTranslation()(React.memo(SflowGraph, (prevProps, nextProps) => {
    if (prevProps.statsRequest !== nextProps.statsRequest) {
        return false;
    }
    if (prevProps.t !== nextProps.t) {
        return false;
    }
    return true;
}));
