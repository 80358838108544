import React, { useMemo, useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { paginationTotalRenderer, columnsFactory, StyleContainer } from './InteractiveTable';
import { CenteredAlert } from 'components/styled';
import { useTranslation } from 'react-i18next';

type TableChangeEventType = 'filter' | 'pagination' | 'sort' | 'cellEdit';
type SortOrder = 'asc' | 'desc';

interface TableState {
    page: number;
    sizePerPage: number;
    sortField: string;
    sortOrder: SortOrder;
}

interface Props {
    keyField: string;
    data: object[];
    columns: object[];
    paginationOptions: {
        page: number;
        sizePerPage: number;
        totalSize: number;
    };
    defaultSorted: {
        dataField: string;
        order: SortOrder;
    };
    loading: boolean;
    noPadding?: boolean;
    fetchPage: (pageNumber: number, pageSize: number, sortField: string, sortOrder: string) => void;
}

const defaultPaginationOptions = {
    hideSizePerPage: true,
    showTotal: true,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    paginationTotalRenderer,
};

function useDefferedValues(loading: boolean, ...values: any[]) {
    const [defferedValues, setDefferedValues] = useState(values);

    useEffect(() => {
        if (!loading) {
            setDefferedValues(values);
        }
    }, [...values, loading, setDefferedValues]);

    return defferedValues;
}

function InteractiveRemoteTable(props: Props) {
    const { keyField, data, columns, paginationOptions, defaultSorted, loading, noPadding = false, fetchPage } = props;
    const { t } = useTranslation();
    const processedColumns = useMemo(() => columnsFactory(columns), [columns]);
    const [defferedData, defferedPaginationOptions] = useDefferedValues(loading, data, paginationOptions);
    const pagination = useMemo(() => {
        const options = {
            ...defaultPaginationOptions,
            ...defferedPaginationOptions,
            currPage: defferedPaginationOptions.page,
            currSizePerPage: defferedPaginationOptions.sizePerPage
        }
        if (defferedData.length === 0) {
            options.showTotal = false;
        }
        return paginationFactory(options)
    }, [defferedPaginationOptions, defferedData]);

    function onTableChange(eventType: TableChangeEventType, newState: TableState) {
        if (eventType === 'pagination') {
            fetchPage(newState.page, newState.sizePerPage, newState.sortField, newState.sortOrder);
        } else if (eventType === 'sort') {
            fetchPage(1, newState.sizePerPage, newState.sortField, newState.sortOrder);
        }
    }

    return (
        <StyleContainer addPadding={!noPadding}>
            <BootstrapTable
                remote
                bootstrap4
                bordered={false}
                hover
                keyField={keyField}
                data={defferedData}
                columns={processedColumns}
                onTableChange={onTableChange}
                pagination={pagination}
                defaultSorted={[defaultSorted]}
                loading={loading}
                overlay={overlayFactory({ styles: { overlay: (base: any) => ({ ...base, background: 'rgba(255, 255, 255, 0.8)' }) } })}
                noDataIndication={() => (<CenteredAlert color="none">{t('common.noData')}</CenteredAlert>)}
            />
        </StyleContainer>
    );
}

export default InteractiveRemoteTable;
