import React from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import get from 'lodash/get';

// constants
import { User } from 'constants/api';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';

// components
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import Tooltip from 'components/utils/Tooltip';

interface InputProps {
    user: User;
    invalidateUsersCache: () => void;
}

interface EnhanceProps {
    disableMfa: ApiDataBinding<any>;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const connectApiData = withApiData(
    {
        disableMfa: 'disableMfaForUser',
    },
    (ownProps) => ({
        disableMfa: {
            id: ownProps.user.uuid,
        },
    })
);

const enhance = compose<EnhanceProps, InputProps>(connectApiData, withTranslation());

class UserDisableMFAModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { disableMfa, t } = this.props;
        this.handleFormSubmission(prevProps.disableMfa.request, disableMfa.request, {
            errorMessage: t('notification.disableMfaForUserFailed'),
            successMessage: t('notification.disableMfaForUserSuccessful'),
        });
    }

    _submit = async () => {
        const { disableMfa, invalidateUsersCache } = this.props;

        await disableMfa.perform();
        invalidateUsersCache();
    };

    render(): any {
        const { disableMfa, user, t } = this.props;
        const username = get(user, 'email');

        return (
            <React.Fragment>
                <BaseModalTemplate
                    data-test="user-disable-mfa-modal-tmpl"
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('disableMfaForUserModal.headerTitle')}
                    body={
                        <Trans i18nKey="disableMfaForUserModal.bodyMessage">
                            <strong>{{ username }}</strong>
                        </Trans>
                    }
                    footer={
                        <FormModalButtons
                            data-test="user-disable-mfa-modal-buttons"
                            loading={get(disableMfa.request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={this._submit}
                        />
                    }
                />

                <Tooltip
                    data-test="user-disable-mfa-btn"
                    triggerBtn={
                        <p style={{ textAlign: 'center' }}>
                            <i className="fas fa-lock mt-1 cursor-pointer text-primary" onClick={this.toggle} />
                        </p>
                    }
                >
                    {t('disableMfaForUserModal.headerTitle')}
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default enhance(UserDisableMFAModal);
