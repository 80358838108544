import React from 'react';
import { Badge } from 'reactstrap';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { Link } from 'react-router-dom';
import { getLinkToUser } from 'utils/linksUtils';

import i18next from 'locales/i18next';
import { User } from 'constants/api';
import { Roles } from '../literals';

export const getOrganisationUsersTableColumns = memoizeOne((organisationUuid: string, userRoles: Roles[], invalidateUsersCache: () => void): any => {

    const defaultColumns = [{
        dataField: 'active',
        text: i18next.t('common.active'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (active: boolean) => {
            return <Badge color={active ? 'success' : 'danger'}>{active ? 'Active' : 'Inactive'}</Badge>;
        }
    }, {
        dataField: 'username',
        text: i18next.t('common.userName'),
        sort: true,
        headerClasses: 'align-middle',
    }, {
        dataField: 'email',
        text: i18next.t('common.email'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (email: string) => <a href={`mailto:${email}`}>{email}</a>
    }, {
        dataField: 'roles',
        text: i18next.t('userDetails.roles'),
        headerClasses: 'align-middle',
        sort: true,
        formatter: (cell: User['roles']) => {
            return cell.map(role => role).join(', ');
        }
    }, {
        dataField: 'last_logged_in',
        text: i18next.t('contract.lastLoggedIn'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (lastLoggedIn: string) => {
            if (!lastLoggedIn) {
                return '-';
            }
            return moment(lastLoggedIn).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'link',
        isDummyField: true,
        text: 'Link',
        formatter: (cell: any, row: User) => (
            <Link to={getLinkToUser(row.uuid)}>{i18next.t('common.view')}</Link>
        ),
    }];

    return defaultColumns;
});

const formatFullName = (row: User) => `${row.first_name || ''} ${row.last_name || ''}` || '-';
