import {
    ApiDataEndpointConfig,
    ApiDataGlobalConfig,
    getResultData,
    purgeApiData,
    ApiDataConfigAfterProps
} from 'react-api-data/lib';
import { toast } from 'react-toastify';
import { schema } from 'normalizr';
import get from 'lodash/get';
import pick from 'lodash/pick';
import forOwn from 'lodash/forOwn';

import { AuthenticationToken, LinkFull } from './api';
import { ReduxState } from 'createStore';
import browserHistory from 'createBrowserHistory';
import {
    refetchMemberLink,
    updateLinkEntity,
    updateMemberLink,
    getLinkSchemaId
} from 'utils/linksUtils';
import { getBaseRoutePath } from 'utils/commonUtils';
import { handleResourceTransitionSuccess } from 'utils/apiUtils';
import { isCustomer } from 'utils/userUtils';
import { updateAppPathAction } from 'redux/app/appActions';
import i18next from 'locales/i18next';
import CACHE_CONFIG from 'constants/cacheConfig';
import { getUserRolesSelector } from 'redux/apiData/apiDataSelectors';
import { formatRequestErrors } from 'utils/formatUtils';
import { updateVlanAction } from 'redux/apiData/apiDataActions';

const BASE_URL = process.env.REACT_APP_API_URL || '';
const API_URL = BASE_URL + '/api/v1';
const API_V2_URL = BASE_URL + '/api/v2';

function shouldRedirectToLoginPage(endpointKey: string) {
    return !['postLoginInfo', 'otpConfirm', 'disableMfa', 'putCompleteRegistration'].includes(endpointKey);
}

function addDebugModeToRequestProperties(defaultProperties: any, state: any) {
    const debugProvisioningMode = state.app.provisioning ? state.app.provisioning.debugProvisioningMode : false;
    if (debugProvisioningMode) {
        if ('body' in defaultProperties) {
            if (defaultProperties.body !== undefined) {
                defaultProperties.body.debug_provisioning_mode = debugProvisioningMode;
            } else {
                defaultProperties.body = { debug_provisioning_mode: debugProvisioningMode };
            }
        }
    }
    return defaultProperties;
}
// normalizr Schemas
export const locationSchema = new schema.Entity('location', {}, { idAttribute: 'uuid' });
export const exchangeSchema = new schema.Entity('exchange', { locations: [locationSchema] }, { idAttribute: 'short_name' });
export const scopeSchema = new schema.Entity('scope', { exchanges: [exchangeSchema] }, { idAttribute: 'name' });
export const exchangesResponseSchema = new schema.Entity('exchangesResponse', { scopes: [scopeSchema] }, { idAttribute: () => 'dummy' });
export const linkSchema = new schema.Entity('link', {}, { idAttribute: (obj) => getLinkSchemaId(obj.exchange.short_name, obj.uuid) });

export const globalConfig: ApiDataGlobalConfig = {
    setHeaders: (defaultHeaders: object, state: ReduxState) => {
        const headers: any = {
            ...defaultHeaders,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (state.app.authenticationToken) {
            headers.Authorization = `${state.app.authenticationToken.token_type} ${state.app.authenticationToken.access_token}`;
        }

        return headers;
    },
    afterSuccess: ({ request, dispatch, getState, endpointKey }) => {
        const endpointItemConfig = endPointConfig[endpointKey];
        if (get(endpointItemConfig, 'refetchLinkAfterSuccess')) {
            refetchMemberLink(dispatch, get(request, 'params'));
        }
        if (get(endpointItemConfig, 'resourceTransition')) {
            const successMessage = get(endpointItemConfig, 'resourceTransition[0]');
            handleResourceTransitionSuccess(request, dispatch, getState, successMessage);
        }
        if (get(endpointItemConfig, 'removeDebugProvisioning')) {
            handleDebugMode(dispatch);
        }
    },
    afterFailed: ({ endpointKey, request, requestBody, resultData, dispatch, getState }) => {
        const responseStatus = get(request, 'response.status');
        const endpointItemConfig = endPointConfig[endpointKey];
        if (responseStatus === 403) {

            const userRoles = getUserRolesSelector(getState() as ReduxState);
            if (!isCustomer(userRoles)) {
                toast.error(i18next.t('notification.accessDenied'));
            }
        } else if (responseStatus === 401 && shouldRedirectToLoginPage(endpointKey)) {
            browserHistory.push(`${getBaseRoutePath()}/login`);
            dispatch(updateAppPathAction('loginRedirect', window.location.pathname));
            dispatch(updateAppPathAction('authenticationToken', undefined));
            // clear requests cache after logout
            dispatch(purgeApiData());
            toast.warn(i18next.t('notification.sessionExpired'), { toastId: 'sessionExpired' });
        } else if (responseStatus >= 500) {
            toast.error(i18next.t('notification.error500'));
        } else if (get(endPointConfig[endpointKey], 'resourceTransition')) {
            const failedMessage = get(endPointConfig[endpointKey], 'resourceTransition[1]');
            toast.error(`${i18next.t(failedMessage)}: ${get(request.errorBody, 'message')}`);
        }
        if (get(endpointItemConfig, 'removeDebugProvisioning')) {
            handleDebugMode(dispatch);
        }
    },
};

interface EndpointConfig extends ApiDataEndpointConfig {
    refetchLinkAfterSuccess?: boolean;
    removeDebugProvisioning?: boolean;
    // if endpoint updates resource transition
    // We need to display success or error message after getting response
    // And update connection data
    resourceTransition?: [string, string]; // [pathToSuccessMessage, pathToErrorMessage]
}

const endPointConfig: { [endpointKey: string]: EndpointConfig; } = {
    getExternalURL: {
        url: ':url',
        method: 'GET',
    },
    getMemberLink: {
        url: `${API_URL}/links/:linkId?exchange=:exchange`,
        method: 'GET',
        afterSuccess: ({ request, dispatch, getState }) => {
            const params = get(request, 'params');
            const link: LinkFull = getResultData(getState().apiData, 'getMemberLink', params);
            if (link) {
                const history = getState().app.linkHistory.filter((elem: LinkFull) => elem.uuid !== link.uuid);
                history.push(link);
                dispatch(updateAppPathAction('linkHistory', history.slice(-10)));

                // update link entity in the Global search
                updateLinkEntity(link, dispatch, getState, params);
            }
        }
    },
    // when update getAllMemberLinks.url, update it in the apiDataReducer
    getAllMemberLinks: {
        url: `${API_URL}/links_compact?exchange=:exchange`,
        method: 'GET',
    },
    getAllLinks: {
        url: `${API_URL}/links_compact`,
        method: 'GET',
        autoTrigger: false
    },
    getSflowURL: {
        url: `${API_URL}/ips/sflow_url/?exchange=:exchange&ip=:ip`,
        method: 'GET',
    },
    getAllExchanges: {
        url: `${API_URL}/exchanges`,
        method: 'GET',
        responseSchema: exchangesResponseSchema,
    },
    getExchange: {
        url: `${API_URL}/exchanges/:uuid`,
        method: 'GET'
    },
    getDataCenters: {
        url: `${API_URL}/locations`,
        method: 'GET'
    },
    getDataCenter: {
        url: `${API_URL}/locations/:uuid`,
        method: 'GET'
    },
    getPort: {
        url: `${API_URL}/link_ports/:portId?exchange=:exchange`,
        method: 'GET',
    },
    getRouterPeerings: {
        url: `${API_URL}/routers/:routerId/peerings/router_peerings?exchange=:exchange&peering_with=:peeringWith`,
        method: 'GET',
    },
    getLinkStats: {
        url: `${API_URL}/links/:linkId/stats?exchange=:exchange&type=data&periods=:interval`,
        method: 'GET',
    },
    getNativeRouters: {
        url: `${API_URL}/native_routers/?exchange=:exchange`,
        method: 'GET'
    },
    getNativeRouter: {
        url: `${API_URL}/native_routers/:nativeRouterUuid`,
        method: 'GET'
    },
    putNativeRouter: {
        url: `${API_URL}/native_routers/:nativeRouterUuid`,
        method: 'PUT',
    },
    postNativeRouter: {
        url: `${API_URL}/native_routers?exchange=:exchange`,
        method: 'POST'
    },
    postExchange: {
        url: `${API_URL}/exchanges`,
        method: 'POST'
    },
    putExchange: {
        url: `${API_URL}/exchanges/:exchangeUuid`,
        method: 'PUT'
    },
    putDataCenter: {
        url: `${API_URL}/locations/:locationUuid`,
        method: 'PUT'
    },
    postDataCenter: {
        url: `${API_URL}/locations?exchange=:exchange`,
        method: 'POST'
    },
    getRouteServers: {
        url: `${API_URL}/route_servers?exchange=:exchange`,
        method: 'GET',
    },
    getRouteServer: {
        url: `${API_URL}/route_servers/:routeServerUuid`,
        method: 'GET'
    },
    putRouteServer: {
        url: `${API_URL}/route_servers/:routeServerUuid`,
        method: 'PUT',
    },
    postRouteServer: {
        url: `${API_URL}/route_servers?exchange=:exchange`,
        method: 'POST'
    },
    getRouters: {
        url: `${API_URL}/routers?asn_number=:asnNumber&vlan_number=:vlanNumber&organisation_id=:organisationId&exchange=:exchange&bgp_router_uuid=:bgpRouterUuid`,
        method: 'GET',
    },
    getSwitches: {
        url: `${API_URL}/switches/?exchange=:exchange`,
        method: 'GET',
    },
    getSwitch: {
        url: `${API_URL}/switches/:name`,
        method: 'GET'
    },
    getVlans: {
        url: `${API_URL}/vlans?exchange=:exchange`,
        method: 'GET'
    },
    getIpRanges: {
        url: `${API_URL}/ip_ranges?exchange=:exchange&new_format=true`,
        method: 'GET',
    },
    postIpRange: {
        url: `${API_URL}/ip_ranges?exchange=:exchange`,
        method: 'POST'
    },
    putIpRange: {
        url: `${API_URL}/ip_ranges/:id`,
        method: 'PUT'
    },
    deleteIpRange: {
        url: `${API_URL}/ip_ranges/:id`,
        method: 'DELETE'
    },
    getIpRange: {
        url: `${API_URL}/ip_ranges/:id`,
        method: 'GET',
    },
    getIps: {
        url: `${API_URL}/ip_ranges/:id/ips`,
        method: 'GET',
        autoTrigger: false,
    },
    getVlan: {
        url: `${API_URL}/vlans/:vlanUuid`,
        method: 'GET',
    },
    getLinkVlan: {
        url: `${API_URL}/link_vlans/:linkVlanUuid`,
        method: 'GET',
    },
    putLinkVlanMoveToQuarantine: {
        url: `${API_URL}/link_vlans/:uuid/move_to_quarantine`,
        method: 'PUT',
    },
    putLinkVlanMoveToProduction: {
        url: `${API_URL}/link_vlans/:uuid/move_to_production`,
        method: 'PUT',
    },
    getIpv4: {
        url: `${API_URL}/ips/:id`,
        method: 'GET',
        autoTrigger: false
    },
    getFreeIpv4: {
        url: `${API_URL}/ips/free_ipv4_list?vlan=:vlan&exchange=:exchange`,
        method: 'GET',
    },
    getFreeIpv6: {
        url: `${API_URL}/ips/get_free_ipv6?vlan=:vlan&asn=:asn&exchange=:exchange`,
        method: 'GET',
        autoTrigger: false
    },
    getVlanLinks: {
        url: `${API_URL}/vlans/:vlanUuid/links`,
        method: 'GET',
    },
    getBanner: {
        url: `${API_URL}/banner`,
        method: 'GET',
    },
    putBanner: {
        url: `${API_URL}/banner`,
        method: 'PUT',
        afterSuccess: handleBannerResponse
    },
    postVlan: {
        url: `${API_URL}/vlans`,
        method: 'POST',
        afterSuccess: invalidateVlansList,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putVlan: {
        url: `${API_URL}/vlans/:vlanUuid`,
        method: 'PUT',
        afterSuccess: handleVlanResponse,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putVlanConfigure: {
        url: `${API_URL}/vlans/:vlanUuid/configure`,
        method: 'PUT',
        afterSuccess: handleVlanResponse,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putVlanConfigurationSuccessful: {
        url: `${API_URL}/vlans/:vlanUuid/configuration_successful`,
        method: 'PUT',
        afterSuccess: handleVlanResponse
    },
    putVlanConfigurationFailed: {
        url: `${API_URL}/vlans/:vlanUuid/configuration_failed`,
        method: 'PUT',
        afterSuccess: handleVlanResponse
    },
    putVlanDeconfigure: {
        url: `${API_URL}/vlans/:vlanUuid/deconfigure`,
        method: 'PUT',
        afterSuccess: handleVlanResponse,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putVlanDeconfigurationSuccessful: {
        url: `${API_URL}/vlans/:vlanUuid/deconfiguration_successful`,
        method: 'PUT',
        afterSuccess: handleVlanResponse
    },
    putVlanDeconfigurationFailed: {
        url: `${API_URL}/vlans/:vlanUuid/deconfiguration_failed`,
        method: 'PUT',
        afterSuccess: handleVlanResponse
    },
    getLinkVlanStats: {
        url: `${API_URL}/link_vlans/:vlanId/stats?exchange=:exchange&type=data&periods=:interval`,
        method: 'GET',
    },
    getStatistics: {
        url: `:baseUrl?target=:target&counter=:counter&interval=:interval&imgformat=json`,
        method: 'GET',
    },
    getLogout: {
        url: `${API_URL}/logout/`,
        method: 'GET',
    },
    getContracts: {
        url: `${API_V2_URL}/contracts?exchange=:exchange`,
        method: 'GET',
        autoTrigger: false
    },
    getContractsCompact: {
        url: `${API_URL}/contracts_compact`,
        method: 'GET',
    },
    getContractLinks: {
        url: `${API_URL}/contracts/:contractUuid/links?exchange=:exchange`,
        method: 'GET',
        autoTrigger: false
    },
    getVirtualLinkVlans: {
        url: `${API_URL}/virtual_link_vlans`,
        method: 'GET',
    },
    getContractVirtualLinkVlans: {
        url: `${API_URL}/contracts/:contractUuid/virtual_link_vlans`,
        method: 'GET',
    },
    getContract: {
        url: `${API_URL}/contracts/:contractUuid?exchange=:exchange`,
        method: 'GET',
    },
    getHomeContract: {
        url: `${API_URL}/contracts/:contractUuid?exchange=:exchange`,
        method: 'GET',
    },
    getContractUsers: {
        url: `${API_URL}/contracts/:contractUuid/users?exchange=:exchange`,
        method: 'GET',
    },
    getContractAccounts: {
        url: `${API_URL}/contracts/:contractUuid/accounts`,
        method: 'GET',
    },
    getOrganisationUsers: {
        url: `${API_URL}/organisations/:organisationUuid/users`,
        method: 'GET',
    },
    getPortInformation: {
        url: `${API_URL}/quick_debug/links/:linkId/ports`,
        method: 'GET',
    },
    getPortConfig: {
        url: `${API_URL}/quick_debug/links/:linkId/ports/port_config`,
        method: 'GET',
    },
    getPortPeering: {
        url: `${API_URL}/quick_debug/links/:linkId/peerings`,
        method: 'GET',
    },
    getFreePorts: {
        url: `${API_URL}/ports/free_ports?exchange=:exchange`,
        method: 'GET'
    },
    getPortDetails: {
        url: `${API_URL}/ports/:portId/port_details`,
        method: 'GET',
        autoTrigger: true
    },
    putPortChangeStatus: {
        url: `${API_URL}/ports/:portId/change_status`,
        method: 'PUT',
        afterSuccess: () => {
            toast.success(i18next.t('notification.portChangeStatusSuccessful'));
        },
        afterFailed: ({ request }) => {
            toast.error(get(request, 'errorBody.message', i18next.t('notification.portChangeStatusFailed')));
        }
    },
    getPortSnmpData: {
        url: `${API_URL}/ports/:portId/snmp_data`,
        method: 'GET',
    },
    getSwitchPorts: {
        url: `${API_URL}/switches/:switchId/ports?exchange=:exchange`,
        method: 'GET',
    },
    getAvailablePorts: {
        url: `${API_URL}/ports/free_ports?exchange=:exchange`,
        method: 'GET',
        autoTrigger: false
    },
    getLinkFlapInformation: {
        url: `${API_URL}/quick_debug/links/:linkId/logs/link_flap?days=:days`,
        method: 'GET',
    },
    getL2Violations: {
        url: `${API_URL}/quick_debug/links/:linkId/logs/l2_violation?days=:days`,
        method: 'GET',
    },
    getOptics: {
        url: `${API_URL}/quick_debug/links/:linkId/logs/optics?days=:days`,
        method: 'GET',
    },
    getPortStats: {
        url: `${API_URL}/link_ports/:portUuid/stats?exchange=:exchange&type=data&periods=:interval`,
        method: 'GET',
    },
    getTotalStats: {
        url: `${API_URL}/stats?exchange=:exchange&aggregate=:aggregate&periods=:interval`,
        method: 'GET',
    },
    getSflowStats: {
        url: `${API_URL}/stats/sflow?exchange=:exchange&periods=:interval&type=:type&counter=:counter&src=:src&dst=:dst`,
        method: 'GET',
    },
    getRankedSflow: {
        url: `${API_URL}/peerings/sflow_tally?exchange=:exchange&period=:interval&counter=:counter&lookup=:lookup&direction=:direction`,
        method: 'GET',
    },
    getSflowAggregatedStats: {
        url: `${API_URL}/stats/sflow?exchange=:exchange&periods=:interval&type=:type`,
        method: 'GET',
    },
    getPeersList: {
        url: `${API_URL}/stats/peers_list?exchange=:exchange&type=:type&src=:src`,
        method: 'GET',
    },
    getLinkStatus: {
        url: `${API_URL}/quick_debug/links/:linkId/status?exchange=:exchange`,
        method: 'GET',
    },
    getPrefixLimit: {
        url: `${API_URL}/quick_debug/ips/:ip/bgp/prefix`,
        method: 'GET',
        afterFailed: () => {
            toast.error(i18next.t('common.errorRetrievingData'));
        }
    },
    getUser: {
        url: `${API_URL}/users/:userId/?exchange=:exchange`,
        method: 'GET',
        afterFailed: ({ request }) => {
            const responseStatus = get(request, 'response.status');
            if (responseStatus !== 401 && responseStatus !== 500) {
                toast.error(i18next.t('notification.getUserRequestFailed'));
            }
        }
    },
    getUserContracts: {
        url: `${API_URL}/users/:userId/contracts`,
        method: 'GET'
    },
    getAsns: {
        url: `${API_URL}/asns?organisation_id=:organisation`,
        method: 'GET',
    },
    getOrganisation: {
        url: `${API_URL}/organisations/:uuid`,
        method: 'GET',
        autoTrigger: false,
    },
    getOrganisationContracts: {
        url: `${API_URL}/organisations/:organisationUuid/contracts`,
        method: 'GET',
    },
    getContractsInOrganisation: {
        url: `${API_URL}/organisations/:organisationUuid/contracts`,
        method: 'GET',
    },
    getOrganisations: {
        url: `${API_URL}/organisations`,
        method: 'GET',
    },
    getAvailableSpeedsAndInterfaceTypes: {
        url: `${API_URL}/speed_and_interface_types`,
        method: 'GET',
    },
    deleteUser: {
        url: `${API_URL}/users/:userId?exchange=:exchange`,
        method: 'DELETE',
        afterSuccess: ({ request, actions }) => {
            const requestParams = pick(get(request, 'params'), ['exchange', 'contractUuid']);
            actions.invalidateCache('getContractUsers', requestParams);
            actions.perform('getContractUsers', requestParams);
        }
    },
    deleteAccount: {
        url: `${API_URL}/accounts/:accountUuid`,
        method: 'DELETE',
        afterSuccess: ({ request, actions }) => {
            const requestParams = pick(get(request, 'params'), ['contractUuid']);
            actions.invalidateCache('getContractAccounts', requestParams);
            actions.perform('getContractAccounts', requestParams);
        }
    },
    postLoginInfo: {
        url: `${BASE_URL}/oauth/token?exchange=${process.env.REACT_APP_EXCHANGE_ID}`,
        method: 'POST',
        afterSuccess: ({ request, dispatch, getState }) => {
            const data: AuthenticationToken = getResultData(getState().apiData, 'postLoginInfo');
            if (get(data, 'access_token')) {
                if (get(data, 'scope') === 'mfa_confirmation') {
                    dispatch(updateAppPathAction('needsOtp', true));
                }

                dispatch(updateAppPathAction('authenticationToken', data));
                const redirectPath = getState().app.loginRedirect || '/';
                browserHistory.push(redirectPath);
                dispatch(updateAppPathAction('loginRedirect', undefined));
            }
        }
    },
    impersonateUser: {
        url: `${API_URL}/users/:userId/impersonate`,
        method: 'POST',
        afterSuccess: ({ dispatch, resultData, getState }) => {
            const currentToken = getState().app.authenticationToken;
            const data: AuthenticationToken = resultData as AuthenticationToken;

            // clear all cached data first
            dispatch(updateAppPathAction('authenticationToken', undefined));
            dispatch(updateAppPathAction('impersonatorToken', undefined));
            dispatch(purgeApiData());

            dispatch(updateAppPathAction('impersonatorToken', currentToken));
            dispatch(updateAppPathAction('authenticationToken', data));
        }
    },
    revokeToken: {
        url: `${BASE_URL}/oauth/revoke`,
        method: 'POST',
        afterFailed: ({ request }) => {
            toast.error(request.errorBody.message);
        }
    },
    otpConfirm: {
        url: `${API_URL}/multi_factor_auth/confirm?exchange=${process.env.REACT_APP_EXCHANGE_ID}`,
        method: 'POST',
        afterSuccess: ({request, dispatch, getState}) => {
            const data: AuthenticationToken = getResultData(getState().apiData, 'otpConfirm');
            const accessToken = get(data, 'access_token');
            const isGeneralAccessToken = get(data, 'scope') === 'general_access';
            if (accessToken && isGeneralAccessToken) {
                dispatch(updateAppPathAction('authenticationToken', data));
                dispatch(updateAppPathAction('needsOtp', false));
            }
        },
        afterFailed: ({request, dispatch}) => {
            toast.error(request.errorBody.message);
        }
    },
    mfaConfirm: {
        url: `${API_URL}/multi_factor_auth/enable?exchange=${process.env.REACT_APP_EXCHANGE_ID}`,
        method: 'PUT',
        afterSuccess: ({ request, dispatch, getState }) => {
            const data: AuthenticationToken = getResultData(getState().apiData, 'otpConfirm');
            const accessToken = get(data, 'access_token');
            const isGeneralAccessToken = get(data, 'scope') === 'general_access';
            if (accessToken && isGeneralAccessToken) {
                dispatch(updateAppPathAction('authenticationToken', data));
            }
        },
        afterFailed: ({ request, actions }) => {
            actions.invalidateCache('mfaConfirm');
            toast.error(request.errorBody.message);
        }
    },
    disableMfa: {
        url: `${API_URL}/multi_factor_auth/disable?exchange=${process.env.REACT_APP_EXCHANGE_ID}`,
        method: 'PUT',
        afterSuccess: ({ request, dispatch, getState }) => {
            dispatch(updateAppPathAction('needsOtp', false));
            dispatch(updateAppPathAction('mfaQrCode', null));
        },
    },
    disableMfaForUser: {
        url: `${API_URL}/multi_factor_auth/disable_for_user?exchange=${process.env.REACT_APP_EXCHANGE_ID}`,
        method: 'PUT',
        autoTrigger: false,
    },
    getMfaQrCode: {
        url: `${API_URL}/multi_factor_auth/provisioning_uri?exchange=${process.env.REACT_APP_EXCHANGE_ID}`,
        method: 'PUT',
        afterSuccess: ({ request, dispatch, getState }) => {
            const data: AuthenticationToken = getResultData(getState().apiData, 'getMfaQrCode');
            const mfaCode = get(data, 'provisioning_uri');
            if (mfaCode) {
                dispatch(updateAppPathAction('mfaQrCode', get(data, 'provisioning_uri')));
                return mfaCode;
            }
        },
        afterFailed: ({ request, dispatch }) => {
            toast.error(request.errorBody.message);
        }
    },
    postResetPassword: {
        url: `${API_URL}/users/reset_password`,
        method: 'POST',
        afterSuccess: ({ request }) => {
            toast.success(i18next.t('notification.resetPasswordRequestSuccessful'));
            browserHistory.push(`${getBaseRoutePath()}/login`);
        },
    },
    putPassword: {
        url: `${API_URL}/users/password`,
        method: 'PUT',
        afterSuccess: ({ request }) => {
            toast.success(i18next.t('notification.changePasswordSuccessful'));
            browserHistory.push(`${getBaseRoutePath()}/login`);
        },
        afterFailed: ({ request }) => {
            toast.error(request.errorBody.message);
        }
    },
    putCompleteRegistration: {
        url: `${API_URL}/users/complete_registration`,
        method: 'PUT',
        afterSuccess: () => {
            toast.success(i18next.t('notification.registrationCompleted'));
            browserHistory.push(`${getBaseRoutePath()}/login`);
        },
        afterFailed: ({ request }) => {
            if (request.response!.status === 422) {
                toast.error(request.errorBody.errors.join('. '));
            } else {
                toast.error(request.errorBody.message);
            }
        }
    },
    getBgpLogs: {
        url: `${API_URL}/quick_debug/ips/:ip/bgp/logs?exchange=:exchange&log=:log`,
        method: 'GET',
    },
    postMacAddress: {
        url: `${API_URL}/links/:linkId/mac_addresses`,
        method: 'POST',
        afterSuccess: ({ request, dispatch, getState }) => {
            // update Link without API call
            const newLink = get(request, 'result.data');
            const params = get(request, 'params');
            updateMemberLink(newLink, dispatch, getState, params);
            updateLinkEntity(newLink, dispatch, getState, params);
        },
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    postLinkVlanTag: {
        url: `${API_V2_URL}/link_vlans/:vlanId/tag?exchange=:exchange`,
        method: 'POST',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    postLinkVlanUntag: {
        url: `${API_V2_URL}/link_vlans/:vlanId/untag?exchange=:exchange`,
        method: 'POST',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    postLinkVlanMacAddress: {
        url: `${API_URL}/link_vlans/:vlanUuid/mac_addresses?exchange=:exchange`,
        method: 'POST',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    postLinkVlanClone: {
        url: `${API_URL}/link_vlans/:vlanId/clone?exchange=:exchange`,
        method: 'POST'
    },
    postLinkVlanSwapIps: {
        url: `${API_URL}/link_vlans/:vlanId/swap_ips?exchange=:exchange`,
        method: 'POST',
        refetchLinkAfterSuccess: true
    },
    postIpv6: {
        url: `${API_URL}/ips/ipv6?exchange=:exchange`,
        method: 'POST'
    },
    postLink: {
        url: `${API_URL}/links/?exchange=:exchange`,
        method: 'POST'
    },
    postLinkVlan: {
        url: `${API_URL}/links/:linkId/link_vlans?exchange=:exchange`,
        method: 'POST',
        refetchLinkAfterSuccess: true
    },
    putLinkVlan: {
        url: `${API_URL}/link_vlans/:uuid/?exchange=:exchange`,
        method: 'PUT'
    },
    postAsn: {
        url: `${API_URL}/asns`,
        method: 'POST',
        afterSuccess: ({ request }) => {
            const errors = get(request.result, 'errors');
            if (errors.length !== 0) {
                toast.error(errors.join('. '));
            }
        }
    },
    postRateLimiter: {
        url: `${API_URL}/link_vlans/:vlanId/rate_limit?exchange=:exchange`,
        method: 'POST',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    deleteRateLimiter: {
        url: `${API_URL}/link_vlans/:vlanId/rate_limit?exchange=:exchange`,
        method: 'DELETE',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    postContract: {
        url: `${API_URL}/contracts?exchange=:exchange`,
        method: 'POST',
        afterSuccess: ({ request, actions }) => {
            actions.invalidateCache('getContracts', { exchange: get(request, 'params.exchange') });
        }
    },
    postUserForContract: {
        url: `${API_URL}/contracts/:contractUuid/users?exchange=:exchange`,
        method: 'POST',
        afterSuccess: ({ request, actions }) => {
            const requestParams = pick(get(request, 'params'), ['exchange', 'contractUuid']);
            actions.invalidateCache('getContractUsers', requestParams);
            actions.perform('getContractUsers', requestParams);
        }
    },
    putUserSettings: {
        url: `${API_URL}/users/:userId/user_settings?exchange=:exchange`,
        method: 'PUT',
    },
    putUser: {
        url: `${API_URL}/users/:userId`,
        method: 'PUT',
        afterSuccess: () => {
            toast.success(i18next.t('notification.userEditSuccessful'));
        },
        afterFailed: ({ request }) => {
            const error = get(request.errorBody, 'message', i18next.t('notification.userEditFailed'));

            if (error) toast.error(error);
        }
    },
    putPortMerge: {
        url: `${API_URL}/link_ports/:portId/merge`,
        method: 'PUT',
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putFqdn: {
        url: `${API_URL}/routers/:routerId/fqdn?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putDeleteRouterFromLink: {
        url: `${API_URL}/routers/:routerId/remove_from_link?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putLacpTimeout: {
        url: `${API_V2_URL}/links/:linkId/lacp_timeout?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putLag: {
        url: `${API_V2_URL}/links/:linkId/lag?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    deleteLag: {
        url: `${API_V2_URL}/links/:linkId/lag?exchange=:exchange`,
        method: 'DELETE',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    archiveMemberLink: {
        url: `${API_URL}/links/:linkUuid/archive?exchange=:exchange`,
        method: 'PUT',
    },
    putAsn: {
        url: `${API_URL}/links/:linkId/change_asn?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putVlanAsn: {
        url: `${API_URL}/link_vlans/:vlanId/change_asn?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putMacAddress: {
        url: `${API_URL}/mac_addresses/:addressId`,
        method: 'PUT',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putMacAddressDeconfigure: {
        url: `${API_URL}/mac_addresses/:id/deconfigure?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putDisablePeering: {
        url: `${API_URL}/routers/:routerId/peerings/disable_amsix?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putEnablePeering: {
        url: `${API_URL}/routers/:routerId/peerings/enable_amsix?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putEnablePeeringRouteServer: {
        url: `${API_URL}/routers/:routerId/peerings/enable_rs?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putPeeringRouteServerMode: {
        url: `${API_URL}/routers/:routerId/peering_mode?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putPeeringAttributes: {
        url: `${API_URL}/peerings/:peeringId?exchange=:exchange`,
        method: 'PUT',
        afterSuccess: ({ request, actions }) => {
            const requestParams = {
                routerId: get(request, 'params.routerId'),
                exchange: get(request, 'params.exchange', '').toUpperCase(),
            }
            actions.invalidateCache('getRouterPeerings', requestParams);
            actions.perform('getRouterPeerings', requestParams);
        }
    },
    putSwapLink: {
        url: `${API_URL}/quick_debug/links/:linkId/swap?exchange=:exchange`,
        method: 'PUT',
        afterSuccess: ({ request, actions }) => {
            const requestParams = {
                linkId: get(request, 'params.linkId'),
                exchange: get(request, 'params.exchange'),
            }
            toast.success(i18next.t('notification.swapLinkSuccessful'));
            actions.invalidateCache('getLinkStatus', requestParams);
            actions.perform('getLinkStatus', requestParams);
        },
        afterFailed: ({ request }) => {
            const errorBody = get(request, 'errorBody');
            const message = get(errorBody, 'message') || get(errorBody, 'errors');
            toast.error(message);
        }
    },
    putLinkComment: {
        url: `${API_URL}/links/:linkId/add_comment?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    putLink: {
        url: `${API_URL}/links/:linkId/update_db?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true,
        autoTrigger: false
    },
    postMonitorLink: {
        url: `${API_URL}/monitor_links`,
        method: 'POST'
    },
    putChangePrefixLimit: {
        url: `${API_URL}/quick_debug/ips/:ip/bgp/prefix`,
        method: 'PUT',
        afterSuccess: ({ request, dispatch }) => {
            toast.success(i18next.t('notification.changePrefixLimitSuccessful'));
        },
        afterFailed: () => {
            toast.error(i18next.t('notification.changePrefixLimitFailed'));
        }
    },
    putUnblockBGPSession: {
        url: `${API_URL}/quick_debug/ips/:ip/bgp/unblock_session`,
        method: 'PUT',
        afterSuccess: ({ request, dispatch, getState }) => {
            toast.success(i18next.t('notification.bgpSessionUnblocked', { ip: get(request, 'params.ip') }));
        },
        afterFailed: ({ request }) => {
            toast.error(formatRequestErrors(request) || i18next.t('notification.unblockBgpSessionedFailed'));
        }
    },
    putResourceConfigure: {
        url: `${API_URL}/resources/:resourceId/configure`,
        method: 'PUT',
        resourceTransition: ['notification.configurationSuccessful', 'notification.configurationFailed'],
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putResourceDeconfigure: {
        url: `${API_URL}/resources/:resourceId/deconfigure`,
        method: 'PUT',
        resourceTransition: ['notification.deconfigurationSuccessful', 'notification.deconfigurationFailed'],
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putResourceConfigurationSuccessful: {
        url: `${API_URL}/resources/:resourceId/configuration_successful`,
        method: 'PUT',
        resourceTransition: ['notification.configurationSuccessfulSuccess', 'notification.configurationSuccessfulFailed']
    },
    putResourceDeconfigurationSuccessful: {
        url: `${API_URL}/resources/:resourceId/deconfiguration_successful`,
        method: 'PUT',
        resourceTransition: ['notification.deconfigurationSuccessfulSuccess', 'notification.deconfigurationSuccessfulFailed']
    },
    putResourceConfigurationFailed: {
        url: `${API_URL}/resources/:resourceId/configuration_failed`,
        method: 'PUT',
        resourceTransition: ['notification.configurationFailedSuccess', 'notification.configurationFailedFailed']
    },
    putResourceDeconfigurationFailed: {
        url: `${API_URL}/resources/:resourceId/deconfiguration_failed`,
        method: 'PUT',
        resourceTransition: ['notification.deconfigurationFailedSuccess', 'notification.deconfigurationFailedFailed']
    },
    putResourceRequestTesting: {
        url: `${API_URL}/resources/:resourceId/request_testing`,
        method: 'PUT',
        resourceTransition: ['notification.resourceTestingSuccess', 'notification.resourceTestingFailed'],
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putResourceRequestTestingWithDifferentIp: {
        url: `${API_URL}/resources/:resourceId/request_testing_with_different_ip`,
        method: 'PUT',
        autoTrigger: false,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    putOrganisation: {
        url: `${API_URL}/organisations/:uuid`,
        method: 'PUT',
    },
    putContract: {
        url: `${API_URL}/contracts/:uuid`,
        method: 'PUT',
    },
    putPortUpdate: {
        url: `${API_URL}/ports/:portId`,
        method: 'PUT',
        afterSuccess: ({ request }) => {
            toast.success(get(request, 'result.message') || i18next.t('notification.portSelectableUpdate'));
        },
    },
    deleteMacAddress: {
        url: `${API_URL}/mac_addresses/:id`,
        method: 'DELETE',
        refetchLinkAfterSuccess: true,
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    deletePeeringRouteServer: {
        url: `${API_URL}/routers/:routerId/peerings/disable_rs?exchange=:exchange`,
        method: 'PUT',
        refetchLinkAfterSuccess: true
    },
    deletePort: {
        url: `${API_URL}/link_ports/:portId`,
        method: 'DELETE',
        refetchLinkAfterSuccess: true,
        afterFailed: ({ request }) => {
            toast.error(get(request, 'errorBody.message', i18next.t('notification.portDeleteFailed')));
        },
        setRequestProperties: addDebugModeToRequestProperties,
        removeDebugProvisioning: true
    },
    updateResourceStatus: {
        url: `${API_URL}/resources/:resourceId/update_status?exchange=:exchange&status=:resourceStatus`,
        method: 'PUT',
        refetchLinkAfterSuccess: true,
        afterFailed: ({ request }) => {
            toast.error(get(request, 'errorBody.message', i18next.t('notification.resourceStatusUpdateFailed')));
        }
    },
    deleteVlan: {
        url: `${API_URL}/vlans/:vlanUuid?exchange=:exchange`,
        method: 'DELETE',
        afterSuccess: ({ request, actions }) => {
            const requestParams = pick(get(request, 'params'), ['exchange', 'uuid']);
            actions.invalidateCache('getVlans', requestParams);
            actions.perform('getVlans', requestParams);
        }
    },
    deleteLinkVlan: {
        url: `${API_URL}/link_vlans/:vlanId?exchange=:exchange`,
        method: 'DELETE',
        refetchLinkAfterSuccess: true,
        afterFailed: ({ request }) => {
            toast.error(get(request, 'errorBody.message', i18next.t('notification.vlanDeleteFailed')));
        }
    },
    deleteNativeRouter: {
        url: `${API_URL}/native_routers/:nativeRouterUuid?exchange=:exchange`,
        method: 'DELETE',
        afterSuccess: ({ request, actions }) => {
            const requestParams = pick(get(request, 'params'), ['exchange', 'uuid']);
            actions.invalidateCache('getNativeRouters', requestParams);
            actions.perform('getNativeRouters', requestParams);
        },
    },
    archiveContract: {
        url: `${API_URL}/contracts/:contractId/archive?exchange=:exchange`,
        method: 'PUT',
        afterSuccess: ({ request, actions }) => {
            const requestParams = { exchange: get(request, 'params.exchange') };
            actions.invalidateCache('getContracts', requestParams);
            actions.perform('getContracts', requestParams); 
            toast.success(i18next.t('notification.archiveContractSuccessful'));
        },
        afterFailed: ({ request }) => {
            toast.error(get(request, 'errorBody.message', i18next.t('notification.archiveContractFailed')));
        }
    },
    deleteRouteServer: {
        url: `${API_URL}/route_servers/:routeServerUuid?exchange=:exchange`,
        method: 'DELETE'
    },
    getSwappableVlans: {
        url: `${API_URL}/link_vlans/:vlanId/find_swappable_vlans`,
        method: 'GET',
        cacheDuration: 0
    },
    getLinkEvents: {
        url: `${API_URL}/links/:linkUuid/events`,
        method: 'GET',
    },
    getVlanEvents: {
        url: `${API_URL}/vlans/:vlanUuid/events`,
        method: 'GET',
    },
    getPortEvents: {
        url: `${API_URL}/ports/:portId/events`,
        method: 'GET',
    }
};

forOwn(endPointConfig, (endpoint: any, key: string) => {
    if (endpoint.method === 'GET') {
        const endPointCache = get(process.env, `REACT_APP_CACHE_${key}`, get(CACHE_CONFIG, `endpoints.${key}`, CACHE_CONFIG.cacheDurationDefault));
        endpoint.cacheDuration = Number(endPointCache);
    }
});

function handleVlanResponse(afterProps: ApiDataConfigAfterProps) {
    const { request, dispatch } = afterProps;
    dispatch(updateVlanAction(request.result));
    invalidateVlansList(afterProps);
}

function invalidateVlansList({ request, actions }: ApiDataConfigAfterProps) {
    const vlansRequestParams = { exchange: request.result.data.exchange };
    actions.invalidateCache('getVlans', vlansRequestParams);
    actions.perform('getVlans', vlansRequestParams);
};

function handleBannerResponse({ actions }: ApiDataConfigAfterProps) {
    actions.invalidateCache('getBanner');
};

function handleDebugMode(dispatch: any) {
    dispatch(updateAppPathAction('provisioning.debugProvisioningMode', false));
}

export default endPointConfig;
