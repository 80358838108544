import React, { FunctionComponent, useMemo } from 'react';
import { compose } from 'recompose';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

// components
import AlertMessage from 'components/utils/AlertMessage';
import InteractiveTable from 'components/utils/InteractiveTable';

// constants
import { defaultPaginationOptions } from 'constants/pagination';
import { ApiResponse, ContractItem } from 'constants/api';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { Badge, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { debounce, get } from 'lodash';
import { updateAppPathAction } from 'redux/app/appActions';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import memoizeOne from 'memoize-one';
import i18next from 'locales/i18next';
import { Link } from 'react-router-dom';
import { getLinkToContract } from 'utils/linksUtils';
import { StyledTable } from 'components/styled';
import { ContractStatus, Roles } from 'constants/literals';
import { isAdmin, isNoc } from 'utils/userUtils';
import ContractArchiveModal from 'components/modals/ContractArchiveModal';
import { getUserRolesSelector } from 'redux/apiData/apiDataSelectors';
import { getStatusColor } from 'utils/formatUtils';

interface InProps {
    organisationUuid: string;
}

interface ConnectProps {
    searchTerm: string;
    updateSearchTerm: (value: string) => void;
}

interface EnhanceProps {
    organisationCustomersRequest: ApiDataBinding<ApiResponse<{contracts: ContractItem[]}>>;
    userRoles: Roles[];
}

type OrganisationCustomersProps = InProps & ConnectProps & EnhanceProps & WithTranslation;

interface TableData {
    is_active: boolean,
    uuid: string,
    name: string;
    type: string;
    exchange?: string;
    organisation_uuid: string;
}

interface OrganisationCustomersTableProps {
    organisationCustomers: ContractItem[];
    searchTerm: string;
    userRoles: Roles[];
}

function filterTableData(dataArray: TableData[], searchTerm: string) {
    if (searchTerm.length === 0) {
        return dataArray;
    }
    const normalizedSearchTerm = searchTerm.toLowerCase();
    return dataArray.filter(
        (data) =>
            data.name.toLowerCase().includes(normalizedSearchTerm)
    );
}

function buildTableData(contracts: ContractItem[]) {
    return contracts.map((contract) => {
        return {
            is_active: contract.is_active,
            uuid: contract.uuid,
            name: contract.name,
            type: contract.type,
            exchange: contract.exchange,
            organisation_uuid: contract.organisation_uuid
         };
    });
}

const getOrganisationCustomersTableColumns = memoizeOne((userRoles: Roles[]): any => {
    let columns = [
        {
            dataField: 'is_active',
            text: i18next.t('common.status'),
            sort: true,
            formatter: (is_active?: boolean) => {
                const status = is_active ? ContractStatus.Active : ContractStatus.Inactive;
                return <Badge color={getStatusColor(status)}>{status}</Badge>;
            }
        },
        {
            dataField: 'uuid',
            text: i18next.t('connection.uuid'),
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: i18next.t('connection.contractName'),
            sort: true,
            hidden: false
        },
        {
            dataField: 'type',
            text: i18next.t('common.type'),
            sort: true,
        },
        {
            dataField: 'exchange',
            text: i18next.t('common.exchange'),
            sort: true,
        },
        {
            dataField: 'organisationUuid',
            text: i18next.t('contract.orgName'),
            hidden: true,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: 'Link',
            formatter: (_: any, row: ContractItem) => (
                !row.exchange ? null : <Link to={getLinkToContract(row.exchange, row.uuid)}>{i18next.t('common.view')}</Link>
            )
        }
    ];

    if (isAdmin(userRoles) || isNoc(userRoles)) {
        columns.push({
            dataField: 'archive',
            isDummyField: true,
            text: i18next.t('common.archive'),
            formatter: (_: any, row: ContractItem) => {
                return <ContractArchiveModal contract={row} exchange={row.exchange!} organisationUuid={row.organisation_uuid} />;
            },
        });
    }

    return columns;
});

function OrganisationCustomersTable(props: OrganisationCustomersTableProps) {
    const { organisationCustomers, userRoles, searchTerm  } = props;
    const { t } = useTranslation();

    const tableData = useMemo(() => buildTableData(organisationCustomers), [organisationCustomers]);

    if (organisationCustomers.length === 0) {
        return <AlertMessage data-test="no-organisationCustomers-message" message={t('organisation.noData')} />;
    } else {
        return (
            <StyledTable className="animated fadeIn table-responsive-lg">
                <InteractiveTable
                    data-test="organisationCustomers-table"
                    classes="table-responsive-lg"
                    keyField="short_name"
                    columns={getOrganisationCustomersTableColumns(userRoles)}
                    data={filterTableData(tableData, searchTerm)}
                    defaultSorted={[
                        {
                            dataField: 'name',
                            order: 'asc',
                        },
                    ]}
                    paginationOptions={{
                        ...defaultPaginationOptions,
                        sizePerPageRenderer: sizePerPageRenderer('up'),
                    }}
                />
            </StyledTable>
        );
    }
}

const enhance = compose<OrganisationCustomersProps, InProps>(
    connect(
        (state: ReduxState) => ({
            searchTerm: get(state, 'app.organisationCustomers.searchTerm', ''),
            userRoles: getUserRolesSelector(state)
        }),
        (dispatch) => ({
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('organisationCustomers.searchTerm', searchTerm));
            }, 500),
        })
    ),
    withApiData({ organisationCustomersRequest: 'getOrganisationContracts' }, (ownProps) => ({
        organisationCustomersRequest: { organisationUuid: ownProps.organisationUuid, all_contracts: 'true' },
    })),
    withTranslation()
);

export const OrganisationCustomers: FunctionComponent<OrganisationCustomersProps> = ({
    organisationCustomersRequest,
    userRoles,
    searchTerm,
    updateSearchTerm,
    t,
}) => {
   
    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <Row className="justify-content-left">
                    <Col xl={3}>
                        <Input
                            data-test="search-term-control"
                            className="form-control"
                            name="searchTerm"
                            defaultValue={searchTerm}
                            placeholder={t('table.searchInputPlaceholder')}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                updateSearchTerm(event.currentTarget.value);
                            }}
                        />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <RequestStatusRenderer
                    request={organisationCustomersRequest}
                    failedMessage={t('notification.unexpectedError')}
                    success={() => (
                        <OrganisationCustomersTable
                            organisationCustomers={get(organisationCustomersRequest, 'data.data.contracts', [])}
                            searchTerm={searchTerm}
                            userRoles={userRoles}
                        />
                    )}
                />
            </CardBody>
        </Card>
    );
};
export default enhance(OrganisationCustomers);
