import React, { FunctionComponent, useEffect } from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data/lib';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiResponse, RouterCompact } from 'constants/api';
import get from 'lodash/get';
import { ConnectionStatus } from 'constants/literals';

interface InProps {
    onChange: (router_uuid: string) => void;
    exchange: string;
    bgpRouterUuid: string;
    type: string;
    asnNumber: number;
    organisationId: number;
    vlanNumber: number;
    value: string | null;
}

type RoutersSelectProps = InProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    routersRequest: ApiDataBinding<ApiResponse<{ routers: RouterCompact[] }>>;
}

const enhance = compose<RoutersSelectProps, InProps>(
    withApiData({ routersRequest: 'getRouters' }, (ownProps) => ({
        routersRequest: {
            exchange: ownProps.exchange,
            asnNumber: ownProps.asnNumber,
            organisationId: ownProps.organisationId,
            vlanNumber: ownProps.vlanNumber,
            bgpRouterUuid: ownProps.bgpRouterUuid,
        },
    })),
    withTranslation()
);

interface OptionType {
    label: string;
    value: string | null;
}

const RoutersSelect: FunctionComponent<RoutersSelectProps> = ({
    routersRequest,
    onChange,
    exchange,
    type,
    value,
    asnNumber,
    vlanNumber,
    t,
}) => {
    const options = get(routersRequest, 'data.data.routers', [])
        .filter(
            (router: RouterCompact) =>
                router.type === type &&
                router.exchange === exchange &&
                [ConnectionStatus.enabled, ConnectionStatus.configured].includes(router.link_status)
        )
        .map((router: RouterCompact) => ({
            label: router.link_xml_id + '->' + router.ip,
            value: router.uuid,
        }))
        .concat([{ label: t('common.none'), value: null }]);
    const selectedOption = options.find((option: OptionType) => option.value === value);
    const optChanged = (e: any) => {
        onChange(e.value);
    };
    const filterOption = (option: OptionType, inputValue: string): boolean =>
        (option.label.toString().match(inputValue) || option.label.toString().match(t('common.none')) || []).length > 0;

    useEffect(() => {
        if (
            routersRequest.request.networkStatus === 'success'
            &&
            (!options.map((option: OptionType) => option.value).includes(value))
        ) {
            onChange(options[0].value);
        }
        routersRequest.invalidateCache();
    }, [value, asnNumber, vlanNumber]);

    return (
        <Select
            options={options}
            onChange={optChanged}
            filterOption={filterOption}
            isLoading={routersRequest.request.networkStatus === 'loading'}
            value={selectedOption}
        />
    );
};

// @ts-ignore
export default enhance(RoutersSelect);
