import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { getEntity, withApiData, ApiDataBinding } from 'react-api-data/lib';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import {
    ApiResponse,
    Exchange,
    LinkFull,
    PublicVlanItem,
    NativeRouter,
    RouteServer,
    Vlan
} from 'constants/api';
import { exchangeSchema } from 'constants/endpointConfig';

// utils

// components
import LinkVlanAddModal from 'components/modals/LinkVlanAddModal';
import { DetailsTable, CenteredAlert } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import { get } from 'lodash';
import VlanInfoCard from './VlanInfoCard';
import { Roles } from 'constants/literals';

interface InProps {
    link: LinkFull;
    userRoles: Roles[];
}

interface OutProps {
    exchange: Exchange;
}

interface EnhanceProps {
    publicVlans: Array<ApiDataBinding<ApiResponse<PublicVlanItem>>>;
    nativeRouters: ApiDataBinding<ApiResponse<{ native_routers: NativeRouter[] }>>;
    routeServers: ApiDataBinding<ApiResponse<{ route_servers: RouteServer[] }>>;
}

type VlanProps = InProps & OutProps & EnhanceProps & WithTranslation;

const mapStateToProps = (state: ReduxState, inProps: InProps) => ({
    exchange: getEntity(state.apiData, exchangeSchema, get(inProps, 'link.exchange.short_name')),
});

const enhance = compose<VlanProps, InProps>(
    connect(mapStateToProps),
    withTranslation(),
    withApiData({
        publicVlans: 'getVlan',
        nativeRouters: 'getNativeRouters',
        routeServers: 'getRouteServers'
    }, (ownProps: VlanProps) => ({
        publicVlans: ownProps.link.vlans.map((linkVlan: Vlan) => ({ vlanUuid: linkVlan.vlan_uuid })),
        nativeRouters: { exchange: ownProps.link.exchange.short_name },
        routeServers: { exchange: ownProps.link.exchange.short_name },
    }))
);

const VlanInfo: FunctionComponent<VlanProps> = ({ link, userRoles, exchange, t, publicVlans, nativeRouters, routeServers }) => {
    const linkVlans = link.vlans;

    const vlanHasNativeRouter = (vlanUuid: string) => {
        return nativeRouters.data!.data!.native_routers.some(item => item.vlan.uuid === vlanUuid);
    };

    const vlanHasRouteServer = (vlanUuid: string) => {
        return routeServers.data!.data!.route_servers.some(item => item.vlan.uuid === vlanUuid);
    };

    const sortVlans = (a: Vlan, b: Vlan) => {
        return Math.sign(a.vlan_id - b.vlan_id) ||
               new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    }

    if (
        publicVlans.some(pVlan => pVlan.request.networkStatus !== 'success') ||
        nativeRouters.request.networkStatus !== 'success' ||
        routeServers.request.networkStatus !== 'success'
    ) {
        return (<PageLoading/>);
    }

    return (
        <>
            <h2>
                {t('vlan.vlansTitle')}
                {link && exchange && (
                    <LinkVlanAddModal
                        data-test="vlan-add-modal"
                        link={link}
                        exchange={exchange}
                    />
                )}
            </h2>
            <hr />
            {linkVlans.length === 0 && (
                <CenteredAlert data-test="no-vlans-message" color="none">{t('vlan.noVlansFound')}</CenteredAlert>
            )}
            {linkVlans.sort(sortVlans).map(linkVlan => {
                const vlan = publicVlans.map(binding => binding.data!.data!)
                    .find(vlan => vlan.uuid === linkVlan.vlan_uuid);
                
                return (
                    <VlanInfoCard
                        linkVlan={linkVlan}
                        vlan={vlan!}
                        key={linkVlan.uuid}
                        exchange={exchange}
                        link={link}
                        userRoles={userRoles}
                        vlanHasNativeRouter={vlanHasNativeRouter}
                        vlanHasRouteServer={vlanHasRouteServer}
                    />
                )
            })}
        </>
    );
};

export const RoutersTable = styled(DetailsTable)`
  td {
    border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
    padding-left: 1rem !important;
  }
  td:not(:last-child) {
    padding-right: 1rem !important;
  }
`;

export default enhance(VlanInfo);
