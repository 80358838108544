import React, { FunctionComponent, useState, ReactNode } from 'react';
import { Button } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { BaseModalTemplate } from './BaseModalTemplate';
import Tooltip from 'components/utils/Tooltip';

interface InProps {
    onConfirm: () => void;
    modalHeaderTitle: string;
    modalBodyTitle: string;
    renderTransitionBtn?: (props: {transitionName: string, onClick: () => void}) => ReactNode;
    transitionIconClass?: string;
    transitionName: string;
}
type Props = InProps & WithTranslation;

const BaseTransitionModal: FunctionComponent<Props> = ({
    t, onConfirm, modalHeaderTitle, modalBodyTitle, renderTransitionBtn, transitionIconClass, transitionName
}) => {
    const [isOpen, seIsOpen] = useState(false);
    const toggle = () => {
        seIsOpen(!isOpen);
    };
    const confirmClickHandler = () => {
        toggle();
        onConfirm();
    };

    return (
        <>
            {renderTransitionBtn ?
                <React.Fragment>
                    {renderTransitionBtn({
                        transitionName: t(transitionName),
                        onClick: toggle,
                    })}
                </React.Fragment>
                :
                <Tooltip
                    data-test="transition-modal-toggle"
                    triggerBtn={
                        <i
                            className={`fas ml-2 transition-item text-primary cursor-pointer ${transitionIconClass}`}
                            onClick={toggle}
                        />
                    }
                >
                    {t(transitionName)}
                </Tooltip>
            }
            <BaseModalTemplate
                data-test="transition-modal"
                isOpen={isOpen}
                toggle={toggle}
                header={t(modalHeaderTitle)}
                body={t(modalBodyTitle)}
                footer={<>
                    <Button color="primary" data-test="transition-modal-confirm-btn" onClick={confirmClickHandler}>{t('modal.confirmBtn')}</Button>
                    <Button color="light" data-test="transition-modal-cancel-btn" onClick={toggle}>{t('modal.closeBtn')}</Button>
                </>}
            />
        </>
    );
};

export default compose<Props, InProps>(
    withTranslation()
)(BaseTransitionModal);