import React, { FunctionComponent } from 'react';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import get from 'lodash/get';

interface Props {
    controlName: string;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
}

const FormControlError: FunctionComponent<Props> = ({ controlName, errors, touched }) => (
    get(errors, controlName) && get(touched, controlName) ? (
        <div className="help-block invalid-feedback">{errors[controlName]}</div>
    ) : null
);

export default FormControlError;