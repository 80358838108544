import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { compose } from 'recompose';
import filter from 'lodash/filter';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// constants
import { defaultPaginationOptions } from 'constants/pagination';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';

// components
import InteractiveTable from 'components/utils/InteractiveTable';
import { Exchanges, Port, SpeedAndInterfaceTypes } from 'constants/api';
import { connect } from 'react-redux';
import { NetworkRequestStatus, PortSpeed, PortType } from 'constants/literals';
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import { ReduxState } from 'createStore';
import { ApiDataBinding, ApiDataState, EndpointParams, withApiData } from 'react-api-data';
import { debounce, get } from 'lodash';
import { updateAppPathAction } from 'redux/app/appActions';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import i18next from 'i18next';
import { getAllExchangesSelector, getUserRolesSelector } from 'redux/apiData/apiDataSelectors';
import { Roles } from 'constants/literals';
import { hasSomeRole } from 'utils/userUtils';
import { Link } from 'react-router-dom';
import { getLinkToPort } from 'utils/linksUtils';
import usePreselectExchange from 'hooks/usePreselectExchange';

interface InProps {
    syncList: boolean;
}

interface ConnectProps {
    exchangesData: Exchanges;
    selectedExchange: string;
    selectedLocation: { uuid: string; description: string };
    searchTerm: string;
    selectExchange: (selectedExchange: string) => void;
    selectLocation: (selectedLocation: { uuid: string; description: string }) => void;
    updateSearchTerm: (searchTerm?: string) => void;
}

interface EnhanceProps {
    portsRequest: ApiDataBinding<Port[]>;
    speedAndInterfaceTypesRequest: ApiDataBinding<{data: {speed_and_interface_types: SpeedAndInterfaceTypes[]}}>
    targetExchange: string;
    apiData: ApiDataState;
    userRoles: Roles[];
}

type ComponentProps = InProps & ConnectProps & WithTranslation & EnhanceProps;
type TableData = { fqid: string; speed: number; interface_type: string; portnumber: string, snmp_url: string, inconsistencies: string | null };

interface AvailablePortsTableProps {
    searchTerm: string;
    ports: TableData[];
    filterBySpeed: string;
    filterByInterface: string;
    userRoles: Roles[];
    exchange: string;
}

const enhance = compose<ComponentProps, InProps>(
    withApiData(
        {
            portsRequest: 'getAvailablePorts',
            speedAndInterfaceTypesRequest: 'getAvailableSpeedsAndInterfaceTypes'
        },
        (ownProps: ComponentProps) => ({
            portsRequest: { exchange: 'nl', location_uuid: '' }
        })
    ),
    connect(
        (state: ReduxState) => ({
            exchangesData: getAllExchangesSelector(state.apiData),
            searchTerm: get(state, 'app.availablePorts.searchTerm', ''),
            selectedExchange: get(state, 'app.availablePorts.selectedExchange', ''),
            selectedLocation: get(state, 'app.availablePorts.selectedLocation', { uuid: '', description: '' }),
            userRoles: getUserRolesSelector(state)
        }),
        (dispatch) => ({
            selectExchange: (selectedExchange: string) => {
                dispatch(updateAppPathAction('availablePorts.selectedExchange', selectedExchange));
            },
            selectLocation: (selectedLocation: {uuid: string, description: string}) => {
                dispatch(updateAppPathAction('availablePorts.selectedLocation', { uuid: selectedLocation.uuid, description: selectedLocation.description }));
            },
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('availablePorts.searchTerm', searchTerm));
            }, 500),
        })
    ),
    withTranslation()
);

const getAvailablePortsTableColumns = (hideSnmp: boolean, exchange: string) => {
    return [
        {
            dataField: 'fqid',
            text: i18next.t('ports.id'),
            sort: true,
            formatter: (cell: any, row: Port) => (
                <Link to={getLinkToPort(exchange, cell)}>{row.fqid}</Link>
            )
        },
        {
            dataField: 'speed',
            text: i18next.t('availablePorts.speed'),
            sort: true,
        },
        {
            dataField: 'interface_type',
            text: i18next.t('availablePorts.interfaceType'),
            sort: true,
        },
        {
            dataField: 'snmp_url',
            text: i18next.t('availablePorts.snmp'),
            sort: true,
            hidden: !hideSnmp,
            formatter: (cell: string, row: TableData) => {
                return (
                    <>
                        <a href={cell} target="_blank" rel="noopener noreferrer">{i18next.t('availablePorts.snmpCheckText')}</a>
                        {(row.inconsistencies !== null && row.inconsistencies !== '') && <span className="ml-1">({i18next.t('port.inconsistent')})</span>}
                    </>
                );
            }
        },
    ];
};

const filterSearchData = memoizeOne((data: TableData[], searchTerm: string): TableData[] => {
    if (!searchTerm) {
        return data;
    }
    
    return filter(data, (row: TableData) => {
        return (
            `${row.fqid} ${row.portnumber} ${row.speed} ${row.interface_type}`
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase()) !== -1
        );
    });
});

const AvailablePortsTable: FunctionComponent<AvailablePortsTableProps> = ({
    searchTerm = '',
    ports,
    filterBySpeed,
    filterByInterface,
    userRoles,
    exchange
}) => {
    const tableData = useMemo(() => {
        return ports.map((port) => {
            return {
                fqid: `${port.fqid}/${port.portnumber}`,
                portnumber: port.portnumber,
                interface_type: port.interface_type,
                speed: port.speed,
                snmp_url: port.snmp_url,
                inconsistencies: port.inconsistencies
            };
        });
    }, [ports]);

    const portsBySpeed = (speed: string, inData: TableData[]) => {
        if (tableData && tableData.length > 0) {
            switch (speed) {
                case PortSpeed.ALL:
                    return inData;
                default:
                    return inData.filter((port) => port.speed === Number(speed));
            }
        }
        return inData;
    };

    const portsByInterface = (interfaceType: string, inData: TableData[]) => {
        if (tableData && tableData.length > 0) {
            switch (interfaceType) {
                case PortType.ALL:
                    return inData;
                default:
                    return inData.filter((port) => port.interface_type === interfaceType);
            }
        }
        return inData;
    };

    const portsSpeedData = portsBySpeed(filterBySpeed, tableData);
    const portsInterfaceData = portsByInterface(filterByInterface, portsSpeedData);
    const filteredTableData = filterSearchData(portsInterfaceData, searchTerm);
    const columns = getAvailablePortsTableColumns(hasSomeRole(userRoles, Roles.admin, Roles.noc), exchange);

    return (
        <InteractiveTable
            data-test="availablePorts-interactive-table"
            classes="table-responsive-lg"
            keyField="fqid"
            columns={columns}
            data={filteredTableData}
            defaultSorted={[
                {
                    dataField: 'fqid',
                    order: 'asc',
                },
            ]}
            paginationOptions={{
                ...defaultPaginationOptions,
                sizePerPageRenderer: sizePerPageRenderer('up'),
            }}
        />
    );
};

export const AvailablePortsList: FunctionComponent<ComponentProps> = ({
    selectExchange,
    selectLocation,
    selectedExchange,
    selectedLocation,
    t,
    portsRequest,
    speedAndInterfaceTypesRequest,
    searchTerm,
    exchangesData,
    updateSearchTerm,
    userRoles,
    syncList
}) => {
    const getExchange = (exchangeShortName: string) => {
        return exchangesData.scopes
            .flatMap((scope) => scope.exchanges)
            .find((exchange) => exchange.short_name === exchangeShortName);
    };

    const [exchangeLocations, setLocations] = useState([{ uuid: '', description: '' }]);
    const [dataForSelectedOptions, setSelectedOptions] = useState([{
        fqid: '',
        speed: 0,
        interface_type: '',
        portnumber: '',
        snmp_url: '',
        inconsistencies: '',
    } as TableData]);
    const [filterBySpeed, setFilterSpeed] = useState(PortSpeed.ALL);
    const [filterByInterfaceType, setFilterInterfaceType] = useState(PortType.ALL);
    const [inconsistentOnly, setInconsistentOnly] = useState(false);

    const availableSpeedAndInterfaceTypes = speedAndInterfaceTypesRequest.data ? speedAndInterfaceTypesRequest.data.data.speed_and_interface_types : [];
    const speedValuesFromDB = Array.from(
        new Set(
            availableSpeedAndInterfaceTypes
                .map((item) => item.speed)
                .filter(item => item)
                .sort((a, b) => (a > b ? 1 : -1))
        )
    );
    const iterfaceTypesFromDB = Array.from(
        new Set(availableSpeedAndInterfaceTypes.map(item => item.interface_type).filter(item => item))
    );
    const speedValues = ['All', ...speedValuesFromDB];
    const iterfaceTypes = ['All', ...iterfaceTypesFromDB];

    usePreselectExchange(selectedExchange, exchangesData, handleExchangeChange);
    useEffect(() => {
        fetchPorts(selectedExchange, selectedLocation.uuid);
        selectLocation({ uuid: selectedLocation.uuid, description: selectedLocation.description });
    }, [syncList, inconsistentOnly]);

    const fetchPorts = (targetExchange: string, locationUuid?: string) => {
        const exchangeObject = getExchange(targetExchange);

        if (exchangeObject !== undefined) {
            const locations = exchangeObject ? exchangeObject.locations : [];
            setLocations(locations.map((location) => ({ uuid: location.uuid, description: location.description })));
            const requestParams: EndpointParams = { exchange: targetExchange };

            if (locationUuid) {
                requestParams.location_uuid = locationUuid;
            }

            requestParams.inconsistent = String(inconsistentOnly);

            portsRequest.invalidateCache();
            portsRequest
                .perform(requestParams)
                .then((response) => {
                    if (response.request.networkStatus === NetworkRequestStatus.Success) {
                        try {
                            const data = response!.data!.map((port) => ({
                                fqid: port.fqid,
                                speed: port.speed,
                                interface_type: port.interface_type!,
                                portnumber: port.portnumber,
                                snmp_url: port.snmp_url,
                                inconsistencies: port.inconsistencies,
                            }));
                            setSelectedOptions(data);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                });
        }
    };

    function handleExchangeChange (exchange: string) {
        selectExchange(exchange);
        fetchPorts(exchange);
    }

    const handleLocationChange = (location: any) => {
        selectLocation({
            uuid: location.target.value,
            description: location.target.options[location.target.selectedIndex].label,
        });
        fetchPorts(selectedExchange, location.target.value);
    };

    const handleSpeedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterSpeed(event.target.value as PortSpeed);
    };

    const handleInterfaceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterInterfaceType(event.target.value as PortType);
    };

    const handleSnmpFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInconsistentOnly(event.target.value === "inconsistent");
    };

    return (
        <>
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row>
                        <Col xl="2">
                            <Label htmlFor="searchTerm">{t('availablePorts.searchBy')}</Label>
                        </Col>
                        <Col xl="2">
                            <Label htmlFor="filterBySpeed">{t('availablePorts.speed')}</Label>
                        </Col>
                        <Col xl="2">
                            <Label htmlFor="filterByInterfaceType">{t('availablePorts.interfaceType')}</Label>
                        </Col>
                        <Col xl="2">
                            <Label htmlFor="snmp">{t('availablePorts.snmp')}</Label>
                        </Col>
                        <Col xl="2">
                            <Label htmlFor="location">{t('common.location')}</Label>
                        </Col>
                        <Col xl="2">
                            <Label htmlFor="exchange">{t('common.exchange')}</Label>
                        </Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col xl={2}>
                            <Input
                                data-test="search-term-control"
                                className="form-control"
                                name="searchTerm"
                                defaultValue={searchTerm}
                                placeholder={t('table.searchInputPlaceholder')}
                                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                    updateSearchTerm(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xl={2}>
                            <Input
                                type="select"
                                name="filterBySpeed"
                                defaultValue={PortSpeed.ALL}
                                onChange={handleSpeedChange}
                            >
                                {speedValues.map((portSpeed) => (
                                    <option key={portSpeed} value={portSpeed}>
                                        {portSpeed}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        <Col xl={2}>
                            <Input
                                type="select"
                                name="filterByInterfaceType"
                                defaultValue={PortType.ALL}
                                onChange={handleInterfaceTypeChange}
                            >
                                {iterfaceTypes.map((portType) => (
                                    <option key={portType} value={portType}>
                                        {portType}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        <Col xl={2}>
                            <Input
                                type="select"
                                name="snmp"
                                defaultValue="All"
                                onChange={handleSnmpFilterChange}
                            >
                                <option key="all" value="all">All</option>
                                <option key="inconsistent" value="inconsistent">Inconsistent</option>
                            </Input>
                        </Col>
                        <Col xl={2}>
                            <Input
                                type="select"
                                name="locations"
                                defaultValue={'All'}
                                onChange={handleLocationChange}
                            >
                                <option key="All" value="">All</option>
                                {exchangeLocations.map((location) => (
                                    <option key={location.uuid} value={location.uuid}>
                                        {location.description}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        <Col xl={2} className="text-right">
                            <ExchangeSelect
                                name="exchange"
                                defaultValue={selectedExchange}
                                exchanges={exchangesData}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleExchangeChange(e.target.value)}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <RequestStatusRenderer
                        request={portsRequest}
                        failedMessage={t('availablePorts.noAvailablePorts')}
                        defaultMessage={t('validation.exchangeNotSelected')}
                        success={() => (
                            <AvailablePortsTable
                                searchTerm={searchTerm}
                                ports={dataForSelectedOptions}
                                filterBySpeed={filterBySpeed}
                                filterByInterface={filterByInterfaceType}
                                userRoles={userRoles}
                                exchange={selectedExchange}
                            />
                        )}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default enhance(AvailablePortsList);
