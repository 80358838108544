import React from 'react';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';

interface Props {
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

function MacAddressInput({ value, onChange, className }: Props) {
    return (
        <TextMask
            Component={InputAdapter}
            mask={[
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                '.',
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                '.',
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                /[a-f0-9]/i,
                /[a-f0-9]/i,
            ]}
            value={value}
            onChange={(e: any) => {
                const newValue = e.target.value;
                if (newValue.length <= 14) {
                    onChange(newValue);
                }
            }}
            guide={false}
            className={className}
        />
    );
}

export default MacAddressInput;
