import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import {
    Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps,
} from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import LoaderButton from 'components/utils/LoaderButton';

interface ModalContentProps {
    toggle?: () => void;
    header?: ReactNode | string;
    body?: ReactNode | string;
    footer?: ReactNode | string;
}

type ModalInProps = ModalProps & ModalContentProps;

interface FormModalFooterProps {
    loading: boolean;
    disabled?: boolean;
    toggle: () => void;
    onSubmit?: any;
    text?: string;
}

const BaseModal: FunctionComponent<ModalProps> = ({ size = 'lg', className, children, ...modalProps }) => (
    <Modal
        data-test="base-modal"
        size={size}
        className={classnames(['modal-primary', className])}
        {...modalProps}
    >
        {children}
    </Modal>
);

export const BaseModalContent: FunctionComponent<ModalContentProps> = ({ toggle, header, body, footer }) => (
    <>
        <ModalHeader data-test="base-modal-content-header" toggle={toggle}>{header}</ModalHeader>
        <ModalBody data-test="base-modal-content-body">{body}</ModalBody>
        <ModalFooter data-test="base-modal-content-footer">{footer}</ModalFooter>
    </>
);

export const BaseModalTemplate: FunctionComponent<ModalInProps> = ({ toggle, header, body, footer, ...modalProps }) => (
    <BaseModal data-test="base-modal-template" toggle={toggle} {...modalProps}>
        <BaseModalContent
            data-test="base-modal-content"
            toggle={toggle}
            header={header}
            body={body}
            footer={footer}
        />
    </BaseModal>
);

export const BaseFormModalTemplate: FunctionComponent<ModalInProps & { handleSubmit: any }> = ({
    toggle, header, body, footer, handleSubmit, ...modalProps
}) => (
    <BaseModal data-test="base-form-modal-template" toggle={toggle} {...modalProps}>
        <Form data-test="base-form-modal-form" className="form-horizontal" onSubmit={handleSubmit}>
            <BaseModalContent
                data-test="base-modal-content"
                toggle={toggle}
                header={header}
                body={body}
                footer={footer}
            />
        </Form>
    </BaseModal>
);

const BaseFormModalButtons: FunctionComponent<FormModalFooterProps & WithTranslation> = ({ t, loading, toggle, disabled, onSubmit, text }) => (
    <>
        <LoaderButton
            data-test="base-form-modal-confirm-btn"
            loading={loading}
            type="submit"
            disabled={loading || disabled}
            onClick={onSubmit ? onSubmit : undefined}
        >
            { text && text.length > 0 ? text : t('modal.confirmBtn')}
        </LoaderButton>
        <Button data-test="base-form-modal-cancel-btn" color="light" onClick={toggle} disabled={loading}>{t('modal.cancelBtn')}</Button>
    </>
);

export const FormModalButtons = withTranslation()(BaseFormModalButtons);