import {
    Alert
} from 'reactstrap';
import React from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';
import { LinkFull } from 'constants/api';
import { connect } from 'react-redux';
import { archiveLinkAction } from 'redux/app/appActions';

interface InputProps {
    uuid: string;
    exchange: string;
    xmlId: string;
    invalidateData: () => void;
}

interface EnhanceProps {
    archiveMemberLink: ApiDataBinding<{ data: LinkFull }>;
    removeLinkFromLinksHistory: (uuid: string) => void;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    withApiData(
        {
            archiveMemberLink: 'archiveMemberLink',
        }
    ),
    withTranslation(),
    connect(null, (dispatch) => ({
        removeLinkFromLinksHistory: (linkUuid: string) => dispatch(archiveLinkAction({ linkUuid })),
    }))
);

class ConnectionDeleteModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { archiveMemberLink, t } = this.props;
        this.handleFormSubmission(prevProps.archiveMemberLink.request, archiveMemberLink.request, {
            successMessage: t('archiveMemberLink.successful')
        });
    }

    _handleSubmit = () => {
        const { uuid, exchange, invalidateData, archiveMemberLink, removeLinkFromLinksHistory } = this.props;
        archiveMemberLink.perform(
            {
                linkUuid: uuid,
                exchange: exchange
            }
        ).then(binding => {
            if (binding.request.networkStatus === NetworkRequestStatus.Success) {
                removeLinkFromLinksHistory(uuid);
                invalidateData();
            }
        })
    };

    render() {
        const { archiveMemberLink, xmlId, t } = this.props;

        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('archiveMemberLink.modalTitle') + ': ' + xmlId}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('archiveMemberLink.warningMessage')}</p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(archiveMemberLink.request, 'networkStatus') === NetworkRequestStatus.Loading}
                            toggle={this.toggle}
                            onSubmit={this._handleSubmit}
                        />
                    }
                />
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc]}>
                    <span data-test="Connection-delete-btn" className="fas fa-trash fa-pull-left mt-1 text-primary cursor-pointer" 
                          onClick={this.toggle} title={t('archiveMemberLink.modalTitle')}>
                    </span>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(ConnectionDeleteModal);
