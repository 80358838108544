import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import Raven from 'raven-js';

import App from 'components/App';
import createStore from 'createStore';
import history from './createBrowserHistory';

import 'locales/i18next';

const { store, persistor } = createStore();

const root = document.getElementById('root');

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY) {
    Raven
        .config(process.env.REACT_APP_SENTRY)
        .install();
}

if (root) {
    ReactDOM.render(
        <Router history={history}>
            <Suspense fallback={null}>
                <App store={store} persistor={persistor} history={history} />
            </Suspense>
        </Router>,
        root
    );
}

if (module.hot) {
    module.hot.accept();
}