import React, { FunctionComponent } from 'react';
import { Input } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { OptionItem } from 'constants/literals';

interface AggregateSelectProps {
    defaultValue?: string;
    onChange?: any;
    options: OptionItem[];
}

const AggregateSelect: FunctionComponent<AggregateSelectProps & WithTranslation> = ({
    onChange, defaultValue = '', options, t
}) => (
    <Input
        type="select"
        name="aggregates"
        onChange={onChange}
        defaultValue={defaultValue}
    >
        <option value="" disabled>{t('stats.selectAggregateOption')}</option>
        {options.map((aggregate: OptionItem) => (
            <option
                key={aggregate.value}
                value={aggregate.value}
            >
                {aggregate.title}
            </option>
        ))}
    </Input>
);

export default withTranslation()(AggregateSelect);