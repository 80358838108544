import React from 'react';
import { performApiRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { ConnectionStatus, Roles } from 'constants/literals';

import { DropdownItem } from 'reactstrap';
import RolesChecker from './RolesChecker';

interface InProps {
    resourceId: string | number;
    resourceStatus: string;
    linkId: string;
    exchange: string;
    isDropdownItem?: boolean;
}

interface ConnectProps {
    onDelete: () => void;
}

const getStatus = (status: string) => shouldDeconfigure(status) ? ConnectionStatus.deconfigured : ConnectionStatus.configured;

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    onDelete: () => {
        dispatch(performApiRequest('updateResourceStatus', {
            resourceId: get(ownProps, 'resourceId'),
            linkId: ownProps.linkId,
            resourceStatus: getStatus(ownProps.resourceStatus),
            exchange: ownProps.exchange
        }));
    },
});

const shouldDeconfigure = (status: string) => {
    return [ConnectionStatus.configured, ConnectionStatus.enabled, ConnectionStatus.deconfiguring]
        .map((x) => x.toString()).includes(status.toLowerCase());
};

export default compose<InProps & ConnectProps, InProps>(
    connect(null, mapDispatchToProps)
)(({ resourceStatus, onDelete, isDropdownItem = false }) => {
    const label = shouldDeconfigure(resourceStatus) ?
        'Simulate deconfiguration successful' :
        'Simulate configuration successful';

    return (
        <RolesChecker data-test="update-status-roles" roles={[Roles.admin, Roles.noc]}>
            <DropdownItem
                onClick={onDelete}
                data-test="resource-update-status-btn"
            >
                {label}
            </DropdownItem>
        </RolesChecker>
    );
});