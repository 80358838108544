import React from 'react';
import { ApiDataRequest, ApiDataBinding, withApiData } from 'react-api-data/lib';
import get from 'lodash/get';
import { compose, withProps } from 'recompose';

import { SflowStats, ApiResponse } from 'constants/api';

import SflowGraph, { SflowGraphInProps } from 'components/graphs/SflowGraph';

interface InProps extends SflowGraphInProps {
    exchange: string;
}

interface ComposeProps extends InProps {
    sflowApiData: ApiDataBinding<ApiResponse<SflowStats>>;
    statsRequest: ApiDataRequest;
}

type Props = InProps & ComposeProps;

export default compose<Props, InProps>(
    withApiData(
        { sflowApiData: 'getSflowAggregatedStats' },
        (ownProps: InProps) => ({
            sflowApiData: {
                exchange: ownProps.exchange,
                type: ownProps.sflowType,
                interval: ownProps.interval,
            }
        })
    ),
    withProps((ownProps: InProps) => ({
        statsRequest: get(ownProps, 'sflowApiData.request')
    }))
)((props: Props) => <SflowGraph {...props} />);