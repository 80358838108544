import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

// components
import LoadingIndicator from './LoadingIndicator';
import TabTitle from './TabTitle';

// constants
import { LinkFull } from 'constants/api';
import { getConnectionTableColumns } from 'constants/columns';

// selectors
import { ConnectionStatus, NetworkRequestStatus, searchByOptions } from 'constants/literals';
import { get, map } from 'lodash';
import InteractiveRemoteTable from 'components/utils/InteractiveRemoteTable';
import { Button, Col, Input, Row } from 'reactstrap';

interface EnhanceProps {
    allConnectionsBinding: ApiDataBinding<LinkFull[]>;
}

interface InProps {
    onLinkClick: () => void;
}

type Props = EnhanceProps & InProps;

const SIZE_PER_PAGE = 10;
const DEFAULT_SORT_FIELD = 'xml_id';
const DEFAULT_SORT_ORDER = 'asc';

const enhance = compose<Props, InProps>(
    withApiData(
        {
            allConnectionsBinding: 'getAllLinks',
        },
        () => ({
            allConnectionsBinding: {
                page: 1,
                per_page: SIZE_PER_PAGE,
                sort: DEFAULT_SORT_FIELD,
                order: DEFAULT_SORT_ORDER
            },
        })
    ),
);

function ConnectionList(props: Props) {
    const { allConnectionsBinding, onLinkClick } = props;
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('');
    const [sortByField, setSortField] = useState(DEFAULT_SORT_FIELD);
    const [sortInOrder, setSortOrder] = useState(DEFAULT_SORT_ORDER);

    const fetchLinks = (pageNumber: number, sortField: string, sortOrder: string, searchTerm: string) => {
        const baseParams = {
            page: pageNumber,
            per_page: SIZE_PER_PAGE,
            sort: sortField,
            order: sortOrder
        };

        const params = { ...baseParams, [searchBy]: searchTerm };
        return allConnectionsBinding.perform(searchTerm !== '' ? params : baseParams);
    };

    const fetchConnections = useCallback(fetchLinks, [searchBy, searchTerm, sortByField, sortInOrder])

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchBy(event.target.value);
        if (event.target.value.toLowerCase() === searchByOptions.status.toLowerCase()) {
            setSearchTerm('configured');
        }
        else
            setSearchTerm('');
    };

    useEffect(() => {
        if (searchBy.toLowerCase() === searchByOptions.status.toLowerCase()) {
            fetchConnections(1, sortByField, sortInOrder, searchTerm);
        }
    }, [searchTerm, searchBy, fetchConnections, sortByField, sortInOrder]);

    const invalidateData = useCallback(() => {
        allConnectionsBinding.invalidateCache();
        allConnectionsBinding.perform(allConnectionsBinding.request.params);
    }, [allConnectionsBinding]);

    const tableColumns = useMemo(
        () =>
        getConnectionTableColumns(
            invalidateData,
            {
                onLinkClick: onLinkClick,
                isCloudHidden: process.env.REACT_APP_SHOW_CLOUD !== 'true',
            },
            [
                {
                    dataField: 'extra_search_content',
                    text: 'Search Content',
                    hidden: true,
                },
            ]
        ),
        [onLinkClick, invalidateData]
    );

    return (
        <>
            <LoadingIndicator show={allConnectionsBinding.request.networkStatus === NetworkRequestStatus.Loading} />
            <TabTitle
                onSync={() => {
                    fetchConnections(1, sortByField, sortInOrder, searchTerm);
                }}
            >
                {t('globalSearch.connectionsTitle')}
            </TabTitle>
            <Row className="align-items-center">
                {searchBy !== 'status' ? (
                    <Col xl={3}>
                        <Input
                            data-test="search-term-control"
                            className="form-control"
                            placeholder={t('table.searchInputPlaceholder')}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </Col>
                ) : (
                    <Col xl={3}>
                        <Input type="select" name="searchValue" value={searchTerm} onChange={handleSearchChange}>
                            {Object.keys(ConnectionStatus).map((cStatus) => (
                                <option key={cStatus} value={cStatus}>
                                    {cStatus}
                                </option>
                            ))}
                        </Input>
                    </Col>
                )}
                {searchBy !== searchByOptions.status && (
                    <Button
                        data-test="search-connections-btn"
                        className="float-right"
                        color="light"
                        onClick={() => fetchConnections(1, sortByField, sortInOrder, searchTerm)}
                    >
                        {t('globalSearch.executeSearch')}
                    </Button>
                )}
                <Col></Col>
                <strong>{t('globalSearch.searchBy')}</strong>
                <Col xl={3}>
                    <Input type="select" name="searchType" defaultValue={searchTerm} onChange={handleSearchByChange}>
                        {map(searchByOptions, (value: string, key: string) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </Input>
                </Col>
            </Row>
            <br></br>
            <InteractiveRemoteTable
                keyField="uuid"
                columns={tableColumns}
                data={get(allConnectionsBinding, 'data.data.links', [])}
                defaultSorted={{ dataField: sortByField, order: sortInOrder as 'asc' | 'desc'}}
                loading={allConnectionsBinding.request.networkStatus === NetworkRequestStatus.Loading}
                fetchPage={(pageNumber, _pageSize, sortField, sortOrder) => {
                    setSortField(sortField);
                    setSortOrder(sortOrder);
                    fetchConnections(pageNumber, sortField, sortOrder, searchTerm);
                }}
                paginationOptions={{
                    page: get(allConnectionsBinding, 'data.meta.page', 1),
                    sizePerPage: get(allConnectionsBinding, 'data.meta.page_size', SIZE_PER_PAGE),
                    totalSize: get(allConnectionsBinding, 'data.meta.total_items', 1),
                }}
            />
        </>
    );
}

export default enhance(ConnectionList);
