import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import memoizeOne from 'memoize-one';

// utils
import { getStatusColor } from 'utils/formatUtils';
import { getLinkToContract } from 'utils/linksUtils';
import i18next from 'locales/i18next';

import { ContractItem } from 'constants/api';
import { ContractStatus, Roles } from 'constants/literals';
import { isAdmin, isNoc } from 'utils/userUtils';
import ContractArchiveModal from 'components/modals/ContractArchiveModal';

export const getCustomersTableColumns = memoizeOne((userRoles: Roles[], exchange: string): any => {
    let columns = [{
        dataField: 'is_active',
        text: i18next.t('common.status'),
        sort: true,
        formatter: (is_active?: boolean) => {
            const status = is_active ? ContractStatus.Active : ContractStatus.Inactive;
            return <Badge color={getStatusColor(status)}>{status}</Badge>;
        }
    }, {
        dataField: 'organisation_name',
        text: i18next.t('contract.organisationName'),
        sort: false,
    }, {
        dataField: 'erp_customer_identifier',
        text: i18next.t('contract.erpCustomerIdentifier'),
        sort: false,
    }, {
        dataField: 'name',
        text: i18next.t('contract.contractName'),
        sort: true,
    }, {
        dataField: 'type',
        text: i18next.t('common.type'),
        sort: true,
    }, {
        dataField: 'handle',
        text: i18next.t('common.handle'),
        sort: false,
        hidden: true
    }, {
        dataField: 'link',
        isDummyField: true,
        text: 'Link',
        formatter: (_: any, row: ContractItem) => (
            !exchange ? null : <Link to={getLinkToContract(exchange, row.uuid)}>{i18next.t('common.view')}</Link>
        )
    }];

    if (isAdmin(userRoles) || isNoc(userRoles)) {
        columns.push({
            dataField: 'archive',
            isDummyField: true,
            text: i18next.t('common.archive'),
            formatter: (_: any, row: ContractItem) => {
                return <ContractArchiveModal contract={row} exchange={exchange} />;
            }
        });
    }

    return columns;
});
