import React, { FunctionComponent } from 'react';
import { ApiDataState, getEntity } from 'react-api-data/lib';
import { Exchange as ApiExchange } from 'constants/api';
import { ReduxState } from 'createStore';
import { connect } from 'react-redux';
import { exchangeSchema } from 'constants/endpointConfig';

interface Props {
    shortName: string;
    apiState: ApiDataState;
}

const mapStateToProps = ((state: ReduxState) => ({
    apiState: state.apiData
}));

const flagMapping: {[handle: string]: string} = {
    mum: 'in',
    ny: 'us',
    chi: 'us',
    ba: 'us',
    ir: 'none'
};

interface ExchangeNodeProps {
    handle: string;
    exchangeName: string;
}
const ExchangeNode: FunctionComponent<ExchangeNodeProps> = ({ handle, exchangeName }) => {
    const flagCode = flagMapping[handle] || handle;

    const flagIcon = flagCode === 'none' ? <></> : (<><span className={`flag-icon flag-icon-${flagCode}`}/> </>);

    return (
        <>
            {flagIcon}{exchangeName}
        </>
    );
};

const Exchange: FunctionComponent<Props> = ({ shortName, apiState }) => {
    const exchange: ApiExchange = getEntity(apiState, exchangeSchema, shortName);
    if (!exchange) {
        return <>{shortName || '???'}</>;
    }

    return (
        <ExchangeNode exchangeName={exchange.name} handle={exchange.short_name.toLowerCase()} />
    );
};

export default connect(mapStateToProps)(Exchange);