import React, { FunctionComponent } from 'react';
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem
} from 'reactstrap';
import { AppHeaderDropdown, AppNavbarBrand } from '@coreui/react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { purgeApiData, performApiRequest } from 'react-api-data/lib';
import get from 'lodash/get';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constatns
import { ReduxState } from 'createStore';
import { AuthenticationToken, Contract, User } from 'constants/api';

// utils
import { getBaseRoutePath } from 'utils/commonUtils';
import { getLinkToAvailablePorts, getLinkToCustomers, getLinkToSwitches, getLinkToIpRanges, getLinkToExchanges, getLinkToHomeContract } from 'utils/linksUtils';
import { getLinkToVlans } from 'utils/linksUtils';
import { getLinkToNativeRouters } from 'utils/linksUtils';
import { getLinkToRouteServers } from 'utils/linksUtils';
import { changeAppLanguage, getAvailableLanguages } from 'utils/languageUtils';

// redux
import { updateAppPathAction } from 'redux/app/appActions';
import { getUserEmailSelector, getUserSelector, getFirstExchangeShortNameSelector, getHomeContractSelector } from 'redux/apiData/apiDataSelectors';

// components
import GlobalSearch from './GlobalSearch';
import UserUpdateModal from './modals/UserUpdateModal';
import BannerModal from './modals/BannerModal';
import { Roles } from 'constants/literals';
import SetupMfaModal from './modals/SetupMfaModal';
import { getMainRole, hasSomeRole } from 'utils/userUtils';
import DebugModeSetReset from './utils/DebugModeSetReset';

interface Props {
    onLogout: (currentToken: AuthenticationToken) => void;
    changeLanguage: (language: string) => void;
    authenticationToken?: AuthenticationToken;
    appLanguage?: string;
    userEmail: string;
    exchange: string;
    user: User;
    homeContract: Contract;
}

const Header: FunctionComponent<Props & WithTranslation> = ({
    onLogout, authenticationToken, changeLanguage, appLanguage, t, userEmail, user, exchange, homeContract
}) => {
    if (!authenticationToken) {
        return null;
    }
    const availableLanguages = getAvailableLanguages();
    const basePath = getBaseRoutePath();
    const organisation = get(homeContract, 'organisation');
    const mainRole = getMainRole(user.roles);

    return (
        <>
            <AppNavbarBrand
                full={{ src: '/images/logo.svg', height: 35, alt: 'AMS-IX Logo' }}
                minimized={{ src: '/images/logo-compact.svg', height: 25, alt: 'AMS-IX Logo' }}
                className="d-md-down-none"
            />

            <Nav navbar>
                <NavItem className="px-3">
                    <Link to={basePath} className="nav-link">{t('header.dashboardLink')}</Link>
                </NavItem>
                <NavItem className="px-3">
                    <Link to={getLinkToCustomers()} className="nav-link">{t('header.customersLink')}</Link>
                </NavItem>
                {hasSomeRole(user.roles, Roles.admin, Roles.noc) && organisation &&
                    <NavItem className="px-3">
                        <Link to={getLinkToHomeContract(homeContract.exchange , homeContract.uuid)} className="nav-link">{organisation.name}</Link>
                    </NavItem>
                }
                {hasSomeRole(user.roles, Roles.admin, Roles.noc, Roles.ixaas_client, Roles.ixaas_client_reader) &&
                    <NavItem className="px-3">
                        <Link to={getLinkToVlans()} className="nav-link">{t('header.vlansLink')}</Link>
                    </NavItem>
                }
                {hasSomeRole(user.roles, Roles.admin, Roles.noc, Roles.qnoc, Roles.ixaas_client) &&
                    <UncontrolledDropdown className="px-3" nav inNavbar>
                        <DropdownToggle nav>
                            {t('header.Resources')}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem toggle={true} hidden={!hasSomeRole(user.roles, Roles.admin, Roles.noc)}>
                                <Link to={getLinkToNativeRouters()} className="nav-link">
                                    {t('header.nativeRouters')}
                                </Link>
                            </DropdownItem>
                            <DropdownItem toggle={true} hidden={!hasSomeRole(user.roles, Roles.admin, Roles.noc)}>
                                <Link to={getLinkToRouteServers()}
                                      className="nav-link">{t('header.routeServers')}
                                </Link>
                            </DropdownItem>
                            <DropdownItem toggle={true} hidden={!hasSomeRole(user.roles, Roles.admin, Roles.noc, Roles.ixaas_client)}>
                                <Link to={getLinkToSwitches()} className="nav-link">
                                    {t('header.switches')}
                                </Link>
                            </DropdownItem>
                            <DropdownItem toggle={true} hidden={!hasSomeRole(user.roles, Roles.admin, Roles.noc)}>
                                <Link to={getLinkToIpRanges()} className="nav-link">
                                    {t('header.ipRanges')}
                                </Link>
                            </DropdownItem>
                            <DropdownItem toggle={true} hidden={!hasSomeRole(user.roles, Roles.admin, Roles.noc)}>
                                <Link to={getLinkToAvailablePorts()} className="nav-link">
                                    {t('header.availablePorts')}
                                </Link>
                            </DropdownItem>
                            <DropdownItem toggle={true}>
                                <Link to={getLinkToExchanges()} className="nav-link">
                                    {t('header.exchangesAndDataCenters')}
                                </Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }
                {hasSomeRole(user.roles, Roles.admin, Roles.noc, Roles.ixaas_client) &&
                    <UncontrolledDropdown className="px-3" nav inNavbar>
                        <DropdownToggle nav>
                            {t('header.tools')}
                        </DropdownToggle>
                        <DropdownMenu>
                            <BannerModal
                                renderModalToggleBtn={(props: any) => (
                                    <DropdownItem
                                        toggle={true}
                                        hidden={!hasSomeRole(user.roles, Roles.admin, Roles.noc, Roles.ixaas_client)}
                                        onClick={props.onClick}
                                    >
                                        <i className="fas fa-edit"/> {t('header.banner')}
                                    </DropdownItem>
                                )}
                            />
                            {hasSomeRole(user.roles, Roles.admin, Roles.noc) &&
                                <DebugModeSetReset
                                    data-test="set-debug-provisioning"
                                />
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                }
            </Nav>
            <Nav className="ml-auto" navbar>
                <GlobalSearch />
                {availableLanguages.length > 1 && (
                    <NavItem data-test="languages-dropdown">
                        <UncontrolledDropdown>
                            <DropdownToggle
                                tag="i"
                                className="fas fa-language text-primary font-2xl"
                                caret={false}
                                title={t('header.languageToggleTitle')}
                            />
                            <DropdownMenu>
                                {availableLanguages.map((language: any) => (
                                    <DropdownItem
                                        data-test="languages-dropdown-item"
                                        key={language.lang}
                                        active={appLanguage === language.lang}
                                        onClick={() => changeLanguage(language.lang)}
                                    >
                                        {language.title}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </NavItem>
                )}
                <AppHeaderDropdown direction="down">
                    <DropdownToggle nav>
                        <Avatar email={userEmail} name={userEmail} textSizeRatio={2} size="35" round color="#f47f22" />
                    </DropdownToggle>
                    <DropdownMenu className={'user-menu'} right persist={true} >
                        <DropdownItem header tag="div">
                            <strong>{userEmail}</strong><br/>{mainRole}
                        </DropdownItem>
                        <DropdownItem
                            data-test="logout-btn"
                            href="#"
                            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                                e.preventDefault();
                                onLogout(authenticationToken);
                            }}
                        >
                            <i className="fas fa-lock" /> {t('header.logout')}
                        </DropdownItem>
                        <UserUpdateModal
                            exchange={exchange}
                            uuid={user.uuid}
                            email={userEmail}
                            renderModalToggleBtn={(props: any) => (
                                <DropdownItem
                                    toggle={false}
                                    onClick={props.onClick}
                                >
                                    <i className="fas fa-edit" /> {t('header.settings')}
                                </DropdownItem>
                            )}
                        />
                        <SetupMfaModal
                            exchange={exchange}
                            uuid={user.uuid}
                            mfaEnabled={user.mfa_enabled}
                            renderModalToggleBtn={(props: any) => (
                                <DropdownItem
                                    toggle={false}
                                    onClick={props.onClick}
                                >
                                    <i className="fas fa-user-shield" /> {t('header.setupMfa')}
                                </DropdownItem>
                            )}
                        />
                    </DropdownMenu>
                </AppHeaderDropdown>
            </Nav>
        </>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    authenticationToken: state.app.authenticationToken,
    appLanguage: get(state, 'app.appLanguage'),
    userEmail: getUserEmailSelector(state),
    user: getUserSelector(state),
    exchange: getFirstExchangeShortNameSelector(state.apiData),
    homeContract: getHomeContractSelector(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    onLogout: (currentToken: AuthenticationToken) => {
        dispatch(performApiRequest('revokeToken', {}, { token: currentToken.access_token }));
        dispatch(updateAppPathAction('authenticationToken', undefined));
        dispatch(updateAppPathAction('impersonatorToken', undefined));
        dispatch(purgeApiData());
    },
    changeLanguage: (language: string) => {
        changeAppLanguage(language, dispatch);
    }
});

export default compose<Props & WithTranslation, {}>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Header);
