import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Asn, Contract } from 'constants/api';
import { NONE_SELECTED } from 'constants/index';
import { get } from 'lodash';
import { NetworkRequestStatus } from 'constants/literals';

interface InProps {
    value: number | null;
    contractUuid: string;
    exchange: string;
    onChange: (asnNumber: number, asnId: number) => void;
}

type AsnSelectContractProps = InProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    contract: ApiDataBinding<Contract>;
}

const enhance = compose<AsnSelectContractProps, InProps>(
    withApiData({ contract: 'getContract' }, (ownProps) => ({
        contract: {
            exchange: ownProps.exchange,
            contractUuid: ownProps.contractUuid,
        },
    })),
    withTranslation()
);

interface OptionType {
    label: string;
    value: number;
    asn_id: number;
}

const AsnSelectContract: FunctionComponent<AsnSelectContractProps> = ({ contract, value, onChange }) => {
    const isLoading = contract.request.networkStatus === NetworkRequestStatus.Loading;
    const asnOptions = get(contract, 'data.asns', []).map((asn: Asn) => {
        return {
            label: asn.number.toString(),
            value: asn.number,
            asn_id: asn.id
        };
    });
    const optionNone = { label: NONE_SELECTED, value: null, asn_id: null };
    const options = [optionNone, ...asnOptions];
    const selectedValue = options.find((o: { value: number | undefined; }) => o.value === value);
    const filterOption = (option: OptionType, inputValue: string): boolean =>
        (option.label.toString().match(inputValue) || []).length > 0;

    return (
        <Select
            options={options}
            defaultValue={optionNone}
            value={selectedValue}
            onChange={(e: any) => onChange(e.value, e.asn_id)}
            filterOption={filterOption}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    );
};

export default enhance(AsnSelectContract);
