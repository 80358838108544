import { ApiDataBinding, withApiData, performApiRequest, invalidateApiDataRequest } from 'react-api-data/lib';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { ApiResponse, DebugData, LinkFull } from 'constants/api';
import QuickDebugTab from './QuickDebugTab';

interface InProps {
    link: LinkFull;
}

interface ApiProps {
    debugData: ApiDataBinding<ApiResponse<DebugData>>;
}

type Props = InProps & ApiProps;

const connectApiData = withApiData(
    { debugData: 'getPortPeering' },
    (ownProps: Props) => ({
        debugData: {
            linkId: ownProps.link.uuid,
        }
    })
);

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    refetchData: () => {
        const requestParams = {
            linkId: get(ownProps, 'link.uuid'),
        };
        dispatch(invalidateApiDataRequest('getPortPeering', requestParams));
        dispatch(performApiRequest('getPortPeering', requestParams));
    }
});

// @ts-ignore
export default compose<ApiProps, InProps>(connectApiData, connect(null, mapDispatchToProps))(QuickDebugTab);
