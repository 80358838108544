import React, { useState, FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownProps, DropdownMenuProps } from 'reactstrap';
import styled from 'styled-components';
import classNames from 'classnames';
import { get } from 'lodash';

interface Props {
    children: ReactNode;
    className?: string;
    dropdownProps?: DropdownProps;
    dropdownMenuProps?: DropdownMenuProps;
}

function useForceInitialRerender() {
    const [, setState] = useState(false);

    useEffect(() => {
        setState(true);
    }, []);
}

const ContextMenuDropdown: FunctionComponent<Props> = ({ children, className, dropdownProps, dropdownMenuProps }) => {
    const [isOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!isOpen);
    const menuRef = useRef(null);
    const childrenCount = get(menuRef, 'current.childElementCount');

    // NOTE: The `childrenCount` value will always be `undefined` on the initial render because it is calculated before
    // the content is actually inserted into the DOM. As a result of this the dropdown will always be invisible after 
    // the initial rendering. This hook will force the component to re-render and make the dropdown visible if needed.
    useForceInitialRerender();

    return (
        <Dropdown
            tag={'span' as React.ReactType}
            isOpen={isOpen}
            toggle={toggle}
            className={classNames(['context-menu-dropdown', className, !childrenCount ? 'd-none' : null])}
            {...dropdownProps}
        >
            <DropdownToggle tag="span" onClick={toggle}>
                <span className="context-menu-dropdown__toggle cursor-pointer">
                    <i className="fas fa-ellipsis-v context-menu-dropdown__icon" />
                </span>
            </DropdownToggle>
            <DropdownMenu {...dropdownMenuProps}>
                <div ref={menuRef}>{children}</div>
            </DropdownMenu>
        </Dropdown>
    );
};

const StyledDropdown = styled(ContextMenuDropdown)`
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    &.text-primary {
      .context-menu-dropdown__toggle:hover {
        color: white;
      }    
    }
    .context-menu-dropdown {
        width: 26px;
        height: 26px;
        display: inline-block;
    }
    .context-menu-dropdown__toggle {
        padding: 13px;
        position: relative;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        transition: background-color 0.3s ease;
        :hover {
            background-color: var(--primary);
        }
    }
    .context-menu-dropdown__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 14px;
        margin: -7px 0 0 -3px;
        text-align: center;
    }
`;

export default StyledDropdown;
