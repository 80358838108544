import { FunctionComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import React from 'react';

interface Props {
    label: string;
    value: string;
    color: string;
    icon: string;
    capitalizeValue?: boolean;
}

const ColoredIconWidget: FunctionComponent<Props> = ({ label, value, color, icon, capitalizeValue = false }) => {
    return (
        <Card>
            <CardBody className="clearfix p-0">
                <i className={`fas fa-${icon} text-${color} p-4 font-2xl mr-0 float-left`} />
                <div className={`h5 mb-0 pt-3 text-${color}`}>{label}</div>
                <div className={`${capitalizeValue ? 'text-capitalize' : ''} font-weight-bold font-xs text-muted`}>{value}</div>
            </CardBody>
        </Card>
    );
};

export default ColoredIconWidget;
