import React, { FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// constants
import { Exchanges, Exchange } from 'constants/api';
import { ReduxState } from 'createStore';
import { defaultPaginationOptions } from 'constants/pagination';

// redux
import { updateAppPathAction } from 'redux/app/appActions';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';

// components
import InteractiveTable from 'components/utils/InteractiveTable';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { getExchangesTableColumns } from 'constants/columns/exchangesTableColumns';
import { StyledTable } from 'components/styled';



interface ConnectProps {
    exchangesRequest: ApiDataBinding<Exchanges>;
    searchTerm: string;
    updateSearchTerm: (searchTerm?: string) => void;
}

type ComponentProps = ConnectProps & WithTranslation;

interface ExchangesTableProps {
    exchangesTableData: Exchanges;
    searchTerm: string;
}

type TableData = Pick<Exchange,  'name' | 'short_name' | 'active'>

const enhance = compose<ComponentProps, {}>(
    withApiData(
        {
            exchangesRequest: 'getAllExchanges'
        }
    ),
    connect(
        (state: ReduxState) => ({
            searchTerm: get(state, 'app.exchanges.searchTerm', '')
        }),
        (dispatch) => ({
            updateSearchTerm: debounce((searchTerm: string) => {
                dispatch(updateAppPathAction('exchanges.searchTerm', searchTerm));
            }, 500),
        })
    ),
    withTranslation()
);

const filterSearchData = memoizeOne((data: TableData[], searchTerm: string): TableData[] => {
    if (!searchTerm) {
        return data;
    }
    return filter(data, (row: TableData) => {
        return (
            `${row.name} ${row.short_name} ${row.active}`
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) !== -1
        );
    });
});

const ExchangesTable: FunctionComponent<ExchangesTableProps> = ({ exchangesTableData, searchTerm = '' }) => {
    const exchanges = exchangesTableData.scopes.find((scope) => {
        return scope.current_scope;
    })!.exchanges;
      
    const tableData = useMemo(() => {
        return filterSearchData(exchanges, searchTerm);
    }, [exchanges, searchTerm])

    const columns = getExchangesTableColumns();

    return (
        <StyledTable className="animated fadeIn table-responsive-lg">
            <InteractiveTable
                data-test="exchanges-interactive-table"
                classes="table-responsive-lg"
                keyField="short_name"
                columns={columns}
                data={tableData}
                defaultSorted={[
                    {
                        dataField: 'name',
                        order: 'asc',
                    },
                ]}
                paginationOptions={{
                    ...defaultPaginationOptions,
                    sizePerPageRenderer: sizePerPageRenderer('up'),
                }}
            />
        </StyledTable>
    );
};

export const ExchangesList: FunctionComponent<ComponentProps> = ({
    exchangesRequest, t, searchTerm, updateSearchTerm
}) => {

    return (
        <>
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between">
                        <Col xl={3}>
                            <Input
                                data-test="search-term-control"
                                className="form-control"
                                placeholder={t('table.searchInputPlaceholder')}
                                defaultValue={searchTerm}
                                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                    updateSearchTerm(event.currentTarget.value);
                                }}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <RequestStatusRenderer
                        request={exchangesRequest}
                        failedMessage={t('exchangesAndDataCenters.noExchanges')}
                        success={() => (
                            <ExchangesTable
                                exchangesTableData={exchangesRequest.data!}
                                searchTerm={searchTerm}
                            />
                        )}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default enhance(ExchangesList);
