import React from "react";
import { FunctionComponent, useState } from "react";
import { User, AuthenticationToken } from "constants/api";
import { BaseFormModalProps } from "./BaseFormModal";
import { BaseModalTemplate, FormModalButtons } from "./BaseModalTemplate";
import { useTranslation } from "react-i18next";
import Alert from "reactstrap/lib/Alert";
import { Button } from "reactstrap";
import { ApiDataBinding, withApiData } from "react-api-data";
import { compose } from "recompose";

interface InProps {
    user: User;
}

interface EnhanceProps {
    impersonateRequest: ApiDataBinding<AuthenticationToken>;
}

type Props = InProps & EnhanceProps & BaseFormModalProps;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            impersonateRequest: 'impersonateUser'
        },
        (ownProps: InProps) => ({
            impersonateRequest: {
                userId: ownProps.user.uuid
            }
        })
    )
);

const LoginAsUserModal: FunctionComponent<Props> = ({ impersonateRequest }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const onSubmit = () => {
        impersonateRequest.perform();
    };

    return (
        <>
            <BaseModalTemplate
                isOpen={isOpen}
                toggle={toggle}
                header={t('loginAsUserModal.headerTitle')}
                body={
                    <Alert color="warning" className="mb-0">
                        <strong>{t('common.warning')}</strong>
                        <p>{t('loginAsUserModal.warningMessage')}</p>
                    </Alert>
                }
                footer={
                    <FormModalButtons
                        loading={false}
                        toggle={toggle}
                        onSubmit={onSubmit}
                    />
                }
            />

            <Button color="warning" onClick={toggle}>Login as this user</Button>
        </>
    );
};

export default enhance(LoginAsUserModal);
