import React from 'react';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { FormGroup, Col, Label, Button } from 'reactstrap';
import { Formik } from 'formik';
import { ApiResponse, NativeRouter, Organisation, ReactApiDataActions } from 'constants/api';
import { IpRouterType, NetworkRequestStatus, Roles, VlanLanType } from 'constants/literals';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import RolesChecker from 'components/utils/RolesChecker';
import AsnSelectAll from 'components/utils/AsnSelectAll';
import VlanSelectAll from 'components/utils/VlanSelectAll';
import RoutersSelect from 'components/utils/RoutersSelect';
import { CenteredAlert } from 'components/styled';

interface InputProps {
    nativeRouter: NativeRouter;
    organisation: Organisation;
}

interface EnhanceProps {
    updateNativeRouter: ApiDataBinding<ApiResponse<NativeRouter>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InputProps & BaseFormModalProps & WithTranslation;

const enhance = compose<Props, InputProps & BaseFormModalProps>(
    withApiData({
        updateNativeRouter: 'putNativeRouter',
    }),
    withTranslation()
);

type FormInitialValuesType = {
    description: string;
    rs_id: string;
    rs_type: string;
    management_address: string;
    vlan_number: number;
    ipv4_router_uuid: string | null;
    ipv6_router_uuid: string | null;
    as_number: number;
    organisation_id: number | null;
    peering_prefix_v4: string | null;
    peering_prefix_v6: string | null;
};

class NativeRouterEditModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { updateNativeRouter } = this.props;
        this.handleFormSubmission(prevProps.updateNativeRouter.request, updateNativeRouter.request, {
            successMessage: this.props.t('nativeRouterEditModal.successMessage')
        });
    };

    _handleSubmit = (values: FormInitialValuesType) => {
        const { updateNativeRouter, nativeRouter, apiDataActions } = this.props;
        updateNativeRouter.perform(
                { nativeRouterUuid: nativeRouter.uuid },{ native_router: values }
        ).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getNativeRouter', { nativeRouterUuid: nativeRouter.uuid });
                apiDataActions.perform('getNativeRouter', { nativeRouterUuid: nativeRouter.uuid });
            }
        });
    };

    render() {

        const { nativeRouter, organisation, t } = this.props;

        return (
            <>
                <Formik
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            description: nativeRouter.description,
                            rs_id: nativeRouter.name,
                            rs_type: nativeRouter.type,
                            management_address: nativeRouter.management_address,
                            vlan_number: nativeRouter.vlan.number,
                            ipv4_router_uuid: nativeRouter.ipv4_router_uuid,
                            ipv6_router_uuid: nativeRouter.ipv6_router_uuid,
                            as_number: nativeRouter.as_number,
                            organisation_id: organisation.id,
                            peering_prefix_v4: nativeRouter.peering_prefix_v4,
                            peering_prefix_v6: nativeRouter.peering_prefix_v6
                        } as FormInitialValuesType
                    }
                    onSubmit={this._handleSubmit}
                >
                    {({ values, setValues, setFieldValue, errors, isSubmitting, touched, handleSubmit }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('nativeRouterEditModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="description">{t('nativeRouterModal.description')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="description" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="rs_id">{t('nativeRouterModal.id')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="rs_id" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="rs_type">{t('nativeRouterModal.type')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="rs_type" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="management_address">
                                                {t('nativeRouterModal.managementAddress')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="management_address" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="peering_prefix_v4">
                                                {t('nativeRouterModal.peeringPrefixV4')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="peering_prefix_v4" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="peering_prefix_v6">
                                                {t('nativeRouterModal.peeringPrefixV6')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl name="peering_prefix_v6" errors={errors} touched={touched} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="as_number">{t('addVlanModal.asnSelect')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <AsnSelectAll
                                                organisationId={organisation.id}
                                                onChange={(asnNumber) => setValues({ ...values, as_number: asnNumber })}
                                                defaultValue={nativeRouter.as_number}
                                                defaultLabel={
                                                    nativeRouter.as_number
                                                        ? nativeRouter.as_number + ',  ' + organisation.name
                                                        : ''
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="vlan_number">{t('addVlanModal.vlanSelect')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <VlanSelectAll
                                                exchange={nativeRouter.exchange}
                                                excludeVlanType={[VlanLanType.PeerToPeer]}
                                                onChange={(vlan) => setValues({ ...values, vlan_number: vlan })}
                                                defaultLabel={nativeRouter.vlan.description}
                                                defaultValue={nativeRouter.vlan.number}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="Ipv4">{t('nativeRouterEditModal.Ipv4')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <RoutersSelect
                                                exchange={nativeRouter.exchange}
                                                type={IpRouterType.Ipv4Router}
                                                asnNumber={values.as_number}
                                                organisationId={organisation.id}
                                                vlanNumber={values.vlan_number}
                                                bgpRouterUuid={nativeRouter.uuid}
                                                onChange={(routerUuid) => setFieldValue('ipv4_router_uuid', routerUuid)}
                                                value={values.ipv4_router_uuid}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="Ipv6">{t('nativeRouterEditModal.Ipv6')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <RoutersSelect
                                                exchange={nativeRouter.exchange}
                                                type={IpRouterType.Ipv6Router}
                                                asnNumber={values.as_number}
                                                organisationId={organisation.id}
                                                vlanNumber={values.vlan_number}
                                                bgpRouterUuid={nativeRouter.uuid}
                                                onChange={(routerUuid) => setFieldValue('ipv6_router_uuid', routerUuid)}
                                                value={values.ipv6_router_uuid}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <CenteredAlert  color="warning">{t('nativeRouterModal.noteMandatoryFields')}</CenteredAlert>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={
                                        !values.description ||
                                        !values.rs_id ||
                                        !values.rs_type ||
                                        !values.management_address ||
                                        !values.vlan_number ||
                                        !values.as_number ||
                                        (!values.ipv4_router_uuid && !values.ipv6_router_uuid)
                                    }
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker roles={[Roles.admin, Roles.noc]}>
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('nativeRouterEditModal.editBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

export default enhance(NativeRouterEditModal);
