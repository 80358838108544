import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import get from 'lodash/get';

// constants
import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import Tooltip from 'components/utils/Tooltip';
import includes from 'lodash/includes';

interface InputProps {
    link: LinkFull;
}

interface EnhanceProps {
    onSubmit: () => void;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'deleteLag', {
        linkId: ownProps.link.uuid,
        exchange: get(ownProps, 'link.exchange.short_name')
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            dispatch(performApiRequest('deleteLag', {
                linkId: ownProps.link.uuid,
                exchange: get(ownProps, 'link.exchange.short_name')
            }));
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class LagModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            pathToError: 'response.lag.0',
            successMessage: this.props.t('notification.changeLagSuccessfull')
        });
    }

    render() {
        const { onSubmit, t, request, link } = this.props;

        const canDeleteLag = get(link, 'lag') && get(link, 'ports').length === 1 && includes(['configured', 'enabled'], get(link, 'status'));

        if (!canDeleteLag) { return <React.Fragment/>; }

        return (
            <React.Fragment>
                <BaseModalTemplate
                    data-test="mac-delete-modal-tmpl"
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('lagModal.deleteLagHeaderTitle')}
                    body={
                        <Trans i18nKey="lagModal.deleteLagInputLabel"/>
                    }
                    footer={
                        <FormModalButtons
                            data-test="mac-delete-modal-buttons"
                            loading={get(request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />
                <Tooltip
                    data-test="mac-delete-btn"
                    triggerBtn={<i className="fas fa-trash fa-pull-right mt-1 cursor-pointer text-primary" onClick={this.toggle} />}
                >
                    {t('lagModal.deleteLagHeaderTitle')}
                </Tooltip>
            </React.Fragment>
        );
    }
}
export default enhance(LagModal);
