import React, { FunctionComponent, useMemo, useState } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { ApiResponse, ContractItem } from 'constants/api';
import { compose } from 'recompose';
import Card from 'reactstrap/lib/Card';
import CardHeader from 'reactstrap/lib/CardHeader';
import { Row, Col, Input, Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CardBody from 'reactstrap/lib/CardBody';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import AlertMessage from 'components/utils/AlertMessage';
import { StyledTable } from 'components/styled';
import InteractiveTable from 'components/utils/InteractiveTable';
import memoizeOne from 'memoize-one';
import { Roles, ContractStatus } from 'constants/literals';
import { getStatusColor } from 'utils/formatUtils';
import { Link } from 'react-router-dom';
import { getLinkToContract } from 'utils/linksUtils';
import { isAdmin, isNoc } from 'utils/userUtils';
import ContractArchiveModal from 'components/modals/ContractArchiveModal';
import i18next from 'locales/i18next';
import withUserRoles from 'hocs/withUserRoles';
import { defaultPaginationOptions } from 'constants/pagination';
import { sizePerPageRenderer } from 'utils/commonUtils';

interface InProps {
    userId: string;
}

interface EnhanceProps {
    userContractsRequest: ApiDataBinding<ApiResponse<{ contracts: ContractItem[] }>>;
    userRoles: Roles[];
}

type UserContractsProps = InProps & EnhanceProps;

interface UserContractsTableProps {
    contracts: ContractItem[];
    searchTerm: string;
    userRoles: Roles[];
}

interface TableData {
    is_active: boolean,
    uuid: string,
    name: string;
    type: string;
    exchange?: string;
}

const enhance = compose<UserContractsProps, InProps>(
    withApiData(
        {
            userContractsRequest: 'getUserContracts'
        },
        (ownProps: InProps) => ({
            userContractsRequest: {
                userId: ownProps.userId
            }
        })
    ),
    withUserRoles
);

const buildTableData = (contracts: ContractItem[]): TableData[] => {
    return contracts.map((contract) => {
        return {
            is_active: contract.is_active,
            uuid: contract.uuid,
            name: contract.name,
            type: contract.type,
            exchange: contract.exchange
         };
    });
};

const filterTableData = (tableData: TableData[], searchTerm: string) => {
    if (searchTerm.length === 0) return tableData;

    const normalizedSearchTerm = searchTerm.toLowerCase();

    return tableData.filter((data) => data.name.toLowerCase().includes(normalizedSearchTerm));
};

const getUserContractsTableColumns = memoizeOne((userRoles: Roles[]): any => {
    let columns = [
        {
            dataField: 'is_active',
            text: i18next.t('common.status'),
            sort: true,
            formatter: (is_active?: boolean) => {
                const status = is_active ? ContractStatus.Active : ContractStatus.Inactive;
                return <Badge color={getStatusColor(status)}>{status}</Badge>;
            }
        },
        {
            dataField: 'uuid',
            text: i18next.t('connection.uuid'),
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: i18next.t('connection.contractName'),
            sort: true,
            hidden: false,
            formatter: (_: any, row: ContractItem) => (
                !row.exchange ? null : <Link to={getLinkToContract(row.exchange, row.uuid)}>{row.name}</Link>
            )
        },
        {
            dataField: 'type',
            text: i18next.t('common.type'),
            sort: true,
        },
        {
            dataField: 'exchange',
            text: i18next.t('common.exchange'),
            sort: true,
        },
        ((isAdmin(userRoles) || isNoc(userRoles)) && {
            dataField: 'archive',
            isDummyField: true,
            text: i18next.t('common.archive'),
            formatter: (_: any, row: ContractItem) => (
                !row.exchange ? null : <ContractArchiveModal contract={row} exchange={row.exchange!} />
            ),
        })
    ];

    return columns;
});

const UserContractsTable: FunctionComponent<UserContractsTableProps> = ({ contracts, userRoles, searchTerm }) => {
    const { t } = useTranslation();

    const tableData = useMemo(() => buildTableData(contracts), [contracts]);

    if (contracts.length === 0) {
        return (
            <AlertMessage
                message={t('common.noData')}
            />
        );
    }

    return (
        <StyledTable className="table-responsive-lg">
            <InteractiveTable
                columns={getUserContractsTableColumns(userRoles)}
                data={filterTableData(tableData, searchTerm)}
                keyField="uuid"
                defaultSorted={[
                    {
                        dataField: 'name',
                        order: 'asc'
                    }
                ]}
                paginationOptions={{
                    ...defaultPaginationOptions,
                    sizePerPageRenderer: sizePerPageRenderer('up'),
                }}
            />
        </StyledTable>
    );
};

const UserContracts: FunctionComponent<UserContractsProps> = ({ userContractsRequest, userRoles }) => {
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState<string>('');

    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <Row className="justify-content-left">
                    <Col xl={3}>
                        <Input
                            className="form-control"
                            name="searchTerm"
                            placeholder={t('table.searchInputPlaceholder')}
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                setSearchTerm(event.currentTarget.value);
                            }}
                        />
                    </Col>
                </Row>
            </CardHeader>

            <CardBody>
                <RequestStatusRenderer
                    request={userContractsRequest}
                    failedMessage={t('notification.unexpectedError')}
                    success={() => (
                        <UserContractsTable
                            contracts={userContractsRequest.data!.data!.contracts}
                            userRoles={userRoles}
                            searchTerm={searchTerm}
                        />
                    )}
                />
            </CardBody>
        </Card>
    );
};

export default enhance(UserContracts);
