import {
    Card, CardBody, Col, Button, FormGroup, Label,
} from 'reactstrap';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import _values from 'lodash/values';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import { getApiDataRequest, ApiDataRequest, performApiRequest } from 'react-api-data';
import { Formik, FieldArray } from 'formik';
import { string, object, array } from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import memoizeOne from 'memoize-one';

// constants
import { BgpAttribute } from 'constants/api';
import { ReduxState } from 'createStore';
import i18n from 'locales/i18next';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import withUserRoles from 'hocs/withUserRoles';
import { Roles } from 'constants/literals';
import { hasSomeRole } from 'utils/userUtils';

interface UpdatePeeringModalProps {
    bgpAttributes: BgpAttribute[];
    peeringId: string;
    routerId: string;
    exchange: string;
    toggle: () => void;
    isOpen: boolean;
}

interface UpdatePeeringModalContainerProps {
    request: ApiDataRequest;
    onSubmit: (values: any) => void;
    userRoles: Roles[];
}

type ModalProps = UpdatePeeringModalProps & UpdatePeeringModalContainerProps & BaseFormModalProps & WithTranslation;

interface Option {
    option: string | number;
    label: string;
    disabled?: boolean;
}

interface Attribute {
    label: string;
    name: string;
    type: string;
    options?: Option[];
}

const getAttributes = memoizeOne((t: i18n.TFunction): { [key: string]: Attribute } => {
    return {
        'peer-group': {
            label: 'Peer group',
            name: 'peer-group',
            type: 'text'
        },
        'password': {
            label: 'Password',
            name: 'password',
            type: 'text'
        },
        'filtering': {
            label: 'Filtering',
            name: 'filtering',
            type: 'select',
            options: [{
                option: '',
                disabled: true,
                label: t('peering.selectFiltering')
            }, {
                option: 'tag-only',
                label: t('common.tagOnly')
            }, {
                option: 'rpki+irrdb',
                label: t('common.rpkiIrrdb')
            }, {
                option: 'irrdb',
                label: t('common.irrdb')
            }, {
                option: 'rpki',
                label: t('common.rpki')
            }]
        },
        'ttl': {
            label: 'TTL',
            name: 'ttl',
            type: 'text'
        },
        'prefix_limit_v4': {
            label: 'Prefix limit v4',
            name: 'prefix_limit_v4',
            type: 'text'
        },
        'prefix_limit_v6': {
            label: 'Prefix limit v6',
            name: 'prefix_limit_v6',
            type: 'text'
        },
        'as_set_v4': {
            label: 'AS SET v4',
            name: 'as_set_v4',
            type: 'text'
        },
        'as_set_v6': {
            label: 'AS SET v6',
            name: 'as_set_v6',
            type: 'text'
        },
        'maps_microsoft': {
            label: 'Maps Microsoft',
            name: 'maps_microsoft',
            type: 'text'
        },
        'maps_client': {
            label: 'Maps Client',
            name: 'maps_client',
            type: 'text'
        }
    };
});

const getAttributesList = memoizeOne((t: i18n.TFunction): Attribute[] => _values(getAttributes(t)));

export const createRequestBody = (propsAttributes: BgpAttribute[], formAttributes: Array<{ id?: string; name: string; value: string; }>) => {
    const requestBody = {
        peering: {
            bgp_attributes: cloneDeep(formAttributes),
        }
    };
    propsAttributes.forEach((attribute: BgpAttribute) => {
        const isRemoved = !find(formAttributes, ['id', attribute.id]);
        if (isRemoved) {
            const cloneAttribute: any = cloneDeep(attribute);
            cloneAttribute.delete = true;
            requestBody.peering.bgp_attributes.push(cloneAttribute);
        }
    });
    return requestBody;
};

const peeringModalMapStateToProps = (state: ReduxState, ownProps: UpdatePeeringModalProps) => ({
    request: getApiDataRequest(state.apiData, 'putPeeringAttributes', {
        peeringId: ownProps.peeringId,
        routerId: ownProps.routerId,
        exchange: ownProps.exchange.toUpperCase()
    })
});

const peeringModalmapDispatchToProps = (dispatch: any, ownProps: UpdatePeeringModalProps) => {
    return {
        onSubmit: (formValues: any) => {
            const requestBody = createRequestBody(ownProps.bgpAttributes, formValues.bgpAttributes);
            dispatch(performApiRequest('putPeeringAttributes', {
                peeringId: ownProps.peeringId,
                routerId: ownProps.routerId,
                exchange: ownProps.exchange.toUpperCase()
            }, requestBody));
            return true;
        }
    };
};

class PeeringAttributesUpdateModal extends BaseFormModal<ModalProps> {

    componentDidUpdate(prevProps: Readonly<ModalProps>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            successMessage: this.props.t('notification.peeringAttributesUpdated')
        });
        if (!this.props.isOpen && prevProps.isOpen && this.formik) {
            this.formik.resetForm({ bgpAttributes: this.props.bgpAttributes });
        }
    }

    render() {
        // Notice: It gets isOpen, toggle options from its props, but not from its state, like other modals do
        const { onSubmit, bgpAttributes, toggle, isOpen, userRoles, t } = this.props;
        return (
            <Formik
                data-test="form"
                ref={formik => this.formik = formik}
                initialValues={{
                    bgpAttributes: bgpAttributes || []
                }}
                validationSchema={object().shape({
                    bgpAttributes: array().of(object().shape({
                        id: string(),
                        name: string().required(t('validation.nameRequired')),
                        value: string().required(t('validation.valueRequired'))
                    }))
                })}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, isSubmitting, dirty, handleSubmit, handleChange, setFieldValue }) => (
                    <BaseFormModalTemplate
                        data-test="peering-attr-modal-tmpl"
                        isOpen={isOpen}
                        toggle={toggle}
                        onClosed={this.handleModalClose}
                        handleSubmit={handleSubmit}
                        header={t('peering.modalHeaderTitle')}
                        body={
                            <FieldArray
                                name="bgpAttributes"
                                render={arrayHelpers => (
                                    <>
                                        {values.bgpAttributes.map((bgpAttribute: BgpAttribute, index: number) => (
                                            <Card key={index} className="card-accent-primary">
                                                <CardBody>
                                                    <FormGroup row>
                                                        <Col lg="3" md="2" sm="2">
                                                            <Label>{t('common.name')}:</Label>
                                                        </Col>
                                                        <Col lg="8" md="8" sm="8">
                                                            <FormControl
                                                                component="select"
                                                                name={this.getPathToField('name', index)}
                                                                errors={errors}
                                                                touched={touched}
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                    setFieldValue(this.getPathToField('value', index), '');
                                                                }}
                                                            >
                                                                {getAttributesList(t).map((attribute: Attribute) => (
                                                                    <option
                                                                        key={attribute.name}
                                                                        value={attribute.name}
                                                                    >
                                                                        {attribute.label}
                                                                    </option>
                                                                ))}
                                                            </FormControl>
                                                        </Col>
                                                        <Col lg="1" md="1" sm="1">
                                                            <i
                                                                className="fas fa-trash text-danger cursor-poiner font-lg btn"
                                                                title={t('peering.removeAttribute')}
                                                                onClick={() => arrayHelpers.remove(index)}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Col lg="3" md="2" sm="2">
                                                            <Label>{t('common.value')}:</Label>
                                                        </Col>
                                                        <Col lg="8" md="8" sm="8">
                                                            {this.getValueFieldControl(t, bgpAttribute, index, errors, touched, userRoles)}
                                                        </Col>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        ))}
                                        <Button
                                            color="primary"
                                            onClick={() => arrayHelpers.push({ name: 'peer-group', value: '' })}
                                        >
                                            {t('peering.addNewAttribute')}
                                        </Button>
                                    </>
                                )}
                            />
                        }
                        footer={
                            <FormModalButtons
                                data-test="peering-attr-modal-buttons"
                                loading={isSubmitting}
                                toggle={toggle}
                            />
                        }
                    />
                )}
            </Formik>
        );
    }

    private getPathToField = (fieldName: string, index: number): string => {
        return `bgpAttributes.${index}.${fieldName}`;
    }

    private getValueFieldControl = (t: i18n.TFunction, bgpAttribute: BgpAttribute, index: number, errors: any, touched: any, userRoles: Roles[]): ReactNode => {
        const attributes = getAttributes(t);
        const selectedAttribute: Attribute = get(attributes, `${bgpAttribute.name}`);

        if (selectedAttribute.name === 'filtering' && !hasSomeRole(userRoles, Roles.admin, Roles.noc)){
            const tagOnlyOption = selectedAttribute.options!.find((option) => option.option === 'tag-only');
            if (tagOnlyOption)
                tagOnlyOption.disabled = true;
        }

        const ControlField = (
            <FormControl
                type="text"
                name={this.getPathToField('value', index)}
                errors={errors}
                touched={touched}
            />
        );
        if (!selectedAttribute) {
            return ControlField;
        }
        if (selectedAttribute.type === 'password') {
            return React.cloneElement(ControlField, { type: 'password' });
        } else if (selectedAttribute.type === 'select') {
            const Options = get(selectedAttribute, 'options', []).map((attributeOption: Option) => (
                <option
                    key={attributeOption.option}
                    value={attributeOption.option}
                    disabled={attributeOption.disabled}
                >
                    {attributeOption.label}
                </option>
            ));
            const SelectField = React.cloneElement(
                ControlField,
                { component: 'select' },
                Options
            );
            return SelectField;
        } else {
            return ControlField;
        }
    }
}

const PeeringAttributesUpdateModalContainer = compose<ModalProps, UpdatePeeringModalProps>(
    connect(peeringModalMapStateToProps, peeringModalmapDispatchToProps),
    withTranslation(),
    withUserRoles,
)(PeeringAttributesUpdateModal);

export default PeeringAttributesUpdateModalContainer;
