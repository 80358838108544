import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ApiDataBinding, withApiData, invalidateApiDataRequest, performApiRequest, getEntity, ApiDataState } from 'react-api-data';
import { ApiResponse, Switch } from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import SwitchPorts from 'components/cards/SwitchPorts';
import { ReduxState } from 'createStore';
import { locationSchema } from 'constants/endpointConfig';

interface SwitchDetailsProps {
    switchComp: Switch;
    apiData: ApiDataState;
    refetchSwitch: () => undefined;
}

function SwitchDetails(props: SwitchDetailsProps) {
    const { switchComp, apiData, refetchSwitch } = props;
    const { t } = useTranslation();
    const location = getEntity(apiData, locationSchema, switchComp.location_uuid);

    return (
        <>
            <Helmet title={switchComp.name} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('switchDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('switchDetails.refreshButton')}
                                onClick={refetchSwitch}
                            />
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('switchDetails.SwitchDetailsTitle')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('switchDetails.name')}</th>
                                            <td>{switchComp.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('switchDetails.technology')}</th>
                                            <td>{switchComp.platform}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('switchDetails.scope')}</th>
                                            <td>{switchComp.scope}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('switchDetails.location')}</th>
                                            <td>{location.description}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('switchDetails.type')}</th>
                                            <td>{switchComp.type}</td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>{t('switchDetails.portsTitle')}</h2>
                        <hr />
                        <SwitchPorts switchData={switchComp} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type SwitchProps = RouteComponentProps<{ name: string }>;

interface ApiProps {
    switchRequest: ApiDataBinding<ApiResponse<Switch>>;
    apiData: ApiDataState;
    searchTerm: string;
    updateSearchTerm: (searchTerm?: string) => void;
    refetchSwitch: () => undefined;
}

type WrapperProps = ApiProps & SwitchProps;

function SwitchDetailsWrapper(props: WrapperProps) {
    const { switchRequest, apiData, refetchSwitch } = props;
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={switchRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => (
                <SwitchDetails
                    switchComp={switchRequest.data!.data!}
                    apiData={apiData}
                    refetchSwitch={refetchSwitch}
                />
            )}
        />
    );
}

const connectApiData = withApiData({ switchRequest: 'getSwitch' }, (ownProps: SwitchProps) => ({
    switchRequest: {
        name: ownProps.match.params.name
    },
}));

const mapStateToProps = (state: ReduxState) => ({
    apiData: state.apiData,
});

const mapDispatchToProps = (dispatch: any, ownProps: WrapperProps) => ({
    refetchSwitch: () => {
        const params = { name: ownProps.match.params.name };
        dispatch(invalidateApiDataRequest('getSwitch', params));
        dispatch(performApiRequest('getSwitch', params));
    },
});

const enhance = compose<WrapperProps, SwitchProps>(connectApiData, connect(mapStateToProps, mapDispatchToProps));

export default enhance(SwitchDetailsWrapper);


