import {
    Button, Card, CardHeader,
} from 'reactstrap';
import React from 'react';
import { getApiDataRequest, performApiRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { withRouter } from 'react-router';
import { ApiDataRequest } from 'react-api-data/lib';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Router } from 'constants/api';

// components
import Tooltip from 'components/utils/Tooltip';
import { BaseModalTemplate } from 'components/modals/BaseModalTemplate';
import BaseModal from 'components/modals/BaseModal';
import LoaderButton from 'components/utils/LoaderButton';

interface InputProps {
    router: Router;
    exchange: string;
}

interface EnhanceProps {
    onLoadImport: () => boolean;
    onLoadExport: () => boolean;
    importRequest: ApiDataRequest;
    exportRequest: ApiDataRequest;
    isLoading: boolean;
}

type Props = EnhanceProps & InputProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    importRequest: getApiDataRequest(state.apiData, 'getRouterPeerings', { routerId: ownProps.router.uuid, exchange: ownProps.exchange }),
    exportRequest: getApiDataRequest(state.apiData, 'getRouterPeerings', { routerId: ownProps.router.uuid, exchange: ownProps.exchange }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onLoadImport: () => {
            dispatch(performApiRequest('getRouterPeerings', { routerId: ownProps.router.uuid, exchange: ownProps.exchange }));
            return true;
        },
        onLoadExport: () => {
            dispatch(performApiRequest('getRouterPeerings', { routerId: ownProps.router.uuid, exchange: ownProps.exchange }));
            return true;
        },
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    withRouter,
    withState('loading', 'setLoading', false),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class RsPeeringFilteringModal extends BaseModal<Props> {
    render() {
        const { onLoadImport, onLoadExport, isLoading, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={isLoading ? undefined : this.toggle}
                    header={t('rsPeeringFiltering.headerTitle')}
                    body={<>
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <LoaderButton
                                    loading={isLoading}
                                    onClick={onLoadImport}
                                >
                                    {t('rsPeeringFiltering.import')}
                                </LoaderButton>
                            </CardHeader>
                        </Card>
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <LoaderButton
                                    loading={isLoading}
                                    onClick={onLoadExport}
                                >
                                    {t('rsPeeringFiltering.export')}
                                </LoaderButton>
                            </CardHeader>
                        </Card>
                    </>}
                    footer={<Button color="light" onClick={this.toggle}>{t('modal.closeBtn')}</Button>}
                />
                <Tooltip
                    triggerBtn={
                        <i
                            className="fas fa-filter fa-pull-right mt-1 text-primary cursor-pointer"
                            onClick={this.toggle}
                        />
                    }
                >
                    {t('rsPeeringFiltering.headerTitle')}
                </Tooltip>
            </React.Fragment>
        );
    }
}

export default enhance(RsPeeringFilteringModal);
