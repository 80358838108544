import { createReducer } from 'redux-act';
import { ApiDataState } from 'react-api-data';
import get from 'lodash/get';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';

import { updateMemberLinkAction, updateLinkEntityAction, updateVlanAction, updateNativeRouterAction, archiveLinkEntityAction } from './apiDataActions';
import { getLinkSchemaId } from 'utils/linksUtils';
import { LinkFull, PublicVlanItem, ApiResponse, NativeRouter } from 'constants/api';

export const apiDataReducer = createReducer({
    [updateMemberLinkAction]: (state: ApiDataState, payload: LinkFull) => {
        const linkKey = `getMemberLink/exchange=${payload.exchange.short_name}&linkId=${payload.uuid}`;
        return {
            ...state,
            requests: {
                ...state.requests,
                [linkKey]: {
                    ...state.requests[linkKey],
                    result: payload
                }
            }
        };
    },
    [archiveLinkEntityAction]: (state: ApiDataState, payload: { exchange: string, linkUuid: string }) => {
        const linkShemaId = getLinkSchemaId(payload.exchange, payload.linkUuid);
        const link = state.entities.link[linkShemaId] as LinkFull;
        const allMemberLinksKey = `getAllMemberLinks/exchange=${payload.exchange}`;
        const getContractLinksKey = `getContractLinks/contractUuid=${link.contract_uuid}&exchange=${payload.exchange}`;
        const newLinkEntities = { ...state.entities.link }
        delete newLinkEntities[linkShemaId];
        return {
            ...state,
            entities: {
                ...state.entities,
                link: newLinkEntities,
            },
            requests: {
                ...state.requests,
                [allMemberLinksKey]: {
                    ...state.requests[allMemberLinksKey],
                    result: state.requests[allMemberLinksKey].result.filter(
                        (schemaId: string) => schemaId !== linkShemaId
                    ),
                },
                [getContractLinksKey]: {
                    ...state.requests[getContractLinksKey],
                    result: state.requests[getContractLinksKey].result.filter(
                        (link: LinkFull) => link.uuid !== payload.linkUuid
                    ),
                }
            }
        };
    },
    [updateLinkEntityAction]: (state: ApiDataState, payload: LinkFull) => {
        const linkShemaId = getLinkSchemaId(payload.exchange.short_name, payload.uuid);
        const allMemberLinksKey = `getAllMemberLinks/exchange=${payload.exchange.short_name}`;
        let linkIds = get(state, `requests.${allMemberLinksKey}.result`, []);
        if (!includes(linkIds, linkShemaId)) {
            linkIds = uniq([...linkIds, linkShemaId]);
        }
        return {
            ...state,
            entities: {
                ...state.entities,
                link: {
                    ...state.entities.link,
                    [linkShemaId]: payload
                }
            },
            requests: {
                ...state.requests,
                [allMemberLinksKey]: {
                    ...state.requests[allMemberLinksKey],
                    result: linkIds
                }
            }
        };
    },
    [updateVlanAction]: (state: ApiDataState, payload: ApiResponse<PublicVlanItem>) => {
        const vlanKey = `getVlan/vlanUuid=${payload.data!.uuid}`;
        return {
            ...state,
            requests: {
                ...state.requests,
                [vlanKey]: {
                    ...state.requests[vlanKey],
                    result: payload,
                },
            },
        };
    },
    [updateNativeRouterAction]: (state: ApiDataState, payload: ApiResponse<NativeRouter>) => {
        const nativeRouterKey = `getNativeRouter/nativeRouterUuid=${payload.data!.uuid}`;
        return {
            ...state,
            requests: {
                ...state.requests,
                [nativeRouterKey]: {
                    ...state.requests[nativeRouterKey],
                    result: payload,
                },
            },
        };
    }
});
