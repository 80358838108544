import {
    Button, Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ApiDataRequest, performApiRequest, getApiDataRequest, getResultData } from 'react-api-data';
import { number, object } from 'yup';
import get from 'lodash/get';
import size from 'lodash/size';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, Exchange, LinkFull, PublicVlanItem } from 'constants/api';
import { Roles, LinkVlanMode } from 'constants/literals';
import { createTagValidation, createMacAddressValidation } from 'validation/index';
import i18n from 'locales/i18next';

// components
import { ReduxState } from 'createStore';
import AsnSelectAll from 'components/utils/AsnSelectAll';
import AsnSelectContract from 'components/utils/AsnSelectContract';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import { isCustomer, hasSomeRole } from 'utils/userUtils';
import { getUserRolesSelector } from 'redux/apiData/apiDataSelectors';
import { BaseModalState } from './BaseModal';
import ContractSelect from 'components/utils/ContractSelect';
import Tooltip from 'components/utils/Tooltip';
import { TAG_MIN_VALUE, TAG_MAX_VALUE } from 'constants/index';

interface InputProps {
    link: LinkFull;
    exchange: Exchange;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    submission: ApiDataRequest;
    vlansResponse: ApiResponse<{ vlans: PublicVlanItem[] }>;
    userRoles: Roles[];
    fetchVlansRequest: (exchange: string) => undefined;
}

type Props = EnhanceProps & InputProps & BaseFormModalProps & WithTranslation;

export const VLAN_REQUEST_STATUS_PARAM = 'configured';
export const VLAN_REQUEST_ORDER_PARAM = 'description';

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    submission: getApiDataRequest(state.apiData, 'postLinkVlan', {
        linkId: ownProps.link.uuid,
        exchange: ownProps.exchange.short_name,
    }),
    vlansResponse: getResultData(state.apiData, 'getVlans', {
        exchange: ownProps.exchange.short_name,
        status: VLAN_REQUEST_STATUS_PARAM,
        order: VLAN_REQUEST_ORDER_PARAM
    }),
    userRoles: getUserRolesSelector(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => ({
    fetchVlansRequest: (selectedExchange: string) => {
        if (!selectedExchange) return;
        dispatch(performApiRequest('getVlans', {
            exchange: selectedExchange,
            status: VLAN_REQUEST_STATUS_PARAM,
            order: VLAN_REQUEST_ORDER_PARAM
        }));
    },
    onSubmit: (values: any) => dispatch(performApiRequest(
        'postLinkVlan',
        {
            linkId: ownProps.link.uuid,
            exchange: ownProps.exchange.short_name,
        },
        { link_vlan: values }
    )),
});

const enhance = compose<Props, InputProps & BaseFormModalProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

type FormValuesType = {
    vlan_number: number | undefined,
    as_number: number | null,
    mode: string,
    qtag: string,
    inner_qtag: string,
    speed: number,
    mac_address: string,
    organisation_id: number | null,
    organisation_uuid: string | null,
    erp_contract_identifier: string | null
    virtual: boolean,
    external_id: string | null,
};

class LinkVlanAddModal extends BaseFormModal<Props, BaseModalState> {

    componentDidMount() {
        if (!this.props.vlansResponse) {
            this.props.fetchVlansRequest(this.props.exchange.short_name);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.submission, this.props.submission, {
            successMessage: this.props.t('notification.addLinkVlanSuccessful'),
            pathToError: 'errorBody.errors'
        });
    }

    _onSubmit = (values: FormValuesType) => {
        const params = {
            vlan_number: values.vlan_number,
            as_number: values.as_number,
            mode: values.mode,
            qtag: values.qtag,
            inner_qtag: values.inner_qtag,
            virtual: values.virtual,
            erp_contract_identifier: values.erp_contract_identifier,
            external_id: values.external_id || null,
        } as any;
        if (values.mac_address && values.mac_address !== '') {
            params.mac_addresses = [values.mac_address];
        }
        if (values.speed !== 0) {
            params.speed = values.speed;
        }

        this.props.onSubmit(params);
    }

    render() {
        const { link, exchange, t, vlansResponse } = this.props;
        const vlans = get(vlansResponse, 'data.vlans');
        const availableVlans = !!vlans ? vlans.filter((vlan: PublicVlanItem) => vlan.number === 501 ||
            !link.vlans.some(linkVlan => linkVlan.vlan_id === vlan.number
            )) : [];
        const hasVlans = size(availableVlans) > 0;
        // @todo should only be 'configured' ?
        const hasEnabledPort = link.ports && link.ports.some(port => ['configured', 'enabled'].includes(port.status));
        const hasUntaggedVlan = link.vlans && link.vlans.some(
            linkVlan => [LinkVlanMode.Untagged].includes(linkVlan.mode) && !['unconfigured', 'deconfigured'].includes(linkVlan.status)
        );

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            vlan_number: undefined,
                            as_number: null,
                            qtag: '',
                            inner_qtag: '',
                            mode: LinkVlanMode.Untagged,
                            speed: 0,
                            mac_address: '',
                            organisation_id: null,
                            organisation_uuid: '',
                            erp_contract_identifier: null,
                            virtual: false,
                            external_id: '',
                        } as FormValuesType
                    }
                    validationSchema={object().shape({
                        vlan_number: number().required(t('validation.vlanRequired')).typeError(t('validation.vlanRequired')),
                        qtag: createTagValidation(t),
                        inner_qtag: createTagValidation(t),
                        mac_address: createMacAddressValidation(t),
                    })}
                    onSubmit={this._onSubmit}
                >
                    {({ handleSubmit, setValues, setFieldValue, isSubmitting, values, errors, touched }) => (
                        <BaseFormModalTemplate
                            data-test="vlan-add-modal-tmpl"
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('addVlanModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="vlan_number">{t('addVlanModal.vlanSelect')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                component="select"
                                                name="vlan_number"
                                                errors={errors}
                                                touched={touched}
                                                required
                                            >
                                                <>
                                                    <option></option>
                                                    {
                                                        availableVlans.map((vlan: any) => (
                                                            <option value={vlan.number} key={vlan.number}>
                                                                {vlan.description}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    {hasSomeRole(this.props.userRoles, Roles.noc, Roles.admin, Roles.ixaas_client) && (
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="virtual">
                                                    {t('addVlanModal.virtual')}
                                                </Label>
                                            </Col>
                                            <Col md={1}>
                                                <FormControl
                                                    type="checkbox"
                                                    name="virtual"
                                                    onChange={(event) => setValues(
                                                        {
                                                            ...values,
                                                            virtual: event.target.checked,
                                                            as_number: null,
                                                            erp_contract_identifier: null,
                                                        }
                                                    )}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="as_number">{t('addVlanModal.asnSelect')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            {values.virtual ? (
                                                <AsnSelectAll
                                                    onChange={(asn, organisationId, organisationUuid) =>
                                                        setValues({
                                                            ...values,
                                                            as_number: asn,
                                                            organisation_id: organisationId,
                                                            organisation_uuid: organisationUuid,
                                                        })
                                                    }
                                                />
                                            ) : (
                                                <AsnSelectContract
                                                    value={values.as_number}
                                                    onChange={(asn) => setValues({ ...values, as_number: asn })}
                                                    contractUuid={link.contract_uuid}
                                                    exchange={exchange.short_name}
                                                />
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="mode">{t('common.mode')}</Label>
                                        </Col>
                                        <Col md={4}>
                                            <FormControl
                                                component="select"
                                                name="mode"
                                                errors={errors}
                                                touched={touched}
                                                onChange={(event) => {
                                                    const newValue = event.target.value;
                                                    if (newValue === LinkVlanMode.Untagged) {
                                                        setValues({ ...values, mode: newValue, qtag: '', inner_qtag: '' })
                                                    } else if (newValue === LinkVlanMode.Tagged) {
                                                        setValues({ ...values, mode: newValue, inner_qtag: '' })
                                                    } else {
                                                        setFieldValue('mode', newValue);
                                                    }
                                                }}
                                            >
                                                {hasSomeRole(this.props.userRoles, Roles.noc, Roles.admin) ? (
                                                    <>
                                                        {Object.values(LinkVlanMode).map((tag: string) => (
                                                            <option value={tag} key={tag}>
                                                                {tag}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {[LinkVlanMode.Untagged, LinkVlanMode.Tagged].map((tag: string) => (
                                                            <option value={tag} key={tag}>
                                                                {tag}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </FormControl>
                                        </Col>
                                        {(values.mode === LinkVlanMode.Tagged || values.mode === LinkVlanMode.Dual) && (
                                            <>
                                                <Col md={1}>
                                                    <div id="qtagLabel">
                                                        <Label htmlFor="qtag">{t('common.qtag')}</Label>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div id="qtag">
                                                        <FormControl
                                                            type="number"
                                                            name="qtag"
                                                            min={TAG_MIN_VALUE}
                                                            max={TAG_MAX_VALUE}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </FormGroup>
                                    {values.mode === LinkVlanMode.Dual && (
                                        <>
                                            <FormGroup row>
                                                <Col md={7}></Col>
                                                <Col md={1}>
                                                    <div id="innerQtagLabel">
                                                        <Label htmlFor="inner_qtag">{t('common.innerTag')}</Label>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div id="inner_qtag">
                                                        <FormControl
                                                            type="number"
                                                            name="inner_qtag"
                                                            min={TAG_MIN_VALUE}
                                                            max={TAG_MAX_VALUE}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                        </>
                                    )}
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="mac_address">
                                                {values.virtual ? t('common.macAddress') : t('common.macAddressOptional')}
                                            </Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="string"
                                                name="mac_address"
                                                errors={errors}
                                                touched={touched}
                                                required={values.virtual}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {values.virtual && (
                                        <>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="speed">{t('common.speed')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <FormControl
                                                        type="number"
                                                        name="speed"
                                                        errors={errors}
                                                        touched={touched}
                                                        required={values.virtual}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="erp_contract_identifier">
                                                        {t('common.externalCustomerId')}
                                                    </Label>
                                                </Col>
                                                <Col md={9}>
                                                    <ContractSelect
                                                        organisationId={values.organisation_id ? values.organisation_id : 0}
                                                        organisationUuid={
                                                            values.organisation_uuid ? values.organisation_uuid : ' '
                                                        }
                                                        onChange={(erpContractId) =>
                                                            setFieldValue(
                                                                'erp_contract_identifier',
                                                                erpContractId
                                                            )
                                                        }
                                                        value={values.erp_contract_identifier}
                                                        asnNumber={values.as_number}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </>
                                    )}
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="external_id">{t('common.externalId')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <FormControl
                                                type="string"
                                                name="external_id"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            footer={<FormModalButtons loading={isSubmitting} toggle={this.toggle} />}
                        />
                    )}
                </Formik>
                <RolesChecker
                    data-test="user-roles-checker"
                    roles={[Roles.admin, Roles.noc, Roles.customer, Roles.ixaas_client, Roles.qnoc]}
                >
                    {isCustomer(this.props.userRoles)
                        ? process.env.REACT_APP_CUSTOMER_SHOW_ADD_VLAN === 'true' &&
                        this.renderAddVlanBtn(hasEnabledPort, hasVlans, hasUntaggedVlan, t)
                        : this.renderAddVlanBtn(hasEnabledPort, hasVlans, hasUntaggedVlan, t)}
                </RolesChecker>
            </React.Fragment>
        );
    }

    private renderAddVlanBtn = (hasEnabledPort: boolean, hasVlans: boolean, hasUntaggedVlan: boolean, t: i18n.TFunction) => {
        let disabledMessage;
        if (!hasEnabledPort) {
            disabledMessage = t('addVlanModal.noEnabledPorts');
        } else if (!hasVlans) {
            disabledMessage = t('addVlanModal.noVlans');
        } else if (hasUntaggedVlan) {
            disabledMessage = t('addVlanModal.untaggedLinkVlanPresent');
        }

        const disabled = !hasEnabledPort || !hasVlans || hasUntaggedVlan;

        const button = (
            <Button
                onClick={disabled ? () => {} : this.toggle}
                className={`float-right ${disabled ? 'disabled' : ''}`}
            >
                {t('addVlanModal.addVlanBtn')}
            </Button>
        );

        return (
            <Tooltip triggerBtn={button} data-test="vlan-add-btn-tooltip">
                {disabledMessage}
            </Tooltip>
        );
    }
}

export default enhance(LinkVlanAddModal);
