import React, { FunctionComponent } from 'react';
import { Col, Jumbotron, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';

import RecentConnections from 'components/cards/RecentConnections';
import TotalStatistics from 'components/cards/TotalStatistics';
import SflowTrafficStatistics from 'components/cards/SflowTrafficStatistics';
import ViewContainer from 'components/utils/ViewContainer';

const Dashboard: FunctionComponent<WithTranslation> = ({ t }) => {
    const tips = t('dashboard.tips');

    return (
        <>
            <Helmet title="Dashboard" />
            <ViewContainer>
                <Row>
                    <Col xl={6}>
                        <Jumbotron className="bg-primary">
                            <h1>{t('dashboard.welcome')} {process.env.REACT_APP_APPLICATION_NAME}</h1>
                            <hr />
                            <p>{t('dashboard.tip')}: {tips[Math.floor(Math.random() * tips.length)]}</p>
                        </Jumbotron>
                        <TotalStatistics />
                        <SflowTrafficStatistics />
                    </Col>
                    <Col xl={6}>
                        <RecentConnections />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default withTranslation()(Dashboard);