
import { Col, FormGroup } from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { ApiDataRequest, performApiRequest, getApiDataRequest, EndpointParams } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull, RateLimiter } from 'constants/api';
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import Tooltip from 'components/utils/Tooltip';
import DeleteBtnConditionalRenderer from '../utils/DeleteBtnConditionalRenderer';
import { get } from 'lodash';

interface InputProps {
    link: LinkFull;
    rateLimiter?: RateLimiter;
    endpointKey: string;
    currentSpeed?: number;
    endpointParams: EndpointParams;
}

interface EnhanceProps {
    onSubmit: () => void;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps & RouteComponentProps<{
    exchange: string;
}>;

const mapStateToProps = (state: ReduxState, ownProps: Props) => {
    return {
        request: getApiDataRequest(state.apiData, ownProps.endpointKey, ownProps.endpointParams),
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest(ownProps.endpointKey, ownProps.endpointParams, values));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class RateLimiterDeleteModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            successMessage: this.props.t('notification.deleteRateLimiterSuccessful'),
        });
    }

    render() {
        const { onSubmit, rateLimiter, t } = this.props;

        if (!rateLimiter || !get(rateLimiter, 'output.limit')) return <React.Fragment/>;

        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{}}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            header={t('deleteRateLimiter.headerTitle')}
                            handleSubmit={handleSubmit}
                            onClosed={this.handleModalClose}
                            body={
                                <FormGroup row className="mb-0">
                                    <Col md={9}>
                                        {t('deleteRateLimiter.modalText')}
                                    </Col>
                                </FormGroup>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                />
                            }
                        />)}
                </Formik>
                <DeleteBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    rateLimiter={rateLimiter}
                    roles={[Roles.admin, Roles.customer, Roles.ixaas_client]}
                >
                    <Tooltip
                        data-test="deleter-rl-btn-tooltip"
                        triggerBtn={
                            <i
                                className="fas fa-trash fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('deleteRateLimiter.removeRateLimiterIcon')}
                    </Tooltip>
                </DeleteBtnConditionalRenderer>
            </React.Fragment>
        );
    }
};

export default enhance(RateLimiterDeleteModal);
