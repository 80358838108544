import {
    Button, Col, FormGroup, Label, Spinner, Alert,
} from 'reactstrap';
import React from 'react';
import { Field, Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApiDataRequest, performApiRequest, getApiDataRequest, ApiDataState } from 'react-api-data';
import { object, string } from 'yup';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Exchanges } from 'constants/api';
import { Roles, VlanLanType, VlanUse } from 'constants/literals';

// utils
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import { getLinkToVlan } from 'utils/linksUtils';

// components
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import { updateAppPathAction } from '../../redux/app/appActions';
import withUserRoles from 'hocs/withUserRoles';
import { isAdmin, isIxaasClient, isNoc } from 'utils/userUtils';

interface InProps {
    exchangesData: Exchanges;
    targetExchange: string;
    switchExchanges: (exchange: string) => void;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    submission: ApiDataRequest;
    selectExchange: (exchange: string) => void;
    apiData: ApiDataState;
    userRoles: Roles[];
}

type Props = EnhanceProps & InProps & BaseFormModalProps & RouteComponentProps & WithTranslation;

const mapStateToProps = (state: ReduxState) => ({
    submission: getApiDataRequest(state.apiData, 'postVlan'),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest(
                'postVlan',
                undefined,
                {
                    exchange: ownProps.targetExchange,
                    vlan: values
                }
            ));
            return true;
        },
        selectExchange: (selectedExchange: string) => {
            ownProps.switchExchanges(selectedExchange);
            dispatch(updateAppPathAction('vlans.selectedExchange', selectedExchange));
        },
    };
};

const enhance = compose<EnhanceProps, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTranslation(),
    withUserRoles
);

class VlanAddModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.submission, this.props.submission, {
            successMessage: this.props.t('vlanAddModal.successMessage')
        });
    }

    changeExchange(exchange: string): void {
        if (!exchange) return;
        this.props.selectExchange(exchange);
    }

    render() {
        const { onSubmit, exchangesData, userRoles, t } = this.props;

        if (!exchangesData) return <Spinner data-test="spinner" />;

        return (
            <>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        cpu_protection: false,
                        description: '',
                        lan_type: isIxaasClient(userRoles) ? VlanLanType.PeerToPeer : VlanLanType.PeeringLan,
                        // tslint:disable-next-line:max-line-length
                        use: VlanUse.Customer, // When changing this make sure that IXaaS client users can only add VLANs with `use` equal to `customer`
                        auto_assign_ips: false,
                    }}
                    validationSchema={object().shape({
                        description: string().required(t('validation.descriptionRequired')),
                        lan_type: string().required(t('validation.typeRequired')),
                        use: string().required(t('validation.useRequired')),
                    })}
                    onSubmit={() => onSubmit(this.formik.state.values)}
                >
                    {({ handleSubmit, isSubmitting, errors, touched, values }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('vlanAddModal.headerTitle')}
                            body={<>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="exchange">{t('common.exchange')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <ExchangeSelect
                                            name="exchange"
                                            exchanges={exchangesData}
                                            defaultValue={this.props.targetExchange}
                                            onChange={(event: any) => this.changeExchange(event.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="description">{t('vlanModal.description')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            type="text"
                                            name="description"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="lan_type">{t('vlanModal.lanType')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            component="select"
                                            name="lan_type"
                                            errors={errors}
                                            touched={touched}
                                            disabled={isIxaasClient(userRoles)}
                                        >
                                            {Object.values(VlanLanType).map((lanType) => (
                                                <option key={lanType} value={lanType}>
                                                    {lanType}
                                                </option>
                                            ))}
                                        </FormControl>

                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="use">{t('vlanModal.use')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            component="select"
                                            name="use"
                                            errors={errors}
                                            touched={touched}
                                            disabled={isIxaasClient(userRoles)}
                                        >
                                            {Object.values(VlanUse).map((use) => (
                                                <option key={use} value={use}>
                                                    {use}
                                                </option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                                <FormGroup row hidden={!isAdmin(userRoles) && !isNoc(userRoles)}>
                                    <Col md={3}>
                                        <Label htmlFor="cpu_protection">{t('vlanModal.cpuProtection')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Field
                                            type="checkbox"
                                            name="cpu_protection"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="auto_assign_ips">{t('vlanModal.autoAssignIps')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Field
                                            type="checkbox"
                                            name="auto_assign_ips"
                                        />
                                    </Col>
                                </FormGroup>
                                {values.auto_assign_ips && (
                                    <Alert color="danger">{t('vlanModal.autoAssignIpsWarning')}</Alert>
                                )}
                            </>}
                            footer={<FormModalButtons loading={isSubmitting} toggle={this.toggle} />}
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.ixaas_client]}>
                    <Button
                        className="float-right"
                        color="light"
                        onClick={this.toggle}
                    >
                        {t('vlanAddModal.addBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }

    protected afterSuccessCallback = () => {
        const { submission } = this.props;
        const vlanUuid = get(submission, 'result.data.uuid');
        const currentExchange = this.props.targetExchange;
        this.props.history.push(getLinkToVlan(currentExchange, vlanUuid));
    }
}

export default enhance(VlanAddModal);
