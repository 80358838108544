import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data/lib';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiResponse, IpAddress } from 'constants/api';
import get from 'lodash/get';
import { NetworkRequestStatus } from '../../constants/literals';
import { NONE_SELECTED } from 'constants/index';

interface InProps {
    onChange: (ipv4Id: number | null) => void;
    exchange: string;
    vlanNumber: number;
    value: number | null;
}

type Ipv4SelectProps = InProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    freeIpv4Request: ApiDataBinding<ApiResponse<{ ips: IpAddress[] }>>;
    currentIpv4Request: ApiDataBinding<ApiResponse<IpAddress>>;
}

const enhance = compose<Ipv4SelectProps, InProps>(
    withApiData({
        freeIpv4Request: 'getFreeIpv4',
        currentIpv4Request: 'getIpv4'
    }, (ownProps) => ({
        freeIpv4Request: {
            exchange: ownProps.exchange,
            vlan: ownProps.vlanNumber,
        },
        currentIpv4Request: {
            id: ownProps.value,
        },
    })),
    withTranslation()
);

interface OptionType {
    label: string;
    value: number | null;
}

const Ipv4Select: FunctionComponent<Ipv4SelectProps> = ({ freeIpv4Request, currentIpv4Request, value, onChange }) => {
    const ips = get(freeIpv4Request, 'data.data.ips', []);
    const optionNone = useMemo(() => ({ label: NONE_SELECTED, value: null }), []);
    const options = useMemo(() => {
        let allIps = ips;
        if (currentIpv4Request.data) {
            allIps = [...allIps, currentIpv4Request.data!.data!];
        }
        const ipOptions = allIps.map((ip: IpAddress) => ({ label: ip.ip, value: ip.id }))
            .sort((a: OptionType, b: OptionType) => a.label < b.label ? 1 : -1);
        return [optionNone, ...ipOptions];
    }, [ips, optionNone, currentIpv4Request.data]);
    const selectedOption = useMemo(() => {
        return options.find((option: OptionType) => option.value === value)
    }, [options, value]);

    const optChanged = (option: OptionType) => {
        onChange(option.value);
    };

    const isLoading = freeIpv4Request.request.networkStatus === NetworkRequestStatus.Loading ||
        currentIpv4Request.request.networkStatus === NetworkRequestStatus.Loading;

    useEffect(() => {
        if (value && !ips.find((ip: IpAddress) => ip.id === value)) {
            currentIpv4Request.perform({ id: value });
        }
    }, [])

    return (
        <Select
            options={options}
            value={selectedOption}
            defaultValue={optionNone}
            onChange={optChanged}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    );
};

export default enhance(Ipv4Select);
