import {
    Button, Col, FormGroup, Label
} from 'reactstrap';
import React from 'react';
import { Field, Formik, FormikActions } from 'formik';
import { compose, withState } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ApiResponse, Exchanges, IpRange, ReactApiDataActions } from 'constants/api';
import { IpRangeType, NetworkRequestStatus, Roles } from 'constants/literals';

// utils
import { ExchangeFormikSelect } from 'components/utils/ExchangeSelect';

// components
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';

import VlanSelectAll from 'components/utils/VlanSelectAll';
import { validateIpAddress } from 'components/utils/IpValidator';
import { object, string } from 'yup';
import { getOnlyOneExchangeName } from 'utils/linksUtils';

interface InProps {
    exchangesData: Exchanges;
}

interface EnhanceProps {
    onSubmit: () => boolean;
    userRoles: Roles[];
    ipRangeAdd: ApiDataBinding<ApiResponse<IpRange>>;
    apiDataActions: ReactApiDataActions;
}

interface WithStateProps {
    targetExchange: string;
    setTargetExchange: (exchange: string) => void;
}

type Props = EnhanceProps & InProps & BaseFormModalProps & RouteComponentProps & WithTranslation & WithStateProps;

const enhance = compose<EnhanceProps & WithTranslation, InProps>(
    withState('targetExchange', 'setTargetExchange', 'nl'),
    withTranslation(),
    withApiData(
        {
            ipRangeAdd: 'postIpRange'
        }
    )
);

type FormInitialValuesType = {
    value: string;
    subnet_mask: string;
    type: IpRangeType;
    description: string;
    vlan_number: number;
    public: boolean;
    exchange: string;
};

class IpRangeAddModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.ipRangeAdd.request, this.props.ipRangeAdd.request, {
            successMessage: this.props.t('ipRangeAddModal.successMessage'),
        });
    }

    _handleOnSubmit = (values: any, formikBag: FormikActions<FormInitialValuesType>) => {
        const { ipRangeAdd, apiDataActions, setTargetExchange } = this.props;
        const { exchange, ...params } = values;
        setTargetExchange(values.exchange);

        ipRangeAdd.perform({ exchange: values.exchange }, { ip_range: params }).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getIpRanges', { exchange: values.exchange });
                apiDataActions.perform('getIpRanges', { exchange: values.exchange });
                apiDataActions.invalidateCache('getVlans', { exchange: values.exchange });
                apiDataActions.perform('getVlans', { exchange: values.exchange });
            } 
        });
    };

    render() {
        const { exchangesData, t } = this.props;

        return (
            <>
                <Formik
                    data-test="form"
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            value: '',
                            subnet_mask: '',
                            type: IpRangeType.Ipv4Range,
                            description: '',
                            vlan_number: NaN,
                            public: false,
                            exchange: getOnlyOneExchangeName(exchangesData) || '',
                        } as FormInitialValuesType
                    }
                    validationSchema={object().shape({
                        value: string().required(t('validation.valueRequired')),
                        subnet_mask: string().required(t('validation.subnetMaskRequired'))
                    })}
                    onSubmit={this._handleOnSubmit}
                >
                    {({ handleSubmit, isSubmitting, errors, setValues, touched, values }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('ipRangeAddModal.headerTitle')}
                            body={
                                <>
                                    <FormGroup row>
                                        <Col md={3}>
                                            <Label htmlFor="exchange">{t('common.exchange')}</Label>
                                        </Col>
                                        <Col md={9}>
                                            <ExchangeFormikSelect
                                                name="exchange"
                                                touched={touched}
                                                errors={errors}
                                                exchanges={exchangesData}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {values.exchange && (
                                        <div>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="value">{t('ipRangeModal.value')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <FormControl
                                                        type="text"
                                                        name="value"
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="subnet_mask">{t('ipRangeModal.subnetMask')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <FormControl
                                                        type="text"
                                                        name="subnet_mask"
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="type">{t('ipRangeModal.type')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <FormControl
                                                        component="select"
                                                        name="type"
                                                        errors={errors}
                                                        touched={touched}
                                                    >
                                                        {Object.values(IpRangeType).map((ipRangeType) => (
                                                            <option key={ipRangeType} value={ipRangeType}>
                                                                {ipRangeType}
                                                            </option>
                                                        ))}
                                                    </FormControl>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="description">{t('ipRangeModal.description')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <FormControl
                                                        type="text"
                                                        name="description"
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="vlan_number">{t('ipRangeModal.vlanSelect')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <VlanSelectAll
                                                        exchange={values.exchange}
                                                        excludeVlanType={['p2p']}
                                                        onChange={(vlan) => {
                                                            setValues({ ...values, vlan_number: vlan });
                                                        }}
                                                        defaultLabel={''}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={3}>
                                                    <Label htmlFor="public">{t('ipRangeModal.public')}</Label>
                                                </Col>
                                                <Col md={9}>
                                                    <Field type="checkbox" name="public" checked={values.public} />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    )}
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!validateIpAddress(values.value, values.type)}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.ixaas_client]}>
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('ipRangeAddModal.addBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

export default enhance(IpRangeAddModal);
