import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { FormGroup, Col, Label, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import memoizeOne from 'memoize-one';
import i18next from 'i18next';
import pick from 'lodash/pick';
import get from 'lodash/get';
import size from 'lodash/size';
import { object, string, number } from 'yup';
import classNames from 'classnames';

import { ReduxState } from 'createStore';
import { StatsCounterType, StatsInterval, SflowStatsType, SflowPeerType, OptionItem } from 'constants/literals';
import { createIpValidation } from 'validation/index';

import { BaseFormModalTemplate } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalState } from './BaseModal';
import CenteredSpinner from 'components/utils/CenteredSpinner';
import SflowGraph from 'components/graphs/SflowGraph';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import PeersDropdown from 'components/utils/PeersDropdown';
import LoaderButton from 'components/utils/LoaderButton';

interface InProps {
    exchange: string;
    sflowTypes?: SflowStatsType[];
    defaultSflowType?: SflowStatsType;
    srcIp?: string;
    srcAsn?: number;
    iconClasses?: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

interface WithStateProps {
    searchValues: any;
    setSearchValues: (searchValues: any) => void;
}

type Props = EnhanceProps & InProps & WithStateProps & BaseFormModalProps & WithTranslation;

const getSflowTypes = memoizeOne((t: i18next.TFunction): OptionItem[] => {
    return [
        {
            title: t('stats.as2as'),
            value: SflowStatsType.as2as
        },
        {
            title: t('stats.ip2ip'),
            value: SflowStatsType.ip2ip
        },
    ];
});

const getTimePeriods = memoizeOne((t: i18next.TFunction): OptionItem[] => {
    return [
        {
            title: t('stats.daily'),
            value: StatsInterval.Daily
        },
        {
            title: t('stats.weekly'),
            value: StatsInterval.Weekly
        },
        {
            title: t('stats.monthly'),
            value: StatsInterval.Monthly
        },
        {
            title: t('stats.yearly'),
            value: StatsInterval.Yearly
        },
    ];
});

const getCounters = memoizeOne((t: i18next.TFunction): OptionItem[] => {
    return [
        {
            title: t('stats.bps'),
            value: StatsCounterType.bps,
        },
        {
            title: t('stats.pps'),
            value: StatsCounterType.pps,
        },
        {
            title: t('stats.sps'),
            value: StatsCounterType.sps,
        },
    ];
});

const updateIPValidation = (typeValue: SflowStatsType, t: i18next.TFunction) => {
    if (typeValue === SflowStatsType.ip2ip) {
        return createIpValidation(t);
    }
    return string();
};

const updateASNValidation = (typeValue: SflowStatsType, t: i18next.TFunction) => {
    if (typeValue === SflowStatsType.as2as) {
        return number().required(t('validation.requiredField'));
    }
    return string();
};

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'getSflowStats', ownProps.searchValues)
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            const requestParams: any = pick(values, ['type', 'interval', 'counter']);
            requestParams.exchange = ownProps.exchange;
            requestParams.src = values[`${values.type}_src`];
            requestParams.dst = values[`${values.type}_dst`];
            ownProps.setSearchValues(requestParams);
            dispatch(performApiRequest('getSflowStats', requestParams));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InProps>(
    withState('searchValues', 'setSearchValues', {}),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class SflowStatsModal extends BaseFormModal<Props, BaseModalState> {
    protected settings = {
        resetFormOnClose: true,
        closeAfterSuccess: true,
    };

    componentDidUpdate(prevProps: Props, prevState: BaseModalState) {
        const { request } = this.props;
        const requestStatus = get(request, 'networkStatus');
        if (this.formik && prevProps.request !== request && (requestStatus === 'success' || requestStatus === 'failed')) {
            this.formik.setSubmitting(false);
        }
        if (this.formik && !prevState.isOpen && this.state.isOpen) {
            this.formik.validateForm().then((errors: any) => {
                if (!size(errors)) {
                    this.formik.executeSubmit();
                }
            });
        }
    }

    render() {
        const { onSubmit, t, request, defaultSflowType, srcIp, srcAsn, iconClasses, exchange } = this.props;

        return (
            <>
                <Formik
                    ref={formik => this.formik = formik}
                    initialValues={{
                        type: defaultSflowType || '',
                        interval: 'daily',
                        counter: 'bps',
                        as2as_src: srcAsn || '',
                        as2as_dst: '',
                        ip2ip_src: srcIp || '',
                        ip2ip_dst: '',
                    }}
                    validationSchema={object().shape({
                        type: string(),
                        interval: string(),
                        counter: string(),
                        as2as_src: number().when('type', (typeValue: SflowStatsType) => updateASNValidation(typeValue, t)),
                        as2as_dst: number().when('type', (typeValue: SflowStatsType) => updateASNValidation(typeValue, t)),
                        ip2ip_src: number().when('type', (typeValue: SflowStatsType) => updateIPValidation(typeValue, t)),
                        ip2ip_dst: number().when('type', (typeValue: SflowStatsType) => updateIPValidation(typeValue, t)),
                    })}
                    onSubmit={onSubmit}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status, errors, touched, setFieldValue }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            handleSubmit={handleSubmit}
                            onClosed={this.handleModalClose}
                            header={t('sflowStats.headerTitle')}
                            body={<>
                                <FormGroup row>
                                    <Col md={4}>
                                        <Label>{t('sflowStats.selectType')}</Label>
                                        <FormControl
                                            component="select"
                                            name="type"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectType')}</option>
                                            {getSflowTypes(t).map((option: OptionItem) => (
                                                <option key={option.value} value={option.value}>{option.title}</option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('sflowStats.selectPeriod')}</Label>
                                        <FormControl
                                            component="select"
                                            name="interval"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectPeriod')}</option>
                                            {getTimePeriods(t).map((option: OptionItem) => (
                                                <option key={option.value} value={option.value}>{option.title}</option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('sflowStats.selectCounter')}</Label>
                                        <FormControl
                                            component="select"
                                            name="counter"
                                            showErrorHint={false}
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option value="" disabled>{t('sflowStats.selectCounter')}</option>
                                            {getCounters(t).map((option: OptionItem) => (
                                                <option key={option.value} value={option.value}>{option.title}</option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                                {values.type === SflowStatsType.as2as && (
                                    <FormGroup row>
                                        <Col md={6}>
                                            <Label>{t('sflowStats.enterASNumberSrc')}</Label>
                                            <br />
                                            <FormControl
                                                component="select"
                                                type="number"
                                                name="as2as_src"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                <option key={values.as2as_src} value={values.as2as_src}>{values.as2as_src}</option>
                                            </FormControl>
                                        </Col>
                                        <Col md={6}>
                                            <Label>{t('sflowStats.enterASNumberDst')}</Label>
                                            <br />
                                            <PeersDropdown
                                                name="as2as_dst"
                                                srcName="as2as_src"
                                                type="number"
                                                exchange={exchange}
                                                sflowType={SflowPeerType.as2as}
                                                src={values.as2as_src}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                {values.type === SflowStatsType.ip2ip && (
                                    <FormGroup row>
                                        <Col md={6}>
                                            <Label>{t('sflowStats.enterIPaddressSrc')}</Label>
                                            <br />
                                            <FormControl
                                                component="select"
                                                name="ip2ip_src"
                                                errors={errors}
                                                touched={touched}
                                            >
                                                <option key={values.ip2ip_src} value={values.ip2ip_src}>{values.ip2ip_src}</option>
                                            </FormControl>
                                        </Col>
                                        <Col md={6}>
                                            <Label>{t('sflowStats.enterIPaddressDst')}</Label>
                                            <br />
                                            <PeersDropdown
                                                name="ip2ip_dst"
                                                srcName="ip2ip_src"
                                                exchange={exchange}
                                                sflowType={SflowPeerType.ip2ip}
                                                src={values.ip2ip_src}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                <FormGroup className="text-right">
                                    <LoaderButton
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={isSubmitting || !!size(errors)}
                                    >
                                        {t('common.view')}
                                    </LoaderButton>
                                </FormGroup>
                                {isSubmitting ?
                                    <CenteredSpinner /> :
                                    <SflowGraph
                                        statsRequest={request}
                                        sflowType={values.type as SflowStatsType}
                                        counter={values.counter as StatsCounterType}
                                        interval={values.interval as StatsInterval}
                                    />
                                }
                            </>}
                            footer={<>
                                <Button color="light" onClick={this.toggle} disabled={isSubmitting}>
                                    {t('modal.closeBtn')}
                                </Button>
                            </>}
                        />
                    )}
                </Formik>
                <Tooltip
                    triggerBtn={
                        <i
                            className={classNames([
                                'fas fa-chart-bar cursor-pointer text-primary',
                                iconClasses,
                            ])}
                            onClick={this.toggle}
                        />
                    }
                >
                    {t('sflowStats.headerTitle')}
                </Tooltip>
            </>
        );
    }
}

export default enhance(SflowStatsModal);
