export const defaultPaginationOptions = Object.freeze({
    sizePerPage: 10,
    paginationSize: 10,
    hideSizePerPage: false,
    sizePerPageList: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
    ],
    showTotal: false,
    alwaysShowAllBtns: false,
    withFirstAndLast: false,
    hidePageListOnlyOnePage: true
});