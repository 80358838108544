import React, { FunctionComponent } from 'react';
import { Card, CardBody, CardHeader, Table, Badge } from 'reactstrap';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

// constants
import { Contract, Organisation } from 'constants/api';

// components
import ContractEditModal from 'components/modals/ContractEditModal';
import { getStatusColor } from 'utils/formatUtils';
import { Link } from 'react-router-dom';
import { getLinkToOrganisation } from 'utils/linksUtils';
import { ContractStatus } from 'constants/literals';

interface InProps {
    exchange: string;
    contract: Contract;
    organisation: Organisation;
}

type Props = InProps & WithTranslation;

const ContractInfo: FunctionComponent<Props> = ({ contract, organisation, t, exchange }) => {

    const contractStatus = contract.is_active ? ContractStatus.Active : ContractStatus.Inactive;

    return (
        <>
            <Helmet title={contract.contractname} />
            <Card className="card-accent-primary">
                <CardHeader>
                    <strong>{t('common.details')}</strong>
                </CardHeader>
                <CardBody>
                    {contract.reserved_for_testing && (
                        <div className="alert alert-info" data-test="reserved-for-testing-alert">
                            <h5 className="alert-heading">{t('contract.reservedForTesting')}</h5>
                            <p className="mb-0">{t('contract.reservedForTestingDescription')}</p>
                        </div>
                    )}
                    <ContractInfoTable size="sm" borderless>
                        <tbody>
                            <tr>
                                <th>{t('contract.orgName')}</th>
                                <td>
                                    <span>
                                        <Link
                                            to={getLinkToOrganisation(contract.organisation.uuid)}
                                        >
                                            {organisation.name}
                                        </Link>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th />
                            </tr>
                            <tr>
                                <th>{t('contract.contractName')}</th>
                                <td>
                                    <span>{contract.contractname}</span>
                                    <Badge className="ml-2" color={getStatusColor(contractStatus)} data-test="contract-status">{contractStatus}</Badge>
                                    <ContractEditModal
                                        exchange={exchange}
                                        contract={contract}
                                        data-test="edit-contract-modal"
                                    />
                                </td>
                            </tr>
                            {contract.erp_contract_identifier && (
                                <tr>
                                    <th>{t('contract.erp')}</th>
                                    <td>{contract.erp_contract_identifier}</td>
                                </tr>
                            )}
                            <tr>
                                <th>{t('contract.contractHandle')}</th>
                                <td>{contract.handle}</td>
                            </tr>
                            <tr>
                                <th>{t('contract.peeringDbId')}</th>
                                <td>{contract.peering_db_id}</td>
                            </tr>
                            <tr>
                                <th>{t('contract.peeringDbSalesEmail')}</th>
                                <td>{contract.peering_db_sales_email}</td>
                            </tr>
                        </tbody>
                    </ContractInfoTable>
                </CardBody>
            </Card>
        </>
    );
};

const ContractInfoTable = styled(Table)`
    th {
        width: 10em;
    }
`;

export default withTranslation()(ContractInfo);
