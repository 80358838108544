import React, { FunctionComponent, useEffect } from 'react';
import { FormikErrors, FormikTouched, FormikValues, Formik } from 'formik';
import { ApiDataRequest, getApiDataRequest, performApiRequest } from 'react-api-data/lib';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import SearchDropdown from './SearchDropdown';
import { SflowPeerType } from 'constants/literals';
import { ReduxState } from 'createStore';

interface InProps {
    name: string;
    type?: string;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    setFieldValue: typeof Formik.prototype.setFieldValue;
    handleChange: typeof Formik.prototype.handleChange;
    handleBlur: typeof Formik.prototype.handleBlur;
    exchange: string;
    sflowType: SflowPeerType;
    src?: string | number;
    srcName: string;
}

interface ApiDataProps {
    request: ApiDataRequest;
    getPeersList: () => void;
}

interface AsnPeer {
    asn: string;
    name: string;
}

interface IpPeer {
    ip: string;
    name: string;
}

type PeersDropdownProps = InProps & ApiDataProps;

const optionExtractors: {
    [key in SflowPeerType]: (request: ApiDataRequest) => string[]
} = {
    [SflowPeerType.as2as]: (request) => get(request, 'result.data.peers', []).map((peer: AsnPeer) => peer.asn),
    [SflowPeerType.ip2ip]: (request) => get(request, 'result.data.peers', []).map((peer: IpPeer) => peer.ip),
}

const debouncedGetPeersList = debounce((dispatch: any, ownProps: InProps) => {
    if (!ownProps.src || !ownProps.sflowType || !ownProps.exchange || !!get(ownProps, `errors.${ownProps.srcName}`)) {
        return;
    }
    dispatch(performApiRequest('getPeersList', {
        exchange: ownProps.exchange,
        type: ownProps.sflowType,
        src: ownProps.src,
    }));
}, 300);

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    request: getApiDataRequest(state.apiData, 'getPeersList', {
        exchange: ownProps.exchange,
        type: ownProps.sflowType,
        src: ownProps.src || '',
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    getPeersList: () => debouncedGetPeersList(dispatch, ownProps),
});

const PeersDropdown: FunctionComponent<PeersDropdownProps> = ({
    name, type, errors, touched, setFieldValue, handleChange, handleBlur, src, srcName, request, getPeersList, sflowType 
}) => {
    const options = optionExtractors[sflowType](request);

    useEffect(() => {
        getPeersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, errors[srcName]]);

    return (
        <SearchDropdown
            name={name}
            type={type}
            options={options}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
    );
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(PeersDropdown);
