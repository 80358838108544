import React, { FunctionComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import size from 'lodash/size';
import { connect } from 'react-redux';

import { getBgpLogTypesSelector } from 'redux/apiData/apiDataSelectors';
import { ReduxState } from 'createStore';

interface InProps {
    exchange: string;
}

interface StateProps {
    bgpLogTypes?: string[];
}

type Props = InProps & StateProps & WithTranslation;

const mapStateToProps = (store: ReduxState, ownProps: InProps): StateProps => ({
    bgpLogTypes: getBgpLogTypesSelector(store.apiData, ownProps.exchange)
});

const enhance = compose<Props, InProps>(
    connect(mapStateToProps),
    withTranslation()
);

const BgpLogTypeOptionsConnected: FunctionComponent<Props> = ({ bgpLogTypes = [], t }) => {
    if (!size(bgpLogTypes)) {
        return <option data-type="no-log-types" value="">{t('bgpLogTypeOptions.noLogTypes')}</option>;
    }

    return (
        <>
            <option value="" disabled>{t('quickdebug.selectLogType')}</option>
            <BgpLogTypeOptions data-test="bgp-log-type-options" options={bgpLogTypes} />
        </>
    );
};

export const BgpLogTypeOptions: FunctionComponent<{ options: string[] }> = ({ options }) => (
    <>
        {options && options.map(option => (
            <option value={option} key={option}>{option}</option>
        ))}
    </>
);

export default enhance(BgpLogTypeOptionsConnected);
