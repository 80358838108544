import {
    Alert, Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { object } from 'yup';
import { TextMask, InputAdapter } from 'react-text-mask-hoc';
import { ApiDataRequest } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';
import classnames from 'classnames';

import { NOC_SUPPORT_EMAIL, PORT_SECURITY_URL } from 'constants/index';
import { createMacAddressValidation } from 'validation/index';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import FormControlError from 'components/utils/FormControlError';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { isConfigurable } from 'utils/linksUtils';

interface Props extends WithTranslation, BaseFormModalProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    resourceStatus: string;
}

class MacAddressAddModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { t, request } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            successMessage: t('notification.addMacSuccessful')
        });
    }

    render() {
        const { onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ mac_address: '' }}
                    onSubmit={onSubmit}
                    validationSchema={object().shape({
                        mac_address: createMacAddressValidation(t).required(t('validation.requiredField')),
                    })}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('addMacAddressModal.headerTitle')}
                            body={<>
                                <Alert color="warning">
                                    <strong>{t('common.warning')}</strong>
                                    <p>{t('addMacAddressModal.paragraph1')}</p>
                                    <p>{t('addMacAddressModal.paragraph2')}<br />
                                        <a
                                            href={PORT_SECURITY_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {PORT_SECURITY_URL}
                                        </a>
                                    </p>
                                    <p>{t('addMacAddressModal.paragraph3')}.</p>
                                    <p>{t('addMacAddressModal.paragraph4')} <a href={`mailto:${NOC_SUPPORT_EMAIL}`}>{NOC_SUPPORT_EMAIL}</a>
                                    </p>
                                </Alert>
                                <FormGroup row className="mb-0">
                                    <Col md="3">
                                        <Label htmlFor="mac_address">{t('common.macAddress')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <TextMask
                                            Component={InputAdapter}
                                            mask={[/[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, '.', /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, '.', /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i, /[a-f0-9]/i]}
                                            guide={false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="mac_address"
                                            id="mac_address"
                                            value={values.mac_address}
                                            className={classnames({
                                                'form-control': true,
                                                'is-invalid': errors.mac_address && touched.mac_address
                                            })}
                                        />
                                        <FormControlError
                                            controlName="mac_address"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                {isConfigurable(this.props.resourceStatus) && (
                    <span data-test="mac-address-add-btn" className="cursor-pointer text-primary" onClick={this.toggle} title={t('addMacAddressModal.headerTitle')}>
                        <i className="fas fa-plus" /> {t('addMacAddressModal.headerTitle')}
                    </span>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(MacAddressAddModal);
