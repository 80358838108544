import {
    invalidateApiDataRequest, performApiRequest, ApiDataRequest
} from 'react-api-data';
import get from 'lodash/get';
import { toast } from 'react-toastify';

import { ResourceType } from 'constants/literals';
import i18next from 'locales/i18next';

// utils
import { updateLinkEntity, updateMemberLink } from './linksUtils';

export const refetchDataAfterResourceTransition = (resourceName: ResourceType, request: any, dispatch: any, getState: any): void => {
    switch (resourceName) {
        case ResourceType.link:
        case ResourceType.link_vlan:
            const newLink = get(request, 'result.data');
            const params = get(request, 'params');
            updateMemberLink(newLink, dispatch, getState, params);
            updateLinkEntity(newLink, dispatch, getState, params);
            break;
        case ResourceType.port:
            const portId = get(request, 'params.portId');
            const exchange = get(request, 'params.exchange');
            refetchPort(dispatch, portId, exchange);
            break;
        default:
            break;
    }
};

export const handleResourceTransitionSuccess = (request: ApiDataRequest | undefined, dispatch: any, getState: any, pathToMessage: string): void => {
    const resourceName = get(request, 'params.resourceName');
    refetchDataAfterResourceTransition(resourceName, request, dispatch, getState);
    toast.success(i18next.t(pathToMessage));
};

export const refetchPort = (dispatch: any, portId: string | number, exchange: string): void => {
    dispatch(invalidateApiDataRequest('getPort', { portId, exchange }));
    dispatch(performApiRequest('getPort', { portId, exchange }));
};
