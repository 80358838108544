import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { ApiDataRequest, getApiDataRequest, performApiRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// constants
import { Exchanges, Switch, Location } from 'constants/api';
import { ReduxState } from 'createStore';
import { defaultPaginationOptions } from 'constants/pagination';
import usePreselectExchange from 'hooks/usePreselectExchange';

// redux
import { updateAppPathAction } from 'redux/app/appActions';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';

// components
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import InteractiveTable from 'components/utils/InteractiveTable';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { getSwitchesTableColumns } from 'constants/columns/switchesTableColumns';

interface InProps {
    exchangesData: Exchanges;
    dataCenter?: Location;
}

interface ConnectProps {
    switchesRequest: ApiDataRequest;
    selectedExchange: string;
    searchTerm: string;
    selectExchange: (selectedExchange: string) => void;
    updateSearchTerm: (searchTerm?: string) => void;
    fetchswitchesRequest: (selectedExchange: string) => boolean;
}

type ComponentProps = InProps & ConnectProps & WithTranslation;

interface SwitchesTableProps {
    switchesRequest: ApiDataRequest;
    locations: Location[];
    selectedExchange: string;
    searchTerm: string;
    dataCenter?: Location;
}

type TableData = Pick<Switch,  'id' | 'name' | 'scope' | 'type' | 'platform'> & {
    locationDescription: string,
    locationShortName: string,
    locationUuid: string,
    exchange: string
}

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    selectedExchange: get(state, 'app.switches.selectedExchange', ''),
    searchTerm: get(state, 'app.switches.searchTerm', ''),
    switchesRequest: getApiDataRequest(state.apiData, 'getSwitches', {
        exchange: get(state, 'app.switches.selectedExchange', '')
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => {
    return {
        fetchswitchesRequest: (selectedExchange: string) => {
            if (!selectedExchange) return true;
            dispatch(performApiRequest('getSwitches', {
                exchange: selectedExchange
            }));
            return true;
        },
        selectExchange: (selectedExchange: string) => {
            dispatch(updateAppPathAction('switches.selectedExchange', selectedExchange));
        },
        updateSearchTerm: debounce((searchTerm: string = '') => {
            dispatch(updateAppPathAction('switches.searchTerm', searchTerm));
        }, 500)
    };
};

const enhance = compose<ComponentProps, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

const filterSearchData = memoizeOne((data: TableData[], searchTerm: string): TableData[] => {
    if (!searchTerm) {
        return data;
    }
    return filter(data, (row: TableData) => {
        return (
            `${row.scope} ${row.name} ${row.platform} ${row.locationDescription} ${row.locationShortName}`
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) !== -1
        );
    });
});

const SwitchesTable: FunctionComponent<SwitchesTableProps> = ({ switchesRequest, locations, selectedExchange, dataCenter, searchTerm = '' }) => {
    const tableData = useMemo(() => {
        return switchesRequest.result.data.switches.filter((sw:Switch) => (sw.status === 'active')).map((sw: Switch) => {
            const location = locations.find(location => location.uuid === sw.location_uuid)!;
            return {
                id: sw.id,
                name: sw.name,
                scope: sw.scope,
                type: sw.type,
                platform: sw.platform,
                locationDescription: location.description,
                locationShortName: location.short_name,
                locationUuid: location.uuid
            }
        })
    }, [switchesRequest, locations])
    
    const filteredTableData = filterSearchData(tableData, searchTerm);
    const switchesByLocation = !dataCenter ? filteredTableData : filteredTableData.filter((location) => location.locationUuid === dataCenter.uuid);

    const columns = getSwitchesTableColumns(selectedExchange);

    return (
        <InteractiveTable
            data-test="switches-interactive-table"
            classes="table-responsive-lg"
            keyField="id"
            columns={columns}
            data={switchesByLocation}
            defaultSorted={[{
                dataField: 'locationShortName',
                order: 'asc',
            }]}
            paginationOptions={{
                ...defaultPaginationOptions,
                sizePerPageRenderer: sizePerPageRenderer('up'),
            }}
        />
    );
};

export const SwitchesList: FunctionComponent<ComponentProps> = ({
    exchangesData, fetchswitchesRequest, switchesRequest,
    selectedExchange, selectExchange, t, searchTerm, updateSearchTerm, dataCenter
}) => {
    const exchange = useMemo(() => {
        return exchangesData.scopes.flatMap(scope => scope.exchanges)
            .find(exchange => exchange.short_name === selectedExchange);
    }, [exchangesData, selectedExchange]);
    const locations = exchange ? exchange.locations : [];

    const changeExchange = (exchangeValue: string) => {
        selectExchange(exchangeValue);
        fetchswitchesRequest(exchangeValue);
    };

    const handleExchangeChange = (exchange: React.ChangeEvent<HTMLInputElement>) => {
        const exchangeValue = exchange.target.value;
        changeExchange(exchangeValue);
        updateSearchTerm(); // reset search term when Exchange had been changed
    };

    useEffect(() => {
        if (!!selectedExchange) {
            fetchswitchesRequest(selectedExchange);
        }
    }, [fetchswitchesRequest, selectedExchange]);

    usePreselectExchange(selectedExchange, exchangesData, changeExchange);

    return (
        <>
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between">
                        <Col xl={3}>
                            <Input
                                data-test="search-term-control"
                                className="form-control"
                                placeholder={t('table.searchInputPlaceholder')}
                                defaultValue={searchTerm}
                                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                    updateSearchTerm(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        {!dataCenter && (
                            <Col xl={3} className="text-right">
                                <ExchangeSelect
                                    name="exchange"
                                    defaultValue={selectedExchange}
                                    exchanges={exchangesData}
                                    onChange={handleExchangeChange}
                                />
                            </Col>
                        )}
                    </Row>
                </CardHeader>
                <CardBody>
                    <RequestStatusRenderer
                        request={switchesRequest}
                        failedMessage={t('switches.noSwitches')}
                        defaultMessage={t('validation.exchangeNotSelected')}
                        success={() => (
                            <SwitchesTable
                                switchesRequest={switchesRequest}
                                locations={locations}
                                selectedExchange={!dataCenter ? selectedExchange : dataCenter.exchange}
                                searchTerm={searchTerm}
                                dataCenter={dataCenter}
                            />
                        )}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default enhance(SwitchesList);
