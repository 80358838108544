import React, { useMemo } from 'react';
import { compose } from 'recompose';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { ApiResponse, Exchange as ExchangeType, VirtualLinkVlan } from 'constants/api';
import { useTranslation } from 'react-i18next';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { get } from 'lodash';
import { Badge } from 'reactstrap';
import { formatSpeed, getStatusColor } from 'utils/formatUtils';
import Exchange from 'components/utils/Exchange';
import { Link } from 'react-router-dom';
import { getLinkToContract, getLinkToLinkVlan } from 'utils/linksUtils';
import { StyledTable } from 'components/styled';
import InteractiveTable from 'components/utils/InteractiveTable';
import { defaultPaginationOptions } from 'constants/pagination';
import { sizePerPageRenderer } from 'utils/commonUtils';
import AlertMessage from 'components/utils/AlertMessage';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { getAllExchangesForCurrentScopeSelector } from 'redux/apiData/apiDataSelectors';

interface InProps {
    contractUuid: string;
}

interface ApiProps {
    virtualLinkVlansBinding: ApiDataBinding<ApiResponse<{ virtual_link_vlans: VirtualLinkVlan[] }>>;
}

interface StateProps {
    exchanges: ExchangeType[];
}

type Props = InProps & ApiProps & StateProps;

function ContractVirtualLinkVlansContainer({ virtualLinkVlansBinding, exchanges }: Props) {
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={virtualLinkVlansBinding}
            failedMessage={t('notification.unexpectedError')}
            success={
                <ContractVirtualLinkVlans
                    virtualLinkVlans={get(virtualLinkVlansBinding, 'data.data.virtual_link_vlans', [])}
                    exchanges={exchanges}
                />
            }
        />
    );
}

type TableData = VirtualLinkVlan & {
    exchange_short_name: string;
    location_short_name: string;
};

function composeTableData(virtualLinkVlans: VirtualLinkVlan[], exchanges: ExchangeType[]): TableData[] {
    return virtualLinkVlans.map((virtualLinkVlan) => {
        const exchange = exchanges.find((exchange) => exchange.uuid === virtualLinkVlan.exchange_uuid)!;
        const location = exchange.locations.find((location) => location.uuid === virtualLinkVlan.location_uuid)!;
        return {
            ...virtualLinkVlan,
            exchange_short_name: exchange.short_name,
            location_short_name: location.short_name,
        };
    });
}

interface ContractVirtualLinkVlansProps {
    virtualLinkVlans: VirtualLinkVlan[];
    exchanges: ExchangeType[];
}

function ContractVirtualLinkVlans({ virtualLinkVlans, exchanges }: ContractVirtualLinkVlansProps) {
    const { t } = useTranslation();
    const tableData = useMemo(() => composeTableData(virtualLinkVlans, exchanges), [virtualLinkVlans, exchanges]);
    const tableColumns = [
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
            formatter: (cell?: string) => {
                return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
            },
        },
        {
            dataField: 'xml_id',
            text: t('connection.xmlIdLabel'),
            sort: true,
            copy: true,
        },
        {
            dataField: 'contract_name',
            text: t('Consuming contract'),
            sort: true,
            formatter: (_: any, row: TableData) => (
                <Link to={getLinkToContract(row.exchange_short_name, row.contract_uuid)}>
                    {row.contract_name}
                </Link>
            ),
        },
        {
            dataField: 'managing_contract_name',
            text: t('Managing contract'),
            sort: true,
            formatter: (_: any, row: TableData) =>
                !row.managing_contract_uuid ? 
                (
                   <></>
                ) : 
                (
                    <Link to={getLinkToContract(row.exchange_short_name, row.managing_contract_uuid)}>
                        {row.managing_contract_name}
                    </Link>
                ),
        },
        {
            dataField: 'speed',
            text: t('common.speed'),
            sort: true,
            formatter: formatSpeed,
        },
        {
            dataField: 'location_short_name',
            text: t('common.location'),
            sort: true,
            copy: true,
        },
        {
            dataField: 'exchange_short_name',
            text: t('common.exchange'),
            sort: true,
            formatter: (cell: string) => <Exchange shortName={cell} />,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: t('common.link'),
            formatter: (_: any, row: TableData) => {
                return <Link to={getLinkToLinkVlan(row.exchange_short_name, row.uuid)}>{t('common.view')}</Link>;
            },
        },
    ];

    return (
        <>
            {tableData.length > 0 ? (
                <StyledTable className="animated fadeIn table-responsive-lg">
                    <InteractiveTable
                        keyField="uuid"
                        columns={tableColumns}
                        data={tableData}
                        defaultSorted={[
                            {
                                dataField: 'status',
                                order: 'asc',
                            },
                        ]}
                        paginationOptions={{
                            ...defaultPaginationOptions,
                            sizePerPageRenderer: sizePerPageRenderer('up'),
                        }}
                    />
                </StyledTable>
            ) : (
                <AlertMessage message={t('contractVirtualLinkVlans.emptyStateMessage')} />
            )}
        </>
    );
}

export default compose<Props, InProps>(
    connect((state: ReduxState) => ({
        exchanges: getAllExchangesForCurrentScopeSelector(state.apiData),
    })),
    withApiData({ virtualLinkVlansBinding: 'getContractVirtualLinkVlans' }, (props: InProps) => ({
        virtualLinkVlansBinding: { contractUuid: props.contractUuid },
    }))
)(ContractVirtualLinkVlansContainer);
