import React, { Fragment, FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    noPaddingTop?: boolean;
}

const NotFound: FunctionComponent<Props> = ({ noPaddingTop, t }) => {
    return (
        <Fragment>
            <Helmet title="Page not found" />
            <Row className="justify-content-center align-items-center" style={{ paddingTop: noPaddingTop ? '0' : '30vh' }}>
                <Col md="4">
                    <div className="clearfix">
                        <h1 className="float-left display-3 mr-4">404</h1>
                        <h4 className="pt-3">{t('notFound.title')}</h4>
                        <p className="text-muted float-left">{t('notFound.message')}</p>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

export default withTranslation()(NotFound);