import React, { FunctionComponent } from 'react';

import { Roles } from 'constants/literals';
import { isDeleteRateLimiterBtnShown } from 'utils/linksUtils';
import withUserRoles from 'hocs/withUserRoles';
import { RateLimiter } from '../../constants/api';

interface InProps {
    rateLimiter?: RateLimiter;
    roles?: Roles[];
}

interface ConnectProps {
    userRoles: Roles[];
}

type Props = InProps & ConnectProps;

/**
 * Renders children according to the RL presence and user roles
 */
const DeleteBtnConditionalRenderer: FunctionComponent<Props> = ({ userRoles, rateLimiter, children }) => (
    isDeleteRateLimiterBtnShown(userRoles, rateLimiter) ? <>{children}</> : null
);

export default withUserRoles<InProps>(DeleteBtnConditionalRenderer);
