// tslint:disable:no-any
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApiDataRequest, getApiDataRequest, performApiRequest, invalidateApiDataRequest } from 'react-api-data/lib';
import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import FormControl from '../utils/FormControl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ReduxState } from '../../createStore';
import Tooltip from '../utils/Tooltip';
import classNames from 'classnames';
import { QRCode } from 'react-qrcode-logo';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { MfaApi } from '../../constants/api';
import get from 'lodash/get';

interface EnhanceProps {
    onSubmit: (values: any) => Promise<boolean>;
    mfaQrCode: ApiDataBinding<MfaApi>;
    confirmMfaRequest: ApiDataRequest;
    disableMfaRequest: ApiDataRequest;
    getQrCode: (usingMfa: boolean) => void;
    uuid: string;
    exchange: string;
}

interface InputProps {
    exchange: string;
    uuid: string;
    mfaEnabled: boolean;
    renderModalToggleBtn?: (props: {onClick: () => void}) => ReactNode;
}

type Props = EnhanceProps & WithTranslation & BaseFormModalProps & InputProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    confirmMfaRequest: getApiDataRequest(state.apiData, 'mfaConfirm', {}),
    disableMfaRequest: getApiDataRequest(state.apiData, 'disableMfa', {}),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: async (values: any) => {
            if (!values.mfa_enabled && ownProps.mfaEnabled) {
                await dispatch(performApiRequest('disableMfa', {}, { password: values.password }));
            } else {
                await dispatch(performApiRequest('mfaConfirm', {}, { otp_code: `${values.otp_code}` }));
            }
            await dispatch(invalidateApiDataRequest('getUser', { userId: ownProps.uuid, exchange: ownProps.exchange }));
            return true;
        },
        getQrCode: (usingMfa: boolean) => {
            if (!usingMfa || ownProps.mfaEnabled) return;
            if (ownProps.mfaQrCode && ownProps.mfaQrCode.data && ownProps.mfaQrCode.data.provisioning_uri) return;

            dispatch(performApiRequest('getMfaQrCode', {}));
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    withApiData({ mfaQrCode: 'getMfaQrCode' }),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class SetupMfaModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.confirmMfaRequest, this.props.confirmMfaRequest, {
            successMessage: this.props.t('notification.mfaUpdatedSuccessfully'),
        });
        this.handleFormSubmission(prevProps.disableMfaRequest, this.props.disableMfaRequest, {
            successMessage: this.props.t('notification.mfaDisabledSuccessfully'),
        });
    }

    render() {
        const { onSubmit, t, renderModalToggleBtn, mfaQrCode, mfaEnabled, getQrCode, disableMfaRequest } = this.props;
        const qrCode = mfaQrCode.data && mfaQrCode.data.provisioning_uri;
        const disablingFailed = get(disableMfaRequest, 'networkStatus') === 'failed';
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{
                        otp_code: '',
                        mfa_enabled: mfaEnabled,
                        password: ''
                    }}
                    onSubmit={onSubmit}
                    validationSchema={object().shape({
                        otp_code: string()
                            .matches(/^\d{6}$/, t('otp.lengthValidation'))
                    })}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          dirty
                    }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('setupMfa.headerTitle')}
                            body={<>
                                <FormGroup row>
                                    <Col md="3">{t('setupMfa.useMfa')}</Col>
                                    <Col md="3">
                                        <FormControl
                                            name={'mfa_enabled'}
                                            onClick={() => getQrCode(!values.mfa_enabled)}
                                            type={'checkbox'}
                                            errors={errors}
                                            touched={touched}
                                            className={'no-focus'}
                                            checked={values.mfa_enabled}
                                        />
                                    </Col>
                                </FormGroup>
                                { values.mfa_enabled && !mfaEnabled && qrCode && (
                                        <FormGroup row>
                                            <Col md="3">Scan QR code with your authenticator app</Col>
                                            <Col md="9">
                                                <QRCode
                                                    removeQrCodeBehindLogo={true}
                                                    value={qrCode || ''}
                                                    logoImage={'/images/logo.svg'}
                                                    logoWidth={45}
                                                    logoHeight={18}
                                                />
                                            </Col>
                                        </FormGroup>
                                )}
                                { (mfaEnabled && !values.mfa_enabled) && (
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="password">Enter password to confirm</Label>
                                        </Col>
                                        <Col md="9">
                                            <InputGroup className="mb-4">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fas fa-lock" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="password"
                                                    placeholder={t('login.passwordPlaceholder')}
                                                    autoComplete="current-password"
                                                    name="password"
                                                    id="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={disablingFailed}
                                                    required
                                                />
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                )}
                                { ((values.mfa_enabled && !mfaEnabled)) && qrCode && (
                                    <>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="otp_code">Enter the code from authenticator</Label>
                                            </Col>
                                            <Col md="9">
                                                <FormControl
                                                    name="otp_code"
                                                    type="text"
                                                    errors={errors}
                                                    placeholder={t('otp.otpCode')}
                                                    touched={touched}
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}/>
                                        </FormGroup>
                                    </>
                                )}
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty && !isSubmitting && (!values.otp_code || !values.password)}
                                    text={!values.mfa_enabled ? t('setupMfa.disableMfa') : t('setupMfa.enableMfa')}
                                />
                            }
                        />
                    )}
                </Formik>
                {renderModalToggleBtn ?
                    renderModalToggleBtn({ onClick: this.toggle }) : (
                        <Tooltip
                            triggerBtn={
                                <i
                                    className={classNames([
                                        'fas fa-chart-bar cursor-pointer text-primary',

                                    ])}
                                    onClick={this.toggle}
                                />
                            }
                        >
                            <i className="fas fa-user-lock" /> {t('setupMfa.setupMfa')}
                        </Tooltip>
                    )}
            </React.Fragment>
        );
    }
}

export default enhance(SetupMfaModal);
// tslint:enable:no-any
