import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router';
import { Col, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import nth from 'lodash/nth';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull, Event, ApiResponse } from 'constants/api';
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// utils
import { refetchMemberLink } from 'utils/linksUtils';

// hocs
import withUserRoles from 'hocs/withUserRoles';

// components
import ActivityLog from 'components/cards/ActivityLog';
import LinkInfo from 'components/cards/LinkInfo';
import ColoredIconWidget from 'components/cards/ColoredIconWidget';
import VlansInfo from 'components/cards/VlanInfo';
import PageLoading from 'components/utils/PageLoading';
import VirtualLinks from 'components/cards/VirtualLinks';
import PortInfo from 'components/cards/PortInfo';
import LinkStatistics from 'components/cards/LinkStatistics';
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import LinkEditDatabaseModal from 'components/modals/LinkEditDatabaseModal';
import { hasSomeRole } from 'utils/userUtils';

interface InputProps {
    linkId: string;
    exchange: string;
}

interface Props extends InputProps, WithTranslation {
    memberLinkRequest: ApiDataBinding<LinkFull>;
    linkEventsRequest: ApiDataBinding<ApiResponse<{ events: Event[] }>>;
    refetchConnectionData: () => boolean;
    locationHistory: string[];
    userRoles: Roles[];
}

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    locationHistory: get(state, 'app.locationHistory', []),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => ({
    refetchConnectionData: () => {
        const requestParams = {
            linkId: ownProps.linkId,
            exchange: ownProps.exchange.toUpperCase()
        };
        refetchMemberLink(dispatch, requestParams);
        return true;
    }
});

export const ConnectionDetails: FunctionComponent<Props> = ({
    memberLinkRequest, linkEventsRequest, refetchConnectionData, t, userRoles, locationHistory
}) => (
    <RequestStatusRenderer
        data-test="request-renderer"
        request={memberLinkRequest}
        defaultComponent={null}
        loading={<PageLoading />}
        failedMessage={t('connection.failedMessage')}
        success={() => {   
            if (!memberLinkRequest.data) {
                if (get(memberLinkRequest, 'request.response.status') === 500) {
                    const lastLocation: string = nth(locationHistory, -2) || '/';
                    return <Redirect to={lastLocation} />;
                }
                return null;
            }

            const memberLink = memberLinkRequest.data;
    
            return (
                <>
                    <Helmet title={memberLink.xml_id} />
                    <ViewContainer data-test="view-container">
                        <Row>
                            <Col lg={6} xl={3}>
                                <ColoredIconWidget
                                    label={t('connection.xmlIdLabel')}
                                    value={memberLink.xml_id}
                                    color="primary"
                                    icon="link"
                                />
                            </Col>
                            <Col lg={6} xl={3}>
                                <ColoredIconWidget
                                    label={t('common.handle')}
                                    value={memberLink.handle}
                                    color="dark"
                                    icon="id-card"
                                />
                            </Col>
                            <Col lg={6} xl={3}>
                                <ColoredIconWidget
                                    label={t('connection.classificationLabel')}
                                    value={memberLink.classification}
                                    color="info"
                                    icon="network-wired"
                                    capitalizeValue={true}
                                />
                            </Col>
                            <Col lg={6} xl={3}>
                                <ColoredIconWidget
                                    label={t('common.location')}
                                    value={memberLink.location}
                                    color="success"
                                    icon="compass"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <h2>
                                    {t('connection.pageTitle')}
                                    <SyncButton
                                        data-test="refresh-btn"
                                        className="ml-2"
                                        title={t('connection.refreshConnectionBtn')}
                                        onClick={refetchConnectionData}
                                    />
                                    {hasSomeRole(userRoles, Roles.noc, Roles.admin) &&
                                        memberLink.classification !== 'virtual-link' &&
                                        memberLink.exchange && (
                                            <LinkEditDatabaseModal
                                                data-test="link-editdb-modal"
                                                link={memberLink}
                                                exchange={memberLink.exchange.short_name}
                                            />
                                        )}
                                </h2>
                                <hr/>
                                <LinkInfo
                                    data-test="link-info"
                                    link={memberLink}
                                    userRoles={userRoles}
                                />
                                <LinkStatistics data-test="link-stats" link={memberLink} />
                                {!hasSomeRole(userRoles, Roles.customer_reader, Roles.ixaas_client_reader) &&
                                    <ActivityLog
                                        data-test="activity-log"
                                        eventsApiBinding={linkEventsRequest}
                                        userRoles={userRoles}
                                    />
                                }
                            </Col>
                            <Col lg={6}>
                                {memberLink.classification !== 'virtual-link' && (
                                    <PortInfo data-test="port-info" link={memberLink} />
                                )}
                                {memberLink.classification !== 'virtual-link-container' && (
                                    <VlansInfo data-test="vlan-info" link={memberLink} userRoles={userRoles} />
                                )}
                                {memberLink.classification === 'virtual-link-container' && (
                                    <VirtualLinks data-test="virtual-links" links={memberLink.virtual_links || []} speed={memberLink.speed || 0} />
                                )}
                            </Col>
                        </Row>
                    </ViewContainer>
                </>
            );
        }}
    />
);

const enhance = compose<Props, InputProps>(
    withApiData({
        memberLinkRequest: 'getMemberLink',
        linkEventsRequest: 'getLinkEvents',
    },
    (ownProps: Props) => ({
        memberLinkRequest: {
            linkId: ownProps.linkId,
            exchange: ownProps.exchange.toUpperCase()
        },
        linkEventsRequest: {
            linkUuid: ownProps.linkId,
            per_page: 25
        },
    })),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
    withUserRoles
);

// @ts-ignore Possible error in react-api-data type definitions
export default enhance(ConnectionDetails);
