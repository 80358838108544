import React, { FunctionComponent } from 'react';

import BaseTransition, { InProps } from './BaseTransition';
import { TransitionsType } from 'constants/literals';

const TransitionRequestTesting: FunctionComponent<InProps> = (props) => {
    return (
        <BaseTransition
            transitionType={TransitionsType.request_testing}
            modalHeaderTitle="resourceTransitions.testingHeaderTitle"
            modalBodyTitle="resourceTransitions.testingBodyText"
            transitionName="resourceTransitions.testing"
            transitionIconClass="fa-compress-arrows-alt"
            { ...props }
        />
    );
};

export default TransitionRequestTesting;