import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import { performApiRequest, invalidateApiDataRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Exchanges, Exchange, Contract } from 'constants/api';

// redux
import { getAllExchangesSelector, getHomeContractSelector } from 'redux/apiData/apiDataSelectors';

// components
import NativeRoutersList from 'components/cards/NativeRoutersList';
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import NativeRouterAddModal from 'components/modals/NativeRouterAddModal';

interface ApiProps {
    selectedExchange: string;
    exchangesData: Exchanges;
    contract: Contract;
    refetchNativeRouters: (exchange: string, exchangesData: Exchanges) => boolean;
}

type ComponentProps = ApiProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: {}) => ({
    exchangesData: getAllExchangesSelector(state.apiData),
    selectedExchange: get(state, 'app.native_routers.selectedExchange', ''),
    contract: getHomeContractSelector(state)
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    refetchNativeRouters: (exchange: string, exchangesData: Exchanges) => {
        if (!exchange) {
            return false;
        }
        // clear getNativeRouters cache for every exchange
        get(exchangesData, 'scopes', [])
        .forEach((exchangeScope: { name: string, exchanges: Exchange[] }) => {
            exchangeScope.exchanges.forEach((exchangeItem: Exchange) => {
                dispatch(invalidateApiDataRequest('getNativeRouters', { exchange: exchangeItem.short_name }));
            });
        });
        // refetch currently selected exchange
        dispatch(performApiRequest('getNativeRouters', { exchange }));
        return true;
    }
});

const enhance = compose<ComponentProps, {}>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

const NativeRoutersView: FunctionComponent<ComponentProps> = ({ exchangesData, refetchNativeRouters, selectedExchange, contract, t }) => {
    const organisation = get(contract, 'organisation');

    return (
        <>
            <Helmet title="Native Routers" />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            <span>
                                {t('nativeRouters.pageTitle')}
                                <SyncButton
                                    data-test="native_routers-refresh-btn"
                                    className="ml-2"
                                    title={t('nativeRouters.refreshButton')}
                                    onClick={() => refetchNativeRouters(selectedExchange, exchangesData)}
                                />
                            </span>
                            {organisation && <NativeRouterAddModal exchangesData={exchangesData} organisation={organisation}/>}
                        </h2>
                        <hr/>
                        <NativeRoutersList exchangesData={exchangesData} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default enhance(NativeRoutersView);
