import { string, number, StringSchema, NumberSchema } from 'yup';
import i18n from 'i18next';
import ipRegex from 'ip-regex';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { TAG_MIN_VALUE, TAG_MAX_VALUE } from 'constants/index';

export const createMacAddressValidation = (t: i18n.TFunction): StringSchema => (
    string()
    .matches(/^.(?!1)/i, t('validation.multicastMac'))
    .matches(/^(?!0000\.5e|0200\.5E)/i, t('validation.reservedMac'))
    .matches(/([a-f0-9]){4}\.([a-f0-9]){4}\.([a-f0-9]){4}/i, t('validation.notValidMac'))
);

export const createTagValidation = (t: i18n.TFunction): NumberSchema => (
    number()
    .notOneOf([1, 10], t('validation.vlanTagReserved'))
    .min(TAG_MIN_VALUE, t('validation.vlanRange'))
    .max(TAG_MAX_VALUE, t('validation.vlanRange'))
);

export const createIpValidation = (t: i18n.TFunction): StringSchema => (
    string().matches(ipRegex({ exact: true }), t('validation.ipInvalid')).required(t('validation.requiredField'))
);

export const createIpv6Validation = (t: i18n.TFunction): StringSchema => (
    string().matches(ipRegex.v6({ exact: true }), t('validation.ipInvalid'))
);

export const createPhoneNumberValidation = (t: i18n.TFunction): StringSchema => (
    string()
    .test('phoneNumber', t('validation.phoneNumberInvalid'), (value: string): boolean => {
        if (!value) return true;
        const phoneNumber = parsePhoneNumberFromString(value);
        if (phoneNumber) return phoneNumber.isValid();
        return false;
    })
);

export const createLettersValidation = (errorMessage: string): StringSchema => (
    string().matches(/^[A-Za-z]*$/, errorMessage)
);
