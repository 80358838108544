import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Spinner } from 'reactstrap';

function LoadingIndicator({ show }: { show: boolean }) {
    const { t } = useTranslation();

    if (!show) return null;

    return (
        <Alert color="warning">
            {t('globalSearch.loadingWarning')} <Spinner size="sm" type="grow" />
        </Alert>
    );
}

export default LoadingIndicator;
