import React, { FunctionComponent } from 'react';
import { ApiDataBinding } from 'react-api-data/lib';
import { Spinner } from 'reactstrap';
import { getIn } from 'formik';
import { WithTranslation, useTranslation } from 'react-i18next';

// constants
import { ApiResponse, TrafficStats } from 'constants/api';
import { StatsInterval, StatsType, StatsCounterType } from 'constants/literals';

// components
import { CenteredAlert } from 'components/styled';
import Graph from './Graph';
// import { mockStatsData } from 'constants/mockStatsData';

export interface ResourceGraphProps {
    interval: StatsInterval;
    type: StatsType;
    height?: number;
    simple?: boolean;
}

interface Props extends ResourceGraphProps {
    statsData: ApiDataBinding<ApiResponse<TrafficStats>>;
}

const getStepFromInterval = (interval: StatsInterval): number => {
    switch (interval) {
        case StatsInterval.Daily: return 60 * 60 * 1000;
        case StatsInterval.Weekly: return 24 * 60 * 60 * 1000;
        case StatsInterval.Monthly: return 24 * 60 * 60 * 1000;
        case StatsInterval.Yearly: return 30 * 24 * 60 * 60 * 1000;
        case StatsInterval.ThreeYears: return 60 * 24 * 60 * 60 * 1000;
        case StatsInterval.All: return 30 * 24 * 60 * 60 * 1000;
    }
};

const ResourceGraph: FunctionComponent<Props> = ({ statsData, height, interval, type, simple }) => {
    height = height || 250;

    const { t } = useTranslation();

    if (statsData.request.networkStatus === 'loading') {
        return (
            <CenteredAlert color="none" style={{ height, margin: 0 }}>
                <Spinner type="grow" color="primary" />
            </CenteredAlert>
        );
    }

    const responseData = getIn(statsData, `data.data.traffic_statistics.${interval}.${type}`);
    // const responseData = mockStatsData[type][interval]; // Used for debug
    if (responseData) {
        const counter = type === StatsType.Error ? StatsCounterType.pps : StatsCounterType.bps;

        return (
            <Graph
                data={responseData}
                height={height}
                counter={counter}
                interval={interval}
                simple={simple}
                noDataMessage={t('common.noData')}
                step={getStepFromInterval(interval)}
            />
        );
    }

    return (
        <CenteredAlert color="none" style={{ height }} className="text-danger">
            {t('stats.errorLoading')}
        </CenteredAlert>
    );
};

// @ts-ignore
export default ResourceGraph;
