import React from 'react';
import { performApiRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { LinkPortCompact } from 'constants/api';
import { getPortName } from 'utils/commonUtils';
import ResourceDeleteBtn from './ResourceDeleteBtn';

interface InProps {
    port: LinkPortCompact;
    linkId: string;
    exchange: string;
    isDropdownItem?: boolean;
}

interface ConnectProps {
    onDelete: () => void;
}

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    onDelete: () => {
        dispatch(performApiRequest('deletePort', {
            portId: get(ownProps, 'port.uuid'),
            linkId: ownProps.linkId,
            exchange: ownProps.exchange
        }));
    },
});

export default compose<InProps & ConnectProps, InProps>(
    connect(null, mapDispatchToProps)
)(({ port, onDelete, isDropdownItem = false }) => (
    <ResourceDeleteBtn
        data-test="resource-delete-btn"
        onDelete={onDelete}
        isDropdownItem={isDropdownItem}
        resourceName={getPortName(port)}
        resourceStatus={port.status}
    />
));
