import get from 'lodash/get';

import { ReduxState } from 'createStore';
import { getUserUuid } from 'utils/userUtils';

export const accessTokenSelector = (state: ReduxState): string => (
    get(state, 'app.authenticationToken.access_token', '')
);

export const userUuidSelector = (state: ReduxState): string => (
    getUserUuid(accessTokenSelector(state))
);
