import React from 'react';
import { Badge } from 'reactstrap';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import i18next from 'locales/i18next';
import { Account, User } from 'constants/api';
import AccountDeleteModal from 'components/modals/AccountDeleteModal';
import UserDisableMFAModal from '../../components/modals/UserDisableMFAModal';
import { hasEditRole, isAdmin, isNoc } from '../../utils/userUtils';
import { Roles } from '../literals';
import { Link } from 'react-router-dom';
import { getLinkToUser } from 'utils/linksUtils';

export const getcontractUsersTableColumns = memoizeOne((exchange: string, contractUuid: string, userRoles: Roles[], invalidateUsersCache: () => void): any => {

    const defaultColumns = [{
        dataField: 'user.active',
        text: i18next.t('common.active'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (active: boolean) => {
            return <Badge color={active ? 'success' : 'danger'}>{active ? 'Active' : 'Inactive'}</Badge>;
        }
    }, {
        dataField: 'user.username',
        text: i18next.t('common.userName'),
        sort: true,
        headerClasses: 'align-middle',
    }, {
        dataField: 'user.email',
        text: i18next.t('common.email'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (email: string) => <a href={`mailto:${email}`}>{email}</a>
    }, {
        dataField: 'user.roles',
        text: i18next.t('userDetails.roles'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (cell: User['roles']) => {
            return cell.map(role => role).join(', ');
        }
    }, {
        dataField: 'user.last_logged_in',
        text: i18next.t('contract.lastLoggedIn'),
        sort: true,
        headerClasses: 'align-middle',
        formatter: (lastLoggedIn: string) => {
            if (!lastLoggedIn) {
                return '-';
            }
            return moment(lastLoggedIn).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'link',
        isDummyField: true,
        text: 'Link',
        headerClasses: 'align-middle',
        formatter: (cell: any, row: Account) => (
            <Link to={getLinkToUser(row.user.uuid)}>{i18next.t('common.view')}</Link>
        ),
    }];

    if (hasEditRole(userRoles)) {
        const removeColumn = {
            dataField: 'remove',
            isDummyField: true,
            text: i18next.t('common.remove'),
            headerClasses: 'align-middle',
            formatter: (cell: any, row: Account) => {
                return (
                    <AccountDeleteModal
                        data-test="user-delete-modal"
                        contractUuid={contractUuid}
                        account={row}
                        uuid={row.uuid}
                    />
                );
            }
        };
        defaultColumns.splice(-1, 0, removeColumn);
    }

    if (isAdmin(userRoles) || isNoc(userRoles)) {
        defaultColumns.push({
            dataField: 'disable_mfa',
            isDummyField: true,
            text: i18next.t('setupMfa.disableMfa'),
            headerClasses: 'align-middle',
            formatter: (cell: any, row: Account) => {
                return row.user.mfa_enabled ?
                    (
                        <UserDisableMFAModal
                            data-test="user-disable-mfa-modal"
                            user={row.user}
                            invalidateUsersCache={invalidateUsersCache}
                        />
                    ) : <p style={{ textAlign: 'center' }}>-</p>;
            }
        });
    }

    return defaultColumns;
});
