import React, { useState, useMemo } from 'react';
import { Badge } from 'reactstrap';
import InteractiveTable from 'components/utils/InteractiveTable';
import { useTranslation } from 'react-i18next';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { VirtualLinkVlan, Exchange as ExchangeType, ApiResponse } from 'constants/api';
import { NetworkRequestStatus } from 'constants/literals';
import { get } from 'lodash';
import { getStatusColor, formatSpeed } from 'utils/formatUtils';
import Exchange from 'components/utils/Exchange';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { getAllExchangesForCurrentScopeSelector } from 'redux/apiData/apiDataSelectors';
import { Link } from 'react-router-dom';
import { getLinkToLinkVlan } from 'utils/linksUtils';
import LoadingIndicator from './LoadingIndicator';
import TabTitle from './TabTitle';

interface InProps {
    onLinkClick: () => void;
}

interface EnhanceProps {
    virtualLinkVlansBinding: ApiDataBinding<ApiResponse<{ virtual_link_vlans: VirtualLinkVlan }>>;
    exchanges: ExchangeType[];
}

type Props = InProps & EnhanceProps;

type TableData = VirtualLinkVlan & {
    exchange_short_name: string;
    location_short_name: string;
};

function composeTableData(virtualLinkVlans: VirtualLinkVlan[], exchanges: ExchangeType[]): TableData[] {
    return virtualLinkVlans.map((virtualLinkVlan) => {
        const exchange = exchanges.find((exchange) => exchange.uuid === virtualLinkVlan.exchange_uuid)!;
        const location = exchange.locations.find((location) => location.uuid === virtualLinkVlan.location_uuid)!;
        return {
            ...virtualLinkVlan,
            exchange_short_name: exchange.short_name,
            location_short_name: location.short_name,
        };
    });
}

function VirtualLinkVlansList(props: Props) {
    const { virtualLinkVlansBinding, exchanges, onLinkClick } = props;
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const tableColumns = useMemo(
        () => [
            {
                dataField: 'status',
                text: t('common.status'),
                sort: true,
                formatter: (cell?: string) => {
                    return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
                },
            },
            {
                dataField: 'xml_id',
                text: t('connection.xmlIdLabel'),
                sort: true,
                copy: true,
            },
            {
                dataField: 'speed',
                text: t('common.speed'),
                sort: true,
                formatter: formatSpeed,
            },
            {
                dataField: 'location_short_name',
                text: t('common.location'),
                sort: true,
                copy: true,
            },
            {
                dataField: 'exchange_short_name',
                text: t('common.exchange'),
                sort: true,
                formatter: (cell: string) => <Exchange shortName={cell} />,
            },
            {
                dataField: 'link',
                isDummyField: true,
                text: t('common.link'),
                formatter: (_: any, row: TableData) => {
                    return (
                        <Link to={getLinkToLinkVlan(row.exchange_short_name, row.uuid)} onClick={onLinkClick}>
                            {t('common.view')}
                        </Link>
                    );
                },
            },
        ],
        [onLinkClick]
    );
    const virtualLinkVlans = get(virtualLinkVlansBinding, 'data.data.virtual_link_vlans', []);
    const data = useMemo(() => composeTableData(virtualLinkVlans, exchanges), [virtualLinkVlans, exchanges]);

    return (
        <>
            <LoadingIndicator show={virtualLinkVlansBinding.request.networkStatus === NetworkRequestStatus.Loading} />
            <TabTitle onSync={() => virtualLinkVlansBinding.invalidateCache()}>
                {t('globalSearch.virtualLinkVlansTitle')}
            </TabTitle>
            <InteractiveTable
                keyField="uuid"
                columns={tableColumns}
                data={data}
                defaultSorted={[
                    {
                        dataField: 'status',
                        order: 'asc',
                    },
                ]}
                paginationOptions={{
                    sizePerPage: 10,
                    paginationSize: 10,
                }}
                searchTerm={searchTerm}
                updateSearchTerm={(newTerm: string) => setSearchTerm(newTerm)}
                noDataIndication={() => t('globalSearch.noResultsFound')}
                tableWrapperClassName="table-responsive-xl"
                search
                noPadding
            />
        </>
    );
}

const enhance = compose<Props, InProps>(
    connect((state: ReduxState) => ({
        exchanges: getAllExchangesForCurrentScopeSelector(state.apiData),
    })),
    withApiData({ virtualLinkVlansBinding: 'getVirtualLinkVlans' })
);

export default enhance(VirtualLinkVlansList);
