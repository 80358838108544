import React, { FunctionComponent, useState } from 'react';
import { PortDetails, ApiResponse, ReactApiDataActions } from 'constants/api';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import { useTranslation } from 'react-i18next';
import FormGroup from 'reactstrap/lib/FormGroup';
import Col from 'reactstrap/lib/Col';
import Label from 'reactstrap/lib/Label';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import StyledCheckbox from 'components/styled/StyledCheckbox';
import FormControl from 'components/utils/FormControl';
import { NetworkRequestStatus } from 'constants/literals';

interface InProps {
    port: PortDetails;
}

interface EnhanceProps {
    portUpdateRequest: ApiDataBinding<ApiResponse<PortDetails>>;
    apiDataActions: ReactApiDataActions;
}

type Props = InProps & EnhanceProps;

const enhance = compose<Props, InProps>(
    withApiData({ portUpdateRequest: 'putPortUpdate' })
);

interface FormValues {
    snmp_failed: boolean;
    inconsistencies: string;
}

const PortEditModal: FunctionComponent<Props> = ({ port, portUpdateRequest, apiDataActions }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onSubmit = (values: FormValues) => {
        portUpdateRequest.perform({ portId: port.id }, { snmp_failed: values.snmp_failed, inconsistencies: values.inconsistencies }).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                // have to invalidate the cache with the ID in the same format as the initial request
                // the initial request used the formatted port ID from the URL (so the slashes are encoded)
                // port/number -> port%2Fnumber
                const portId = encodeURIComponent(`${port.fqid}/${port.portnumber}`);
                apiDataActions.invalidateCache('getPortDetails', { portId: portId });
                toggle();
            }
        });
    };

    return (
        <>
            <Formik
                initialValues={{
                    snmp_failed: port.snmp_failed,
                    inconsistencies: port.inconsistencies
                } as FormValues}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, dirty}) => (
                    <BaseFormModalTemplate
                        isOpen={isOpen}
                        toggle={isSubmitting ? undefined : toggle}
                        onClosed={undefined}
                        handleSubmit={handleSubmit}
                        header={t('portEditModal.headerTitle')}
                        body={
                            <>
                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="snmp_failed">{t('portDetails.selectionBlocked')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <StyledCheckbox name="snmp_failed" checked={values.snmp_failed} />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Col md="3">
                                        <Label htmlFor="inconsistencies">{t('portDetails.inconsistencies')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            component="textarea"
                                            style={{ height: 120 }}
                                            name="inconsistencies"
                                            id="inconsistencies"
                                            errors={errors}
                                            touched={touched}
                                            hidden={false}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        }
                        footer={
                            <FormModalButtons
                                loading={isSubmitting}
                                toggle={toggle}
                                disabled={!dirty}
                            />
                        }
                    />
                )}
            </Formik>

            <DropdownItem onClick={toggle}>{t('portEditModal.headerTitle')}</DropdownItem>
        </>
    );
};

export default enhance(PortEditModal);
