import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiResponse, Asn } from 'constants/api';
import { get } from 'lodash';
import { NONE_SELECTED } from 'constants/index';

interface InProps {
    onChange: (asnNumber: number, organisationId: number, organisationUuid: string, asnId: number) => void;
    organisationId?: number;
    organisationUuid?: string;
    defaultLabel?: string;
    defaultValue?: number;
}

interface ApiDataProps {
    getAsns: () => void;
}

type AsnSelectAllProps = InProps & ApiDataProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    asnsData: ApiDataBinding<ApiResponse<{ asns: Asn[] }>>;
}

const enhance = compose<AsnSelectAllProps, InProps>(
    withApiData({ asnsData: 'getAsns' }, (ownProps) => ({
        asnsData: {
            organisation: ownProps.organisationId
        },
    })),
    withTranslation()
);

interface OptionType {
    label: string;
    value: number;
    organisation_id: number;
    organisation_uuid: string;
    asn_id: number;
}

const AsnSelectAll: FunctionComponent<AsnSelectAllProps> = ({ asnsData, onChange, defaultValue, defaultLabel }) => {
    const optChanged = (option: OptionType) => {
        onChange(option.value, option.organisation_id, option.organisation_uuid, option.asn_id);
    };

    const optionNone = {
        label: NONE_SELECTED,
        value: null,
        organisation_id: null,
        organisation_uuid: '',
        asn_id: null
    };

    const asnOptions = get(asnsData, 'data.data.asns', []).map((asn: Asn) => {
        return {
            label: asn.number + ',  ' + asn.organisation_name,
            value: asn.number,
            organisation_id: asn.organisation_id,
            organisation_uuid: asn.organisation_uuid,
            asn_id: asn.id
        };
    });

    if (asnOptions.length === 0) {
        return (
            <Select defaultValue={{ label: defaultLabel, value: defaultValue }} isLoading={true} isDisabled={true} />
        );
    }

    const options = [optionNone, ...asnOptions];

    const defaultOption = options.find((o: { value: number | undefined }) => o.value === defaultValue);

    const filterOption = (option: OptionType, inputValue: string): boolean =>
        (option.label.toString().match(inputValue) || []).length > 0;

    return (
        <>
            <Select
                options={options}
                defaultValue={defaultOption}
                onChange={optChanged}
                filterOption={filterOption}
                isLoading={false}
                isDisabled={false}
            />
        </>
    );
};

export default enhance(AsnSelectAll);
