import React, { FunctionComponent } from 'react';
import get from 'lodash/get';

import BaseTransition, { InProps } from './BaseTransition';

import { ConnectionStatus, Roles, TransitionsType } from 'constants/literals';

const canDeconfigureStatuses = [ConnectionStatus.configured, ConnectionStatus.new, ConnectionStatus.unconfigured];

const TransitionDeconfigure: FunctionComponent<InProps> = (props) => {
    if (get(props, 'primaryResource')) {
        return null;
    }
    return (
        <BaseTransition
            transitionType={TransitionsType.deconfigure}
            statuses={canDeconfigureStatuses}
            roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.ixaas_client]}
            modalHeaderTitle="resourceTransitions.deconfigureHeaderTitle"
            modalBodyTitle="resourceTransitions.deconfigureBodyText"
            transitionName="resourceTransitions.deconfigure"
            transitionIconClass="fa-arrow-circle-down"
            { ...props }
        />
    );
};

export default TransitionDeconfigure;