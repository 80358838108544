import { FunctionComponent } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import LinkGraph from 'components/graphs/LinkGraph';
import StatisticsWidget from 'components/utils/StatisticsWidget';
import { LinkFull } from 'constants/api';

interface Props extends WithTranslation {
    link: LinkFull;
}

const LinkStatistics: FunctionComponent<Props> = ({ link, t }) => {
    return (
        <Card className="card-accent-primary">
            <CardHeader>
                <strong>{t('stats.statsTitle')}</strong>
            </CardHeader>
            <CardBody>
                <StatisticsWidget>
                    {({ type, interval }) => (
                        <LinkGraph
                            linkId={link.uuid}
                            exchange={link.exchange.short_name}
                            type={type}
                            interval={interval}
                            height={250}
                        />
                    )}
                </StatisticsWidget>
            </CardBody>
        </Card>
    );
};

export default withTranslation()(LinkStatistics);
