import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Label } from 'reactstrap';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { Formik } from 'formik';
import { string, object } from 'yup';
import size from 'lodash/size';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

// selectors
import { getPortMergeableLinksSelector } from 'redux/apiData/apiDataSelectors';

// components
import BaseFormModal, { BaseFormModalProps } from 'components/modals/BaseFormModal';
import { BaseFormModalTemplate, FormModalButtons } from 'components/modals/BaseModalTemplate';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import { refetchPort } from 'utils/apiUtils';
import DropdownItem from 'reactstrap/lib/DropdownItem';

interface InProps {
    portId: string;
    link: LinkFull;
    portName?: string;
    renderMergeButton: (props: { onClick: () => void }) => ReactNode;
}

interface StateProps {
    linkOptions?: LinkFull[];
    submission: ApiDataRequest | undefined;
}

interface DispatchProps {
    onSubmit: (values: { destination_link_uuid: string }) => boolean;
    refreshPortData: () => void;
}

type Props = InProps & BaseFormModalProps & StateProps & DispatchProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: InProps): StateProps => ({
    linkOptions: getPortMergeableLinksSelector(state.apiData, ownProps.link),
    submission: getApiDataRequest(state.apiData, 'putPortMerge', {
        portId: ownProps.portId,
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps): DispatchProps => ({
    onSubmit: ({ destination_link_uuid }) => {
        dispatch(performApiRequest('putPortMerge',
            { portId: ownProps.portId },
            { destination_link_uuid }
        ));
        return true;
    },
    refreshPortData: () => {
        refetchPort(dispatch, ownProps.portId, get(ownProps, 'link.exchange.short_name'));
    }
});

class PortMergeModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { submission, t } = this.props;
        this.handleFormSubmission(prevProps.submission, submission, {
            successMessage: t('notification.mergePortSuccessful')
        });
    }

    render() {
        const { linkOptions, portName, link, t } = this.props;
        return (
            <>
                <Formik
                    data-test="form"
                    ref={formik => this.formik = formik}
                    initialValues={{
                        destination_link_uuid: '',
                    }}
                    validationSchema={object().shape({
                        destination_link_uuid: string().required(t('validation.linkNotSelected'))
                    })}
                    onSubmit={this.props.onSubmit}
                >
                    {({ handleSubmit, isSubmitting, errors, touched }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('mergePort.headerTitle', { port: (portName || '') })}
                            body={<>
                                <FormGroup row>
                                    <Col md={3}>
                                        <Label htmlFor="destination_link_uuid">{t('common.destinationLink')}</Label>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl
                                            component="select"
                                            name="destination_link_uuid"
                                            errors={errors}
                                            touched={touched}
                                        >
                                            <option disabled value={''}>{t('common.selectLink')}</option>
                                            {linkOptions && linkOptions.map((linkItem: LinkFull) => {
                                                // disable not lag links
                                                const isLAG = !isNil(linkItem.lag);
                                                return (
                                                    <option
                                                        key={linkItem.xml_id}
                                                        value={linkItem.uuid}
                                                        disabled={!isLAG}
                                                    >
                                                        {linkItem.xml_id} {!isLAG && '(No LAG)'}
                                                    </option>
                                                );
                                            })}
                                        </FormControl>
                                    </Col>
                                </FormGroup>
                            </>}
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                />
                            }
                        />
                    )}
                </Formik>
                {size(linkOptions) > 0 && every(link.ports, ['status', 'configured']) && (this.props.renderMergeButton({ onClick: this.toggle }))}

                {(size(linkOptions) === 0 || !every(link.ports, ['status', 'configured'])) && (
                    <Tooltip triggerBtn={
                        <DropdownItem toggle={false} className="text-muted cursor-default">
                            {t('mergePort.mergeBtn')}
                        </DropdownItem>
                    }>
                        {t('mergePort.cantMergeExplanation')}
                    </Tooltip>
                )}
            </>
        );
    }

    protected afterSuccessCallback = () => {
        this.props.refreshPortData();
    }
}

export default compose<Props, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(PortMergeModal);
