import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';

import { Vlan } from 'constants/api';
import { ReduxState } from 'createStore';

import MacAddressAddModal from './MacAddressAddModal';

interface InputProps {
    vlan: Vlan;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    resourceStatus: string;
}

type Props = EnhanceProps & InputProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'postLinkVlanMacAddress', {
        vlanUuid: ownProps.vlan.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId,
    }),
    resourceStatus: get(ownProps, 'vlan.status'),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('postLinkVlanMacAddress', {
                vlanUuid: ownProps.vlan.uuid,
                exchange: ownProps.exchange,
                linkId: ownProps.linkId,
            }, values));

            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MacAddressAddModal);
