import {
    Alert
} from 'reactstrap';
import React from 'react';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';
import { ApiResponse, IpRange, ReactApiDataActions } from 'constants/api';

interface InputProps {
    ipRangeId: number;
    selectedExchange: string;
}

interface EnhanceProps {
    ipRangeDelete: ApiDataBinding<ApiResponse<IpRange>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    withTranslation(),
    withApiData(
        {
            ipRangeDelete: 'deleteIpRange'
        }
    )
);

class IpRangeDeleteModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { ipRangeDelete, t } = this.props;
        this.handleFormSubmission(prevProps.ipRangeDelete.request, ipRangeDelete.request, {
            successMessage: t('notification.deleteIpRangeSuccessful')
        });
    }

    _handleOnSubmit = () => {
        const { ipRangeDelete, apiDataActions, selectedExchange, ipRangeId } = this.props;
        ipRangeDelete.perform({ id: ipRangeId }).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getIpRanges', { exchange: selectedExchange });
                apiDataActions.perform('getIpRanges', { exchange: selectedExchange });
            }
        });
    };

    render() {
        const { ipRangeDelete, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('deleteIpRangeItemModal.headerTitle')}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('deleteIpRangeItemModal.warningMessage')}</p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(ipRangeDelete, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={this._handleOnSubmit}
                        />
                    }
                />
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc]}>
                    <span
                        data-test="IpRange-delete-btn"
                        className="fas fa-trash fa-pull-left mt-1 text-primary cursor-pointer"
                        onClick={this.toggle}
                        title={t('deleteIpRangeItemModal.headerTitle')}
                    ></span>
                </RolesChecker>
            </React.Fragment>
        );
    }
}

export default enhance(IpRangeDeleteModal);
