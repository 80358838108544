import React, { FunctionComponent, memo, ReactNode } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { ApiDataRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import head from 'lodash/head';
import size from 'lodash/size';
import last from 'lodash/last';
import { withTranslation, WithTranslation } from 'react-i18next';
import classnames from 'classnames';
import { List, WindowScroller } from 'react-virtualized';
import ReactResizeDetector from 'react-resize-detector';

// constants
import { SflowRankedData } from 'constants/api';
import { StatsInterval, SflowStatsType } from 'constants/literals';
import { isAsn } from 'utils/linksUtils';
// import { sflowRankedDataLarge as sflowRankedDataMock, sflowRankedDataMin as sflowRankedDataMinMock } from '__mocks__/sflowRankedMocks';

// components
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { CenteredAlert } from 'components/styled';
import SflowP2PStatsGraph from 'components/graphs/SflowP2PStatsGraph';

const TABLE_HEIGHT = 250;
const GRAPH_HEIGHT = 250;
const ROW_HEIGHT = GRAPH_HEIGHT + TABLE_HEIGHT;

interface InProps {
    request: ApiDataRequest;
    scrollElementName?: string;
}

type Props = InProps & WithTranslation;

const SflowRankedResults: FunctionComponent<Props> = ({ t, request, scrollElementName }) => {
    return (
        <RequestStatusRenderer
            request={request}
            failedMessage={t('stats.errorLoading')}
            defaultMessage={t('common.noData')}
            success={() => {
                const sflowRankedData: SflowRankedData[] = get(request, 'result.data.data');
                if (!size(sflowRankedData)) {
                    return <CenteredAlert data-test="no-data-message" color="none">{t('common.noData')}</CenteredAlert>;
                }
                const lookup = get(request, 'params.lookup');
                const counter = get(request, 'params.counter');
                const exchange = get(request, 'params.exchange');
                const isASN = isAsn(lookup);

                return (
                    <ResultsRenderer
                        data-test="results-renderer"
                        sflowRankedData={sflowRankedData}
                        scrollElementName={scrollElementName}
                        renderGraph={(dataItem: SflowRankedData) => (
                            <SflowP2PStatsGraph
                                data-test="sflow-ranked-graph"
                                src={lookup}
                                dst={isASN ? dataItem.as_number : last(dataItem.ip_addresses) || lookup}
                                height={GRAPH_HEIGHT}
                                counter={counter}
                                exchange={exchange}
                                interval={StatsInterval.Daily}
                                sflowType={isASN ? SflowStatsType.as2as : SflowStatsType.ip2ip}
                                displayLegend={false}
                            />
                        )}
                    />
                );
            }}
        />
    );
};

interface ResultsRendererInProps {
    sflowRankedData: SflowRankedData[];
    scrollElementName?: string;
    renderGraph: (dataItem: SflowRankedData) => ReactNode;
}

const ResultsRenderer: FunctionComponent<ResultsRendererInProps> = ({ sflowRankedData, scrollElementName, renderGraph }) => {
    const scrollElement = scrollElementName ? head(document.getElementsByClassName(scrollElementName)) || window : window;

    return (
        <ReactResizeDetector
            handleWidth
            refreshMode="debounce"
            refreshRate={300}
            render={({ width = 0 }) => (
                <WindowScroller scrollElement={scrollElement}>
                    {({ height = 0, isScrolling, onChildScroll, scrollTop }) => (
                        <List
                            autoHeight={true}
                            width={width}
                            height={height}
                            rowHeight={ROW_HEIGHT}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            rowCount={sflowRankedData.length}
                            scrollTop={scrollTop}
                            overscanRowCount={3}
                            rowRenderer={({ index, key, style }) => {
                                const dataItem = sflowRankedData[index];
                                return (
                                    <div key={key} style={style}>
                                        <RowRendererEnhanced
                                            dataItem={dataItem}
                                            Graph={renderGraph(dataItem)}
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                </WindowScroller>
            )}
        />
    );
};

interface RowRendererInProps {
    dataItem: SflowRankedData;
    Graph: ReactNode;
}

export const RowRenderer: FunctionComponent<RowRendererInProps & WithTranslation> = ({ dataItem, t, Graph }) => (
    <Card className="card-accent-primary">
        <CardHeader><strong>{dataItem.contract_name}</strong></CardHeader>
        <CardBody>
            <Table size="sm" bordered>
                <tbody>
                    <tr>
                        <th>{t('common.ip')}:</th>
                        <td>{dataItem.ip_addresses && dataItem.ip_addresses.join(', ')}</td>
                    </tr>
                    <tr>
                        <th>{t('common.asn')}:</th>
                        <td>{dataItem.as_number}</td>
                    </tr>
                    <tr>
                        <th>{t('common.switch')}:</th>
                        <td>{dataItem.switch_name}</td>
                    </tr>
                    <tr>
                        <th>{t('common.routeServer')}:</th>
                        <td>
                            <i
                                data-test="route-server-indicator"
                                className={classnames([
                                    'fa',
                                    dataItem.route_server ? 'fa-check-circle text-success' : 'fa-ban text-danger',
                                ])}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
            {Graph}
        </CardBody>
    </Card>
);

const RowRendererEnhanced = withTranslation()(memo(RowRenderer));

export default compose<Props, InProps>(
    withTranslation()
)(memo(SflowRankedResults));
