import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { Router } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';
import { Roles } from '../../constants/literals';

interface InputProps {
    router: Router;
    resourceStatus: string;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'putFqdn', {
        routerId: ownProps.router.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId,
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('putFqdn', {
                routerId: ownProps.router.uuid,
                exchange: ownProps.exchange,
                linkId: ownProps.linkId,
            }, values));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class FqdnModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.request, this.props.request, {
            pathToError: 'response.fqdn.0',
            successMessage: this.props.t('notification.changeFqdnSuccessful'),
        });
    }

    render() {
        const { onSubmit, router, t } = this.props;
        return (
            <React.Fragment>
                <Formik
                    data-test="form"
                    ref={formik => {
                        this.formik = formik;
                    }}
                    initialValues={{ fqdn: get(router, 'fqdn', '') }}
                    onSubmit={onSubmit}
                    validationSchema={object().shape({
                        fqdn: string()
                            .required(t('validation.requiredField'))
                            .matches(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/i, t('validation.notValidFqdn'))
                    })}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('fqdnModal.headerTitle')}
                            body={
                                <FormGroup row className="mb-0">
                                    <Col md="3">
                                        <Label htmlFor="fqdn">{t('common.fqdn')}</Label>
                                    </Col>
                                    <Col md="9">
                                        <FormControl
                                            type="text"
                                            name="fqdn"
                                            id="fqdn"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                </FormGroup>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                <EditBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    resourceStatus={this.props.resourceStatus}
                    roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.customer, Roles.ixaas_client]}
                >
                    <Tooltip
                        data-test="change-fqdn-btn"
                        triggerBtn={
                            <i
                                className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('fqdnModal.headerTitle')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(FqdnModal);
