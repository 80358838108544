import jwtDecode from 'jwt-decode';
import memoizeOne from 'memoize-one';
import includes from 'lodash/includes';
import get from 'lodash/get';
import some from 'lodash/some';
import every from 'lodash/every';
import size from 'lodash/size';

import { Roles } from 'constants/literals';
import { AccessToken } from 'constants/api';

export const decodeJwtToken = memoizeOne((accessToken: string): AccessToken | undefined => {
    try {
        return jwtDecode<AccessToken>(accessToken);
    } catch (error) {
        console.warn(error);
        return undefined;
    }
});

export const hasRoles = memoizeOne((userRoles: Roles[]): boolean => {
    return !!userRoles && size(userRoles) > 0;
});

export const hasRole = memoizeOne((userRoles: Roles[], role: Roles): boolean => {
    return includes(userRoles, role);
});

export const isAdmin = (userRoles: Roles[]): boolean => {
    return hasRole(userRoles, Roles.admin);
};

export const isNoc = (userRoles: Roles[]): boolean => {
    return hasRole(userRoles, Roles.noc);
};

export const isQnoc = (userRoles: Roles[]): boolean => {
    return hasRole(userRoles, Roles.qnoc);
};

export const isCustomer = (userRoles: Roles[]): boolean => {
    return hasRole(userRoles, Roles.customer);
};

export const isIxaasClient = (userRoles: Roles[]): boolean => {
    return hasRole(userRoles, Roles.ixaas_client);
};

/**
 * Checks that user has at least one role in ...roles arguments
 */
export const hasSomeRole = memoizeOne((userRoles: Roles[], ...requiredRoles: Roles[]): boolean => {
    return some(requiredRoles, (role: string) => includes(userRoles, role));
});

/**
 * Checks that user has all roles in ...roles arguments
 */
export const hasEveryRole = memoizeOne((userRoles: Roles[], ...requiredRoles: Roles[]): boolean => {
    return every(requiredRoles, (role: string) => includes(userRoles, role));
});

/**
 * Checks that user has an edit role
 */
export const hasEditRole = memoizeOne((userRoles: Roles[]): boolean => {
    const editRoles = [
        Roles.admin,
        Roles.noc,
        Roles.qnoc,
        Roles.customer,
        Roles.ixaas_client
    ];

    // Check if any user role matches the specified roles
    return userRoles.some(role => editRoles.includes(role));
});

export const getUserUuid = (accessToken?: string): string => {
    if (!accessToken) return '';
    return get(decodeJwtToken(accessToken), 'user.uuid', '');
};

export const getMainRole = (userRoles: Roles[]): Roles | undefined => {
    const enumValues = Object.values(Roles);

    for (const item of enumValues) {
        if (userRoles.includes(item as Roles)) {
            return item as Roles;
        }
    }
    return undefined;
};
