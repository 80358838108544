import React, { FunctionComponent, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getLinkToContract, getLinkToRsPeering, getLinkToNrPeering, getLinkToVlan } from '../../utils/linksUtils';
import ResourceStatus from '../utils/ResourceStatus';
import ContextMenuDropdown from '../utils/ContextMenuDropdown';
import LinkVlanResourceTransitions from '../resourceTransitions/LinkVlanResourceTransitions';
import LinkVlanCloneModal from '../modals/LinkVlanCloneModal';
import ResourceUpdateStatus from '../utils/ResourceUpdateStatus';
import LinkVlanDeleteBtn from '../utils/LinkVlanDeleteBtn';
import { BaseModalTemplate } from '../modals/BaseModalTemplate';
import JSONPretty from 'react-json-prettify';
import { githubGist } from 'react-json-prettify/dist/themes';
import { DetailsTable, StyledMac } from '../styled';
import LinkVlanTagModal from '../modals/LinkVlanTagModal';
import LinkVlanUntagModal from '../modals/LinkVlanUntagModal';
import LinkVlanRateLimiterModal from '../modals/LinkVlanRateLimiterModal';
import AsnModal from '../modals/AsnModal';
import SwapVlanIpsModal from '../modals/SwapVlanIpsModal';
import RouterDeleteModal from '../modals/RouterDeleteModal';
import SflowButton from './SflowButton';
import SflowStatsModal from '../modals/SflowStatsModal';
import { SflowStatsType, Roles, IpRouterType, LinkType, LinkVlanStatus, NetworkRequestStatus, ConnectionStatus } from '../../constants/literals';
import FqdnModal from '../modals/FqdnModal';
import AmsixPeeringModal from '../modals/AmsixPeeringModal';
import RsPeeringModal from '../modals/RsPeeringModal';
import RsPeeringDisableModal from '../modals/RsPeeringDisableModal';
import RsPeeringFilteringModal from '../modals/RsPeeringFilteringModal';
import { getStatusColor } from '../../utils/formatUtils';
import MacAddressDeleteModal from '../modals/MacAddressDeleteModal';
import MacAddressChangeModal from '../modals/MacAddressChangeModal';
import { Exchange, LinkFull, MacAddress, PublicVlanItem, Vlan, SwappableLinkVlan, ApiResponse, ReactApiDataActions } from '../../constants/api';
import MacAddressVlanAddModal from '../modals/MacAddressVlanAddModal';
import StatisticsWidget from '../utils/StatisticsWidget';
import VlanGraph from '../graphs/VlanGraph';
import { RoutersTable } from './VlanInfo';
import { hasSomeRole, hasRole } from 'utils/userUtils';
import LinkVlanEditDatabaseModal from 'components/modals/LinkVlanEditDatabaseModal';
import RolesChecker from 'components/utils/RolesChecker';
import RateLimiterDeleteModal from '../modals/RateLimiterDeleteModal';
import { compose } from 'recompose';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { useTranslation } from 'react-i18next';

interface VlanInfoCardInProps {
    link: LinkFull;
    linkVlan: Vlan;
    vlan: PublicVlanItem;
    exchange: Exchange;
    userRoles: Roles[];
    vlanHasNativeRouter: (arg: string) => boolean;
    vlanHasRouteServer: (arg: string) => boolean;
}

interface VlanInfoCardEnhanceProps {
    moveToQuarantineBinding: ApiDataBinding<ApiResponse<Vlan>>;
    moveToProductionBinding: ApiDataBinding<ApiResponse<Vlan>>;
    apiDataActions: ReactApiDataActions;
}

type VlanInfoCardProps = VlanInfoCardInProps & VlanInfoCardEnhanceProps;

interface SwapIpsButtonInProps {
    link: LinkFull;
    linkVlan: Vlan;
    exchangeName: string;
}

interface SwapIpsButtonEnhanceProps {
    swappableVlansBinding: ApiDataBinding<{ swappable_vlans: SwappableLinkVlan[] }>;
}

type SwapIpsButtonProps = SwapIpsButtonInProps & SwapIpsButtonEnhanceProps;

function SwapIpsButton(props: SwapIpsButtonProps) {
    const { swappableVlansBinding, link, linkVlan, exchangeName } = props;

    if (swappableVlansBinding.data && swappableVlansBinding.data.swappable_vlans && swappableVlansBinding.data.swappable_vlans.length !== 0) {
        return (
            <SwapVlanIpsModal
                link={link}
                vlan={linkVlan}
                swappableLinkVlans={swappableVlansBinding.data!.swappable_vlans}
                endpointKey={'postLinkVlanSwapIps'}
                endpointParams={{
                    linkId: link.uuid,
                    vlanId: linkVlan.uuid,
                    exchange: exchangeName,
                }}
            />
        );
    } else {
        return null;
    }
}

const EnhancedSwapIpsButton = compose<SwapIpsButtonProps, SwapIpsButtonInProps>(
    withApiData({ swappableVlansBinding: 'getSwappableVlans' }, (ownProps: SwapIpsButtonInProps) => ({
        swappableVlansBinding: { exchange: ownProps.exchangeName, vlanId: ownProps.linkVlan.uuid }
    })),
)(SwapIpsButton);

const VlanInfoCard: FunctionComponent<VlanInfoCardProps> = ({
    link,
    exchange,
    linkVlan,
    vlan,
    vlanHasNativeRouter,
    vlanHasRouteServer,
    userRoles,
    moveToQuarantineBinding,
    moveToProductionBinding,
    apiDataActions,
}) => {
    const { t } = useTranslation();
    const [jsonModalOpen, setJsonModalOpen] = useState(false);
    const linkId = link.uuid;
    const isOnlyVlan = link.vlans.length === 1;
    const toggleJsonModal = () => {
        setJsonModalOpen(!jsonModalOpen);
    };

    const routers = linkVlan && linkVlan.routers.sort((a, b) => a.class.localeCompare(b.class));
    const vlanStatus = linkVlan && linkVlan.status;
    const exchangeName = exchange.short_name;
    const vlanLabel = `${linkVlan.vlan_id.toString(10)} -> ${vlan.description}`;
    const routersIpv4 = routers.filter(r => r.class === IpRouterType.Ipv4Router);
    const routersIpv6 = routers.filter(r => r.class === IpRouterType.Ipv6Router);

    function invalidateLinkAndLinkVlanCache() {
        apiDataActions.invalidateCache('getMemberLink', { linkId: link.uuid, exchange: link.exchange.short_name.toUpperCase() });
        apiDataActions.invalidateCache('getLinkVlan', { linkVlanUuid: linkVlan.uuid });
    }

    function moveToQuarantine() {
        moveToQuarantineBinding.perform().then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                invalidateLinkAndLinkVlanCache();
            }
        })
    }

    function moveToProduction() {
        moveToProductionBinding.perform().then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                invalidateLinkAndLinkVlanCache();
            }
        })
    }

    return (
        <Card className="card-accent-primary" key={linkVlan.uuid}>
            <CardHeader>
                <strong>
                    <Link to={getLinkToVlan(exchangeName, linkVlan.vlan_uuid)}>{vlanLabel}</Link>
                </strong>
                <small className="justify-content-between ml-1">
                    <span>
                        <b className="ml-1">Name:</b> {linkVlan.xml_id}
                    </span>
                    {linkVlan.is_virtual && (
                        <>
                            <span>
                                <b className="ml-1"> {t('connection.contractName')}:{' '} </b>
                            </span>{' '}
                            <Link to={getLinkToContract(exchangeName, linkVlan.contract_uuid)}>
                                {linkVlan.contract_name}
                            </Link>{' '}
                        </>
                    )}
                </small>
                {!hasSomeRole(userRoles, Roles.customer_reader, Roles.ixaas_client_reader) && (
                <div className="card-header-actions">
                    <ResourceStatus data-test="resource-status" status={vlanStatus} />
                    <ContextMenuDropdown data-test="context-menu" className="ml-2" dropdownMenuProps={{ right: true, persist: true }}>
                        <RolesChecker roles={[Roles.admin, Roles.noc]}>
                            <LinkVlanEditDatabaseModal
                                linkVlan={linkVlan}
                                vlan={vlan}
                                link={link}
                                ipv4Routers={routersIpv4}
                                ipv6Routers={routersIpv6}
                                renderButton={(props) => (
                                    <DropdownItem toggle={false} onClick={props.onClick}>
                                        {t('linkVlanEditDbModal.editLinkVlanBtn')}
                                    </DropdownItem>
                                )}
                            />
                            {linkVlan.status === LinkVlanStatus.configured && (
                                <DropdownItem onClick={moveToQuarantine}>{t('vlan.moveToQuarantine')}</DropdownItem>
                            )}
                            {linkVlan.status === LinkVlanStatus.quarantined && (
                                <DropdownItem onClick={moveToProduction}>{t('vlan.moveToProduction')}</DropdownItem>
                            )}
                        </RolesChecker>
                        {link.type !== LinkType.MonitorLink && (
                            <LinkVlanResourceTransitions
                                data-test="resource-transitions"
                                currentStatus={vlanStatus}
                                resourceId={linkVlan.uuid}
                                linkId={linkId}
                                exchange={exchangeName}
                                renderTransitionBtn={(props) => (
                                    <DropdownItem toggle={false} onClick={props.onClick}>{props.transitionName}</DropdownItem>
                                )}
                            />
                        )}
                        {link.status === ConnectionStatus.configured && (
                            <LinkVlanCloneModal
                                data-test="vlan-clone-modal"
                                vlan={linkVlan}
                                vlanLabel={vlanLabel}
                                exchange={exchangeName}
                                renderCloneButton={(props) => (
                                    <DropdownItem toggle={false} onClick={props.onClick}>{t('cloneVlan.cloneVlanBtn')}</DropdownItem>
                                )}
                            />
                        )}
                        <DropdownItem data-test="view-raw-link_vlan-json" onClick={toggleJsonModal}>
                            {t('vlan.viewRawJSON')}
                        </DropdownItem>
                        <ResourceUpdateStatus
                            data-test="vlan-update-status"
                            resourceId={linkVlan.uuid}
                            resourceStatus={linkVlan.status}
                            linkId={linkId}
                            exchange={exchangeName}
                            isDropdownItem={true}
                        />
                        <LinkVlanDeleteBtn
                            data-test="vlan-delete-btn"
                            vlanLabel={vlanLabel}
                            vlanId={linkVlan.uuid}
                            vlanStatus={linkVlan.status}
                            linkId={link.uuid}
                            exchange={exchangeName}
                            isDropdownItem={true}
                        />
                    </ContextMenuDropdown>
                    <BaseModalTemplate
                        id={'VlanInfoJsonModal' + linkVlan.uuid}
                        data-test="json-modal"
                        isOpen={jsonModalOpen}
                        toggle={toggleJsonModal}
                        header={t('vlan.rawVlanJsonTitle')}
                        body={<JSONPretty json={linkVlan} theme={githubGist} />}
                        footer={<Button color="light" onClick={toggleJsonModal}>{t('modal.closeBtn')}</Button>}
                    />
                </div>
                )}
            </CardHeader>
            <ListGroup flush>
                <ListGroupItem>
                    <DetailsTable size="sm" className="mb-0" borderless style={{ tableLayout: 'fixed' }}>
                        <tbody>
                        <tr>
                            <th>802.1Q {t('common.mode')}</th>
                            <td>
                                {linkVlan.mode} {linkVlan.qtag && `(${linkVlan.qtag})`} {linkVlan.inner_qtag && `(${linkVlan.inner_qtag})`}
                                <LinkVlanTagModal data-test="vlan-tag-modal" vlan={linkVlan} linkId={linkId} exchange={exchangeName} />
                                {linkVlan.qtag && isOnlyVlan && (
                                    <LinkVlanUntagModal
                                        data-test="vlan-untag-modal"
                                        vlan={linkVlan}
                                        linkId={linkId}
                                        exchange={exchangeName}
                                    />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('vlan.rateLimited')}</th>
                            <td>
                                <span>
                                    {linkVlan.speed ? `${linkVlan.speed}Mbps` : 'No'}
                                </span>
                                <LinkVlanRateLimiterModal
                                    link={link}
                                    endpointKey={'postRateLimiter'}
                                    rateLimiter={linkVlan.rate_limiter}
                                    currentSpeed={linkVlan.speed}
                                    endpointParams={{
                                        linkId: link.uuid,
                                        vlanId: linkVlan.uuid,
                                        exchange: exchangeName
                                    }}
                                />
                                {/*<trash bin*/}
                                {linkVlan.rate_limiter && <RateLimiterDeleteModal
                                    link={link}
                                    endpointKey={'deleteRateLimiter'}
                                    rateLimiter={linkVlan.rate_limiter}
                                    currentSpeed={linkVlan.speed}
                                    endpointParams={{
                                        linkId: link.uuid,
                                        vlanId: linkVlan.uuid,
                                        exchange: exchangeName
                                    }}
                                />}
                            </td>
                        </tr>
                        {linkVlan.as_number !== null && (
                            <tr>
                                <th>{t('common.asn')}</th>
                                <td>
                                    <span>{linkVlan.as_number}</span>
                                    <AsnModal
                                        link={link}
                                        linkVlan={linkVlan}
                                        defaultAsn={linkVlan.as_number}
                                        endpointKey={'putVlanAsn'}
                                        endpointParams={{
                                            linkId: link.uuid,
                                            vlanId: linkVlan.uuid,
                                            exchange: exchangeName,
                                        }}
                                    />
                                    <SflowStatsModal
                                      defaultSflowType={SflowStatsType.as2as}
                                      exchange={link.exchange.short_name}
                                      srcAsn={linkVlan.as_number}
                                      iconClasses="fa-pull-right mt-1 mr-1"
                                    />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th>Handle</th>
                            <td>
                                {linkVlan.handle}
                            </td>
                        </tr>
                        {linkVlan.jira_ticket && hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.qnoc) && (
                            <tr>
                                <th>{t('connection.jiraTicket')}</th>
                                <td>
                                    <a href={linkVlan.jira_ticket} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-jira" style={{ color: '#0052CC' }} />{' '}
                                        {linkVlan.jira_ticket.split('/').pop()}
                                    </a>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </DetailsTable>
                </ListGroupItem>
                {routers.length > 0 && (
                    <ListGroupItem>
                        <RoutersTable
                            data-test="routers-table"
                            size="sm"
                            className="mb-0"
                            borderless
                            style={{ tableLayout: 'fixed' }}
                        >
                            <tbody>
                            <tr>
                                    <th>{t('common.routers')}
                                        {!hasRole(userRoles, Roles.ixaas_client_reader) && (
                                        <EnhancedSwapIpsButton
                                            link={link}
                                            linkVlan={linkVlan}
                                            exchangeName={exchangeName}
                                        />
                                        )}
                                    </th>
                                {routers.map(router => (
                                    <td key={router.uuid}>
                                        <strong>{router.class}</strong>
                                        <RouterDeleteModal
                                            data-test="router-delete-modal"
                                            routerUuid={router.uuid}
                                            linkId={linkId}
                                            exchange={exchangeName}
                                        />
                                        {process.env.REACT_APP_SHOW_SFLOW === 'true' && (
                                            <SflowButton data-test="sflow-btn" router={router} exchange={exchangeName} />
                                        )}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th>{t('vlan.ipAddress')}</th>
                                {routers.map(router => (
                                    <td key={router.uuid}>
                                        {router.ip}/{router.netmask}
                                        <SflowStatsModal
                                            data-test="sflow-stats-modal"
                                            defaultSflowType={SflowStatsType.ip2ip}
                                            exchange={exchangeName}
                                            srcAsn={linkVlan.as_number || undefined}
                                            srcIp={router.ip}
                                            iconClasses="fa-pull-right mt-1"
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th>{t('common.fqdn')}</th>
                                {routers.map(router => (
                                    <td key={router.uuid}>
                                        {router.fqdn} {' '}
                                        <FqdnModal
                                            data-test="fqdn-modal"
                                            router={router}
                                            resourceStatus={vlanStatus}
                                            linkId={linkId}
                                            exchange={exchangeName}
                                        />
                                    </td>
                                ))}
                            </tr>
                            {process.env.REACT_APP_SHOW_AMS_PEERING === 'true' && vlanHasNativeRouter(linkVlan.vlan_uuid) && (
                                <tr>
                                    <th>{t('vlan.peeringWithAMS')}</th>
                                    {routers.map(router => (
                                        <td key={router.uuid}>
                                            <Badge color={router.amsix_routeserver ? 'success' : 'danger'}>
                                                {t(`common.${router.amsix_routeserver ? 'enabled' : 'disabled'}`)}
                                            </Badge>
                                            {' '}
                                            <AmsixPeeringModal
                                                data-test="amsix-peering-modal"
                                                router={router}
                                                resourceStatus={vlanStatus}
                                                linkId={linkId}
                                                exchange={exchangeName}
                                            />
                                            {router.amsix_routeserver && (
                                                <Link
                                                    to={getLinkToNrPeering(router.uuid, exchangeName, linkId)}
                                                    title={t('vlan.viewPeerings')}
                                                >
                                                    <i className="fas fa-eye fa-pull-right mt-1" />
                                                </Link>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            )}
                            {vlanHasRouteServer(linkVlan.vlan_uuid) && (
                                <tr>
                                    <th>{t('vlan.peeringWithRS')}</th>
                                    {routers.map(router => (
                                        <td key={router.uuid}>
                                            <Badge data-test="routeserver-status" color={router.secure_routeserver ? 'success' : 'danger'}>
                                                {t(`common.${router.secure_routeserver ? 'enabled' : 'disabled'}`)}
                                            </Badge>
                                            {' '}
                                            {router.secure_routeserver && router.secure_mode && (
                                                <Badge
                                                    data-test="router-mode"
                                                    color="dark"
                                                    className="text-uppercase"
                                                >
                                                    {router.secure_mode}
                                                </Badge>
                                            )}

                                            <RsPeeringModal
                                                data-test="rs-peering-modal"
                                                router={router}
                                                resourceStatus={vlanStatus}
                                                linkId={linkId}
                                                exchange={exchangeName}
                                            />
                                            {router.secure_routeserver && (
                                                <RsPeeringDisableModal
                                                    data-test="rs-peering-disable-modal"
                                                    router={router}
                                                    linkId={linkId}
                                                    exchange={exchangeName}
                                                    resourceStatus={vlanStatus}
                                                />
                                            )}
                                            {/*router.secure_routeserver && (
                                                            <RsPeeringViewModal router={router} exchange={exchangeName} />
                                                        )*/}
                                            {router.secure_routeserver && (
                                                <Link
                                                    data-test="link-to-rs-peering"
                                                    to={getLinkToRsPeering(router.uuid, exchangeName, linkId)}
                                                    title={t('vlan.viewPeerings')}
                                                >
                                                    <i className="fas fa-eye fa-pull-right mt-1" />
                                                </Link>
                                            )}
                                            {process.env.REACT_APP_SHOW_RS_FILTERING === 'true' && (
                                                <RsPeeringFilteringModal
                                                    data-test="rs-peering-filtering-modal"
                                                    router={router}
                                                    exchange={exchangeName}
                                                />
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            )}
                            </tbody>
                        </RoutersTable>
                    </ListGroupItem>
                )}
                <ListGroupItem>
                    <DetailsTable
                        data-test="mac-addresses-table"
                        borderless
                        size="sm"
                        className="mb-0"
                        style={{ tableLayout: 'fixed' }}
                    >
                        <tbody>
                        <tr>
                            <th rowSpan={Math.max(linkVlan.mac_addresses.length, 1)}>
                                {t('common.macAddresses')}
                            </th>
                            <td>
                                {linkVlan.mac_addresses.length > 0 && (
                                    <div>
                                        <StyledMac data-test="mac-address-value">
                                            {linkVlan.mac_addresses[0].value}
                                        </StyledMac>
                                        <Badge color={getStatusColor(linkVlan.mac_addresses[0].status)}>
                                            {linkVlan.mac_addresses[0].status}
                                        </Badge>
                                        {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                                            <>
                                                <MacAddressDeleteModal
                                                    data-test="mac-delete-modal"
                                                    address={linkVlan.mac_addresses[0]}
                                                    linkId={linkId}
                                                    exchange={exchangeName}
                                                    resourceStatus={vlanStatus}
                                                />
                                                <MacAddressChangeModal
                                                    data-test="mac-change-modal"
                                                    address={linkVlan.mac_addresses[0]}
                                                    resourceStatus={vlanStatus}
                                                    linkId={linkId}
                                                    exchange={exchangeName}
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                            </td>
                        </tr>
                        {linkVlan.mac_addresses.slice(1).map((mac: MacAddress) => (
                            <tr key={mac.uuid}>
                                <td>
                                    <StyledMac data-test="mac-address-value">{mac.value}</StyledMac>
                                    <Badge color={getStatusColor(mac.status)}>
                                        {mac.status}
                                    </Badge>
                                    {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                                        <>
                                            <MacAddressDeleteModal
                                                data-test="mac-delete-modal"
                                                address={mac}
                                                linkId={linkId}
                                                exchange={exchangeName}
                                                resourceStatus={vlanStatus}
                                            />
                                            <MacAddressChangeModal
                                                data-test="mac-change-modal"
                                                address={mac}
                                                resourceStatus={vlanStatus}
                                                linkId={linkId}
                                                exchange={exchangeName}
                                            />
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                        {!hasSomeRole(userRoles, Roles.ixaas_client_reader, Roles.customer_reader) && (
                            <tr>
                                <th/>
                                <td>
                                    <MacAddressVlanAddModal
                                        data-test="mac-add-modal"
                                        vlan={linkVlan}
                                        exchange={exchangeName}
                                        linkId={linkId}
                                    />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </DetailsTable>
                </ListGroupItem>
            </ListGroup>
            <CardBody>
                <StatisticsWidget>
                    {({ type, interval }) => (
                        <VlanGraph
                            vlanId={linkVlan.uuid}
                            exchange={exchangeName}
                            type={type}
                            interval={interval}
                            height={250}
                        />
                    )}
                </StatisticsWidget>
            </CardBody>
        </Card>
    );
};

const EnhancedVlanInfoCard = compose<VlanInfoCardProps, VlanInfoCardInProps>(
    withApiData({
        moveToQuarantineBinding: 'putLinkVlanMoveToQuarantine',
        moveToProductionBinding: 'putLinkVlanMoveToProduction',
    }, (ownProps: VlanInfoCardInProps) => ({
        moveToQuarantineBinding: { uuid: ownProps.linkVlan.uuid },
        moveToProductionBinding: { uuid: ownProps.linkVlan.uuid },
    })),
)(VlanInfoCard);

export default EnhancedVlanInfoCard;
