import { ApiDataRequest } from 'react-api-data';
import fileSize from 'filesize';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import values from 'lodash/fp/values';
import pipe from 'lodash/fp/pipe';
import join from 'lodash/fp/join';
import flatten from 'lodash/flatten';
import isString from 'lodash/isString';

export const getStatusColor = (color?: string) => {
    switch (color) {
        case 'quarantine':
        case 'configuring':
        case 'deconfiguring':
        case 'candidate':
            return 'warning';
        case 'configured':
        case 'active':
            return 'success';
        case 'unconfigured':
        case 'unverified':
            return 'danger';
        case 'deconfigured':
            return 'dark';
        case 'merging':
            return 'provisioning';
        case 'up':
            return 'success';
        case 'down': 
            return 'warning';
        case 'disabled':
            return 'danger';
        default:
            return 'info';
    }
};

export const formatSpeed = (input?: number): string => {
    if (typeof input === 'undefined') {
        return '';
    }

    const converted = fileSize(input / 8 * 1000000, { base: 10, bits: true });

    return `${converted}ps`;
};

export const formatBps = (input: number): string => {
    const converted = fileSize(input / 8, { base: 10, bits: true });

    return `${converted}ps`;
};

export const formatRequestErrors = (request: ApiDataRequest, pathToError?: string): string => {
    let errors: string = '';
    if (!request || request.errorBody instanceof SyntaxError) {
        return '';
    }
    if (pathToError) {
        errors = get(pathToError, request);
        if (errors) {
            return errors;
        }
    }
    errors = pipe(
        get('errorBody.errors'),
        map((error: {[key: string]: string }) => {
            if (isString(error)) {
                return error;
            }
            return flatten(values(error)).join(', ');
        }),
        join(', ')
    )(request);
    if (!errors) {
        errors = get('errorBody.message', request) || get('errorBody.error', request);
    }
    return errors;
};
