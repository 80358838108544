import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, BreadcrumbItem, Badge } from 'reactstrap';
import { getResultData, performApiRequest, invalidateApiDataRequest, withApiData, ApiDataBinding } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import get from 'lodash/get';

// utils
import {
    refetchLinkContract,
    getLinkToConnection,
    getLinkToCustomers,
    getLinkToContract,
    isConnectionDeletable
} from 'utils/linksUtils';

// constants
import { LinkFull, ApiResponse, Contract, Organisation, Exchange as ExchangeType } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import ContractInfo from 'components/cards/ContractInfo';
import ConnectionAddModal from 'components/modals/ConnectionAddModal';
import UserCustomerAddModal from 'components/modals/UserCustomerAddModal';
import ContractAccountsList from 'components/cards/ContractAccountsList';
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import MonitorLinkAddModal from 'components/modals/MonitorLinkAddModal';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { NetworkRequestStatus } from 'constants/literals';
import ContractVirtualLinkVlans from 'components/cards/ContractVirtualLinkVlans';
import { StyledTable } from '../styled';
import InteractiveRemoteTable from '../utils/InteractiveRemoteTable';
import { formatSpeed, getStatusColor } from '../../utils/formatUtils';
import Exchange from '../utils/Exchange';
import ConnectionDeleteModal from '../modals/ConnectionDeleteModal';
import { getAllExchangesForCurrentScopeSelector } from 'redux/apiData/apiDataSelectors';

interface InProps {
    contractUuid: string;
    exchange: string;
    linkId?: string;
}

interface ApiProps {
    link?: LinkFull;
    refetchContract: () => boolean;
    refetchContractAccounts: () => boolean;
    refetchVirtualLinkVlans: () => boolean;
}

interface EnhanceProps {
    contract: ApiDataBinding<Contract>;
    organisation: ApiDataBinding<ApiResponse<Organisation>>;
    contractConnectionsBinding: ApiDataBinding<ApiResponse<{ links: LinkFull[] }>>;
    exchanges: ExchangeType[];
}

export type Props = InProps & ApiProps & EnhanceProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    link: getResultData(state.apiData, 'getMemberLink', {
        linkId: get(ownProps, 'linkId'),
        exchange: ownProps.exchange.toUpperCase(),
    }),
    exchanges: getAllExchangesForCurrentScopeSelector(state.apiData)
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => ({
    refetchContract: () => {
        const requestParams = {
            contractUuid: ownProps.contractUuid,
            exchange: ownProps.exchange.toUpperCase()
        };
        refetchLinkContract(dispatch, requestParams);
        return true;
    },
    refetchVirtualLinkVlans: () => {
        dispatch(invalidateApiDataRequest('getContractVirtualLinkVlans', { contractUuid: ownProps.contractUuid }));
    },
    refetchContractAccounts: () => {
        const requestParams = {
            contractUuid: ownProps.contractUuid,
        };
        dispatch(invalidateApiDataRequest('getContractAccounts', requestParams));
        dispatch(performApiRequest('getContractAccounts', requestParams));
    }
});

interface AddConnectionButtonProps {
    organisation: ApiDataBinding<ApiResponse<Organisation>>;
    contractUuid: string;
    exchange: string;
}

function AddConnectionButton({ organisation, contractUuid, exchange }: AddConnectionButtonProps) {
    if (organisation.request.networkStatus !== NetworkRequestStatus.Success) {
        return null;
    }

    if (organisation.data!.data!.is_amsix) {
        return <MonitorLinkAddModal data-test="monitor-link-add-modal" contractUuid={contractUuid} exchange={exchange} />;
    } else {
        return <ConnectionAddModal data-test="connection-add-modal" contractUuid={contractUuid} />;
    }
}

const SIZE_PER_PAGE = 10;
const DEFAULT_SORT_FIELD = 'xml_id';
const DEFAULT_SORT_ORDER = 'asc';

function composeConnectionsTableData(links: LinkFull[], exchanges: ExchangeType[]) {
    return links.map((link) => {
        const exchange = exchanges.find((exchange) => exchange.uuid === link.exchange.uuid)!;
        const location = exchange.locations.find((location) => location.uuid === link.location_uuid)!;
        return {
            ...link,
            exchange_short_name: exchange.short_name,
            location_short_name: location.short_name,
        };
    });
}

type ConnectionsTableData = LinkFull & {
    exchange_short_name: string;
    location_short_name: string;
};

export const ContractDetails: FunctionComponent<Props> = ({
    contract,
    organisation,
    contractUuid,
    refetchContract,
    contractConnectionsBinding,
    exchange,
    exchanges,
    link,
    refetchContractAccounts,
    refetchVirtualLinkVlans,
    t
}) => {
    const links = get(contractConnectionsBinding, 'data.data.links', []);
    const connectionsTableData = useMemo(() => composeConnectionsTableData(links, exchanges), [links, exchanges]);
    const connectionsTableColumns = [
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
            formatter: (cell?: string) => {
                return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
            },
        },
        {
            dataField: 'xml_id',
            text: t('connection.xmlIdLabel'),
            sort: true,
            copy: true,
        },
        {
            dataField: 'contract_name',
            text: t('Consuming contract'),
            sort: false,
            formatter: (_: any, row: ConnectionsTableData) => (
                <Link to={getLinkToContract(row.exchange_short_name, row.contract_uuid)}>{row.contract_name}</Link>
            ),
        },
        {
            dataField: 'managing_contract_name',
            text: t('Managing contract'),
            sort: false,
            formatter: (_: any, row: ConnectionsTableData) =>
                <Link to={getLinkToContract(row.exchange_short_name, !row.managing_contract_uuid ? row.contract_uuid : row.managing_contract_uuid)}>
                    {!row.managing_contract_uuid ? row.contract_name : row.managing_contract_name}
                </Link>
        },
        {
            dataField: 'speed',
            text: t('common.speed'),
            sort: false,
            formatter: formatSpeed,
        },
        {
            dataField: 'location_short_name',
            text: t('common.location'),
            sort: false,
            copy: true,
        },
        {
            dataField: 'exchange_short_name',
            text: t('common.exchange'),
            sort: false,
            formatter: (cell: any) => <Exchange shortName={cell} />,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: t('common.link'),
            formatter: (_: any, row: ConnectionsTableData) => (
                <Link to={getLinkToConnection(row.exchange_short_name, row.uuid)}>{t('common.view')}</Link>
            ),
        },
        {
            dataField: 'remove',
            isDummyField: true,
            text: t('common.remove'),
            formatter: (_: any, row: ConnectionsTableData) => {
                if (isConnectionDeletable(row.status)) {
                    return (
                        <ConnectionDeleteModal
                            uuid={row.uuid}
                            xmlId={row.xml_id}
                            exchange={row.exchange_short_name}
                            invalidateData={invalidateContractLinks}
                        />
                    );
                }
            },
        },
    ];

    function fetchContractLinks(pageNumber: number, sortField: string, sortOrder: string) {
        return contractConnectionsBinding.perform({
            contractUuid: contractUuid,
            exchange: exchange.toUpperCase(),
            page: pageNumber,
            per_page: SIZE_PER_PAGE,
            sort: sortField,
            order: sortOrder
        });
    };

    function invalidateContractLinks() {
        contractConnectionsBinding.invalidateCache();
        contractConnectionsBinding.perform(contractConnectionsBinding.request.params);
    }

    useEffect(function fetchOrganisation() {
        if (
            contract.request.networkStatus === NetworkRequestStatus.Success &&
            organisation.request.networkStatus === NetworkRequestStatus.Ready
        ) {
            organisation.perform({ uuid: contract.data!.organisation.uuid });
        }
    }, [contract.request.networkStatus, organisation.request.networkStatus, organisation.perform, contract.data]);

    return (
        <ViewContainer>
            <Breadcrumb data-test="breadcrumb">
                <BreadcrumbItem>
                    {link ?
                    <Link to={getLinkToConnection(exchange, link.uuid)}>{link.xml_id}</Link>
                        :
                        <Link to={getLinkToCustomers()}>{t('contract.customers')}</Link>
                    }
                </BreadcrumbItem>
                <BreadcrumbItem active>{t('contract.contractTitle')}</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col lg={4}>
                    <Row>
                        <Col>
                            <h2>
                                {t('contract.contractTitle')}
                                <SyncButton
                                    data-test="refresh-contract-btn"
                                    className="ml-2"
                                    title={t('contract.refreshBtn')}
                                    onClick={refetchContract}
                                />
                            </h2>
                        </Col>
                    </Row>
                    <hr/>
                    <RequestStatusRenderer
                        request={contract}
                        success={() => (
                            <RequestStatusRenderer
                                request={organisation}
                                success={() => (
                                    <ContractInfo 
                                        contract={contract.data!}
                                        organisation={organisation.data!.data!}
                                        exchange={exchange}
                                    />
                                    )
                                }
                            />
                            )
                        }
                    />
                </Col>
                <Col lg={8}>
                    <Row className="justify-content-between">
                        <Col>
                            <h2>
                                {t('contract.usersTitle')}
                                <SyncButton
                                    data-test="refresh-users-btn"
                                    className="ml-2"
                                    title={t('contract.refreshUsersBtn')}
                                    onClick={refetchContractAccounts}
                                />
                            </h2>
                        </Col>
                        <RequestStatusRenderer
                            request={contract}
                            success={() => (
                                <Col className="text-right">
                                    <UserCustomerAddModal
                                        contractUuid={contract.data!.uuid}
                                        contractType={contract.data!.type}
                                        exchange={exchange}
                                    />
                                </Col>
                                )
                            }
                        />
                    </Row>
                    <hr/>
                    <ContractAccountsList
                        data-test="contract-users-list"
                        contractUuid={contractUuid}
                        exchange={exchange}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h2>
                        {t('contract.connectionsTitle')}
                        <SyncButton
                            data-test="refresh-connections-btn"
                            className="ml-2"
                            title={t('contract.refreshConnectionsBtn')}
                            onClick={invalidateContractLinks}
                        />
                        <AddConnectionButton
                            data-test="add-connection-btn"
                            organisation={organisation}
                            contractUuid={contractUuid}
                            exchange={exchange}
                        />
                    </h2>
                    <hr/>
                    <StyledTable className="animated fadeIn table-responsive-lg">
                        <InteractiveRemoteTable
                            data-test="connections-table"
                            keyField="xml_id"
                            columns={connectionsTableColumns}
                            data={connectionsTableData}
                            defaultSorted={{ dataField: 'status', order: 'asc' }}
                            loading={contractConnectionsBinding.request.networkStatus === NetworkRequestStatus.Loading}
                            fetchPage={(pageNumber, _pageSize, sortField, sortOrder) => {
                                fetchContractLinks(pageNumber, sortField, sortOrder);
                            }}
                            paginationOptions={{
                                page: get(contractConnectionsBinding, 'data.meta.page', 1),
                                sizePerPage: get(contractConnectionsBinding, 'data.meta.page_size', SIZE_PER_PAGE),
                                totalSize: get(contractConnectionsBinding, 'data.meta.total_items', 1),
                            }}
                        />
                    </StyledTable>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>
                        {t('contract.virtualConnectionsTitle')}
                        <SyncButton
                            className="ml-2"
                            title={t('contract.refreshVirtualLinkVlansBtn')}
                            onClick={refetchVirtualLinkVlans}
                        />
                    </h2>
                    <hr/>
                    <ContractVirtualLinkVlans contractUuid={contractUuid} />
                </Col>
            </Row>
        </ViewContainer>
    );
};

const enhance = compose<Props, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withApiData({ contractConnectionsBinding: 'getContractLinks' }, (ownProps) => ({
        contractConnectionsBinding: {
            contractUuid: ownProps.contractUuid,
            exchange: ownProps.exchange.toUpperCase(),
            page: 1,
            per_page: SIZE_PER_PAGE,
            sort: DEFAULT_SORT_FIELD,
            order: DEFAULT_SORT_ORDER,
        },
    })),
    withApiData({ contract: 'getContract' }, (ownProps) => ({
        contract: { contractUuid: ownProps.contractUuid, exchange: ownProps.exchange.toUpperCase() },
    })),
    withApiData({ organisation: 'getOrganisation' }, (ownProps) => ({
        organisation: {
            uuid:
                ownProps.contract.request.networkStatus === NetworkRequestStatus.Success
                    ? ownProps.contract.data.organisation.uuid
                    : '',
        },
    })),
    withTranslation()
);

export default enhance(ContractDetails);
