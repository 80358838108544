import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import { performApiRequest, invalidateApiDataRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Exchanges, Exchange } from 'constants/api';

// redux
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';

// components

import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';
import SwitchesList from 'components/cards/SwitchesList';

interface ApiProps {
    selectedExchange: string;
    exchangesData: Exchanges;
    refetchSwitches: (exchange: string, exchangesData: Exchanges) => boolean;
}

type ComponentProps = ApiProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: {}) => ({
    exchangesData: getAllExchangesSelector(state.apiData),
    selectedExchange: get(state, 'app.switches.selectedExchange', ''),
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    refetchSwitches: (exchange: string, exchangesData: Exchanges) => {
        if (!exchange) {
            return false;
        }
        // clear getSwitches cache for every exchange
        get(exchangesData, 'scopes', [])
        .forEach((exchangeScope: { name: string, exchanges: Exchange[] }) => {
            exchangeScope.exchanges.forEach((exchangeItem: Exchange) => {
                dispatch(invalidateApiDataRequest('getSwitches', { exchange: exchangeItem.short_name }));
            });
        });
        // refetch currently selected exchange
        dispatch(performApiRequest('getSwitches', { exchange: exchange }));
        return true;
    }
});

const enhance = compose<ComponentProps, {}>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

const SwitchesView: FunctionComponent<ComponentProps> = ({ exchangesData, refetchSwitches, selectedExchange, t }) => {
    return (
        <>
            <Helmet title="Switches" />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            <span>
                                {t('switches.pageTitle')}
                                <SyncButton
                                    data-test="switches-refresh-btn"
                                    className="ml-2"
                                    title={t('switches.refreshButton')}
                                    onClick={() => refetchSwitches(selectedExchange, exchangesData)}
                                />
                            </span>
                        </h2>
                        <hr/>
                        <SwitchesList exchangesData={exchangesData} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default enhance(SwitchesView);
