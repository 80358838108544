import React, { FunctionComponent, useEffect } from 'react';
import { getResultData, performApiRequest } from 'react-api-data/lib';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiResponse, PublicVlanItem } from 'constants/api';
import { ReduxState } from 'createStore';

interface InProps {
    onChange: (vlan: number) => void;
    excludeVlanType?: string[];
    exchange: string;
    defaultLabel?: string;
    defaultValue?: number;
}

const statusParam = 'configured';
const orderParam = 'description';

interface ApiDataProps {
    getVlans: () => void;
}

type VlanSelectAllProps = InProps & ApiDataProps & EnhanceProps & WithTranslation;

interface EnhanceProps {
    vlansData: ApiResponse<{ vlans: PublicVlanItem[]}>;
}

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    vlansData: getResultData(state.apiData, 'getVlans', {
        exchange: ownProps.exchange,
        status: statusParam,
        order: orderParam,
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => ({
    getVlans: () => {
        dispatch(
            performApiRequest('getVlans', {
                exchange: ownProps.exchange,
                status: statusParam,
                order: orderParam,
            })
        );
    },
});

const enhance = compose<VlanSelectAllProps, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

interface OptionType {
    label: string;
    value: number;
}

const VlanSelectAll: FunctionComponent<VlanSelectAllProps> = ({ vlansData, getVlans, onChange, exchange, excludeVlanType, defaultLabel, defaultValue }) => {
    useEffect(() => {
        getVlans();
    }, [exchange]);

    if (!vlansData || !vlansData.data || !vlansData.data.vlans) {
        return <Select defaultValue={{ label: defaultLabel, value: defaultValue }} isLoading={true} isDisabled={true} />;
    }

    const optChanged = (option: OptionType) => {
        onChange(option.value);
    };


    const options = vlansData.data.vlans.filter(
        (vlan) => excludeVlanType && excludeVlanType.indexOf(vlan.lan_type) > -1 ? false : true
    ).map((vlan) => ({
        label: vlan.description,
        value: vlan.number
    }));

    const defaultOption = options.find(o => o.value === defaultValue);

    const filterOption = (option: OptionType, inputValue: string): boolean =>
        (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0;

    return (
        <>
            <Select
                options={options}
                defaultValue={defaultOption}
                onChange={optChanged}
                filterOption={filterOption}
                isLoading={false}
                isDisabled={false}
            />
        </>
    );
};

export default enhance(VlanSelectAll);
