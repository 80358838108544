import React from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { LinkPortCompact } from 'constants/api';
import { StatsInterval, StatsType } from 'constants/literals';

import BaseModal from './BaseModal';
import { BaseModalTemplate } from './BaseModalTemplate';
import PortGraph from 'components/graphs/PortGraph';
import StatisticsWidget from 'components/utils/StatisticsWidget';

interface Props {
    port: LinkPortCompact;
    exchange: string;
}

class PortStatisticsModal extends BaseModal<Props & WithTranslation> {
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            isOpen: false,
        };
    }

    render() {
        const { port, exchange, t } = this.props;

        return (
            <>
                <BaseModalTemplate
                    data-test="port-stats-modal-tmpl"
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={<>{t('stats.portStatsTitle')}: {port.portnumber}@{port.fqid}</>}
                    body={
                        <StatisticsWidget>
                            {({ type, interval }) => (
                                <PortGraph
                                    portUuid={port.uuid}
                                    exchange={exchange}
                                    type={type}
                                    interval={interval}
                                    height={400}
                                />
                            )}
                        </StatisticsWidget>
                    }
                    footer={<Button color="light" onClick={this.toggle}>{t('modal.closeBtn')}</Button>}
                />
                <LinkOverlay href="#" onClick={this.toggle}>
                    <div className="mask-overlay">
                        <i className="fas fa-search-plus"/>
                    </div>
                    <PortGraph
                        portUuid={port.uuid}
                        exchange={exchange}
                        type={StatsType.Traffic}
                        interval={StatsInterval.Daily}
                        height={70}
                        simple
                    />
                </LinkOverlay>
            </>
        );
    }
}

export const LinkOverlay = styled.a`
  position: relative;
  display: block;

  .mask-overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s 0s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-size: 30px;
    color: var(--white);
  }
  
  :hover .mask-overlay {
    opacity: 1;
  }
`;

export default withTranslation()(PortStatisticsModal);
