import {
    Button,
    Col, FormGroup, Label,
} from 'reactstrap';
import React from 'react';
import { Field, Formik, FormikActions } from 'formik';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NetworkRequestStatus, Roles } from 'constants/literals';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import RolesChecker from 'components/utils/RolesChecker';
import { CenteredAlert } from 'components/styled';
import { ApiResponse, Exchange, ReactApiDataActions } from 'constants/api';

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    exchangeRequest: ApiDataBinding<ApiResponse<Exchange>>;
    apiDataActions: ReactApiDataActions;
}

type Props = EnhanceProps & WithTranslation & BaseFormModalProps;

const enhance = compose<EnhanceProps & WithTranslation, {}>(
    withApiData({
        exchangeRequest: 'postExchange',
    }),
    withTranslation()
);

type FormInitialValuesType = {
    name: string;
    short_name: string;
    website: string;
    active: boolean;
    asn: number | null;
    ixf_id: number | null;
};

class ExchangeAddModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>) {
        this.handleFormSubmission(prevProps.exchangeRequest.request, this.props.exchangeRequest.request, {
            successMessage: this.props.t('exchangeModal.addSuccessMessage'),
        });
    }

   _handleOnSubmit = (values: any, formikBag: FormikActions<FormInitialValuesType>) => {
        const { exchangeRequest, apiDataActions } = this.props;

        exchangeRequest.perform({}, { exchange: values }).then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getAllExchanges');
            }
        });
    };

    render() {
        const { t } = this.props;

        return (
            <>
                <Formik
                    ref={(formik) => {
                        this.formik = formik;
                    }}
                    initialValues={
                        {
                            name: '',
                            short_name: '',
                            website: '',
                            active: false,
                            asn: null,
                            ixf_id: null
                        } as FormInitialValuesType
                    }
                    onSubmit={this._handleOnSubmit}
                >
                    {({ values, errors, isSubmitting, touched, handleSubmit, dirty }) => (
                        <BaseFormModalTemplate
                            isOpen={this.state.isOpen}
                            toggle={isSubmitting ? undefined : this.toggle}
                            onClosed={this.handleModalClose}
                            handleSubmit={handleSubmit}
                            header={t('exchangeModal.headerTitleAdd')}
                            body={
                                <>
                                    <div>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="name">{t('exchangeModal.name')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="name" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="short_name">{t('exchangeModal.shortName')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="short_name" errors={errors} touched={touched} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="website">{t('exchangeModal.webSite')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="website" errors={errors} touched={touched} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="active">{t('exchangeModal.active')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <Field type="checkbox" name="active" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="asn">{t('exchangeModal.asnNumber')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="asn" errors={errors} touched={touched} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md={3}>
                                                <Label htmlFor="ixf_id">{t('exchangeModal.ixfId')}</Label>
                                            </Col>
                                            <Col md={9}>
                                                <FormControl name="ixf_id" errors={errors} touched={touched} />
                                            </Col>
                                        </FormGroup>
                                        <CenteredAlert color="warning">
                                            {t('exchangeModal.noteMandatoryFields')}
                                        </CenteredAlert>
                                    </div>
                                </>
                            }
                            footer={
                                <FormModalButtons
                                    loading={isSubmitting}
                                    toggle={this.toggle}
                                    disabled={!dirty}
                                />
                            }
                        />
                    )}
                </Formik>
                <RolesChecker roles={[Roles.admin, Roles.noc]}>
                    <Button className="float-right" color="light" onClick={this.toggle}>
                        {t('exchangeModal.addBtn')}
                    </Button>
                </RolesChecker>
            </>
        );
    }
}

export default enhance(ExchangeAddModal);
