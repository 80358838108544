import React, { FunctionComponent } from 'react';

import BaseTransition, { InProps } from './BaseTransition';
import { TransitionsType, Roles, ConnectionStatus } from 'constants/literals';

const TransitionDeconfigurationSuccessful: FunctionComponent<InProps> = (props) => {
    // @ts-ignore
    const transitionName = (currentStatus) => {
        return [ConnectionStatus.unconfigured, ConnectionStatus.new].includes(currentStatus) ?
            'resourceTransitions.terminationSuccessful' : 'resourceTransitions.deconfigurationSuccessful';
    };

    return (
        <BaseTransition
            transitionType={TransitionsType.deconfiguration_successful}
            roles={[Roles.admin, Roles.noc]}
            modalHeaderTitle="resourceTransitions.deconfigurationSuccessfulHeaderTitle"
            modalBodyTitle="resourceTransitions.deconfigurationSuccessfulBodyText"
            transitionName={transitionName(props.currentStatus)}
            transitionIconClass="fa-exclamation-circle"
            { ...props }
        />
    );
};

export default TransitionDeconfigurationSuccessful;