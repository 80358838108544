import {
    Alert
} from 'reactstrap';
import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import Tooltip from 'components/utils/Tooltip';
import { Vlan } from 'constants/api';
import { ReduxState } from 'createStore';
import { NOC_SUPPORT_EMAIL } from 'constants/index';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';
import { Roles } from '../../constants/literals';

interface InputProps {
    vlan: Vlan;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    resourceStatus: string;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'postLinkVlanUntag', {
        vlanId: ownProps.vlan.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
    resourceStatus: get(ownProps, 'vlan.status')
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            dispatch(performApiRequest('postLinkVlanUntag', {
                vlanId: ownProps.vlan.uuid,
                exchange: ownProps.exchange,
                linkId: ownProps.linkId
            }));
            return false;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class LinkVlanUntagModal extends BaseFormModal<Props> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { request, t } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            successMessage: t('notification.untagLinkVlanSuccessful'),
            errorMessage: t('notification.untagLinkVlanFailed'),
        });
    }

    render() {
        const { request, onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('untagVlanModal.headerTitle')}
                    body={
                        <Alert color="warning" className="mb-0">
                            <strong>{t('common.warning')}</strong>
                            <p>{t('untagVlanModal.warningMessage')}
                                (<a href={`mailto:${NOC_SUPPORT_EMAIL}`}>{NOC_SUPPORT_EMAIL}</a>).
                            </p>
                        </Alert>
                    }
                    footer={
                        <FormModalButtons
                            loading={get(request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />
                <EditBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    resourceStatus={this.props.resourceStatus}
                    roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.customer, Roles.ixaas_client]}
                >
                    <Tooltip
                        data-test="untag-btn-tooltip"
                        triggerBtn={
                            <i
                                className="fas fa-trash fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('untagVlanModal.removeTagIcon')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(LinkVlanUntagModal);
