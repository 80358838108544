import React from 'react';
import {
    Button, FormGroup, Label,
} from 'reactstrap';
import {
    ApiDataBinding,
    ApiDataRequest,
    ApiDataState,
    getApiDataRequest,
    performApiRequest,
    withApiData,
    invalidateApiDataRequest
} from 'react-api-data/lib';
import { Formik, FormikProps } from 'formik';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import {array, number, object, string} from 'yup';
import { RouteComponentProps, withRouter } from 'react-router';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Roles } from 'constants/literals';

// utils
import { getLinkToConnection, getOnlyOneExchangeName } from 'utils/linksUtils';
import i18n from 'locales/i18next';
import { getExchangeByNameSelector, getUserRolesSelector } from 'redux/apiData/apiDataSelectors';

// components
import { BaseModalState } from './BaseModal';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { Exchanges, NewLink } from 'constants/api';
import PortDataFormGroup from 'components/utils/PortDataFormGroup';
import { ExchangeFormikSelect } from 'components/utils/ExchangeSelect';
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import RolesChecker from 'components/utils/RolesChecker';
import FormControl from 'components/utils/FormControl';
import { isCustomer } from 'utils/userUtils';

interface InProps {
    contractUuid: string;
}

interface WithStateProps {
    targetExchange: string;
    setTargetExchange: (exchange: string) => void;
}

interface WithApiDataProps {
    exchangeData: ApiDataBinding<Exchanges>;
}

interface ConnectProps {
    submission: ApiDataRequest;
    apiData: ApiDataState;
    onSubmit: (values: any) => boolean;
    invalidateContractConnections: (exchange: string) => void;
    invalidatePostLink: (exchange: string) => void;
    userRoles: Roles[];
}

type EnhanceProps = WithStateProps & WithApiDataProps & ConnectProps & RouteComponentProps & WithTranslation;
type Props = InProps & EnhanceProps & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: InProps & WithStateProps) => ({
    apiData: state.apiData,
    submission: getApiDataRequest(state.apiData, 'postLink', { exchange: ownProps.targetExchange }),
    userRoles: getUserRolesSelector(state) || []
});

export const createNewLinkBody = (values: any, ownProps: InProps & WithStateProps): NewLink => {
    const body: NewLink = {
        link: {
            classification: 'link',
            erp_order_identifier: values.orderId,
            sla: !!values.sla,
            port_count: values.portCount,
            location_uuid: values.location,
            speed_and_interface_types: values.speedAndInterfaceTypes,
            contract_uuid: ownProps.contractUuid,
        }
    };
    // when env varable for automated is set, then add it to the body
    const isAutomated = process.env.REACT_APP_AUTOMATED_PROVISIONING_ENABLED;
    if (isAutomated) {
        body.link.automated = isAutomated === 'true';
    }
    return body;
};

const mapDispatchToProps = (dispatch: any, ownProps: InProps & WithStateProps) => ({
    onSubmit: (values: any) => {
        const body: NewLink = createNewLinkBody(values, ownProps);
        ownProps.setTargetExchange(values.exchange);
        dispatch(performApiRequest('postLink', { exchange: values.exchange }, body));
        return true;
    },
    invalidateContractConnections: (exchange: string) => {
        dispatch(invalidateApiDataRequest('getContractLinks', {
            contractUuid: ownProps.contractUuid,
            exchange: exchange.toUpperCase()
        }));
    },
    invalidatePostLink: (exchange: string): void => {
        dispatch(invalidateApiDataRequest('postLink', { exchange }));
    },
});

const enhance = compose<EnhanceProps, InProps>(
    withState('targetExchange', 'setTargetExchange', 'nl'),
    withApiData({ exchangeData: 'getAllExchanges' }),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTranslation()
);

class ConnectionAddModal extends BaseFormModal<Props, BaseModalState> {

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<BaseModalState>): void {
        this.handleFormSubmission(prevProps.submission, this.props.submission, {
            successMessage: this.props.t('notification.createConnectionSuccessful')
        });
    }

    renderForm = ({ values, errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }: FormikProps<any>) => (
        <BaseFormModalTemplate
            isOpen={this.state.isOpen}
            toggle={isSubmitting ? undefined : this.toggle}
            handleSubmit={handleSubmit}
            onClosed={this.handleModalClose}
            header={this.props.t('connection.addNewConnection')}
            body={<>
                <FormGroup>
                    <Label>{this.props.t('common.exchangeMandatory')}</Label>
                    <ExchangeFormikSelect
                        name="exchange"
                        touched={touched}
                        errors={errors}
                        exchanges={get(this.props.exchangeData, 'data')}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>{this.props.t('common.locationMandatory')}</Label>
                    <FormControl
                        component="select"
                        name="location"
                        disabled={!values.exchange}
                        errors={errors}
                        touched={touched}
                    >
                        {values.exchange ? (
                            <>
                                <option disabled value={''}>{this.props.t('connection.selectLocation')}</option>
                                {getExchangeByNameSelector(this.props.apiData, values.exchange)
                                .locations.sort((a, b) => a.description.localeCompare(b.description)).map(location => (
                                    <option key={location.uuid} value={location.uuid}>{location.description}</option>
                                ))}
                            </>
                        ) : (
                            <option>{this.props.t('connection.selectExchangeOptions')}</option>
                        )}
                    </FormControl>
                </FormGroup>
                <PortDataFormGroup
                    handleBlur={handleBlur}
                    locationUuid={values.location}
                    errors={errors}
                    touched={touched}
                    onChange={(types) => setFieldValue('speedAndInterfaceTypes', types)}
                />
                <FormGroup>
                    <Label>{this.props.t('connection.portCount')}</Label>
                    <FormControl
                        name="portCount"
                        errors={errors}
                        touched={touched}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>{this.props.t('connection.erpOrderID')}</Label>
                    <FormControl
                        name="orderId"
                        errors={errors}
                        touched={touched}
                    />
                </FormGroup>
            </>}
            footer={
                <FormModalButtons
                    loading={isSubmitting}
                    toggle={this.toggle}
                />
            }
        />
    )

    render() {
        const { t, exchangeData } = this.props;
        if (!exchangeData.data) {
            return <></>;
        }

        return (
            <>
                <Formik
                    data-test="form"
                    initialValues={{
                        exchange: getOnlyOneExchangeName(exchangeData.data) || '',
                        location: '',
                        speedAndInterfaceTypes: [],
                        portCount: 1,
                        orderId: '',
                        sla: false
                    }}
                    onSubmit={this.props.onSubmit}
                    validationSchema={object().shape({
                        exchange: string().required(t('validation.exchangeRequired')),
                        location: string().required(t('validation.locationRequired')),
                        speedAndInterfaceTypes: array().min(1, t('validation.portTypeRequired')).required(t('validation.portTypeRequired')),
                        portCount: number().min(1, t('validation.portCountRequired')).required(t('validation.portCountRequired')),
                        orderId: string().required(t('validation.orderIdRequired'))
                    })}
                    ref={ref => { this.formik = ref; }}
                >
                    {this.renderForm}
                </Formik>
                <RolesChecker data-test="user-roles-checker" roles={[Roles.admin, Roles.noc, Roles.customer, Roles.ixaas_client]}>
                {isCustomer(this.props.userRoles) ?
                            process.env.REACT_APP_CUSTOMER_SHOW_ADD_CONNECTION === 'true' && this.renderNewConnectionBtn(t)
                        : this.renderNewConnectionBtn(t)
                    }
                </RolesChecker>
            </>
        );
    }

    protected afterSuccessCallback = () => {
        const { submission } = this.props;
        const exchange = get(submission, 'result.exchange.short_name');
        const target = getLinkToConnection(exchange, get(submission, 'result.uuid', ''));
        this.props.invalidateContractConnections(exchange);
        this.props.invalidatePostLink(exchange);
        this.props.history.push(target);
    }

    private renderNewConnectionBtn = (t: i18n.TFunction) => {
        return (
            <Button
                data-test="connection-add-btn"
                className="float-right"
                color="light"
                onClick={this.toggle}
            >
                {t('connection.addNewConnectionBtn')}
            </Button>
        )
    }
}

// @ts-ignore
export default enhance(ConnectionAddModal);
