import {
    Col, FormGroup, Label,
} from 'reactstrap';
import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ApiDataRequest, performApiRequest, getApiDataRequest, withApiData, ApiDataBinding } from 'react-api-data';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';

// components
import { BaseFormModalTemplate, FormModalButtons } from './BaseModalTemplate';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import FormControl from 'components/utils/FormControl';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { BaseModalState } from './BaseModal';
import { ApiResponse, BannerApi } from 'constants/api';

interface InProps {
    renderModalToggleBtn: (props: {onClick: () => void}) => ReactNode;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    updateRequest: ApiDataRequest;
    bannerResponse: ApiDataBinding<ApiResponse<BannerApi>>;
}

type Props = EnhanceProps & InProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState) => ({
    updateRequest: getApiDataRequest(state.apiData, 'putBanner', {}),
});

const mapDispatchToProps = (dispatch: any) => ({
    onSubmit: (values: any) => dispatch(performApiRequest('putBanner', {}, { banner: values })),
});

const enhance = compose<Props, InProps>(
    withApiData({ bannerResponse: 'getBanner' }),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class BannerModal extends BaseFormModal<Props, BaseModalState> {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        this.handleFormSubmission(prevProps.updateRequest, this.props.updateRequest, {
            successMessage: this.props.t('notification.updateBannerSuccessful')
        });
    }

    render() {
        const { bannerResponse, onSubmit, t, renderModalToggleBtn } = this.props;

        return (
            <React.Fragment>
                <RequestStatusRenderer
                    request={bannerResponse}
                    loading={<></>}
                    success={() => (
                        <Formik
                            data-test="form"
                            ref={formik => {
                                this.formik = formik;
                            }}
                            initialValues={bannerResponse.data!.data!}
                            onSubmit={onSubmit}
                        >
                            {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                                <BaseFormModalTemplate
                                    isOpen={this.state.isOpen}
                                    toggle={isSubmitting ? undefined : this.toggle}
                                    onClosed={this.handleModalClose}
                                    handleSubmit={handleSubmit}
                                    header={t('banner.headerTitle')}
                                    body={<>
                                    <FormGroup row className="mb-0">
                                        <Col md="2">
                                            <Label htmlFor="banner_text">{t('banner.bannerText')}</Label>
                                        </Col>
                                        <Col md="10">
                                            <FormControl
                                                component="textarea"
                                                name="banner_text"
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="mb-0">
                                        <Col md={2}>
                                            <Label htmlFor="banner_show">{t('banner.showBanner')}</Label>
                                        </Col>
                                        <Col md={1}>
                                            <FormControl
                                                type="checkbox"
                                                name="show_banner"
                                                checked={values.show_banner}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </FormGroup>
                            </>}
                                footer={
                                    <FormModalButtons
                                        loading={isSubmitting}
                                        toggle={this.toggle}
                                    />
                                }
                            />
                        )}
                    </Formik>
                    )
                }
                />
                {renderModalToggleBtn({ onClick: this.toggle })}
            </React.Fragment>
        );
    }
}

export default enhance(BannerModal);
