import {
    Alert
} from 'reactstrap';
import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';

// constants
import { Router } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import { BaseModalTemplate, FormModalButtons } from 'components/modals/BaseModalTemplate';
import { Roles } from '../../constants/literals';

interface InputProps {
    router: Router;
    resourceStatus: string;
    exchange: string;
    linkId: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    requestDisable: ApiDataRequest;
    requestEnable: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    requestDisable: getApiDataRequest(state.apiData, 'putDisablePeering', {
        routerId: ownProps.router.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
    requestEnable: getApiDataRequest(state.apiData, 'putEnablePeering', {
        routerId: ownProps.router.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            if (ownProps.router.amsix_routeserver) {
                dispatch(performApiRequest('putDisablePeering', {
                    routerId: ownProps.router.uuid,
                    exchange: ownProps.exchange,
                    linkId: ownProps.linkId
                }));
            } else {
                dispatch(performApiRequest('putEnablePeering', {
                    routerId: ownProps.router.uuid,
                    exchange: ownProps.exchange,
                    linkId: ownProps.linkId
                }));
            }
            return false;
        }
    };
};

const enhance = compose<EnhanceProps & WithTranslation, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class AmsixPeeringModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { requestDisable, requestEnable, t } = this.props;
        this.handleFormSubmission(prevProps.requestDisable, requestDisable, {
            successMessage: t('notification.disableAmsPeeringSuccessful'),
            errorMessage: t('notification.amsPeeringFailed')
        });
        this.handleFormSubmission(prevProps.requestEnable, requestEnable, {
            successMessage: t('notification.enableAmsPeeringSuccessful'),
            errorMessage: t('notification.amsPeeringFailed')
        });
    }

    render() {
        const { onSubmit, router, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={<>
                        {t(`common.${router.amsix_routeserver ? 'disabled' : 'enabled'}`)} {t('amsPeeringModal.peeringWithAms')}
                    </>}
                    body={router.amsix_routeserver ?
                        <>{t('amsPeeringModal.disablePeeringWarn')}</>
                        :
                        <>
                            <Alert color="warning">
                                <strong>{t('common.warning')}</strong>
                                <p>{t('amsPeeringModal.warnParagraph1')}</p>
                                <p>{t('amsPeeringModal.warnParagraph2')}</p>
                                <p>{t('amsPeeringModal.warnParagraph3')}</p>
                                <p>{t('amsPeeringModal.warnParagraph4')}
                                    <a
                                        href={t('amsPeeringModal.asSpecificationUrl')}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {t('common.here')}
                                    </a></p>
                                <p>{t('amsPeeringModal.warnParagraph5')}</p>
                                <p>{t('amsPeeringModal.warnParagraph6')}</p>
                            </Alert>
                            {t('amsPeeringModal.enablePeeringWarn')}
                        </>
                    }
                    footer={
                        <FormModalButtons
                            loading={false}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />
                <EditBtnConditionalRenderer
                    data-test="edit-btn-renderer"
                    resourceStatus={this.props.resourceStatus}
                    roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.ixaas_client, Roles.customer]}
                >
                    <Tooltip
                        data-test="peering-change-tooltip"
                        triggerBtn={
                            router.amsix_routeserver ?
                            <i className="fas fa-trash fa-pull-right mt-1 text-primary cursor-pointer" title={t('amsPeeringModal.disablePeering')} onClick={this.toggle} /> :
                            <i className="fas fa-edit fa-pull-right mt-1 text-primary cursor-pointer" title={t('amsPeeringModal.enablePeeering')} onClick={this.toggle} />
                        }
                    >
                        { router.amsix_routeserver ? t('amsPeeringModal.disablePeering') : t('amsPeeringModal.enablePeeering') }
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(AmsixPeeringModal);
