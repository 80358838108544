import React from 'react';
import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import get from 'lodash/get';

// constants
import { Router } from 'constants/api';
import { ReduxState } from 'createStore';

// components
import BaseFormModal, { BaseFormModalProps } from './BaseFormModal';
import { BaseModalTemplate, FormModalButtons } from './BaseModalTemplate';
import Tooltip from 'components/utils/Tooltip';
import EditBtnConditionalRenderer from 'components/utils/EditBtnConditionalRenderer';

interface InputProps {
    router: Router;
    exchange: string;
    linkId: string;
    resourceStatus: string;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
}

type Props = EnhanceProps & InputProps & WithTranslation & BaseFormModalProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'deletePeeringRouteServer', {
        routerId: ownProps.router.uuid,
        exchange: ownProps.exchange,
        linkId: ownProps.linkId
    })
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: () => {
            dispatch(performApiRequest('deletePeeringRouteServer', {
                routerId: ownProps.router.uuid,
                exchange: ownProps.exchange,
                linkId: ownProps.linkId
            }));
            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

class RsPeeringDisableModal extends BaseFormModal<Props> {

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { request, t } = this.props;
        this.handleFormSubmission(prevProps.request, request, {
            errorMessage: t('notification.rsPeeringDisableFailed'),
            successMessage: t('notification.rsPeeringDisableSuccessful'),
        });
    }

    render() {
        const { request, onSubmit, t } = this.props;
        return (
            <React.Fragment>
                <BaseModalTemplate
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    header={t('rsPeeringDisable.headerTitle')}
                    body={t('rsPeeringDisable.bodyMessage')}
                    footer={
                        <FormModalButtons
                            loading={get(request, 'networkStatus') === 'loading'}
                            toggle={this.toggle}
                            onSubmit={onSubmit}
                        />
                    }
                />
                <EditBtnConditionalRenderer data-test="edit-btn-renderer" resourceStatus={this.props.resourceStatus}>
                    <Tooltip
                        data-test="disable-peering-btn"
                        triggerBtn={
                            <i
                                className="fas fa-trash fa-pull-right mt-1 text-primary cursor-pointer"
                                onClick={this.toggle}
                            />
                        }
                    >
                        {t('rsPeeringDisable.disablePeeringBtn')}
                    </Tooltip>
                </EditBtnConditionalRenderer>
            </React.Fragment>
        );
    }
}

export default enhance(RsPeeringDisableModal);
