import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import { performApiRequest, invalidateApiDataRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withTranslation, WithTranslation } from 'react-i18next';

// constants
import { ReduxState } from 'createStore';
import { Exchanges, Exchange } from 'constants/api';

// redux
import { getAllExchangesSelector } from 'redux/apiData/apiDataSelectors';

// components
import VlanAddModal from 'components/modals/VlanAddModal';
import VlansList from 'components/cards/VlansList';
import SyncButton from 'components/utils/SyncButton';
import ViewContainer from 'components/utils/ViewContainer';

interface ApiProps {
    selectedExchange: string;
    exchangesData: Exchanges;
    refetchVlans: (exchange: string, exchangesData: Exchanges) => boolean;
    fetchIpRanges: (exchange: string) => void;
}

type ComponentProps = ApiProps & WithTranslation;

const mapStateToProps = (state: ReduxState, ownProps: {}) => ({
    exchangesData: getAllExchangesSelector(state.apiData),
    selectedExchange: get(state, 'app.vlans.selectedExchange', ''),
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    refetchVlans: (exchange: string, exchangesData: Exchanges) => {
        if (!exchange) {
            return false;
        }
        // clear getVlans cache for every exchange
        get(exchangesData, 'scopes', [])
        .forEach((exchangeScope: { name: string, exchanges: Exchange[] }) => {
            exchangeScope.exchanges.forEach((exchangeItem: Exchange) => {
                dispatch(invalidateApiDataRequest('getVlans', { exchange: exchangeItem.short_name }));
            });
        });
        // refetch currently selected exchange
        dispatch(performApiRequest('getVlans', { exchange }));
        return true;
    },
    fetchIpRanges: (exchange: string) => {
        if (!exchange) {
            return;
        }
        dispatch(invalidateApiDataRequest('getIpRanges', { exchange, selectable: 'true' }));
    },
});

const enhance = compose<ComponentProps, {}>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

const VlansView: FunctionComponent<ComponentProps> = ({ exchangesData, refetchVlans, t, selectedExchange, fetchIpRanges }) => {
    const switchExchanges = (exchange: string) => {
        refetchVlans(exchange, exchangesData);
        fetchIpRanges(exchange);
    };

    return (
        <>
            <Helmet title="Vlans" />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            <span>
                                {t('vlans.pageTitle')}
                                <SyncButton
                                    data-test="vlans-refresh-btn"
                                    className="ml-2"
                                    title={t('vlans.refreshButton')}
                                    onClick={() => refetchVlans(selectedExchange, exchangesData)}
                                />
                            </span>
                            <VlanAddModal exchangesData={exchangesData} targetExchange={selectedExchange} switchExchanges={switchExchanges} />
                        </h2>
                        <hr/>
                        <VlansList exchangesData={exchangesData} fetchIpRanges={fetchIpRanges} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
};

export default enhance(VlansView);
