import React from 'react';
import { Component } from 'react';

export interface BaseModalState {
    isOpen?: boolean;
    toggle?: () => void;
    showQTag?: boolean;
    toggleQTag?: () => void;
}

class BaseModal<T, S extends BaseModalState = BaseModalState> extends Component<T, S> {
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            isOpen: false,
            showQTag: false
        };
    }

    toggle = () => {
        this.setState((prevState: Readonly<S>) => ({
            isOpen: !prevState.isOpen,
        }));
    }

    toggleQtag = () => {
        this.setState((prevState: Readonly<S>) => ({
            showQTag: !prevState.showQTag,
        }));
    }

    render() {
        return <React.Fragment />;
    }
}

export default BaseModal;
