import React from 'react';
import { Roles } from 'constants/literals';
import { DropdownItem } from 'reactstrap';
import RolesChecker from './RolesChecker';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ReduxState } from 'createStore';
import { get } from 'lodash';
import { updateAppPathAction } from 'redux/app/appActions';
import { useTranslation } from 'react-i18next';

interface EnhanceProps {
    selectDebugMode: (debugProvisioningMode: boolean) => void;
    debugModeState: boolean;
}

const enhance = compose<EnhanceProps, {}>(
    connect(
        (state: ReduxState) => ({
            debugModeState: get(state, 'app.provisioning.debugProvisioningMode', false)
        }),
        (dispatch) => ({
            selectDebugMode: (debugProvisioningMode: boolean) => {
                dispatch(updateAppPathAction('provisioning.debugProvisioningMode', debugProvisioningMode));
            }
        })
    ),
);

function DebugModeSetReset(props: EnhanceProps) {
    const { selectDebugMode, debugModeState } = props;
    const { t } = useTranslation();

    const handleClick = () => {
        selectDebugMode(!debugModeState);
    };
    return (
        <RolesChecker data-test="update-status-roles" roles={[Roles.admin, Roles.noc]}>
            <DropdownItem toggle={true} hidden={false} onClick={handleClick}>
                <i className="fas fa-redo" />
                {t('debugProvisioning.debugProvisioningTitle')}
            </DropdownItem>
        </RolesChecker>
    );
}
export default enhance(DebugModeSetReset);