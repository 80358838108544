import React, { FunctionComponent } from 'react';

import BaseTransition, { InProps } from './BaseTransition';
import { TransitionsType, Roles } from 'constants/literals';

const TransitionConfigurationSuccessful: FunctionComponent<InProps> = (props) => {
    return (
        <BaseTransition
            roles={[Roles.admin, Roles.noc]}
            transitionType={TransitionsType.configuration_successful}
            modalHeaderTitle="resourceTransitions.configurationSuccessfulHeaderTitle"
            modalBodyTitle="resourceTransitions.configurationSuccessfulBodyText"
            transitionName="resourceTransitions.configurationSuccessful"
            transitionIconClass="fa-check-circle"
            { ...props }
        />
    );
};

export default TransitionConfigurationSuccessful;