import React, { FunctionComponent } from 'react';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import classnames from 'classnames';

import 'ladda/dist/ladda-themeless.min.css';

interface InProps {
    loading: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit';
    onClick?: any;
    className?: string;
}

const LoaderButton: FunctionComponent<InProps> = ({ children, loading, type = 'button', disabled, onClick, className }) => (
    <LaddaButton
        className={classnames(['btn btn-primary z-index-0', className])}
        loading={loading}
        type={type}
        data-style={EXPAND_LEFT}
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </LaddaButton>
);

export default LoaderButton;