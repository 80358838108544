import React from 'react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withApiData, ApiDataBinding } from 'react-api-data';
import {
    LinkCompact,
    PhotonicMapping,
    PortDetails as PortDetailsType,
    ReactApiDataActions,
    ApiResponse,
    SnmpData
} from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import BoolIcon from 'components/utils/BoolIcon';
import { getLinkToConnection, getLinkToPort, getLinkToSwitch } from 'utils/linksUtils';
import { Link } from 'react-router-dom';
import { NetworkRequestStatus, Roles } from 'constants/literals';
import InteractiveTable from 'components/utils/InteractiveTable';
import { defaultPaginationOptions } from 'constants/pagination';
import withUserRoles from 'hocs/withUserRoles';
import { hasSomeRole } from 'utils/userUtils';
import CompositeRequest from 'utils/compositeRequest';
import ResourceStatus from 'components/utils/ResourceStatus';
import { getPortSnmpStatus } from 'utils/snmpUtils';
import PortChangeStatusModal from 'components/modals/PortChangeStatusModal';
import ContextMenuDropdown from 'components/utils/ContextMenuDropdown';
import PortActivityLog from 'components/utils/PortActivityLog';
import PortEditModal from 'components/modals/PortEditModal';

interface PortDetailsProps {
    port: PortDetailsType;
    snmpData: SnmpData[];
    handleSelectable: (target: any) => void;
    handleSync: () => void;
    userRoles: Roles[];
    apiDataActions: ReactApiDataActions;
}

function PortDetails(props: PortDetailsProps) {
    const { port, snmpData, handleSync, handleSelectable, userRoles, apiDataActions } = props;
    const { t } = useTranslation();

    const onSync = () => {
        handleSync();
        apiDataActions.invalidateCache('getPortEvents', { portId: port.id, per_page: 10 });
    };

    const odfHistoryColumns = [
        {
            dataField: 'odf_id',
            text: t('portDetails.id'),
            hidden: true
        },
        {
            dataField: 'old_odf',
            text: t('portDetails.oldOdf'),
            sort: true,
        },
        {
            dataField: 'new_odf',
            text: t('portDetails.newOdf'),
            sort: true,
        },
        {
            dataField: 'created_at',
            text: t('common.created'),
            sort: true
        }
    ];

    const photonicMappingColumns = [
        {
            dataField: 'switch_port',
            text: t('portDetails.switchPort'),
            sort: true,
            formatter: (_: any, row: PhotonicMapping) => (
                <Link to={getLinkToPort(port.exchange, row.switch_port)}>{row.switch_port}</Link>
            )
        },
        {
            dataField: 'pxc_port',
            text: t('portDetails.pxcPort'),
            sort: true,
            formatter: (_: any, row: PhotonicMapping) => (
                <Link to={getLinkToPort(port.exchange, row.pxc_port)}>{row.pxc_port}</Link>
            )
        },
        {
            dataField: 'created_at',
            text: t('common.created'),
            sort: true
        }
    ];

    const linksColumns = [
        {
            dataField: 'xml_id',
            text: t('connection.xmlIdLabel'),
            sort: true,
            formatter: (_: any, row: LinkCompact) => (
                <Link to={getLinkToConnection(port.exchange, row.uuid)}>{row.xml_id}</Link>
            ),
        },
        {
            dataField: 'status',
            text: t('common.status'),
            sort: true,
        },
        {
            dataField: 'organisation',
            text: t('connection.organisation'),
            sort: true,
        },
    ];

    return (
        <>
            <Helmet title={port.fqid} />
            <ViewContainer>
                <Row>
                    <Col lg={6}>
                        <Row>
                            <Col>
                                <h2>
                                    {t('portDetails.pageTitle')}
                                    <SyncButton className="ml-2" title={t('portDetails.refreshButton')} onClick={onSync} />
                                </h2>
                            </Col>
                        </Row>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong className="h4">{port.fully_qualified_name}</strong>
                                <div className="card-header-actions">
                                    {!port.is_pxc && <ResourceStatus status={getPortSnmpStatus(snmpData)} />}
                                    <ContextMenuDropdown className="ml-2" dropdownMenuProps={{ right: true, persist: true }}>
                                        {!port.is_pxc && (
                                            <PortChangeStatusModal
                                                port={port}
                                                snmpStatus={getPortSnmpStatus(snmpData)}
                                                onChangeStatusRequested={handleSync}
                                            />
                                        )}
                                        <PortEditModal port={port} />
                                    </ContextMenuDropdown>
                                </div>
                            </CardHeader>
                            <CardBody className="overflow-auto">
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        {port.photonic_mapping.length !== 0 ? (
                                            <tr>
                                                <th>{t('portDetails.etherPorts')}</th>
                                                <td>
                                                    <div style={{ width: 600 }}>
                                                        <InteractiveTable
                                                            keyField="switch_port"
                                                            columns={photonicMappingColumns}
                                                            data={port.photonic_mapping}
                                                            paginationOptions={{
                                                                ...defaultPaginationOptions,
                                                                sizePerPage: 5,
                                                                paginationSize: 5,
                                                                hideSizePerPage: true,
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <th>{t('portDetails.headPxc')}</th>
                                                <td>
                                                    {port.head_pxc !== '' && port.head_pxc !== null ? (
                                                        <Link to={getLinkToPort(port.exchange, port.head_pxc)}>{port.head_pxc.toLowerCase()}</Link>
                                                    ) : (
                                                        <BoolIcon value={false} />
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>{t('portDetails.exchange')}</th>
                                            <td>{port.exchange}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('portDetails.location')}</th>
                                            <td>{port.location}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('portDetails.switch')}</th>
                                            <Link to={getLinkToSwitch(port.exchange, port.fqid)}>{port.fqid.toLowerCase()}</Link>
                                        </tr>
                                        <tr>
                                            <th>{t('portDetails.moduleType')}</th>
                                            <td>{port.module_type}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('portDetails.interfaceType')}</th>
                                            <td>{port.interface_type}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('portDetails.speed')}</th>
                                            <td>{port.speed}</td>
                                        </tr>
                                        {port.channel && (
                                            <tr>
                                                <th>{t('portDetails.channel')}</th>
                                                <td>{port.channel}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>{t('portDetails.technology')}</th>
                                            <td>{port.technology}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('portDetails.odf')}</th>
                                            <td>{port.odf !== '' ? port.odf : <BoolIcon value={false} />}</td>
                                        </tr>
                                        {port.odf_histories.length !== 0 && (
                                            <tr>
                                                <th>{t('portDetails.odfHistory')}</th>
                                                <td>
                                                    <div style={{ width: 600 }}>
                                                        <InteractiveTable
                                                            keyField="odf_id"
                                                            columns={odfHistoryColumns}
                                                            data={port.odf_histories}
                                                            paginationOptions={{
                                                                ...defaultPaginationOptions,
                                                                sizePerPage: 5,
                                                                paginationSize: 5,
                                                                hideSizePerPage: true,
                                                            }}
                                                            defaultSorted={[
                                                                {
                                                                    dataField: 'odf_id',
                                                                    order: 'desc',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {port.links && port.links.length !== 0 && (
                                            <tr>
                                                <th>{t('portDetails.links')}</th>
                                                <td>
                                                    <div style={{ width: 600 }}>
                                                        <InteractiveTable
                                                            keyField="xml_id"
                                                            columns={linksColumns}
                                                            data={port.links}
                                                            paginationOptions={{
                                                                ...defaultPaginationOptions,
                                                                sizePerPage: 5,
                                                                paginationSize: 5,
                                                                hideSizePerPage: true,
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th>{t('portDetails.snmpCheckUrl')}</th>
                                            <td>
                                                <a href={port.snmp_url} target="_blank" rel="noopener noreferrer">
                                                    {t('portDetails.snmpCheckText')}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th> {t('portDetails.selectable')}</th>
                                            <td>
                                                <BoolIcon value={port.selectable} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th> {t('portDetails.selectionBlocked')}</th>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id="snmp_failed"
                                                    defaultChecked={port.snmp_failed}
                                                    onClick={handleSelectable}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th> {t('portDetails.inconsistencies')}</th>
                                            <td>
                                                {port.inconsistencies && (
                                                    <Card className="bg-light p-2">
                                                        <pre style={{whiteSpace: 'pre-wrap'}} className="mb-0">
                                                            {port.inconsistencies}
                                                        </pre>
                                                    </Card>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                    </Col>
                    {!hasSomeRole(userRoles, Roles.customer_reader, Roles.ixaas_client_reader) && (
                        <Col lg={6}>
                            <Row className="justify-content-between">
                                <Col>
                                    <h2>{t('activityLog.headerTitle')}</h2>
                                </Col>
                            </Row>
                            <hr />
                            <PortActivityLog port={port} userRoles={userRoles} />
                        </Col>
                    )}
                </Row>
            </ViewContainer>
        </>
    );
}

type RouteProps = RouteComponentProps<{ portId: string }>;

interface ApiProps {
    portRequest: ApiDataBinding<ApiResponse<PortDetailsType>>;
    putUpdateSelectable: ApiDataBinding<ApiResponse<PortDetailsType>>;
    snmpDataRequest: ApiDataBinding<ApiResponse<{ snmp_data: SnmpData[] }>>;
    apiDataActions: ReactApiDataActions;
    userRoles: Roles[];
}

type WrapperProps = ApiProps & RouteProps;

const enhance = compose<WrapperProps, RouteProps>(
    withApiData(
        {
            putUpdateSelectable: 'putPortUpdate',
            portRequest: 'getPortDetails',
            snmpDataRequest: 'getPortSnmpData',
        },
        (ownProps: WrapperProps) => ({
            portRequest: { portId: ownProps.match.params.portId },
            snmpDataRequest: { portId: ownProps.match.params.portId },
        })
    ),
    withUserRoles
);

function PortDetailsWrapper(props: WrapperProps) {
    const { portRequest, putUpdateSelectable, apiDataActions, userRoles, snmpDataRequest } = props;
    const { t } = useTranslation();

    const handleSelectable = (target: any) => {
        putUpdateSelectable.perform({portId: props.match.params.portId }, {snmp_failed: target.currentTarget.checked})
        .then((response) => {
            if (response.request.networkStatus === NetworkRequestStatus.Success) {
                apiDataActions.invalidateCache('getPortDetails', { portId: props.match.params.portId });
            }
        });
    };

    const handleSync = () => {
        portRequest.invalidateCache();
        snmpDataRequest.invalidateCache();
    };

    return (
        <RequestStatusRenderer
            request={new CompositeRequest(portRequest.request, snmpDataRequest.request)}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() =>
                <PortDetails
                    port={portRequest.request.result.data}
                    snmpData={snmpDataRequest.data!.data!.snmp_data}
                    handleSelectable={handleSelectable}
                    handleSync={handleSync}
                    userRoles={userRoles}
                    apiDataActions={apiDataActions}
                />
            }
        />
    );
}

export default enhance(PortDetailsWrapper);
