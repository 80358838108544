import { FunctionComponent } from 'react';
import { Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';
import { getLinkToConnection } from 'utils/linksUtils';
import Exchange from 'components/utils/Exchange';

interface Props {
    linkHistory: LinkFull[];
}

const mapStateToProps = (state: ReduxState) => ({
    linkHistory: state.app.linkHistory
});

const RecentConnections: FunctionComponent<Props & WithTranslation> = ({ linkHistory, t }) => {
    return (
        <Card className="card-accent-primary">
            <CardHeader><strong>{t('recentConnections.title')}</strong></CardHeader>
            <ListGroup>
                {linkHistory && linkHistory.length > 0 ?
                    linkHistory.slice().reverse().map(link => (
                        <Link
                            data-test="link-to-recent-connection"
                            key={link.xml_id}
                            to={getLinkToConnection(link.exchange.short_name, link.uuid)}
                            className="list-group-item list-group-item-action"
                        >
                            {link.xml_id} - {link.organisation_name} - <Exchange shortName={link.exchange.short_name} />
                        </Link>
                    ))
                    :
                    <ListGroupItem data-test="no-connections-message" className="text-center font-italic">
                        {t('recentConnections.noConnections')}
                    </ListGroupItem>
                }
            </ListGroup>
        </Card>
    );
};

export default compose<Props & WithTranslation, {}>(
    connect(mapStateToProps),
    withTranslation()
)(RecentConnections);
