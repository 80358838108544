import React from 'react';
import memoizeOne from 'memoize-one';

// utils
import i18next from 'locales/i18next';
import { Switch } from 'constants/api';
import { Link } from 'react-router-dom';
import { getLinkToSwitch } from 'utils/linksUtils';

export const getSwitchesTableColumns = memoizeOne((exchange: string): any => {
    return [
        {
            dataField: 'name',
            text: i18next.t('switches.name'),
            sort: true,
        },
        {
            dataField: 'scope',
            text: i18next.t('switches.scope'),
            sort: true,
        },
        {
            dataField: 'type',
            text: i18next.t('switches.type'),
            sort: true,
        },
        {
            dataField: 'platform',
            text: i18next.t('switches.technology'),
            sort: true,
        },
        {
            dataField: 'locationDescription',
            text: i18next.t('switches.location'),
            sort: true,
        },
        {
            dataField: 'locationShortName',
            text: i18next.t('switches.locationShortName'),
            sort: true,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: 'Switch',
            formatter: (cell: any, row: Switch) => (
                <Link to={getLinkToSwitch(exchange, row.name)}>{i18next.t('common.view')}</Link>
            ),
        },
    ];
});
