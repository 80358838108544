import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { ApiResponse, SnmpData } from 'constants/api';
import { compose } from 'recompose';
import ResourceStatus from './ResourceStatus';
import { getPortSnmpStatus } from 'utils/snmpUtils';
import RequestStatusRenderer from './RequestStatusRenderer';

interface InProps {
    portId: number;
}

interface EnhanceProps {
    snmpDataBinding: ApiDataBinding<ApiResponse<{ snmp_data: SnmpData[] }>>;
}

type Props = InProps & EnhanceProps;

const PortSnmpStatus: FunctionComponent<Props> = ({ snmpDataBinding }) => {
    return (
        <RequestStatusRenderer
            request={snmpDataBinding}
            success={() => (
                <ResourceStatus status={getPortSnmpStatus(snmpDataBinding.data!.data!.snmp_data)} />
            )}
        />
    );
};

const enhance = compose<Props, InProps>(
    withApiData(
        {
            snmpDataBinding: 'getPortSnmpData',
        },
        (ownProps: InProps) => ({
            snmpDataBinding: { portId: ownProps.portId },
        })
    )
);

export default enhance(PortSnmpStatus);
