import React from 'react';
import classnames from 'classnames';

interface Props {
    onClick: () => void;
    className?: string;
}

function CloseButton({ onClick, className }: Props) {
    return (
        <button
            type="button"
            className={classnames('close d-flex align-items-center', className)}
            aria-label="Close"
            onClick={onClick}
        >
            <span aria-hidden="true">&times;</span>
        </button>
    );
};

export default CloseButton;
