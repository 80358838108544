import get from 'lodash/get';
import head from 'lodash/head';
import last from 'lodash/last';

import i18next from 'locales/i18next';
import { changeLanguageAction } from 'redux/app/appActions';

interface LanguageItem {
    lang: string;
    title: string;
}

export const changeAppLanguage = (language: string, dispatch: any): void => {
    i18next.changeLanguage(language);
    dispatch(changeLanguageAction(language));
};

export const getAvailableLanguages = (): LanguageItem[] => {
    const availableLanguages: LanguageItem[] = [];
    const appLanguages = get(process.env, 'REACT_APP_LANGUAGES', '');
    if (!appLanguages) {
        return availableLanguages;
    }
    const extraLanguages: string[] = appLanguages.split(',');
    extraLanguages.forEach((language: string) => {
        if (!language) {
            return;
        }
        const languageData = language.trim().split('/');
        const lang = head(languageData);
        const title = last(languageData);
        if (!lang || !title) {
            console.warn('Incorrect language format!', lang, title);
            return;
        }
        availableLanguages.push({ lang, title });
    });
    return availableLanguages;
};
