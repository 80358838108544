import { ApiDataRequest, performApiRequest, getApiDataRequest } from 'react-api-data';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import get from 'lodash/get';

import { LinkFull } from 'constants/api';
import { ReduxState } from 'createStore';

import MacAddressAddModal from './MacAddressAddModal';

interface InputProps {
    link: LinkFull;
}

interface EnhanceProps {
    onSubmit: (values: any) => boolean;
    request: ApiDataRequest;
    resourceStatus: string;
}

type Props = EnhanceProps & InputProps;

const mapStateToProps = (state: ReduxState, ownProps: Props) => ({
    request: getApiDataRequest(state.apiData, 'postMacAddress', {
        linkId: ownProps.link.uuid,
        exchange: ownProps.link.exchange.short_name,
    }),
    resourceStatus: get(ownProps, 'link.status')
});

const mapDispatchToProps = (dispatch: any, ownProps: Props) => {
    return {
        onSubmit: (values: any) => {
            dispatch(performApiRequest('postMacAddress', {
                linkId: ownProps.link.uuid,
                exchange: ownProps.link.exchange.short_name,
            }, values));

            return true;
        }
    };
};

const enhance = compose<EnhanceProps, InputProps>(
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MacAddressAddModal);
