import React from 'react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ApiDataBinding, withApiData, ApiDataState } from 'react-api-data';
import { Exchange } from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import ExchangeDataCenters from 'components/cards/ExchangeDataCenters';
import ExchangeEditModal from 'components/modals/ExchangeEditModal';
import BoolIcon from 'components/utils/BoolIcon';
import DataCenterAddModal from 'components/modals/DataCenterAddModal';

interface ExchangeDetailsProps {
    exchangeObject: Exchange;
    apiData: ApiDataState;
    handleSync: () => void;
}

function ExchangeDetails(props: ExchangeDetailsProps) {
    const { exchangeObject, handleSync } = props;
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={exchangeObject.name} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('exchangeDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('exchangeDetails.refreshButton')}
                                onClick={handleSync}
                            />
                            <ExchangeEditModal exchangeObject={exchangeObject} handleSync={handleSync} />
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('exchangeDetails.exchangeDetailsTitle')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('exchangeDetails.name')}</th>
                                            <td>{exchangeObject.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('exchangeDetails.shortName')}</th>
                                            <td>{exchangeObject.short_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('exchangeDetails.displayName')}</th>
                                            <td>{exchangeObject.display_name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('exchangeDetails.active')}</th>
                                            <td>
                                                <BoolIcon value={exchangeObject.active} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('exchangeDetails.website')}</th>
                                            <td>{exchangeObject.website}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('exchangeDetails.asn')}</th>
                                            <td>{exchangeObject.asn}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('exchangeDetails.ifxId')}</th>
                                            <td>{exchangeObject.ixf_id}</td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>
                            {t('exchangeDetails.dataCentersTitle')}
                            <DataCenterAddModal exchangeObject={exchangeObject} />
                        </h2>

                        <hr />
                        <ExchangeDataCenters exchange={exchangeObject} />
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type ExchangeProps = RouteComponentProps<{ uuid: string }>;

interface ApiProps {
    exchangeRequest:  ApiDataBinding<Exchange>;
    apiData: ApiDataState;
}

type WrapperProps = ApiProps & ExchangeProps;

function ExchangeDetailsWrapper(props: WrapperProps) {
    const { exchangeRequest, apiData } = props;
    const { t } = useTranslation();

    const handleSync = () => {
        exchangeRequest.invalidateCache();
    };

    return (
        <RequestStatusRenderer
            request={exchangeRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => (
                <ExchangeDetails
                    exchangeObject={exchangeRequest.data!}
                    apiData={apiData}
                    handleSync={handleSync}
                />
            )}
        />
    );
}


const enhance = compose<WrapperProps, ExchangeProps>(
    withApiData(
        {
            exchangeRequest: 'getExchange',
        },
        (ownProps: WrapperProps) => ({
            exchangeRequest: {
                uuid: ownProps.match.params.uuid
            },
        })
    )
);

export default enhance(ExchangeDetailsWrapper);


