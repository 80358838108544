import React, { FunctionComponent } from 'react';

import BaseTransition, { InProps } from './BaseTransition';

import { TransitionsType, Roles } from 'constants/literals';

const TransitionConfigurationFailed: FunctionComponent<InProps> = (props) => {
    return (
        <BaseTransition
            roles={[Roles.admin, Roles.noc]}
            transitionType={TransitionsType.configuration_failed}
            modalHeaderTitle="resourceTransitions.configurationFailedHeaderTitle"
            modalBodyTitle="resourceTransitions.configurationFailedBodyText"
            transitionName="resourceTransitions.configurationFailed"
            transitionIconClass="fa-ban"
            { ...props }
        />
    );
};

export default TransitionConfigurationFailed;