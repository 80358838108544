import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import localforage from 'localforage';
import { configureApiData, reducer, ApiDataState, ApiDataRequest } from 'react-api-data';
import thunk from 'redux-thunk';
import reduceReducers from 'reduce-reducers';
import reduce from 'lodash/reduce';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import forOwn from 'lodash/forOwn';

import endpointConfig, { globalConfig } from 'constants/endpointConfig';
import { AppState, defaultState, defaultApiDataState } from './appState';
import { apiDataReducer } from 'redux/apiData/apiDataReducer';
import { appReducer } from 'redux/app/appReducer';
import { CACHEDURATION_NONE } from 'constants/cacheConfig';

export interface ReduxState {
    apiData: ApiDataState;
    app: AppState;
}

localforage.config({
    name: `oss_${process.env.NODE_ENV}_${process.env.REACT_APP_EXCHANGE_NAME}`,
    driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
    storeName: `ossDataStore_${process.env.NODE_ENV}_${process.env.REACT_APP_EXCHANGE_NAME}`,
    version: 1.0,
    description: 'Cache storage'
});

const whiteListedEndpoints: string[] = reduce(endpointConfig, (result: string[], enpointValue: any, enpointKey: string) => {
    if (enpointValue.method === 'GET' && enpointValue.cacheDuration !== CACHEDURATION_NONE) {
        result.push(enpointKey);
    }
    return result;
}, []);

export default (initial: any = {}) => {

    const persistConfig = {
        key: 'root',
        storage: localforage,
        whitelist: [
            'app'
        ]
    };

    const apiDataPersistConfig = {
        key: 'apiData',
        storage: localforage,
        blacklist: ['globalConfig', 'endpointConfig'],
        transforms: [
            createTransform(
                (inboundState: any, key: string) => {
                    if (key === 'requests') {
                        // store only GET requests
                        return pickBy(inboundState, (value: any, stateKey: string) => {
                            return includes(whiteListedEndpoints, value.endpointKey);
                        });
                    }
                    return inboundState;
                },
                (outboundState: any, key: string) => {
                    if (key === 'requests') {
                        // reset a networkStatus to 'ready' if it was 'loading'
                        const correctedRequestsState = { ...outboundState };
                        forOwn(correctedRequestsState, (request: ApiDataRequest) => {
                            if (request.networkStatus === 'loading') {
                                request.networkStatus = 'ready';
                            }
                        });
                        return correctedRequestsState;
                    }
                    return outboundState;
                },
            )
        ]
    };

    // @ts-ignore
    const apiReducers = reduceReducers(defaultApiDataState, apiDataReducer, reducer);
    const rootReducer = combineReducers({
        // @ts-ignore
        apiData: persistReducer(apiDataPersistConfig, apiReducers),
        // @ts-ignore
        app: reduceReducers(defaultState, appReducer)
    });

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(thunk)
    );

    const store = createStore(
        persistedReducer,
        initial || {},
        enhancer
    );
    store.dispatch(configureApiData(globalConfig, endpointConfig));

    const persistor = persistStore(store);

    return {
        store,
        persistor
    };
};
