import styled from 'styled-components';
import { Alert, Badge, Table } from 'reactstrap';

import { BaseModalTemplate } from 'components/modals/BaseModalTemplate';

export const StyledTable = styled.div`
    thead {
        border-top: 2px solid var(--primary);
        background-color: #f0f3f5;
        .custom-sort-caret {
            color: #2f353a !important;
            height: 14px;
            transform: translateY(50%);
            top: -8px;
            position: relative;
            vertical-align: middle;
            float: none;
            &.fa-sort-down {
                top: -10px;
            }
            &.fa-sort-up {
                top: -5px;
            }
        }
    }
    tbody {
        background-color: #fff;
    }
    th.sortable {
        cursor: pointer;
        :hover {
            color: var(--primary);
        }
    }
  }
`;

export const StyledMac = styled.span`
  min-width: 108px;
  display: inline-block;
  margin-right: 5px;
`;

export const LargeBadge = styled(Badge)`
  font-size: 90% !important;
`;

export const BaseModalLarge = styled(BaseModalTemplate)`
  max-width: 95% !important;
`;

export const CenteredAlert = styled(Alert)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
`;

export const DetailsTable = styled(Table)`
  th {
    width: 13rem;
  }
`;