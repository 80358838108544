import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import { compose } from 'recompose';

import { LinkFull, ApiResponse } from 'constants/api';

import { hasSomeRole } from '../../utils/userUtils';
import { Roles, LinkSwapStatus } from '../../constants/literals';
import { useTranslation } from 'react-i18next';
import RequestStatusRenderer from './RequestStatusRenderer';
import Spinner from 'reactstrap/lib/Spinner';
import LinkSwapModal from 'components/modals/LinkSwapModal';

interface InProps {
    link: LinkFull;
    userRoles: Roles[];
}

interface EnhanceProps {
    linkStatusRequest: ApiDataBinding<ApiResponse<{ link: string, status: LinkSwapStatus }>>;
}

type Props = InProps & EnhanceProps;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            linkStatusRequest: 'getLinkStatus'
        },
        (ownProps: InProps) => ({
            linkStatusRequest: {
                linkId: ownProps.link.uuid,
                exchange: ownProps.link.exchange.short_name
            }
        })
    )
);

const LinkStatus: FunctionComponent<Props> = ({ link, linkStatusRequest, userRoles }) => {
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={linkStatusRequest}
            loading={
                <Spinner type="grow" color="primary" />
            }
            success={() => {
                const linkStatus = linkStatusRequest.data!.data!.status;

                const swapToStatus = linkStatus === LinkSwapStatus.PRIMARY ? LinkSwapStatus.BACKUP : LinkSwapStatus.PRIMARY;

                return (
                    <div className="d-flex align-items-center">
                        <div className="mr-2" data-test="link-current-status">{linkStatus}</div>
                        {hasSomeRole(userRoles, Roles.admin, Roles.noc, Roles.qnoc) && (
                            <LinkSwapModal
                                data-test="swap-link-btn"
                                link={link}
                                swapToStatus={swapToStatus}
                            />
                        )}
                    </div>
                );
            }}
            failed={() => {
                return (
                    <div className="badge badge-danger">{linkStatusRequest.data!.message}</div>
                );
            }}
        />
    );
};

export default enhance(LinkStatus);
