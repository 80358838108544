import React, { FunctionComponent } from 'react';

import { LinkFull } from 'constants/api';
import { getIpAddresses } from 'utils/linksUtils';

interface InProps {
    link: LinkFull;
}

const IpAddressOptions: FunctionComponent<InProps> = ({ link }) => {
    const ipAddresses = getIpAddresses(link);
    return (
        <>
            {ipAddresses && ipAddresses.map((address: string) => (
                <option data-test="ip-option" value={address} key={address}>{address}</option>)
            )}
        </>
    );
};

export default IpAddressOptions;