import React, { FunctionComponent, useState, useEffect  } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withApiData, ApiDataBinding } from 'react-api-data';
import { ApiResponse, IpRangeIpAddress, IpRange } from 'constants/api';
import Card from 'reactstrap/lib/Card';
import { CardBody, Col, Input } from 'reactstrap';
import { NetworkRequestStatus } from 'constants/literals';
import { Link } from 'react-router-dom';
import { getLinkToConnection } from 'utils/linksUtils';
import BoolIcon from 'components/utils/BoolIcon';
import moment from 'moment';
import InteractiveRemoteTable from 'components/utils/InteractiveRemoteTable';
import { get } from 'lodash';
import CardHeader from 'reactstrap/lib/CardHeader';
import Row from 'reactstrap/lib/Row';
import { useDebounce } from 'hooks/useDebounce';

interface InProps {
    ipRange: IpRange;
}

interface EnhanceProps {
    ipsRequest: ApiDataBinding<ApiResponse<{ ips: IpRangeIpAddress[] }>>;
}

type Props = InProps & EnhanceProps & WithTranslation;

const enhance = compose<Props, InProps>(
    withApiData(
        {
            ipsRequest: 'getIps'
        },
        (ownProps: Props) => ({
            ipsRequest: {},
        })
    ),
    withTranslation()
);

enum IpFilterOptions {
    all = 'All',
    free = 'Free',
    reserved = 'Reserved',
}

const IpsList: FunctionComponent<Props> = ({ ipsRequest, ipRange, t }) => {

    const [ipFilter, setIpFilter] = useState<IpFilterOptions>(IpFilterOptions.all);
    const [sortField, setSortField] = useState('ip');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');

    const fetchIps = (pageNumber: number, sortField: string, sortOrder: string, ipFilter: IpFilterOptions, search: string) => {
        return ipsRequest.perform({
            exchange: ipRange.exchange.short_name,
            id: ipRange.id,
            page: pageNumber,
            per_page: 10,
            sort: sortField,
            order: sortOrder,
            ip_filter: ipFilter,
            search: search,
        });
    };

    const debounceSearchQuery = useDebounce(search, 500);

    useEffect(() => {
        fetchIps(1, sortField, sortOrder, ipFilter, search);
    }, [debounceSearchQuery, ipFilter]);

    const handleIpsFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filter = event.target.value as IpFilterOptions;
        setIpFilter(filter);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        setSearch(searchValue);
    };

    const columnOptions = [
        {
            dataField: 'ip',
            text: t('common.ip'),
            sort: true,
        },
        {
            dataField: 'fqdn',
            text: t('common.fqdn'),
            sort: false,
        },
        {
            dataField: 'links',
            text: t('ipRangeDetails.links'),
            sort: false,
            formatter: (_: any, row: IpRangeIpAddress) => {
                if (row.links.length === 0) return '';

                return (
                    <span>
                        {row.links.map<React.ReactNode>((link) => (
                            <Link key={link.uuid} to={getLinkToConnection(link.exchange, link.uuid)}>{link.xml_id}</Link>
                        )).reduce((prev, curr) => [prev, ', ', curr])}
                    </span>
                );
            }
        },
        {
            dataField: 'date_freed',
            text: t('ipRangeDetails.dateFreed'),
            sort: true,
            formatter: (cell: Date) => {
                if (!cell) return '';

                return moment(cell).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            dataField: 'is_free',
            text: t('ipRangeDetails.free'),
            sort: false,
            formatter: (cell: boolean) => (
                <BoolIcon value={cell} />
            )
        },
    ];

    return (
        <Card>
            <CardHeader>
                <Row className="justify-content-between ml-0 mr-0">
                    <Col sm="8">
                        <Row className="align-items-center">
                            <strong>{t('ipRangeDetails.ipsTitle')}</strong>

                            <Input
                                type="text"
                                name="search"
                                placeholder={t('ipRangeDetails.searchIps')}
                                className="ml-3 w-auto"
                                onChange={handleSearchChange}
                                value={search}
                            />
                        </Row>
                    </Col>
                    <Col sm="4">
                        <Row>
                            <Input
                                type="select"
                                name="ipFilter"
                                data-test="ip-filter"
                                defaultValue={IpFilterOptions.all}
                                onChange={handleIpsFilterChange}
                            >
                                {Object.keys(IpFilterOptions).map((filterOption) => (
                                    <option key={filterOption} value={filterOption}>{t(`ipRangeDetails.${filterOption}Ips`)}</option>
                                ))}
                            </Input>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <InteractiveRemoteTable
                    keyField="id"
                    columns={columnOptions}
                    data-test="ips-table"
                    data={get(ipsRequest, 'data.data.ips', [])}
                    defaultSorted={{ dataField: 'ip', order: 'asc' }}
                    loading={ipsRequest.request.networkStatus === NetworkRequestStatus.Loading}
                    fetchPage={(pageNumber, pageSize, sortField, sortOrder) => {
                        setSortField(sortField);
                        setSortOrder(sortOrder);
                        fetchIps(pageNumber, sortField, sortOrder, ipFilter, search);
                    }}
                    paginationOptions={{
                        page: get(ipsRequest, 'data.meta.page', 1),
                        sizePerPage: get(ipsRequest, 'data.meta.page_size', 10),
                        totalSize: get(ipsRequest, 'data.meta.total_items')
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default enhance(IpsList);

