import React, { useMemo } from 'react';
import { SpeedAndInterfaceTypes } from 'constants/api';
import Select from 'react-select';

interface Props {
    speedAndInterfaceTypes: SpeedAndInterfaceTypes[];
    onChange: (speedAndInterfaceType: SpeedAndInterfaceTypes) => void;
    className?: string;
}

interface Option {
    value: number;
    label: string;
}

function SpeedSelect({ speedAndInterfaceTypes, onChange, className }: Props) {
    const options = useMemo(() => {
        return speedAndInterfaceTypes.map((speedAndInterfaceType) => ({
            label: speedAndInterfaceType.name,
            value: speedAndInterfaceType.id,
        }));
    }, [speedAndInterfaceTypes]);

    function handleChange(option: Option) {
        const speedAndInterfaceType = speedAndInterfaceTypes.find((speedAndInterfaceType) => speedAndInterfaceType.id === option.value);
        onChange(speedAndInterfaceType!);
    }

    return (
        <Select
            options={options}
            onChange={handleChange}
            filterOption={(option: Option, inputValue: string) => option.label.includes(inputValue)}
            className={className}
        />
    );
}

export default SpeedSelect;