import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, NavItem, NavLink, Nav, TabContent, TabPane, CardHeader, Row, Col, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// components
import { BaseModalLarge } from 'components/styled';
import ConnectionsList from './ConnectionsList';
import VirtualLinkVlansList from './VirtualLinkVlansList';

enum Tab {
    Connections = 'connections',
    VirtualLinkVlans = 'virtualLinkVlans',
}

function GlobalSearch() {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const [activeTab, setActiveTab] = useState(Tab.Connections);
    const hotkeyIsPrimed = useRef(false);
    const toggleOpened = useCallback(() => setIsOpened((prevState) => !prevState), [setIsOpened]);

    function focusSearchInput() {
        const searchInput = document.querySelector('input.global-search-field') as HTMLInputElement;
        if (searchInput) {
            searchInput.focus();
        }
    }

    useEffect(
        function bindHotkey() {
            function listener(event: KeyboardEvent) {
                if (event.key === 'Shift') {
                    if (hotkeyIsPrimed.current) {
                        toggleOpened();
                    } else {
                        hotkeyIsPrimed.current = true;
                        setTimeout(() => {
                            hotkeyIsPrimed.current = false;
                        }, 500);
                    }
                }
            };
            document.addEventListener('keyup', listener);
            return () => document.removeEventListener('keyup', listener);
        },
        [toggleOpened, hotkeyIsPrimed]
    );

    return (
        <>
            <NavItem>
                <NavLink href="#" onClick={toggleOpened}>
                    <i className="fas fa-search" />
                </NavLink>
            </NavItem>
            <BaseModalLarge
                isOpen={isOpened}
                toggle={toggleOpened}
                fade={false}
                onOpened={focusSearchInput}
                header={t('globalSearch.searchTitle')}
                body={
                    <>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    active={activeTab === Tab.Connections}
                                    onClick={() => setActiveTab(Tab.Connections)}
                                >
                                    {t('globalSearch.connectionsTab')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={activeTab === Tab.VirtualLinkVlans}
                                    onClick={() => setActiveTab(Tab.VirtualLinkVlans)}
                                >
                                    {t('globalSearch.virtualLinkVlansTab')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={activeTab}
                            className="border-top border-bottom-0 border-left-0 border-right-0"
                        >
                            <TabPane tabId={Tab.Connections}>
                                <ConnectionsList onLinkClick={toggleOpened} />
                            </TabPane>
                            <TabPane tabId={Tab.VirtualLinkVlans}>
                                <VirtualLinkVlansList onLinkClick={toggleOpened} />
                            </TabPane>
                        </TabContent>
                    </>
                }
                footer={
                    <Button color="light" onClick={toggleOpened}>
                        {t('modal.cancelBtn')}
                    </Button>
                }
            />
        </>
    );
}

export default GlobalSearch;
