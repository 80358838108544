import React, { FunctionComponent } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// constants
import { PublicVlanItem } from 'constants/api';
import { ReduxState } from 'createStore';
import { OptionItem } from 'constants/literals';
import i18n from 'locales/i18next';

// utils
import { getPublicVlansSelector } from 'redux/apiData/apiDataSelectors';

// components
import TotalGraph from 'components/graphs/TotalGraph';
import AggregateStatistics from 'components/cards/AggregateStatistics';

interface ConnectProps {
    vlans: PublicVlanItem[];
}

type TotalStatisticsProps = ConnectProps & WithTranslation;

const getAggregateOptions = memoizeOne((vlans: PublicVlanItem[], t: i18n.TFunction): OptionItem[] => {
    const aggregateOptions: OptionItem[] = [{ title: t('stats.allVlans'), value: 'totalall' }];
    return [...aggregateOptions, ...convertVlansToOptions(vlans)];
});

const convertVlansToOptions = (vlans: PublicVlanItem[]): OptionItem[] => (
    map(vlans, (vlanItem: PublicVlanItem) => ({ title: vlanItem.description, value: `vlan-${vlanItem.number}` }))
);

const mapStateToProps = (state: ReduxState) => ({
    vlans: getPublicVlansSelector(state.apiData, get(state, 'app.stats.selectedExchange', ''))
});

const TotalStatistics: FunctionComponent<TotalStatisticsProps> = ({ vlans, t }) => (
    <AggregateStatistics
        statsTitle="stats.statsTitle"
        displayType={false}
        aggregateOptions={getAggregateOptions(vlans, t)}
        selectedExchangeAppPath="stats.selectedExchange"
        selectedAggregateAppPath="stats.selectedAggregate"
        renderGraph={({ interval, type, selectedExchange, selectedAggregate }) => (
            <TotalGraph
                exchange={selectedExchange}
                aggregate={selectedAggregate}
                type={type}
                interval={interval}
                height={250}
            />
        )}
    />
);

export default compose<TotalStatisticsProps, {}>(
    connect(mapStateToProps),
    withTranslation()
)(TotalStatistics);
