import React, { FunctionComponent } from 'react';
import { ApiDataBinding, withApiData } from 'react-api-data';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';

// components
import AlertMessage from '../utils/AlertMessage';
import InteractiveTable from '../utils/InteractiveTable';
import { StyledTable } from '../styled';
import Exchange from 'components/utils/Exchange';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

// constants
import { LinkFull } from '../../constants/api';
import { defaultPaginationOptions } from '../../constants/pagination';
import i18n from 'locales/i18next';

// utils
import { formatSpeed, getStatusColor } from '../../utils/formatUtils';
import { getLinkToConnection } from '../../utils/linksUtils';
import { sizePerPageRenderer } from '../../utils/commonUtils';
import RequestStatusRenderer from '../utils/RequestStatusRenderer';

interface InProps {
    vlanUuid: string;
}

interface ApiProps {
    vlanLinksRequest: ApiDataBinding<LinkFull[]>;
}

type Props = InProps & ApiProps & WithTranslation;

const connectApiData = withApiData(
    {
        vlanLinksRequest: 'getVlanLinks'
    },
    (ownProps: InProps) => ({
        vlanLinksRequest: {
            vlanUuid: ownProps.vlanUuid
        }
    })
);

const VlanLinksContainer: FunctionComponent<Props> = ({ vlanLinksRequest, t }) => (
    <RequestStatusRenderer
        request={vlanLinksRequest}
        failedMessage={t('connection.noConnections')}
        success={
            <VlanLinks
                translate={t}
                vlanLinks={vlanLinksRequest.data}
            />
        }
    />
);

interface VlanLinksProps {
    vlanLinks: LinkFull[] | undefined;
    translate: i18n.TFunction;
}

export const VlanLinks: FunctionComponent<VlanLinksProps> = ({
    vlanLinks,
    translate
}) => {
    const tableColumns = [
        {
            dataField: 'status',
            text: translate('common.status'),
            sort: true,
            formatter: (cell?: string) => {
                return <Badge color={getStatusColor(cell)}>{cell}</Badge>;
            }
        },
        {
            dataField: 'xml_id',
            text: translate('connection.xmlIdLabel'),
            sort: true,
            copy: true,
        },
        {
            dataField: 'organisation_name',
            text: translate('connection.organisation'),
            sort: true,
            copy: true
        },
        {
            dataField: 'speed',
            text: translate('common.speed'),
            sort: true,
            formatter: formatSpeed
        },
        {
            dataField: 'classification',
            text: translate('connection.classificationLabel'),
            sort: true,
            copy: true
        },
        {
            dataField: 'location',
            text: translate('common.location'),
            sort: true,
            copy: true,
        },
        {
            dataField: 'exchange.short_name',
            text: translate('common.exchange'),
            sort: true,
            formatter: (cell: any) => <Exchange shortName={cell} />,
        },
        {
            dataField: 'link',
            isDummyField: true,
            text: translate('common.link'),
            formatter: (_: any, row: LinkFull) => {
                return <Link to={getLinkToConnection(row.exchange.short_name, row.uuid)}>{translate('common.view')}</Link>;
            },
        },
    ];

    return (
        <>
            {vlanLinks && vlanLinks.length > 0 ? (
                <StyledTable className="animated fadeIn">
                    <InteractiveTable
                        data-test="connections-table"
                        classes="table-responsive-lg"
                        keyField="uuid"
                        columns={tableColumns}
                        data={vlanLinks}
                        defaultSorted={[
                            {
                                dataField: 'status',
                                order: 'asc',
                            },
                        ]}
                        paginationOptions={{
                            ...defaultPaginationOptions,
                            sizePerPageRenderer: sizePerPageRenderer('up'),
                        }}
                    />
                </StyledTable>
            ) : (
                <AlertMessage data-test="no-connections-message" message={translate('connection.noConnections')} />
            )}
        </>
    );
};

const enhance = compose<Props, InProps>(
    connectApiData,
    withTranslation()
);

export default enhance(VlanLinksContainer);