import { ApiDataBinding, withApiData } from 'react-api-data/lib';
import { compose } from 'recompose';

import { ApiResponse, TrafficStats } from 'constants/api';
import ResourceGraph, { ResourceGraphProps } from './ResourceGraph';

interface InProps extends ResourceGraphProps {
    linkId: string;
    exchange: string;
}

interface ApiProps {
    statsData: ApiDataBinding<ApiResponse<TrafficStats>>;
}

type Props = InProps & ApiProps;

const connectApiData = withApiData(
    { statsData: 'getLinkStats' },
    (ownProps: Props) => ({
        statsData: {
            linkId: ownProps.linkId,
            exchange: ownProps.exchange,
            interval: ownProps.interval
        }
    })
);

// @ts-ignore
export default compose<Props, InProps>(connectApiData)(ResourceGraph);
