import React, { FunctionComponent } from 'react';

import BaseTransition, { InProps } from './BaseTransition';
import { TransitionsType, Roles, ConnectionStatus } from 'constants/literals';

const canConfigureStatuses = [ConnectionStatus.unconfigured];

const TransitionConfigure: FunctionComponent<InProps> = (props) => {
    return (
        <BaseTransition
            roles={[Roles.admin, Roles.noc, Roles.qnoc, Roles.ixaas_client]}
            transitionType={TransitionsType.configure}
            statuses={canConfigureStatuses}
            modalHeaderTitle="resourceTransitions.configureHeaderTitle"
            modalBodyTitle="resourceTransitions.configureBodyText"
            transitionName="resourceTransitions.configure"
            transitionIconClass="fa-arrow-circle-up"
            { ...props }
        />
    );
};

export default TransitionConfigure;