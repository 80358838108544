import React, { FunctionComponent } from 'react';
import { compose } from 'recompose';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';
import i18next from 'i18next';

// constants
import { SflowStatsType, OptionItem, StatsCounterType, StatsInterval } from 'constants/literals';

// components
import SflowAggregatedStatsGraph from 'components/graphs/SflowAggregatedStatsGraph';
import AggregateStatistics from 'components/cards/AggregateStatistics';

const getSflowAggregates = memoizeOne((t: i18next.TFunction): OptionItem[] => {
    return [
        {
            title: t('stats.ethertype'),
            value: SflowStatsType.ethertype
        },
        {
            title: t('stats.framesize'),
            value: SflowStatsType.framesize
        },
    ];
});

const SflowTrafficStatistics: FunctionComponent<WithTranslation> = ({ t }) => (
    <AggregateStatistics
        statsTitle="stats.sflowStatsTitle"
        displayType={false}
        aggregateOptions={getSflowAggregates(t)}
        selectedExchangeAppPath="stats.sflow.selectedExchange"
        selectedAggregateAppPath="stats.sflow.selectedAggregate"
        renderGraph={({ interval, selectedExchange, selectedAggregate }) => (
            <SflowAggregatedStatsGraph
                exchange={selectedExchange}
                sflowType={selectedAggregate as SflowStatsType}
                counter={StatsCounterType.percent}
                interval={interval as StatsInterval}
                height={250}
            />
        )}
    />
);

export default compose<WithTranslation, {}>(
    withTranslation()
)(SflowTrafficStatistics);
