import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ApiDataBinding, withApiData, invalidateApiDataRequest, performApiRequest } from 'react-api-data';
import { ApiResponse, Contract, RouteServer } from 'constants/api';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';
import { DetailsTable } from 'components/styled';
import PageLoading from 'components/utils/PageLoading';
import ViewContainer from 'components/utils/ViewContainer';
import SyncButton from 'components/utils/SyncButton';
import Exchange from 'components/utils/Exchange';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { Link } from 'react-router-dom';
import { getLinkToConnection, getLinkToVlan } from 'utils/linksUtils';
import RouteServerEditModal from 'components/modals/RouteServerEditModal';
import { get } from 'lodash';
import { getHomeContractSelector } from 'redux/apiData/apiDataSelectors';
import { ReduxState } from 'createStore';

interface RouteServerDetailsProps {
    routeServer: RouteServer;
    refetchRouteServer: () => undefined;
    contract: Contract;
}

function RouteServerDetails(props: RouteServerDetailsProps) {
    const { routeServer, refetchRouteServer, contract } = props;
    const { t } = useTranslation();
    const organisation = get(contract, 'organisation');

    return (
        <>
            <Helmet title={routeServer.description} />
            <ViewContainer>
                <Row>
                    <Col>
                        <h2>
                            {t('routeServerDetails.pageTitle')}
                            <SyncButton
                                className="ml-2"
                                title={t('routeServerDetails.refreshButton')}
                                onClick={refetchRouteServer}
                            />
                            {organisation && <RouteServerEditModal routeServer={routeServer} organisation={organisation}/>}
                        </h2>
                        <hr />
                        <Card className="card-accent-primary">
                            <CardHeader>
                                <strong>{t('routeServerDetails.routeServerDetailsTitle')}</strong>
                            </CardHeader>
                            <CardBody>
                                <DetailsTable size="sm" borderless>
                                    <tbody>
                                        <tr>
                                            <th>{t('routeServerDetails.exchange')}</th>
                                            <td>
                                                <Exchange shortName={routeServer.exchange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.description')}</th>
                                            <td>{routeServer.description}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.id')}</th>
                                            <td>{routeServer.name}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.type')}</th>
                                            <td>{routeServer.type}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.asnNumber')}</th>
                                            <td>{routeServer.as_number}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.managementAddress')}</th>
                                            <td>{routeServer.management_address}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.Ipv4')}</th>
                                            <td><Link to={getLinkToConnection(routeServer.exchange, routeServer.link_ipv4_uuid)}>{routeServer.peering_ipv4}</Link></td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.Ipv6')}</th>
                                            <td><Link to={getLinkToConnection(routeServer.exchange, routeServer.link_ipv6_uuid)}>{routeServer.peering_ipv6}</Link></td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.vlanNumber')}</th>
                                            <td><Link to={getLinkToVlan('row.exchange', routeServer.vlan.uuid)}>{routeServer.vlan.number}</Link></td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.bgpCommunityRoutingPolicies')}</th>
                                            <td>{routeServer.policy_modes.bgp_communities}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.irrdbBasedRoutingPolicies')}</th>
                                            <td>{routeServer.policy_modes.irrdb_policy}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.rpkiPrefixTagging')}</th>
                                            <td>{routeServer.prefix_validation.rpki_validation}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.irrdbPrefixTagging')}</th>
                                            <td>{routeServer.prefix_validation.irrdb_validation}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.maxPrefixIPv4')}</th>
                                            <td>{routeServer.max_prefix_ipv4}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('routeServerDetails.maxPrefixIPv6')}</th>
                                            <td>{routeServer.max_prefix_ipv6}</td>
                                        </tr>
                                    </tbody>
                                </DetailsTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ViewContainer>
        </>
    );
}

type RouteProps = RouteComponentProps<{ routeServerUuid: string }>;

interface ApiProps {
    routeServerRequest: ApiDataBinding<ApiResponse<RouteServer>>;
    refetchRouteServer: () => undefined;
    contract: Contract;
}

type WrapperProps = ApiProps & RouteProps;

function RouteServerDetailsWrapper(props: WrapperProps) {
    const { routeServerRequest, refetchRouteServer, contract } = props;
    const { t } = useTranslation();

    return (
        <RequestStatusRenderer
            request={routeServerRequest}
            loading={<PageLoading />}
            failedMessage={t('notification.unexpectedError')}
            success={() => <RouteServerDetails routeServer={routeServerRequest.data!.data!} refetchRouteServer={refetchRouteServer} contract={contract}/>}
        />
    );
}

const connectApiData = withApiData({ routeServerRequest: 'getRouteServer' }, (ownProps: RouteProps) => ({
    routeServerRequest: {
        routeServerUuid: ownProps.match.params.routeServerUuid
    },
}));

const mapStateToProps = (state: ReduxState, ownProps: {}) => ({
    contract: getHomeContractSelector(state),
});

const mapDispatchToProps = (dispatch: any, ownProps: WrapperProps) => ({
    refetchRouteServer: () => {
        const params = { routeServerUuid: ownProps.match.params.routeServerUuid };
        dispatch(invalidateApiDataRequest('getRouteServer', params));
        dispatch(performApiRequest('getRouteServer', params));
    },
});

const enhance = compose<WrapperProps, RouteProps>(connectApiData, connect(mapStateToProps, mapDispatchToProps));

export default enhance(RouteServerDetailsWrapper);


