import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { ApiDataRequest, getApiDataRequest, performApiRequest } from 'react-api-data';
import { compose } from 'recompose';
import get from 'lodash/get';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import { withTranslation, WithTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// constants
import { Exchanges, Location, IpRange } from 'constants/api';
import { ReduxState } from 'createStore';
import { defaultPaginationOptions } from 'constants/pagination';
import usePreselectExchange from 'hooks/usePreselectExchange';

// redux
import { updateAppPathAction } from 'redux/app/appActions';

// utils
import { sizePerPageRenderer } from 'utils/commonUtils';

// components
import { ExchangeSelect } from 'components/utils/ExchangeSelect';
import InteractiveTable from 'components/utils/InteractiveTable';
import RequestStatusRenderer from 'components/utils/RequestStatusRenderer';
import { getIpRangesTableColumns } from 'constants/columns/ipRangesTableColumns';

interface InProps {
    exchangesData: Exchanges;
}

interface ConnectProps {
    ipRangesRequest: ApiDataRequest;
    selectedExchange: string;
    searchTerm: string;
    selectExchange: (selectedExchange: string) => void;
    updateSearchTerm: (searchTerm?: string) => void;
    fetchIpRangesRequest: (selectedExchange: string) => boolean;
}

type ComponentProps = InProps & ConnectProps & WithTranslation;

interface IpRangesTableProps {
    ipRangesRequest: ApiDataRequest;
    locations: Location[];
    selectedExchange: string;
    searchTerm: string;
}

type TableData = Pick<IpRange,  'id' | 'value' | 'subnet_mask' | 'type' | 'description' | 'public' | 'exchange' | 'vlan'> & {
    exchange: string
}

const mapStateToProps = (state: ReduxState, ownProps: InProps) => ({
    selectedExchange: get(state, 'app.ipRanges.selectedExchange', ''),
    searchTerm: get(state, 'app.ipRanges.searchTerm', ''),
    ipRangesRequest: getApiDataRequest(state.apiData, 'getIpRanges', {
        exchange: get(state, 'app.ipRanges.selectedExchange', '')
    }),
});

const mapDispatchToProps = (dispatch: any, ownProps: InProps) => {
    return {
        fetchIpRangesRequest: (selectedExchange: string) => {
            if (!selectedExchange) return true;
            dispatch(performApiRequest('getIpRanges', {
                exchange: selectedExchange
            }));
            return true;
        },
        selectExchange: (selectedExchange: string) => {
            dispatch(updateAppPathAction('ipRanges.selectedExchange', selectedExchange));
        },
        updateSearchTerm: debounce((searchTerm: string = '') => {
            dispatch(updateAppPathAction('ipRanges.searchTerm', searchTerm));
        }, 500)
    };
};

const enhance = compose<ComponentProps, InProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
);

const filterSearchData = memoizeOne((data: TableData[], searchTerm: string): TableData[] => {
    if (!searchTerm) {
        return data;
    }
    return filter(data, (row: TableData) => {
        return (
            `${row.value} ${row.subnet_mask} ${row.type} ${row.description} ${row.public} ${row.vlan.number} ${row.vlan.description} ${row.exchange.short_name}`
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) !== -1
        );
    });
});

const IpRangesTable: FunctionComponent<IpRangesTableProps> = ({ ipRangesRequest, selectedExchange, searchTerm = '' }) => {
    const tableData = useMemo(() => {
        return ipRangesRequest.result.data.ip_ranges.map((ipR: IpRange) => {
            return {
                id: ipR.id,
                value: ipR.value,
                subnet_mask: ipR.subnet_mask,
                type: ipR.type,
                description: ipR.description,
                public: ipR.public,
                vlan: ipR.vlan,
                exchange: ipR.exchange
            }
        })
    }, [ipRangesRequest])

    const filteredTableData = filterSearchData(tableData, searchTerm);
    const columns = getIpRangesTableColumns(selectedExchange);

    return (
        <InteractiveTable
            data-test="ipRanges-interactive-table"
            classes="table-responsive-lg"
            keyField="id"
            columns={columns}
            data={filteredTableData}
            defaultSorted={[{
                dataField: 'type',
                order: 'asc',
            }]}
            paginationOptions={{
                ...defaultPaginationOptions,
                sizePerPageRenderer: sizePerPageRenderer('up'),
            }}
        />
    );
};

export const IpRangesList: FunctionComponent<ComponentProps> = ({
    exchangesData, fetchIpRangesRequest, ipRangesRequest,
    selectedExchange, selectExchange, t, searchTerm, updateSearchTerm
}) => {
    const exchange = useMemo(() => {
        return exchangesData.scopes.flatMap(scope => scope.exchanges)
            .find(exchange => exchange.short_name === selectedExchange);
    }, [exchangesData, selectedExchange]);
    const locations = exchange ? exchange.locations : [];

    const changeExchange = (exchangeValue: string) => {
        selectExchange(exchangeValue);
        fetchIpRangesRequest(exchangeValue);
    };

    const handleExchangeChange = (exchange: React.ChangeEvent<HTMLInputElement>) => {
        const exchangeValue = exchange.target.value;
        changeExchange(exchangeValue);
        updateSearchTerm(); // reset search term when Exchange had been changed
    };

    useEffect(() => {
        if (!!selectedExchange) {
            fetchIpRangesRequest(selectedExchange);
        }
    }, [fetchIpRangesRequest, selectedExchange]);

    usePreselectExchange(selectedExchange, exchangesData, changeExchange);

    return (
        <>
            <Card className="card-accent-primary">
                <CardHeader>
                    <Row className="justify-content-between">
                        <Col xl={3}>
                            <Input
                                data-test="ipRanges-term-control"
                                className="form-control"
                                placeholder={t('table.searchInputPlaceholder')}
                                defaultValue={searchTerm}
                                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                    updateSearchTerm(event.currentTarget.value);
                                }}
                            />
                        </Col>
                        <Col xl={3} className="text-right">
                            <ExchangeSelect
                                name="exchange"
                                defaultValue={selectedExchange}
                                exchanges={exchangesData}
                                onChange={handleExchangeChange}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <RequestStatusRenderer
                        request={ipRangesRequest}
                        failedMessage={t('ipRanges.noRanges')}
                        defaultMessage={t('validation.exchangeNotSelected')}
                        success={() => (
                            <IpRangesTable
                                ipRangesRequest={ipRangesRequest}
                                locations={locations}
                                selectedExchange={selectedExchange}
                                searchTerm={searchTerm}
                            />
                        )}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default enhance(IpRangesList);
